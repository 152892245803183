import React from "react"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { Box, Card, Divider, Grid, Typography } from "@mui/material"
import MuiBreadcrumbs from "@mui/material/Breadcrumbs"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone"
import HomeIcon from "@mui/icons-material/Home"
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone"

export const Breadcrumbs = ({
  card,
  divider = false,
  icon = true,
  icons = false,
  maxItems,
  navigation,
  rightAlign = true,
  separator = null,
  title = true,
  label = null,
  path = document.location.pathname,
  getText = (menuItem) => menuItem.name,
  titleBottom = "",
  ...others
}) => {
  const theme = useTheme()

  const iconStyle = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: "16px",
    height: "16px",
    color: theme.palette.primary.main
  }

  const [main, setMain] = useState()
  const [item, setItem] = useState()

  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.children) {
          getCollapse(collapse)
        } else if (path !== "/" && path === collapse.path) {
          setMain(menu)
          setItem(collapse)
        }
        return false
      })
    } else if (path !== "/" && path === menu.path) {
      setItem(menu)
    }
  }

  useEffect(() => {
    navigation?.forEach((menu) => {
      getCollapse(menu)
    })
  })

  // item separator
  const SeparatorIcon = separator
  const separatorIcon = separator ? <SeparatorIcon /> : <ChevronRightIcon />

  let mainContent
  let itemContent
  let breadcrumbContent = <Typography />
  let itemTitle = ""
  let CollapseIcon
  let ItemIcon

  if (main?.name) {
    CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon
    mainContent = (
      <Typography variant="subtitle2" sx={{ color: "grey.500" }}>
        {icons && <CollapseIcon style={iconStyle} />}
        {getText(main)}
      </Typography>
    )
  }

  if (item) {
    itemTitle = getText(item)

    ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon
    itemContent = (
      <Typography
        variant="subtitle2"
        sx={{
          display: "flex",
          textDecoration: "none",
          alignContent: "center",
          alignItems: "center",
          color: "grey.500"
        }}
      >
        {icons && <ItemIcon style={iconStyle} />}
        {itemTitle}
      </Typography>
    )

    breadcrumbContent = (
      <Card
        sx={{
          marginBottom: card === false ? 0 : theme.spacing(2),
          background:
            card === false ? "transparent" : theme.palette.background.default
        }}
        {...others}
      >
        <Box sx={{ py: 1.3125, px: card === false ? 0 : 2 }}>
          <Grid
            container
            direction={rightAlign ? "row" : "column"}
            justifyContent={rightAlign ? "space-between" : "flex-start"}
            alignItems={rightAlign ? "center" : "flex-start"}
            spacing={1}
          >
            {title && !titleBottom && (
              <Grid item>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {label ?? getText(item)}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <MuiBreadcrumbs
                sx={{
                  "& .MuiBreadcrumbs-separator": {
                    width: 16,
                    ml: 0.5,
                    mr: 1.5
                  }
                }}
                aria-label="breadcrumb"
                maxItems={maxItems || 8}
                separator={separatorIcon}
              >
                <Typography
                  to="/"
                  component={Link}
                  color="inherit"
                  variant="subtitle2"
                  sx={{
                    display: "flex",
                    color: "grey.900",
                    textDecoration: "none",
                    alignContent: "center",
                    alignItems: "center"
                  }}
                >
                  {icons && <HomeTwoToneIcon sx={iconStyle} />}
                  {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />}
                  {!icon && "Dashboard"}
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
            {title && titleBottom && (
              <Grid item>
                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                  {label ?? getText(item)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        {card === false && divider !== false && (
          <Divider sx={{ borderColor: theme.palette.primary.main, mb: 2 }} />
        )}
      </Card>
    )
  }

  return breadcrumbContent
}

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.array,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool
}
