import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import pick from "lodash/pick"

const prefix = "jass/overview/billing-addresses"
const LOAD_START = `${prefix}/LOADING_START`
const LOAD_SUCCESS = `${prefix}/LOADING_SUCCESS`
const LOAD_FAIL = `${prefix}/LOADING_FAIL`

const UPDATE_START = `${prefix}/UPDATE_START`
const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
const UPDATE_FAIL = `${prefix}/UPDATE_FAIL`

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) =>
    pick(action.result, ["billing_addresses", "sub_customers", "contacts"])
})

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const updating = createReducer(false, {
  [UPDATE_START]: () => true,
  [UPDATE_SUCCESS]: () => false,
  [UPDATE_FAIL]: () => false
})

export default combineReducers({
  items,
  loading,
  updating
})

export const load = (orderId) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`/overview/${orderId}/customer`)
})

export const updateBillingContact = (orderId, data) => ({
  types: [UPDATE_START, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) =>
    client.put(`/orders/${orderId}/update_billing_contact`, { data })
})

export const getBillingAddresses = (state) =>
  state.overview.billingAddresses.items
export const isLoading = (state) => state.overview.billingAddresses.loading
export const isUpdating = (state) => state.overview.billingAddresses.updating
