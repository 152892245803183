import client from "utils/client"

export const DEFAULT_BACKGROUND_COLOR = "#EDEDED"
export const DEFAULT_FONT_COLOR = "#434243"
export const DEFAULT_ICON_TEXT_LENGTH = 2

export const getIconUploadProps = () => {
  return {
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onUpload: (file) => {
      const formData = new FormData()
      formData.append("file", file)

      return client
        .post("/v2/icons.json", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then((response) => response.data)
    }
  }
}

export const defaultIconText = (name) => {
  const words = name.split(/[\s/-]+/)
  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase()
  }
  return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
}
