import React, { useMemo } from "react"
import { Box, Grid, Skeleton, Typography, useTheme } from "@mui/material"
import MainCard from "./MainCard"
import sortBy from "lodash/sortBy"
import { BorderlessLinearProgressWithLabel } from "shared/ui/berry-jass/Progress"
import times from "lodash/times"

const PercentageDataTable = ({
  title,
  data = [],
  loading,
  loadingSize = 2,
  blankContent,
  ...rest
}) => {
  const theme = useTheme()
  const sortedPercentageData = useMemo(
    () =>
      sortBy(
        data.map((d) => d.percentage),
        (d) => -d
      ),
    [data]
  )

  const base = sortedPercentageData?.[0] ?? 1

  const getColor = (value) => {
    if (value >= sortedPercentageData[0]) {
      return theme.palette.primary.main
    } else if (value >= sortedPercentageData[1]) {
      return "#373749"
    } else if (value >= sortedPercentageData[2]) {
      return "#5E6077"
    } else if (value >= sortedPercentageData[3]) {
      return "#7B7D92"
    }
    return "#999CAE"
  }

  return (
    <MainCard
      dividerPadding
      border={false}
      title={title}
      contentSX={{
        pt: 1,
        pb: "20px !important",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
      }}
      headerSX={{
        px: 2,
        pt: 2.5,
        pb: 1
      }}
      {...rest}
    >
      <Grid container spacing={1}>
        {loading &&
          times(loadingSize, (i) => {
            return (
              <Grid key={i} item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  columnSpacing={1}
                  rowSpacing={0}
                >
                  <Grid item xs zeroMinWidth>
                    <Skeleton width={70} />
                  </Grid>
                  <Grid item>
                    <Skeleton width={40} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton height={20} />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        {!loading &&
          data.map(({ percentage, name, total }, i) => {
            const scaledValue = (percentage / base) * 100
            return (
              // index is used as a key here to provide smooth animations on data change
              <Grid key={i} item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  columnSpacing={1}
                  rowSpacing={0}
                >
                  <Grid item xs zeroMinWidth>
                    <Typography variant="h6">{name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" align="right">
                      {total}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <BorderlessLinearProgressWithLabel
                      value={scaledValue}
                      label={`${percentage.toFixed()}%`}
                      color={getColor(percentage)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
      </Grid>
      {!loading && !data.length && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexGrow={1}
          minHeight={100}
        >
          <Typography variant="h2">{blankContent}</Typography>
        </Box>
      )}
    </MainCard>
  )
}

export default PercentageDataTable
