const prefix = "jass/CANDIDATES_BOARD/STAGE_TRANSITIONS"

const UPDATE_STAGE_START = `${prefix}/UPDATE_STAGE_START`
const UPDATE_STAGE_SUCCESS = `${prefix}/UPDATE_STAGE_SUCCESS`
const UPDATE_STAGE_FAIL = `${prefix}/UPDATE_STAGE_FAIL`
export const UPDATE = `${prefix}/UPDATE`

export const updateStage = ({ id, event }) => ({
  types: [UPDATE_STAGE_START, UPDATE_STAGE_SUCCESS, UPDATE_STAGE_FAIL],
  promise: (client) =>
    client.put(`candidates/${id}.json`, {
      data: { id, event }
    })
})

export const update = (payload, meta = {}) => ({
  type: UPDATE,
  payload,
  meta
})
