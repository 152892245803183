import React from "react"
import { Field } from "formik"
import { TextField } from "shared/ui/berry-jass-formik"

const DynamicField = ({ field, name }) => {
  return (
    <Field
      fullWidth
      required={field.is_mandatory}
      component={TextField}
      name={name}
      maxLength={field.length}
      label={field.name}
      validate={(value) => {
        let error
        if (field.is_mandatory && value?.length === 0) {
          error = "required"
        }
        if (value.length && value.length > field.length) {
          error = "too-long"
        }
        return error
      }}
    />
  )
}

export default DynamicField
