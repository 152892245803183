import Appsignal from "@appsignal/javascript"
import { plugin } from "@appsignal/plugin-window-events"

export const appsignal = new Appsignal({
  key: process.env.JASS_APPSIGNAL_CLIENT_TOKEN,
  revision: process.env.JASS_APPSIGNAL_REVISION
})

appsignal.use(
  plugin({
    onerror: true,
    onunhandledrejection: true
  })
)

export const addUserIdTag = (id) => {
  appsignal.addDecorator((span) => {
    return span.setTags({ user_id: id })
  })
}
