import CustomDesign from "components/AdEditor/CustomDesign"
import React from "react"
import { I18n } from "react-redux-i18n"

const Design = ({ index, onClick, selected = false }) => {
  return (
    <CustomDesign
      name={I18n.t("layout-number", { number: index })}
      onClick={onClick}
      selected={selected}
    />
  )
}
export default Design
