import { useMutation } from "@tanstack/react-query"

import client from "utils/client"

export const useIconGeneration = () => {
  return useMutation({
    mutationFn: async ({ values }) => {
      const response = await client.post("/v2/generated_icons.json", {
        icon: values
      })
      return response.data
    }
  })
}
