import React from "react"
import PropTypes from "prop-types"
import StyledMaterialUIAutocomplete from "components/Autocomplete/StyledMaterialUIAutocomplete"
import classNames from "classnames"

const Autocomplete = ({
  className,
  containerClassName,
  multiple,
  disabled,
  error,
  ...autocompleteProps
}) => {
  return (
    <div
      className={classNames(
        containerClassName,
        "jass-autocomplete",
        "new-jass-autocomplete",
        `${className}-container`,
        "fieldContainer",
        {
          "jass-multiple-autocomplete": multiple
        }
      )}
    >
      <StyledMaterialUIAutocomplete
        {...autocompleteProps}
        className={className}
        isDisabled={disabled}
        isMulti={multiple}
        error={!!error}
      />
    </div>
  )
}

Autocomplete.propTypes = {
  initialValue: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  showClearButton: PropTypes.bool,
  onInput: PropTypes.func,
  onChange: PropTypes.func.isRequired
}

Autocomplete.defaultProps = {
  dataSourceConfig: { value: "value", text: "text" },
  showClearButton: true,
  onInput: () => {}
}

export default Autocomplete
