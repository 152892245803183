import { Centrifuge } from "centrifuge"
import * as makeUrl from "utils/url"
import client from "utils/client"

const getToken = async (ctx) => {
  const res = await client.post("/centrifuge/connection_tokens.json", {
    data: ctx
  })

  return res.data.token
}

/*
 * Structure for `callbacks` property:
 *
 * { [channel-id]: { [subscriber-id]: callback(), ... }, ... }
 *
 * Example:
 *
 * { "orders.update": { "Partner/Shop": callback(), ... }, ... }
 */
let callbacks = {}

// ---- SETUP & TEADOWN API: ----

/** @type {Centrifuge} */
let centrifuge

export const setup = (user) => {
  if (!centrifuge) {
    centrifuge = new Centrifuge(makeUrl.socketUrl(), {
      token: user.web_socket_options.token,
      getToken
    })
  }
  callbacks = {}
  centrifuge.connect()
}

export const teardown = () => {
  callbacks = {}
  if (!centrifuge) {
    return
  }

  Object.entries(centrifuge.subscriptions()).forEach(([_channel, sub]) => {
    sub.unsubscribe()
    sub.removeAllListeners()
    centrifuge.removeSubscription(sub)
  })
  centrifuge.disconnect()
  centrifuge.removeAllListeners()
}

// ---- SUBSCRIBE & UNSUBSCRIBE API: ----

export const subscribe = async (channel, subscriber, callback) => {
  if (!centrifuge) {
    return
  }

  if (channel in callbacks) {
    if (!(subscriber in callbacks[channel])) {
      callbacks[channel][subscriber] = callback
    }
  } else {
    callbacks[channel] = { [subscriber]: callback }

    const sub = centrifuge.newSubscription(channel)
    sub.on("publication", (context) => {
      Object.entries(callbacks[channel]).forEach(([_subscriber, cb]) => {
        cb(context)
      })
    })
    sub.subscribe()
  }
}

export const unsubscribe = (channel, subscriber) => {
  if (channel in callbacks) {
    if (subscriber in callbacks[channel]) {
      delete callbacks[channel][subscriber]
    }
  }
}
