import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import { createSelector } from "reselect"
import deepmerge from "deepmerge"
import { getAgencyId } from "store/modules/common/auth"
import { getAddOnIds } from "utils/product"
import { flatten } from "lodash"
import ApiClient from "utils/ApiClient"

const SET_PARAMS = "jass/products/SET_PARAMS"
const LOAD_START = "jass/products/LOAD_START"
const LOAD_SUCCESS = "jass/products/LOAD_SUCCESS"
const LOAD_FAIL = "jass/products/LOAD_FAIL"
const LOAD_PRICES_START = "jass/products/prices/LOAD_START"
const LOAD_PRICES_SUCCESS = "jass/products/prices/LOAD_SUCCESS"
const LOAD_PRICES_FAIL = "jass/products/prices/LOAD_FAIL"
const LOAD_DYNAMIC_FIELDS_START = "jass/products/dynamicFields/LOAD_START"
const LOAD_DYNAMIC_FIELDS_SUCCESS = "jass/products/dynamicFields/LOAD_SUCCESS"
const LOAD_DYNAMIC_FIELDS_FAIL = "jass/products/dynamicFields/LOAD_FAIL"
const RESET_DYNAMIC_FIELDS = "jass/products/dynamicFields/RESET"
const LOAD_EXCEL_START = "jass/products/excel/LOAD_START"
const LOAD_EXCEL_SUCCESS = "jass/products/excel/LOAD_SUCCESS"
const LOAD_EXCEL_FAIL = "jass/products/excel/LOAD_FAIL"
const RESET_PRODUCT_LIST = "jass/products/RESET"
const UPDATE_PRODUCT = "jass/products/UPDATE_PRODUCT"
const LOAD_TOTAL_PRICE_START = "jass/product/for-partner/totalPrice/LOAD_START"
const LOAD_TOTAL_PRICE_SUCCESS =
  "jass/product/for-partner/totalPrice/LOAD_SUCCESS"
const LOAD_TOTAL_PRICE_FAIL = "jass/product/for-partner/totalPrice/LOAD_FAIL"
const SET_TOTAL_PRICE = "jass/products/for-partner/SET_TOTAL_PRICE"
const RESET_TOTAL_PRICES = "jass/products/for-partner/RESET_TOTAL_PRICES"

const REFRESH_START = "jass/products/REFRESH_START"
const REFRESH_SUCCESS = "jass/products/REFRESH_SUCCESS"
const REFRESH_ERROR = "jass/products/REFRESH_ERROR"

const UPDATE_COUNT = "jass/products/UPDATE_COUNT"

const client = new ApiClient()

export default combineReducers({
  dynamicFields: combineReducers({
    items: createReducer([], {
      [LOAD_DYNAMIC_FIELDS_SUCCESS]: (state, action) => {
        return action.result
      },
      [RESET_DYNAMIC_FIELDS]: () => []
    })
  }),
  excel: combineReducers({
    loading: createReducer(false, {
      [LOAD_EXCEL_START]: () => true,
      [LOAD_EXCEL_SUCCESS]: () => false,
      [LOAD_EXCEL_FAIL]: () => false
    })
  }),
  total: createReducer(
    {},
    {
      [LOAD_TOTAL_PRICE_SUCCESS]: (state, action) => {
        return action.result
      },
      [SET_TOTAL_PRICE]: (state, action) => {
        return {
          ...state,
          price: action.price
        }
      },
      [RESET_TOTAL_PRICES]: (state) => {
        return {
          ...state,
          price: 0,
          customer_price: 0,
          budget_products_ids: []
        }
      }
    }
  ),
  list: combineReducers({
    loading: createReducer(false, {
      [LOAD_START]: () => true,
      [LOAD_SUCCESS]: () => false,
      [LOAD_FAIL]: () => false,
      [LOAD_PRICES_START]: () => true,
      [LOAD_PRICES_SUCCESS]: () => false,
      [LOAD_PRICES_FAIL]: () => false,
      [REFRESH_START]: () => true,
      [REFRESH_SUCCESS]: () => false,
      [REFRESH_ERROR]: () => false
    }),
    items: createReducer([], {
      [REFRESH_SUCCESS]: (state, action) => {
        return action.result
      },
      [LOAD_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...state, ...action.result]
        }

        return action.result.items
      },
      [LOAD_PRICES_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...state, ...action.result]
        }

        return action.result.items
      },
      [RESET_PRODUCT_LIST]: () => [],
      [UPDATE_PRODUCT]: (state, action) => {
        return state.map((product) =>
          product.id === action.product.data.id ? action.product.data : product
        )
      }
    }),
    count: createReducer(0, {
      [LOAD_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state,
      [LOAD_PRICES_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state,
      [RESET_PRODUCT_LIST]: () => 0,
      [UPDATE_COUNT]: (_, action) => action.payload
    }),
    params: createReducer(
      { page: 1 },
      {
        [LOAD_SUCCESS]: (_, action) => action.params,
        [LOAD_PRICES_SUCCESS]: (_, action) => action.params,
        [SET_PARAMS]: (_, action) => action.params,
        [RESET_PRODUCT_LIST]: () => ({ page: 1 })
      }
    )
  })
})

export const load = (data) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.getHybrid("web_shop_prices", data),
    params: data
  }
}

export const refreshPrices = (data) => {
  return (dispatch, getState) => {
    const params = getState().product.list.params
    const page = params.page ?? 1

    dispatch({ type: REFRESH_START })

    const requests = [
      client
        // @ts-expect-error
        .get("web_shop_prices/count.json", {
          params: data.params
        })
        .sendRequest()
    ]
    for (let i = 0; i < page; i++) {
      requests.push(
        client
          .getPages("web_shop_prices", {
            ...data,
            params: { ...data.params, page: i + 1 },
            from: i
          })
          .sendRequest()
      )
    }

    return Promise.all(requests)
      .then(([count, ...responses]) => {
        const response = flatten(responses)
        dispatch({ type: UPDATE_COUNT, payload: count })
        dispatch({ type: REFRESH_SUCCESS, result: response })

        return response
      })
      .catch(() => dispatch({ type: REFRESH_ERROR }))
  }
}

export const filterBy = (filter) => {
  return (dispatch, getState) => {
    const params = getState().product.list.params
    dispatch(
      load({
        params: deepmerge(params, { page: 1, additional_search: filter })
      })
    )
  }
}

export const loadDefault = ({ title, customerId }) => {
  return (dispatch, getState) => {
    const agencyId = getAgencyId(getState())

    return dispatch(
      load({
        params: {
          additional_search: {
            affiliate_type: "JapCustomer",
            affiliate_id: customerId,
            agency_id: agencyId
          },
          q: { without_add_ons: true },
          single_package_price: true,
          vacancy_title: title,
          recommendations: true,
          without_agency_product: true,
          affiliate_products: true,
          prices_page: false,
          page: 1
        }
      })
    )
  }
}

export const loadDynamicFields = ({ params = {}, products }) => {
  return {
    types: [
      LOAD_DYNAMIC_FIELDS_START,
      LOAD_DYNAMIC_FIELDS_SUCCESS,
      LOAD_DYNAMIC_FIELDS_FAIL
    ],
    promise: (client) =>
      client.get("sales_products/dynamic_fields.json", {
        params: {
          ...params,
          sales_product_ids: products
        }
      })
  }
}

export const resetDynamicFields = () => {
  return { type: RESET_DYNAMIC_FIELDS }
}

export const loadTotalPrice = ({
  params,
  ids,
  quantities = {},
  discounts = {},
  addOns = {},
  additionalSearch
}) => {
  return {
    types: [
      LOAD_TOTAL_PRICE_START,
      LOAD_TOTAL_PRICE_SUCCESS,
      LOAD_TOTAL_PRICE_FAIL
    ],
    promise: (client) =>
      client.get("web_shop_prices/get_package_price.json", {
        params: {
          ...params,
          ids,
          quantities,
          discounts,
          add_on_ids: getAddOnIds(addOns),
          additional_search: additionalSearch
        }
      })
  }
}

export function setTotalPrice(price) {
  return {
    type: SET_TOTAL_PRICE,
    price
  }
}

export function resetTotalPrices() {
  return {
    type: RESET_TOTAL_PRICES
  }
}

export function resetProductList() {
  return { type: RESET_PRODUCT_LIST }
}

export const setParams = ({ params }) => ({ type: SET_PARAMS, params })

export const getData = createSelector(
  (state, ownProps) => ownProps.data,
  (state) => state.product.list.items,
  (data, products) => data || products
)

export const getSort = createSelector(
  (state) => state.product.list.params.sort,
  (state) => state.product.list.params.ascending,
  (sort, ascending) => ({
    columnKey: sort,
    ascending: ascending
  })
)

export const getTotalCount = createSelector(
  (state, ownProps) => ownProps?.data,
  (state) => state.product.list.count,
  (data, productsCount) => (data ? data.length : productsCount)
)
