import React, { useState } from "react"
import PropTypes from "prop-types"
import Card from "components/Card/Card"

const StatefulCard = ({ defaultExpanded = false, ...props }) => {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const [panels, setPanels] = useState({})

  const handleToggleExpand = async (id) => {
    // if toggle button clicked then ID
    // of the card comes in the params
    // otherwise ID of a filter
    if (id === props.id) {
      setExpanded(!expanded)
    } else {
      setPanels({
        ...panels,
        [id]: panels[id] === undefined ? false : !panels[id]
      })
    }
  }

  return (
    <Card
      {...props}
      expanded={expanded}
      onToggleExpand={handleToggleExpand}
      panels={panels}
    />
  )
}

StatefulCard.propTypes = {
  defaultExpanded: PropTypes.bool,
  id: PropTypes.string.isRequired
}

StatefulCard.defaultProps = {
  defaultExpanded: false
}

// Need this for correct React errors about incorrect
// types of passed props that simplifies debugging
StatefulCard.displayName = "Card"

export default StatefulCard
