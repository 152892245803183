import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const LOAD_START = "jass/affiliateProduct/byId/LOAD_START"
const LOAD_SUCCESS = "jass/affiliateProduct/byId/LOAD_SUCCESS"
const LOAD_FAIL = "jass/affiliateProduct/byId/LOAD_FAIL"
const RESET_DATA = "jass/affiliateProduct/reset-data"

const SAVE_START = "jass/affiliateProduct/save/START"
const SAVE_SUCCESS = "jass/affiliateProduct/save/SUCCESS"
const SAVE_FAIL = "jass/affiliateProduct/save/FAIL"

const REMOVE_START = "jass/affiliateProduct/remove/START"
const REMOVE_SUCCESS = "jass/affiliateProduct/remove/SUCCESS"
const REMOVE_FAIL = "jass/affiliateProduct/remove/FAIL"

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const data = createReducer(
  {},
  {
    [LOAD_SUCCESS]: (state, action) => action.result.affiliate_product || {},
    [RESET_DATA]: () => ({})
  }
)

export default combineReducers({
  affiliateProduct: combineReducers({ loading, data })
})

export const load = ({ id }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`affiliate_products/${id}.json`)
  }
}

export const save = ({ data: values }) => {
  return {
    types: [SAVE_START, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client) => {
      if (values.id) {
        return client.put(`affiliate_products/${values.id}`, {
          attachments: { file: values.supplier_logo },
          params: { affiliate_product: values }
        })
      }
      return client.post("affiliate_products", {
        attachments: { file: values.supplier_logo },
        params: { affiliate_product: values }
      })
    }
  }
}

export const remove = ({ id }) => {
  return {
    types: [REMOVE_START, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: (client) => client.del(`affiliate_products/${id}`)
  }
}

export const resetAffiliateProductData = () => {
  return {
    type: RESET_DATA
  }
}
