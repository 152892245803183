import React from "react"
import MuiCheckbox from "@mui/material/Checkbox"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"
import isBoolean from "lodash/isBoolean"

const useEnabledStyles = makeStyles((theme) => ({
  label: {
    "&:before": {
      borderRight: `2px solid ${theme.palette.primary.main} !important`,
      borderBottom: `2px solid ${theme.palette.primary.main} !important`
    }
  }
}))

const Checkbox = (props) => {
  const enabledClasses = useEnabledStyles()
  if (isBoolean(props.isChecked) && __DEVELOPMENT__) {
    // eslint-disable-next-line no-console
    console.warn(`Unexpected value for Checkbox: ${props.isChecked}`)
  }
  return (
    <MuiCheckbox
      icon={<label />}
      checkedIcon={
        <label
          className={classNames({ [enabledClasses.label]: !props.disabled })}
        />
      }
      {...props}
    />
  )
}

export default Checkbox
