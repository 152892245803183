import React from "react"
import numeral from "numeral"
import isNil from "lodash/isNil"

const Price = ({ value, format = "0,0.00 $", blankValue = null }) => {
  return (
    <>
      {blankValue && isNil(value) ? blankValue : numeral(value).format(format)}
    </>
  )
}

export default Price
