const LOAD_IMPORTED_VACANCY_START =
  "jass/importedVacancy/LOAD_IMPORTED_VACANCY_START"
const LOAD_IMPORTED_VACANCY_SUCCESS =
  "jass/importedVacancy/LOAD_IMPORTED_VACANCY_SUCCESS"
const LOAD_IMPORTED_VACANCY_FAILURE =
  "jass/importedVacancy/LOAD_IMPORTED_VACANCY_FAILURE"

const byId = (state = {}, action) => {
  switch (action.type) {
    case LOAD_IMPORTED_VACANCY_SUCCESS:
      return { ...state, [action.result.id]: action.result }
    default:
      return state
  }
}

export const loadVacancy = (id) => {
  return {
    types: [
      LOAD_IMPORTED_VACANCY_START,
      LOAD_IMPORTED_VACANCY_SUCCESS,
      LOAD_IMPORTED_VACANCY_FAILURE
    ],
    promise: (client) => client.get(`imported_vacancies/${id}`),
    id
  }
}

export default byId
