import React, { memo } from "react"
import { Translate } from "react-redux-i18n"
import { useRouteMatch, useHistory } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Box
} from "@mui/material"

import { localizedDateTime } from "shared/lib/format"
import { ActionsCell } from "shared/ui/berry-jass"
import { getAdEditorUrl } from "../lib"

export const FilesTable = memo(
  ({
    orderId,
    useOrderFiles,
    tableContainerSx = { width: "calc(100vw - 180px)" }
  }) => {
    const { data, isLoading } = useOrderFiles(orderId)
    const { url: currentUrl } = useRouteMatch()
    const history = useHistory()

    const showActions = (file) => {
      if (file.seeded_file) return false

      return file.file_type === "ad" && file.file_name?.endsWith(".zip")
    }

    const getActions = (file) => {
      const url = getAdEditorUrl(file, currentUrl, orderId)

      const actions = [
        {
          label: <Translate value="edit" />,
          onClick: () => {
            history.push(url)
          }
        }
      ]

      return actions
    }

    if (isLoading) {
      return (
        <Box
          sx={{
            maxWidth: "95vw",
            height: 57,
            display: "grid",
            placeItems: "center"
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )
    }

    return (
      <TableContainer
        data-testid="order-files-table"
        sx={{
          height: 282,
          ...tableContainerSx
        }}
      >
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell size="small" sx={{ width: "30%" }}>
                <Translate value="web-shop.job-orders-table.nested-files-table.filename" />
              </TableCell>
              <TableCell size="small" sx={{ width: "30%" }}>
                <Translate value="web-shop.job-orders-table.nested-files-table.product" />
              </TableCell>
              <TableCell size="small" sx={{ width: "20%" }}>
                <Translate value="web-shop.job-orders-table.nested-files-table.updated-by" />
              </TableCell>
              <TableCell size="small" sx={{ width: "15%" }}>
                <Translate value="web-shop.job-orders-table.nested-files-table.date" />
              </TableCell>
              <TableCell size="small" sx={{ width: "5%" }} />
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              ".MuiTableRow-root:last-child .MuiTableCell-root": {
                border: "none"
              }
            }}
          >
            {data.map((file, index) => {
              return (
                <TableRow
                  hover
                  key={file.id}
                  data-row-id={file.id}
                  data-index={index}
                >
                  <TableCell sx={{ width: "30%" }}>
                    <Tooltip title={file.file_name}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {file.file_name}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: "30%" }}>
                    <Tooltip title={file.sales_product_name}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {file.sales_product_name ?? "—"}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    <Tooltip title={file.last_updater_name}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {file.last_updater_name ?? "—"}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: "15%" }}>
                    <Typography variant="subtitle2">
                      {localizedDateTime(file.last_updated_at) ?? "—"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "5%" }}>
                    {showActions(file) && (
                      <ActionsCell
                        id={`${file.id}_actions`}
                        actions={getActions(file)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)
