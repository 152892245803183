import { createReducer } from "store/helpers/reducers"

const OPEN_EDITOR = "jass/adhoc_ad/editor/OPEN_EDITOR"
const CLOSE_EDITOR = "jass/adhoc_ad/editor/CLOSE_EDITOR"
const SET_EDITOR_LOCK = "jass/adhoc_ad/editor/SET_EDITOR_LOCK"
const SET_SHOW_HIDDEN = "jass/adhoc_ad/editor/SET_SHOW_HIDDEN"
const SET_REQUEST_ID = "jass/adhoc_ad/editor/SET_REQUEST_ID"

const initialState = {
  mode: null,
  group: null,
  locked: false,
  info: {},
  config: null,
  showHidden: false,
  requestId: null
}

const reducer = createReducer(initialState, {
  [OPEN_EDITOR]: (state, action) => ({
    ...state,
    ...action.payload,
    locked: false
  }),
  [SET_EDITOR_LOCK]: (state, action) => ({ ...state, locked: action.payload }),
  [CLOSE_EDITOR]: (state) => ({
    ...state,
    mode: null,
    group: null,
    locked: false,
    info: {},
    config: null
  }),
  [SET_SHOW_HIDDEN]: (state, action) => ({
    ...state,
    showHidden: action.payload
  }),
  [SET_REQUEST_ID]: (state, action) => ({
    ...state,
    requestId: action.payload
  })
})

export const openEditor = (payload) => ({ type: OPEN_EDITOR, payload })
export const closeEditor = () => ({ type: CLOSE_EDITOR })

/** @param {boolean} payload */
export const setEditorLock = (payload) => ({ type: SET_EDITOR_LOCK, payload })

/** @param {boolean} payload */
export const setShowHidden = (payload) => ({ type: SET_SHOW_HIDDEN, payload })

export const setRequestId = (payload) => ({ type: SET_REQUEST_ID, payload })

export default reducer

export const getEditorMode = (state) => state.adhocAd.editor.mode
export const getEditorGroup = (state) => state.adhocAd.editor.group
export const getEditorInfo = (state) => state.adhocAd.editor.info ?? {}
export const getEditorConfig = (state) => state.adhocAd.editor.config ?? null

/** @returns {boolean} */
export const isEditorLocked = (state) => state.adhocAd.editor.locked

/** @returns {boolean} */
export const getShowHidden = (state) => state.adhocAd.editor.showHidden
