import makeStyles from "@mui/styles/makeStyles"
import Button from "@mui/material/Button"
import React from "react"
import get from "lodash/get"
import { I18n } from "react-redux-i18n"
import Box from "@mui/material/Box"
import Icon from "@mui/material/Icon"
import InputComponent from "components/FormControls/Input/Input"
import { useDebouncedCallback } from "use-debounce"

const useButtonStyles = makeStyles({
  root: {
    minWidth: "initial",
    width: 25,
    height: 25,
    margin: 0,
    marginTop: 4
  },
  containedSizeSmall: { padding: "1px" }
})

const StyledButton = (props) => {
  const classes = useButtonStyles()
  return <Button classes={classes} {...props} />
}

const useInputStyles = makeStyles({
  input: {
    textAlign: "center"
  }
})

const NumberInput = ({
  field,
  form,
  disabled,
  min = -Infinity,
  max = Infinity,
  step = 1,
  onChange = null,
  ...props
}) => {
  const [handleChange] = useDebouncedCallback(onChange, 500)
  const inputClasses = useInputStyles()
  const value = parseFloat(field.value)
  const error = get(form.touched, field.name) && get(form.errors, field.name)
  let errorText = null
  if (error) {
    if (error instanceof Object) {
      errorText = I18n.t(`errors.${error[Object.keys(error)[0]]}`)
    } else {
      errorText = I18n.t(`errors.${error}`)
    }
  }

  return (
    <Box display="flex">
      <StyledButton
        size="small"
        variant="contained"
        disabled={disabled || min > value - step}
        title="-"
        onClick={() => {
          if (min <= value - step) {
            field.onChange(field.name)(value - step)
            onChange && handleChange(value - step)
          }
        }}
      >
        <Icon>remove</Icon>
      </StyledButton>
      <InputComponent
        noCollectableText
        InputProps={{
          classes: {
            ...(props.InputProps && props.InputProps.classes),
            ...inputClasses
          }
        }}
        {...field}
        {...props}
        helperText={errorText}
        error={error}
        step={step}
        disabled={disabled}
        onChange={(value) => {
          field.onChange(field.name)(value)
          onChange && handleChange(value)
        }}
      />

      <StyledButton
        size="small"
        variant="contained"
        disabled={disabled || max < value + step}
        title="+"
        onClick={() => {
          max >= value + step && field.onChange(field.name)(value + step)
          onChange && handleChange(value + step)
        }}
      >
        <Icon>add</Icon>
      </StyledButton>
    </Box>
  )
}

export default NumberInput
