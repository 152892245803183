import React from "react"
import { Grid } from "@mui/material"
import { connect } from "formik"
import DynamicField from "features/order-create/ui/DynamicField"

export const SpecificDynamicFields = connect(({ formik, fields }) => {
  return (
    <Grid container rowSpacing={2}>
      {fields.map((field) => {
        const index =
          formik.values.orders_products_df_values_attributes.findIndex(
            (df) => df.df_id === field.id
          )
        if (index === -1) {
          return null
        }

        return (
          <Grid item md={12} key={field.id}>
            <DynamicField
              name={`orders_products_df_values_attributes.${index}.value`}
              field={field}
            />
          </Grid>
        )
      })}
    </Grid>
  )
})
