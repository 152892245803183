import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const prefix = "jass/overview/orderDetails"
const LOAD_ORDER_START = `${prefix}/LOAD_ORDER_START`
const LOAD_ORDER_SUCCESS = `${prefix}/LOAD_ORDER_SUCCESS`
const LOAD_ORDER_FAIL = `${prefix}/LOAD_ORDER_FAIL`
import { UPDATE_SUCCESS } from "store/modules/entities/order"

const initialByIds = {}
const byId = createReducer(initialByIds, {
  [LOAD_ORDER_SUCCESS]: (state, action) => {
    const order = action.result
    return {
      ...state,
      [order.id.toString()]: { ...order, id: order.id.toString() }
    }
  },
  [UPDATE_SUCCESS]: (state, action) => {
    const { order } = action.payload
    return {
      ...state,
      [order.id.toString()]: { ...state[order.id], ...order }
    }
  }
})
export const loadOrder = (id) => ({
  types: [LOAD_ORDER_START, LOAD_ORDER_SUCCESS, LOAD_ORDER_FAIL],
  promise: (client) => client.get(`/overview/${id}`)
})

export default combineReducers({
  byId
})
