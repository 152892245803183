import React from "react"
import { Helmet } from "react-helmet-async"
import classNames from "classnames"
import { PageTitle } from "components"
import BodyClassName from "components/Page/BodyClassName"
import { Box } from "@mui/material"

/**
 *
 * @param {object} props
 * @param {string} [props.bodyClassName]
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {import('react').ReactNode} props.children
 * @returns
 */
const Page = ({ bodyClassName, className, children, title }) => (
  <Box className="content-wrap">
    <div className="page-container">
      <BodyClassName className={classNames(bodyClassName, "guest-page")} />
      <Helmet title={title} />
      {title && <PageTitle title={title} />}

      <div className={classNames(["page", className])}>{children}</div>
    </div>
  </Box>
)

export default Page
