import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const prefix = "jass/overview/files/"
const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`

const CREATE = `${prefix}/CREATE`

const DELETE_START = `${prefix}/DELETE_START`
const DELETE_SUCCESS = `${prefix}/DELETE_SUCCESS`
const DELETE_FAIL = `${prefix}/DELETE_FAIL`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result,
  [CREATE]: (state, action) => {
    return [action.payload, ...state]
  },
  [DELETE_SUCCESS]: (state, action) => {
    return state.filter((file) => file.id !== action.payload)
  }
})

export default combineReducers({ loading, items })

export const load = (orderId) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`/overview/${orderId}/files`)
})

export const create = (payload) => ({
  type: CREATE,
  payload
})

export const remove = (model) => ({
  types: [DELETE_START, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`${model.download_path}/${model.id}`),
  payload: model.id
})

export const isLoading = (state) => state.overview.assets.files.loading

export const getItems = (state) => state.overview.assets.files.items
