import React, { useCallback } from "react"
import { Stack, Typography } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime"

import { Field } from "formik"
import { CheckboxGroup } from "shared/ui/berry-jass-formik/CheckboxGroup"
import { SupplierIcon } from "entities/supplier"
import { Translate } from "react-redux-i18n"

const runtimeIconSx = { fontSize: 16 }

export const AddOnCell = ({
  onChange,
  onBeforeChange,
  disabled,
  productId,
  product
}) => {
  const handleChange = useCallback(
    (checked) => onChange(checked, product),
    [onChange, product]
  )

  const handleBeforeChange = useCallback(
    (checked) => onBeforeChange(checked, product),
    [onBeforeChange, product]
  )

  return (
    <Stack flexDirection="row" alignItems="center" gap={2}>
      <Field
        disabled={disabled}
        component={CheckboxGroup}
        name={`addOns[${productId}]`}
        onChange={handleChange}
        onBeforeChange={handleBeforeChange}
        value={product.id}
      />
      <SupplierIcon iconUrl={product.icon_url} />
      <Stack sx={{ flexGrow: 1 }}>
        <Typography variant="h6">{product.supplier}</Typography>
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
          <AccessTimeIcon sx={runtimeIconSx} />
          <Typography variant="subtitle2">
            <Translate value="plurals.days" count={product.runtime_in_days} />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
