import deepmerge from "deepmerge"

export const combineMerge = (target, source, options) => {
  const destination = target.slice()

  source.forEach((item, index) => {
    if (options.isMergeableObject(item)) {
      destination[index] = deepmerge(target[index], item, options)
    } else {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
    }
  })
  return destination
}
