import React, { Component } from "react"
import { connect } from "react-redux"
import { markAsRead } from "store/modules/components/notifications/common"
import { decreaseNotificationBadge } from "store/modules/common/auth"

class Notification extends Component {
  constructor(props) {
    super(props)
    this.markAsRead = this.markAsRead.bind(this)
  }

  markAsRead() {
    const {
      notification: { is_read: isRead, id } = { is_read: false, id: null },
      markAsRead,
      decreaseNotificationBadge
    } = this.props

    if (!isRead) {
      markAsRead({ notificationId: id })
      decreaseNotificationBadge()
    }
  }

  render() {
    const {
      notification: { is_read: isRead, topic, created_at: createdAt, text }
    } = this.props
    const className = isRead ? "read" : "non-read"

    return (
      <div className={`notification ${className}`} onClick={this.markAsRead}>
        <div className="clearfix first-row">
          <span className="topic">{topic}</span>
          <span className="created-at">{createdAt}</span>
        </div>

        <div
          className="read-only-wysiwyg-content"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    )
  }
}

export default connect(() => ({}), {
  markAsRead,
  decreaseNotificationBadge
})(Notification)
