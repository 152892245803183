import makeStyles from "@mui/styles/makeStyles"

const useIconStyles = makeStyles({
  title: {
    fontSize: 9,
    marginTop: 6,
    width: 64,
    height: 18
  }
})

export default useIconStyles
