import { getIn } from "formik"
import * as React from "react"
import { DatePicker as JBDatePicker } from "shared/ui/berry-jass"
import { getErrorText } from "./helpers"

const createErrorHandler = (fieldError, fieldName, setFieldError) => {
  return (error) => {
    if (error !== fieldError && error !== "") {
      setFieldError(fieldName, error ? String(error) : undefined)
    }
  }
}

function fieldToDesktopDatePicker({
  field: { onChange: _onChange, ...field },
  form: {
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    setFieldTouched
  },
  textField: { helperText, onBlur, ...textField } = {},
  disabled,
  label,
  onChange,
  onError,
  renderInput,
  ...props
}) {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    textField: {
      error: showError,
      label: label,
      helperText: showError ? getErrorText(fieldError) : helperText,
      onBlur: () => {
        if (onBlur) {
          onBlur()
        }

        setFieldTouched(field.name, true, true)
      },
      ...textField
    },
    disabled: disabled ?? isSubmitting,
    onChange: function handleChange(date) {
      // Do not switch this order, otherwise you might cause a race condition
      // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
      if (onChange) {
        onChange(date)
      }
      setFieldTouched(field.name, true, false)
      setFieldValue(field.name, date, true)
    },
    onError:
      onError ?? createErrorHandler(fieldError, field.name, setFieldError),
    ...field,
    ...props
  }
}

export function DatePicker({ children, ...props }) {
  return (
    <JBDatePicker {...fieldToDesktopDatePicker(props)}>{children}</JBDatePicker>
  )
}

DatePicker.displayName = "FormikMUIDesktopDatePicker"
