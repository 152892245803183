import React, { Component } from "react"
import { I18n, Translate } from "react-redux-i18n"
import { connect } from "react-redux"
import Button from "@mui/material/Button"
import { on, off } from "utils/dom"
import Notification from "./Notification"
import { clearNotificationBadge } from "store/modules/common/auth"
import {
  markAllAsRead as notificationMarkAllAsRead,
  clearRead as clearReadNotifications
} from "store/modules/components/notifications/common"
import { load as loadAllNotifications } from "store/modules/components/notifications/all"
import { load as loadUnreadNotifications } from "store/modules/components/notifications/unread"
import { TitleSpinner } from "components"
import takeRight from "lodash/takeRight"
import sumBy from "lodash/sumBy"
import size from "lodash/size"
import map from "lodash/map"

const pageSize = 35

const defaultLoadParams = {
  offset: 0
}

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.markAllAsReadHandler = this.markAllAsReadHandler.bind(this)
    this.toggleAllNotifications = this.toggleAllNotifications.bind(this)
    this.scrollListHandler = this.scrollListHandler.bind(this)
    this.loadNotifications = this.loadNotifications.bind(this)
    this.state = {
      viewAll: false
    }
  }

  componentDidMount() {
    this.loadNotifications(defaultLoadParams)
    on("scroll", this.list, this.scrollListHandler)
  }

  componentWillUnmount() {
    off("scroll", this.list)
    this.props.clearReadNotifications()
  }

  loadNotifications({ offset = 0, more = false } = {}) {
    const { all, unread, loadAllNotifications, loadUnreadNotifications } =
      this.props
    const { viewAll } = this.state
    const newParams = {
      offset,
      limit: pageSize,
      more
    }

    if (viewAll) {
      loadAllNotifications({
        params: {
          ...all.params,
          ...newParams
        }
      })
    } else {
      loadUnreadNotifications({
        params: {
          ...unread.params,
          ...newParams
        }
      })
    }
  }

  toggleAllNotifications() {
    const { viewAll } = this.state

    this.setState(
      {
        viewAll: !viewAll
      },
      () => {
        this.loadNotifications(defaultLoadParams)
      }
    )
  }

  markAllAsReadHandler() {
    this.props.notificationMarkAllAsRead()
    this.props.clearNotificationBadge()
  }

  scrollListHandler() {
    const { unread, all, loading } = this.props
    const { viewAll } = this.state
    const { hasMore, params: { pageSize = 35 } = {} } = viewAll ? all : unread
    const $block = $(this.list)
    const scrollTop = $block.scrollTop()
    const $items = $(".notification", $block)
    const height = sumBy($items, (el) => $(el).height())
    const last8ItemsHeight = sumBy(takeRight($items, 8), (el) => $(el).height())

    if (loading || !hasMore || height - scrollTop > last8ItemsHeight) return

    this.loadNotifications({
      offset: size($items) + pageSize,
      more: true
    })
  }

  render() {
    const { unread, all, loading } = this.props
    const { viewAll } = this.state
    const isUnreadEmpty = !size(unread.items)
    const notifications = viewAll ? all.items : unread.items
    const isEmpty = !size(notifications)

    return (
      <div className="notifications card-panel">
        <div className="notification-list" ref={(list) => (this.list = list)}>
          {map(notifications, (notification) => (
            <Notification key={notification.id} notification={notification} />
          ))}

          {loading && (
            <div className="center">
              <TitleSpinner />
            </div>
          )}

          {!loading && isEmpty && (
            <div className="no-notifications">
              {I18n.t("app.notifications.empty")}
            </div>
          )}
        </div>

        <div className="media-footer">
          <Button
            color="primary"
            variant="text"
            onClick={this.markAllAsReadHandler}
            disabled={isUnreadEmpty}
            className="mark-all-as-read"
          >
            <Translate value="app.notifications.mark-all-as-read" />
          </Button>
          <Button
            variant="text"
            color={viewAll ? "primary" : "secondary"}
            onClick={this.toggleAllNotifications}
            className="show-all"
          >
            <Translate value="app.notifications.view-all" />
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    loading:
      state.components.notifications.all.loading ||
      state.components.notifications.unread.loading,
    all: state.components.notifications.all,
    unread: state.components.notifications.unread
  }),
  {
    notificationMarkAllAsRead,
    clearReadNotifications,
    loadAllNotifications,
    loadUnreadNotifications,
    clearNotificationBadge
  }
)(Notifications)
