const LOAD_START = "jass/statistics/LOAD_START"
const LOAD_SUCCESS = "jass/statistics/LOAD_SUCCESS"
const LOAD_FAILURE = "jass/statistics/LOAD_FAILURE"

const totalData = (state = [], action) => {
  switch (action.type) {
    case LOAD_SUCCESS:
      return action.result || []
    default:
      return state
  }
}

export const load = (id) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAILURE],
    promise: (client) => client.get(`orders/${id}/ad_tracking_chart_data.json`)
  }
}

export default totalData
