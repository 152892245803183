// TODO: implement fetching default Agency Manager from Back-End

import get from "lodash/get"
import reduce from "lodash/reduce"
import set from "lodash/set"
import size from "lodash/size"
import snakeCase from "lodash/snakeCase"

const defaultAgencyManagers = [
  {
    agency_manager: {
      company_name: "",
      id: 7,
      name: "Andreas Burike"
    }
  }
]

export function prepareUser(user) {
  const isAgency = checkUserForRole(user, "agency")
  const isPartner = checkUserForRole(user, "partner")

  if ((isAgency || isPartner) && !size(get(user, "agency.agencies_managers"))) {
    set(user, "agency.agencies_managers", defaultAgencyManagers)
  }

  return {
    ...user,
    isBurike: user.is_burike,
    isAdmin: checkUserForRole(user, "admin"),
    isSalesAgent: checkUserForRole(user, "sales_agent"),
    isSalesDirector: checkUserForRole(user, "sales_director"),
    isAgencyManager: checkUserForRole(user, "agency_manager"),
    isAgency,
    isAgencyAccountMember:
      checkUserForRole(user, "account_member") &&
      get(user, "is_account_owner_agency", false),
    isAgencyAccountAdmin:
      checkUserForRole(user, "account_admin") &&
      get(user, "is_account_owner_agency", false),
    isController: checkUserForRole(user, "controller"),
    isEndCustomerAccountMember:
      checkUserForRole(user, "account_member") &&
      get(user, "is_account_owner_end_customer", false),
    isEndCustomerAccountAdmin:
      checkUserForRole(user, "account_admin") &&
      get(user, "is_account_owner_end_customer", false),
    isEndCustomer: checkUserForRole(user, "end_customer"),
    isPartner,
    isPartnerAccountMember:
      checkUserForRole(user, "account_member") &&
      get(user, "is_account_owner_partner", false),
    isPartnerAccountAdmin:
      checkUserForRole(user, "account_admin") &&
      get(user, "is_account_owner_partner", false),
    isTeamLead: checkUserForRole(user, "team_lead"),
    isSecretary: checkUserForRole(user, "secretary"),
    isProducer: checkUserForRole(user, "producer"),
    isProductManager: checkUserForRole(user, "product_manager"),
    isPartnerManager: checkUserForRole(user, "partner_manager"),
    isApiUser: checkUserForRole(user, "api_user"),
    isPartnerCustomerManager: checkUserForRole(
      user,
      "partner_customer_manager"
    ),
    isPartnerCustomerPricesManager: checkUserForRole(
      user,
      "partner_customer_prices_manager"
    ),
    isCustomerManager: checkUserForRole(user, "customer_manager"),
    isAuthorizedOfficer: checkUserForRole(user, "authorized_officer"),
    isAccountant: checkUserForRole(user, "accountant")
  }
}

function checkUserForRole(loadedUser, roleName) {
  return reduce(
    loadedUser.roles,
    (result, role) => role.name === snakeCase(roleName) || result,
    false
  )
}
