import merge from "lodash/merge"
import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const prefix = "jass/TRACKING_DATA"

const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`
const LOAD_PRODUCTS_START = `${prefix}/LOAD_PRODUCTS_START`
const LOAD_PRODUCTS_SUCCESS = `${prefix}/LOAD_PRODUCTS_SUCCESS`
const LOAD_PRODUCTS_FAIL = `${prefix}/LOAD_PRODUCTS_FAIL`
const SET_PARAMS = `${prefix}/SET_PARAMS`
const EXPORT_TRACKING_DATA_START = `${prefix}/EXPORT_TRACKING_DATA_START`
const EXPORT_TRACKING_DATA_SUCCESS = `${prefix}/EXPORT_TRACKING_DATA_SUCCESS`
const EXPORT_TRACKING_DATA_FAIL = `${prefix}/EXPORT_TRACKING_DATA_FAIL`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const ids = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }
    return action.result.ids
  }
})

const initialByIds = {}
const byId = createReducer(initialByIds, {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return { ...state, ...action.result.byId }
    }
    return action.result.byId
  }
})
const initialProductsById = []
const productsById = createReducer(initialProductsById, {
  [LOAD_PRODUCTS_SUCCESS]: (state, action) => {
    return action.result
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) => action.result.count || state
})

const initialParams = { page: 1, additional_search: {} }
const params = createReducer(initialParams, {
  [LOAD_SUCCESS]: (state, action) => action.params,
  [SET_PARAMS]: (state, action) => merge({}, state, action.params)
})

export default combineReducers({
  ids,
  byId,
  count,
  params,
  loading,
  productsById
})

export const load = (options) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.getNormalizedPagedResponse("tracking_data", options)
  }
}

export const loadProducts = (id) => {
  return {
    types: [LOAD_PRODUCTS_START, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAIL],
    promise: (client) => client.get(`tracking_data/${id}`),
    id
  }
}

export const exportTrackingData = (data) => {
  return {
    types: [
      EXPORT_TRACKING_DATA_START,
      EXPORT_TRACKING_DATA_SUCCESS,
      EXPORT_TRACKING_DATA_FAIL
    ],
    promise: (client) => client.get("tracking_data/export", data)
  }
}

export const getProducts = (state) => state.trackingData.productsById
