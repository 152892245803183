import get from "lodash/get"
import pickBy from "lodash/pickBy"
import extend from "lodash/extend"
import map from "lodash/map"
import moment from "moment"
import { extractAutocompleteValues } from "utils/parsers/autocompletes"
import { isNotEmpty } from "store/helpers/reducers"
import pick from "lodash/pick"
import { getAddOnIds } from "utils/product"

const getAdEditorValues = (data) =>
  get(data, "activeTab") === 2 &&
  extractAutocompleteValues(get(data, "adEditor"), {
    single: ["logo_alignment"]
  })

const getCustomDesignAdValues = (data) =>
  data.activeTab === 3 ? data.customDesignAdValues : null

export function prepareParams(params) {
  const sort = get(params, "sort", "").replace(
    /^created_at$/,
    "orders.created_at"
  )
  const search_property = get(
    params,
    "search",
    get(params, "additional_search.search_property", "")
  )
  const agency_id = get(
    params,
    "agency_id",
    get(params, "additional_search.agency_id")
  )
  const customer_id = get(
    params,
    "customer_id",
    get(params, "additional_search.customer_id")
  )
  const order_customer_id = get(
    params,
    "order_customer_id",
    get(params, "additional_search.order_customer_id")
  )
  const manager_id = get(
    params,
    "manager_id",
    get(params, "additional_search.manager_id")
  )
  const creator_id = get(
    params,
    "creator_id",
    get(params, "additional_search.creator_id")
  )

  const search_by_customer = get(
    params,
    "search_by_customer",
    get(params, "additional_search.search_by_customer")
  )
  const product_id = get(
    params,
    "product_id",
    get(params, "additional_search.product_id")
  )
  const switch_orders = get(
    params,
    "switch_orders",
    get(params, "additional_search.switch_orders")
  )
  const created_start_date = get(
    params,
    "created_start_date",
    get(params, "additional_search.created_start_date")
  )
  const created_end_date = get(
    params,
    "created_end_date",
    get(params, "additional_search.created_end_date")
  )
  const customer_company_name = get(
    params,
    "customer_company_name",
    get(params, "additional_search.customer_company_name")
  )
  const key_accounts_ids = get(params, "additional_search.key_accounts_ids")
  const type = get(params, "type", "")
  return pickBy(
    {
      page: get(params, "page", 1),
      sort,
      type,
      ascending: get(params, "ascending"),
      additional_search: pickBy(
        {
          search_property,
          agency_id,
          customer_id,
          manager_id,
          creator_id,
          search_by_customer,
          product_id,
          order_customer_id,
          switch_orders,
          created_start_date,
          created_end_date,
          customer_company_name,
          key_accounts_ids,
          type
        },
        isNotEmpty
      ),
      filters: get(params, "filters")
    },
    isNotEmpty
  )
}

const prepareLocations = (locations) => {
  return locations.map((location) => ({
    city: get(location, "city.city", "").split("|")[0].trim(),
    zip: get(location, "zip.zip_code", ""),
    street: location.street,
    street_number: location.street_number,
    country: location.country
  }))
}

export const dataPreparators = {
  any: (data) => {
    const publishingDate = data.publishDate
      ? data.publishDate.format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
    return pickBy(
      {
        product_ids: data.selectedProducts,
        budget_offer: !!get(data, "budgetProductsIds")?.filter((value) =>
          data.selectedProducts.includes(value)
        )?.length,
        slot_offer: !!get(data, "markedProducts")?.filter(
          (value) => value.slot_product
        )?.length,
        ad_hoc_product_ids: data.adHocProductIds,
        add_on_ids: pick(getAddOnIds(data.addOns), data.products),
        ad_hoc_products: get(data, "adHocProducts", []).map((value) =>
          extractAutocompleteValues(value, { single: ["country"] })
        ),
        reference_number: get(data, "referenceNumber", ""),
        dynamic_fields: extend({}, get(data, "dynamicFields.products", "")),
        orders_products_df_values_attributes:
          data.orders_products_df_values_attributes,
        incomplete_offer_id: get(data, "incomplete_offer", ""),
        imported_vacancy_id: get(data, "imported_vacancy", ""),
        contract_type_id: get(data, "contract_type_id"),
        field_of_work_id: get(data, "field_of_work_id"),
        seniority_id: get(data, "seniority_id"),
        apply_url: get(data, "apply_url"),
        job_type_ids: get(data, "job_type_ids", []),
        industry_id: get(data, "industry_id"),
        keywords: get(data, "keywords"),
        salary_maximum: data.salary_maximum,
        salary_minimum: data.salary_minimum,
        salary_currency: data.salary_currency,
        salary_interval: data.salary_interval?.value,
        locations: prepareLocations(get(data, "locations", [])),
        product_fields: data.product_fields,
        base_info: {
          title: get(data, "title", ""),
          description: get(data, "description", ""),
          application_url: get(data, "applyLink", ""),
          city: get(data, "city", ""),
          country: get(data, "country", ""),
          agency_customer_id: get(data, "customer", ""),
          order_link: get(data, "adLink", ""),
          agency_id: get(data, "agency", ""),
          contact_id: get(data, "contact", ""),
          billing_address_id: get(data, "billingAddress", ""),
          order_text_id: get(data, "adFile.order_text.id", ""),
          offer_price: get(data, "offerPrice"),
          remote_work: get(data, "remote_work"),
          publishing_date: publishingDate
        },
        reference_order_texts_ids: map(
          get(data, "reference_order_texts_ids", []),
          "order_text.id"
        ),
        ad_editor: getAdEditorValues(data),
        custom_design_ad_values: getCustomDesignAdValues(data),
        sales_products_texts_ids: get(data, "productsTextsIds"),
        sales_products_texts_links: get(data, "productsLinks"),
        sections: get(data, "sections"),
        promotion_ids: data.promotionIds,
        itemprops_extraction_id: data.itemprops_extraction_id
      },
      isNotEmpty
    )
  },
  partner: (data) => {
    const publishingDate = data.publishDate
      ? moment(data.publishDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
    return pickBy(
      {
        add_on_ids: pick(getAddOnIds(data.addOns), data.products),
        external_product_ids: get(data, "selectedExternalProducts", ""),
        product_ids: get(data, "selectedProducts", ""),
        reference_number: get(data, "referenceNumber", ""),
        dynamic_fields: extend({}, get(data, "dynamicFields.products", "")),
        orders_products_df_values_attributes:
          data.orders_products_df_values_attributes,
        source_offer_id: data.source_offer_id,
        contact_id: get(data, "contact", ""),
        billing_address_id: get(data, "billingAddress", ""),
        contract_type_id: get(data, "contract_type_id"),
        field_of_work_id: get(data, "field_of_work_id"),
        seniority_id: get(data, "seniority_id"),
        apply_url: get(data, "apply_url"),
        job_type_ids: data?.job_type_ids || [],
        industry_id: get(data, "industry_id"),
        keywords: get(data, "keywords"),
        salary_maximum: data.salary_maximum,
        salary_minimum: data.salary_minimum,
        salary_currency: data.salary_currency,
        salary_interval: data.salary_interval?.value,
        locations: prepareLocations(get(data, "locations", [])),
        product_fields: data.product_fields,
        budget_offer: !!get(data, "budgetProductsIds")?.filter((value) =>
          data.selectedProducts.includes(value)
        )?.length,
        slot_offer: !!get(data, "markedProducts")?.filter(
          (value) => value.slot_product
        )?.length,
        partner_customer_contact: {
          contact_first_name: get(data, "contactFirstName", ""),
          contact_last_name: get(data, "contactLastName", ""),
          contact_salutation: get(data, "contactSalutation", ""),
          contact_phone: get(data, "contactPhone", ""),
          contact_email: get(data, "contactEmail", ""),
          contact_title: get(data, "contactTitle", ""),
          contact_company: get(data, "contactCompany", ""),
          contact_country: get(data, "contactCountry", ""),
          contact_city: get(data, "contactCity", ""),
          contact_street: get(data, "contactStreet", ""),
          contact_street_number: get(data, "contactStreetNumber", ""),
          contact_zip: get(data, "contactZip", "")
        },
        base_info: {
          title: get(data, "title", ""),
          description: get(data, "description", ""),
          application_url: get(data, "applyLink", ""),
          city: get(data, "city", ""),
          country: get(data, "country", ""),
          customer_id: get(data, "customer", ""),
          order_link: get(data, "adLink", ""),
          order_text_id: get(data, "adFile.order_text.id", ""),
          offer_price: get(data, "offerPrice"),
          publishing_date: publishingDate,
          remote_work: get(data, "remote_work.id")
        },
        reference_order_texts_ids: map(
          get(data, "reference_order_texts_ids", []),
          "order_text.id"
        ),
        ad_editor: getAdEditorValues(data),
        sales_products_texts_ids: get(data, "productsTextsIds"),
        sales_products_texts_links: get(data, "productsLinks"),
        sections: get(data, "sections")
      },
      isNotEmpty
    )
  },

  endCustomer: (data) => {
    const publishingDate = data.publishDate
      ? moment(data.publishDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
    return pickBy(
      {
        product_ids: get(data, "selectedProducts", ""),
        add_on_ids: pick(getAddOnIds(data.addOns), data.products),
        dynamic_fields: extend({}, get(data, "dynamicFields.products", "")),
        orders_products_df_values_attributes:
          data.orders_products_df_values_attributes,
        source_offer_id: data.source_offer_id,
        reference_number: get(data, "referenceNumber", ""),
        contact_id: get(data, "contact", ""),
        billing_address_id: get(data, "billingAddress", ""),
        imported_vacancy_id: get(data, "imported_vacancy", ""),
        contract_type_id: get(data, "contract_type_id"),
        field_of_work_id: get(data, "field_of_work_id"),
        seniority_id: get(data, "seniority_id"),
        apply_url: get(data, "apply_url"),
        job_type_ids: get(data, "job_type_ids", []),
        industry_id: get(data, "industry_id"),
        salary_maximum: data.salary_maximum,
        salary_minimum: data.salary_minimum,
        salary_currency: data.salary_currency,
        salary_interval: data.salary_interval?.value,
        keywords: get(data, "keywords"),
        locations: prepareLocations(get(data, "locations", [])),
        product_fields: data.product_fields,
        budget_offer: !!get(data, "budgetProductsIds")?.filter((value) =>
          data.selectedProducts.includes(value)
        )?.length,
        slot_offer: !!get(data, "markedProducts")?.filter(
          (value) => value.slot_product
        )?.length,
        partner_customer_contact: {
          contact_first_name: get(data, "contactFirstName", ""),
          contact_last_name: get(data, "contactLastName", ""),
          contact_salutation: get(data, "contactSalutation", ""),
          contact_phone: get(data, "contactPhone", ""),
          contact_email: get(data, "contactEmail", ""),
          contact_title: get(data, "contactTitle", "")
        },
        base_info: {
          title: get(data, "title", ""),
          description: get(data, "description", ""),
          application_url: get(data, "applyLink", ""),
          city: get(data, "city", ""),
          country: get(data, "country", ""),
          customer_id: get(data, "customer", ""),
          order_link: get(data, "adLink", ""),
          order_text_id: get(data, "adFile.order_text.id", ""),
          imported_vacancy_id: get(data, "imported_vacancy", ""),
          publishing_date: publishingDate,
          offer_price: get(data, "offerPrice"),
          remote_work: get(data, "remote_work")
        },
        reference_order_texts_ids: map(
          get(data, "reference_order_texts_ids", []),
          "order_text.id"
        ),
        ad_editor: getAdEditorValues(data),
        custom_design_ad_values: getCustomDesignAdValues(data),
        sales_products_texts_ids: get(data, "productsTextsIds"),
        sales_products_texts_links: get(data, "productsLinks"),
        sections: get(data, "sections")
      },
      isNotEmpty
    )
  }
}
