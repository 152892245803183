import React from "react"
import FlagIcon from "./FlagIcon"
import { Box } from "@mui/material"

export const CountryIconCell = ({ value }) => {
  return (
    <Box display="flex" gap={1}>
      <FlagIcon key={value} countryCode={value} />
    </Box>
  )
}
