export const createGradientFade = (image) => {
  if (image) {
    return `background: linear-gradient( 180deg, rgba(239, 239, 239, 0.3) 300px, rgba(239, 239, 239, 1) 800px ) fixed, url(${image}) no-repeat top center fixed;`
  }

  return ""
}

export const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"))
  const context = canvas.getContext("2d")
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}
