import { combineReducers } from "redux"

import vendors from "./vendors"
import common from "./common"
import entities from "./entities"
import components from "./components"

export default combineReducers({
  ...vendors,
  ...common,
  ...entities,
  components
})
