import React from "react"
import MuiSwitch from "@mui/material/Switch"

/**
 * Taken with adjustments from: https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Checkbox.tsx
 */
function fieldToSwitch({
  disabled,
  field: { onBlur: fieldOnBlur },
  form: { isSubmitting },
  form,
  field,
  type,
  onBlur,
  ...props
}) {
  return {
    disabled: disabled ?? isSubmitting,
    checked: field.value,
    onBlur:
      onBlur ??
      function onBlur(e) {
        fieldOnBlur(e ?? field.name)
      },
    ...field,
    ...props
  }
}

export function Switch(props) {
  return <MuiSwitch {...fieldToSwitch(props)} />
}

Switch.displayName = "FormikMaterialUISwitch"
