import { default as contact } from "./contact"
import { default as customer } from "./customer"
import { default as order } from "./order"
import { default as incompleteOffer } from "./incompleteOffer"
import { default as offer } from "./offer"
import { default as product } from "./product"
import { default as user } from "./user"
import { default as history } from "./history"
import { default as country } from "./country"
import { default as billingAddress } from "./billingAddress"
import { default as keyAccount } from "./keyAccount"
import adhocAd from "./adhocAd"
import webShopOffersPositions from "./webShopOffersPositions"
import statistics from "./statistics"
import orders from "./orders"
import trackOrders from "./trackOrders"
import importedVacancies from "./importedVacancies"
import trackingData from "./trackingData"
import vacancies from "./vacancies"
import candidates from "./candidates"
import pricesMatrix from "./pricesMatrix"
import affiliateProduct from "./affiliateProducts"
import adEditorTemplates from "./adEditorTemplates"
import overview from "./overview"
import candidatesBoard from "./candidatesBoard"
import customerImports from "./customerImports"
import admin from "./admin"
import offerCommissions from "./offerCommissions"
import agencyProduct from "./agencyProduct"
import subscriptionProduct from "./subscriptionProduct"
import campaigns from "./campaigns"
import invoices from "./invoices"
import salesProducts from "./salesProducts"

export default {
  adEditorTemplates,
  adhocAd,
  admin,
  affiliateProduct,
  agencyProduct,
  billingAddress,
  candidates,
  candidatesBoard,
  contact,
  country,
  customer,
  customerImports,
  history,
  importedVacancies,
  incompleteOffer,
  keyAccount,
  offer,
  offerCommissions,
  order,
  orders,
  overview,
  pricesMatrix,
  product,
  statistics,
  subscriptionProduct,
  trackOrders,
  trackingData,
  user,
  vacancies,
  webShopOffersPositions,
  campaigns,
  invoices,
  salesProducts
}
