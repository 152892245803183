import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/country/LOAD_START"
const LOAD_SUCCESS = "jass/country/LOAD_SUCCESS"
const LOAD_FAIL = "jass/country/LOAD_FAIL"

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result
})

export default combineReducers({ items })

export const load = () => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get("countries.json")
  }
}

export const getCountries = (state) => state.country.items
