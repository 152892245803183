import merge from "lodash/merge"
import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import { dataPreparators } from "./helpers"
import { createGetNormalizedData } from "store/helpers/selectors"

const prefix = "jass/VACANCIES"

const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`
const SET_PARAMS = `${prefix}/SET_PARAMS`
const REMOVE_START = `${prefix}/REMOVE_START`
const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`
const REMOVE_FAIL = `${prefix}/REMOVE_FAIL`
const CHANGE_STATE_START = `${prefix}/CHANGE_STATE_START`
const CHANGE_STATE_SUCCESS = `${prefix}/CHANGE_STATE_SUCCESS`
const CHANGE_STATE_FAIL = `${prefix}/CHANGE_STATE_FAIL`
const CREATE_START = `${prefix}/CREATE_START`
const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
const CREATE_FAIL = `${prefix}/CREATE_FAIL`
const GET_START = `${prefix}/GET_START`
const GET_SUCCESS = `${prefix}/GET_SUCCESS`
const GET_FAIL = `${prefix}/GET_FAIL`
const UPDATE_START = `${prefix}/UPDATE_START`
const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
const UPDATE_FAIL = `${prefix}/UPDATE_FAIL`
const RESET_VACANCY = `${prefix}/RESET_VACANCY`
const GENERATE_TOKEN_START = `${prefix}/GENERATE_TOKEN_START`
const GENERATE_TOKEN_SUCCESS = `${prefix}/GENERATE_TOKEN_SUCCESS`
const GENERATE_TOKEN_FAIL = `${prefix}/GENERATE_TOKEN_FAIL`
const LOAD_PRODUCTS_START = `${prefix}/LOAD_PRODUCTS_START`
const LOAD_PRODUCTS_SUCCESS = `${prefix}/LOAD_PRODUCTS_SUCCESS`
const LOAD_PRODUCTS_FAIL = `${prefix}/LOAD_PRODUCTS_FAIL`
const UPDATE = `${prefix}/UPDATE`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false,
  [REMOVE_START]: () => true,
  [REMOVE_SUCCESS]: () => false,
  [REMOVE_FAIL]: () => false,
  [GET_START]: () => true,
  [GET_SUCCESS]: () => false,
  [GET_FAIL]: () => false,
  [GENERATE_TOKEN_START]: () => true,
  [GENERATE_TOKEN_SUCCESS]: () => false,
  [GENERATE_TOKEN_FAIL]: () => false
})

const ids = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }
    return action.result.ids
  },
  [REMOVE_SUCCESS]: (state, action) =>
    state.filter((id) => id !== action.payload)
})

const initialByIds = {}
const byId = createReducer(initialByIds, {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return { ...state, ...action.result.byId }
    }
    return action.result.byId
  },
  [UPDATE]: (state, action) => {
    return { ...state, [action.payload.id]: action.payload }
  }
})

const edit = createReducer(
  {},
  {
    [GET_SUCCESS]: (state, action) => {
      return action.result
    },
    [RESET_VACANCY]: () => {
      return {}
    }
  }
)

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) => action.result.count || state
})

const initialParams = { additional_search: {} }
const params = createReducer(initialParams, {
  [LOAD_SUCCESS]: (state, action) => action.params,
  [SET_PARAMS]: (state, action) => merge({}, state, action.params)
})

const generated_token = createReducer(null, {
  [GENERATE_TOKEN_SUCCESS]: (state, action) => action.result.token
})

const initialProductsById = []
const productsById = createReducer(initialProductsById, {
  [LOAD_PRODUCTS_SUCCESS]: (state, action) => {
    return action.result
  }
})

export default combineReducers({
  ids,
  byId,
  count,
  params,
  loading,
  edit,
  generated_token,
  productsById
})

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.getNormalizedPagedResponse("vacancies", options)
})

export const setParams = (params) => ({ type: SET_PARAMS, params })

export const setSearchParams = (params) => {
  return {
    type: SET_PARAMS,
    params: { additional_search: params }
  }
}

export const remove = (id) => ({
  types: [REMOVE_START, REMOVE_SUCCESS, REMOVE_FAIL],
  promise: (client) => client.del(`vacancies/${id}`),
  payload: id
})

export const changeState = (id, state) => {
  return {
    types: [CHANGE_STATE_START, CHANGE_STATE_SUCCESS, CHANGE_STATE_FAIL],
    promise: (client) =>
      client.put(`/vacancies/${id}.json`, { data: { state: state } })
  }
}

export const update = (id, { data }) => {
  const preparedData = dataPreparators(data)
  return {
    types: [UPDATE_START, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) =>
      client.put(`/vacancies/${id}.json`, {
        data: preparedData
      })
  }
}

export const create = ({ data }) => {
  return {
    types: [CREATE_START, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client) =>
      client.post("vacancies", { data: dataPreparators(data) })
  }
}

export const loadById = (id) => ({
  types: [GET_START, GET_SUCCESS, GET_FAIL],
  promise: (client) => client.get(`vacancies/${id}.json`)
})

export const resetVacancy = () => ({
  type: RESET_VACANCY
})

export const generateToken = () => ({
  types: [GENERATE_TOKEN_START, GENERATE_TOKEN_SUCCESS, GENERATE_TOKEN_FAIL],
  promise: (client) => client.get("vacancies/free_token.json")
})

export const loadProducts = (id) => {
  return {
    types: [LOAD_PRODUCTS_START, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAIL],
    promise: (client) => client.get(`vacancies/${id}/booked_products`),
    id
  }
}

export const updateVacancy = (payload) => ({
  type: UPDATE,
  payload
})

export const getProducts = (state) => state.vacancies.productsById

export const getVacancies = createGetNormalizedData("vacancies")
