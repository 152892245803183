import React from "react"
import Input from "./Input"

const renderInput = ({ input, label, type, meta, onChange, ...rest }) => (
  <Input
    {...meta}
    {...input}
    {...rest}
    onChangeCustom={onChange}
    label={label}
    type={type}
  />
)

export default renderInput
