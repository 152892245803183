import React from "react"
import { I18n } from "react-redux-i18n"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { IconButton } from "@mui/material"

/**
 * @param {object} props
 * @param {import("@tanstack/react-table").Row} props.row
 */
export const ExpandCell = ({ row }) => {
  const expanded = row.getIsExpanded()

  return (
    <IconButton
      size="small"
      onClick={() => row.toggleExpanded()}
      aria-expanded={expanded}
      title={expanded ? I18n.t("collapse") : I18n.t("expand")}
    >
      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  )
}
