import React, { useCallback } from "react"
import Autocomplete from "components/TouchDesign/Components/Autocomplete"
import usePlacesAutocomplete from "use-places-autocomplete"
import { useMemo } from "react"

// GooglePlaces API returns old city names together with new ones.
// For example Hannover -> Hanover.
// This list is here to remove old names from the API response.
export const DENYLIST = ["Hannover"]

const GooglePlacesAutocomplete = ({
  mapResponseToDataSource = (result) => result,
  params = null,
  ...rest
}) => {
  const {
    ready,
    setValue,
    suggestions: { data }
  } = usePlacesAutocomplete({
    requestOptions: params,
    debounce: 1000
  })

  const handleOpen = useCallback(() => setValue(""), [setValue])

  const handleInputChange = useCallback(
    (e, searchText, reason) => {
      if (rest.onInputChange) {
        rest.onInputChange(e, searchText, reason)
      }

      if (reason === "input" && searchText?.length > 2) {
        setValue(searchText)
      }
    },
    [rest, setValue]
  )

  const getOptionLabel = useCallback((option) => option.name, [])

  const options = useMemo(
    () =>
      mapResponseToDataSource(
        data.filter((c) => !DENYLIST.includes(c.description))
      ),
    [data, mapResponseToDataSource]
  )

  return (
    <Autocomplete
      loading={!ready}
      options={options}
      onOpen={handleOpen}
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      onChange={rest.onChange}
      {...rest}
    />
  )
}

export default GooglePlacesAutocomplete
