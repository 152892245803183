import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const LOAD_START = "jass/key-account/byId/START"
const LOAD_SUCCESS = "jass/key-account/byId/SUCCESS"
const LOAD_FAIL = "jass/key-account/byId/FAIL"
const SAVE_START = "jass/key-account/save/START"
const SAVE_SUCCESS = "jass/key-account/save/SUCCESS"
const SAVE_FAIL = "jass/key-account/save/FAIL"
const REMOVE_START = "jass/key-account/remove/START"
const REMOVE_SUCCESS = "jass/key-account/remove/SUCCESS"
const REMOVE_FAIL = "jass/key-account/remove/FAIL"
const CLEAR = "jass/key-account/clear"

const data = createReducer(null, {
  [LOAD_SUCCESS]: (state, action) => action.result.key_account || {},
  [CLEAR]: () => ({})
})

export default combineReducers({
  keyAccount: combineReducers({ data })
})

export const load = ({ id }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`key_accounts/${id}.json`)
  }
}

export const save = ({ data }) => {
  return {
    types: [SAVE_START, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client) => {
      if (data.id) {
        return client.put(`key_accounts/${data.id}`, {
          data: { key_account: data }
        })
      }
      return client.post("key_accounts", { data: { key_account: data } })
    }
  }
}

export const remove = ({ id }) => {
  return {
    types: [REMOVE_START, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: (client) => client.del(`key_accounts/${id}`)
  }
}

export const clearKeyAccount = () => {
  return {
    type: CLEAR
  }
}
