import { createTheme, alpha } from "@mui/material/styles"
import merge from "deepmerge"
import withStyles from "@mui/styles/withStyles"
import { useSelector } from "react-redux"
import { isValidHexColor } from "shared"

export const orange = "#EC6708"

const black = "#000"
const white = "#fff"
const opaqueBlack = "rgba(0, 0, 0, 0.6)"

const isTests = !!(process.env.TESTS && JSON.parse(process.env.TESTS))

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const coreTheme = {
  palette: {
    primary: { main: orange, light: orange, dark: orange },
    secondary: { main: black, light: white, dark: black },
    grey: {
      300: orange,
      A100: "rgba(236,103,8, 0.6)"
    },
    text: {
      primary: black,
      secondary: opaqueBlack
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.3)"
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontSize: 22,
      lineHeight: "32px",
      fontWeight: 400
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: "1.077rem"
        },
        text: {
          color: orange
        },
        containedSecondary: {
          backgroundColor: white,
          color: black,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          height: 44,
          color: black,
          "&.Mui-selected": {
            color: white,
            backgroundColor: orange,
            "&:hover": {
              backgroundColor: alpha(orange, 0.75)
            }
          },
          "&:hover": {
            backgroundColor: alpha(black, 0.05)
          },
          "&:disabled": {
            color: alpha(black, 0.25),
            backgroundColor: alpha(black, 0.05)
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          marginTop: 12,
          marginBottom: 24,
          padding: 0
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: { fontSize: 14 }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        text: { fontSize: 12 }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          outline: "none"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowX: "hidden"
        },
        scrollPaper: {
          alignItems: "flex-start"
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "24px 32px 12px 32px"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "30px 32px 12px 32px",
          "& h2": {
            fontSize: 38,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline"
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: "0 32px 24px 32px",
          alignItems: "baseline"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before":
            {
              borderBottom: "1px solid rgb(224, 224, 224)"
            },
          "&:before": {
            borderBottom: "1px solid rgb(224, 224, 224)"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          transform: "translate(0, 24px) scale(1)"
        },
        shrink: {
          transform: "translate(0, 2px) scale(1)"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: "auto",
          lineHeight: "1em"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          marginTop: 4
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 14
        },
        colorSecondary: {
          color: "rgba(0, 0, 0, 0.6)",
          "&:hover": {
            backgroundColor: "rgb(206, 206, 206)"
          },
          "&:active": {
            backgroundColor: "rgb(206, 206, 206)"
          },
          backgroundColor: "rgb(224, 224, 224)"
        },
        clickableColorSecondary: {
          "&:hover": {
            backgroundColor: "rgb(206, 206, 206)"
          },
          "&:active": {
            backgroundColor: "rgb(206, 206, 206)"
          }
        },
        avatarColorSecondary: {
          backgroundColor: "rgb(188, 188, 188) !important",
          color: "white"
        },
        deleteIconColorSecondary: {
          color: "rgba(0, 0, 0, 0.26)",
          "&:hover": {
            color: "rgba(0, 0, 0, 0.4)"
          },
          "&:active": {
            color: "rgba(0, 0, 0, 0.4)"
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "10px 16px 10px 16px",
          borderBottom: "1px solid rgb(222, 225, 236)"
        },
        title: {
          fontSize: "15.6px"
        },
        action: {
          marginTop: -4
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 8
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          marginRight: 5,
          marginLeft: 5
        },
        secondary: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            color: "rgba(0, 0, 0, 0.6)"
          },
          backgroundColor: "white",
          color: "rgba(0, 0, 0, 0.6)"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: 14,
          color: "inherit",
          lineHeight: "inherit",
          letterSpacing: "inherit"
        },
        subtitle1: {
          fontSize: 15.6,
          color: "inherit",
          lineHeight: "inherit",
          letterSpacing: "inherit"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "11.2px"
        },
        body: {
          fontSize: "12.6px"
        }
      }
    },
    MuiSpeedDial: {
      styleOverrides: {
        directionUp: {
          paddingLeft: 5
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "1rem"
        }
      }
    },
    // https://mui.com/x/react-date-pickers/getting-started/#typescript
    // @ts-ignore
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          color: white,
          backgroundColor: orange,
          "& > .MuiTypography-root": {
            visibility: "hidden"
          }
        }
      }
    },
    // https://mui.com/x/react-date-pickers/getting-started/#typescript
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "1.1rem"
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: "0.9rem"
          }
        }
      }
    }
  },
  ...(isTests && { transitions: { create: () => "none" } })
}

/**
 *
 * @param {import('@mui/material').ThemeOptions} config
 * @returns
 */
const getTheme = (config) => {
  return createTheme(merge(coreTheme, config))
}

export default getTheme

export const colorStyles = (theme) => ({
  color: {
    color: `${theme.palette.primary.main} !important`,
    "&:hover": {
      color: `${theme.palette.primary.main} !important`
    }
  }
})
export const backgroundColorStyles = (theme) => ({
  backgroundColor: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
})
export const brandLogoStyles = () => ({
  brandLogo: {
    position: "absolute",
    color: "#fff !important",
    display: "inline-block",
    fontSize: "1.5em !important",
    width: "auto",
    overflow: "hidden",
    height: "100%",
    padding: "20px 18px 18px 0px",
    whiteSpace: "nowrap",
    "& img": {
      width: "165px",
      verticalAlign: "top"
    }
  },
  customerLogo: {
    padding: "6px",
    "& img": {
      height: "42px",
      width: "unset"
    }
  }
})

export const combineStyles =
  (...styles) =>
  (theme) => {
    const outStyles = styles.map((arg) => {
      if (typeof arg === "function") {
        return arg(theme)
      }
      return arg
    })

    return outStyles.reduce((acc, val) => Object.assign(acc, val))
  }

export const withColor = withStyles(colorStyles)
export const withBackgroundColor = withStyles(backgroundColorStyles)

export const useCustomerPrimaryColor = () => {
  const userColor = useSelector(
    (state) => state.auth?.user?.jap_customer?.color_code
  )

  if (isValidHexColor(userColor)) {
    return userColor
  }

  return null
}
