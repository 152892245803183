import React from "react"
import { IconButton, CircularProgress } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"

export const ExportButton = ({ isFetching, refetch, ...rest }) => {
  return (
    <IconButton
      disabled={isFetching}
      size="small"
      onClick={() => refetch()}
      {...rest}
    >
      {isFetching ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        <DownloadIcon fontSize="small" />
      )}
    </IconButton>
  )
}
