import React from "react"
import getTheme, { useCustomerPrimaryColor } from "components/App/theme"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { isWhiteLabelLoginDomain } from "shared"

const createTheme = (color) => {
  if (color) {
    return getTheme({
      palette: {
        primary: { main: color, dark: color, light: color },
        grey: { 300: color }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            text: { color: color }
          }
        },
        MuiPickersToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: color
            }
          }
        }
      }
    })
  }

  if (isWhiteLabelLoginDomain()) {
    return getTheme({
      palette: {
        primary: {
          main: "#434243",
          dark: "#000000",
          light: "#000000"
        },
        components: {
          MuiButton: {
            styleOverrides: {
              text: { color: "#000000" }
            }
          },
          MuiPickersToolbar: {
            styleOverrides: {
              root: {
                backgroundColor: "#000000"
              }
            }
          }
        }
      }
    })
  }

  return getTheme({})
}

const JassThemeProvider = ({ children }) => {
  const color = useCustomerPrimaryColor()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(color)}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

export const withTouchDesign = (Component) => (props) => {
  return (
    <JassThemeProvider>
      <Component {...props} />
    </JassThemeProvider>
  )
}

export default JassThemeProvider
