import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { confirm } from "store/modules/common/notify"

const useConfirm = () => {
  const dispatch = useDispatch()

  return useCallback(
    (text, config) => dispatch(confirm(text, config)),
    [dispatch]
  )
}

export default useConfirm
