import React from "react"
import { Paper } from "@mui/material"

export const WhiteLabelLoginCard = (props) => {
  return (
    <Paper
      sx={{
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "clamp(200px, 375px, 375px)",
        borderRadius: "8px",
        border: "1px solid #EEE",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.03)"
      }}
      {...props}
    />
  )
}
