import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const prefix = "jass/live-links/"
const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`

const CREATE_START = `${prefix}/CREATE_START`
const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
const CREATE_FAIL = `${prefix}/CREATE_FAIL`

const UPDATE_START = `${prefix}/UPDATE_START`
const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
const UPDATE_FAIL = `${prefix}/UPDATE_FAIL`

const DELETE_START = `${prefix}/DELETE_START`
const DELETE_SUCCESS = `${prefix}/DELETE_SUCCESS`
const DELETE_FAIL = `${prefix}/DELETE_FAIL`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result,
  [CREATE_SUCCESS]: (state, action) => {
    if (action.result.live_link) {
      return [...state, action.result.live_link]
    }
    return state
  },
  [UPDATE_SUCCESS]: (state, action) => {
    return state.map((link) =>
      link.id !== action.result.live_link.id ? link : action.result.live_link
    )
  },
  [DELETE_SUCCESS]: (state, action) => {
    return state.filter((link) => link.id !== action.payload)
  }
})

export default combineReducers({ loading, items })

export const load = (orderId) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`/overview/${orderId}/live_links`)
})

export const create = (orderId, data) => ({
  types: [CREATE_START, CREATE_SUCCESS, CREATE_FAIL],
  promise: (client) => client.post(`/orders/${orderId}/live_links/`, { data })
})

export const update = (orderId, id, data) => ({
  types: [UPDATE_START, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) =>
    client.put(`/orders/${orderId}/live_links/${id}`, { data })
})

export const remove = (orderId, id) => ({
  types: [DELETE_START, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`/orders/${orderId}/live_links/${id}`),
  payload: id
})

export const isLoading = (state) => state.overview.assets.liveLinks.loading

export const getItems = (state) => state.overview.assets.liveLinks.items
