import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/affiliateProduct/LOAD_START"
const LOAD_SUCCESS = "jass/affiliateProduct/LOAD_SUCCESS"
const LOAD_FAIL = "jass/affiliateProduct/LOAD_FAIL"
const SET_PARAMS = "jass/affiliateProduct/SET_PARAMS"

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page > 1) {
      return [...state, ...action.result]
    }

    return action.result.items
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) => {
    return action.result.count ?? state
  }
})

const params = createReducer(
  {},
  {
    [LOAD_SUCCESS]: (_, action) => action.params,
    [SET_PARAMS]: (state, action) => ({ ...state, ...action.params })
  }
)

export default combineReducers({
  loading,
  items,
  count,
  params
})

export const setParams = (params) => {
  return { type: SET_PARAMS, params }
}

export const load = (data) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.getHybrid("affiliate_products", data)
  }
}
