import { useCallback } from "react"

export const useDownloadCancellationInvoiceAction = () => {
  return useCallback((order) => {
    return {
      label: `cancellation-invoice_${order.cancellation_invoice_number}.pdf`,
      onClick: () => window.open(order.cancellation_invoice_pdf, "_blank")
    }
  }, [])
}
