import React from "react"

const ShieldIcon = () => (
  <svg width="90" height="106" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path
        d="M85.155 70.416c-2.834 7.682-7.12 14.36-12.741 19.85-6.398 6.247-14.776 11.212-24.902 14.753-.332.116-.679.21-1.027.28-.46.092-.928.14-1.392.147h-.091c-.495 0-.992-.05-1.485-.146a8.024 8.024 0 01-1.021-.279c-10.138-3.535-18.526-8.497-24.93-14.745-5.624-5.49-9.91-12.164-12.74-19.845C-.32 56.465-.027 41.08.208 28.716l.004-.19c.048-1.02.078-2.093.095-3.277.086-5.814 4.709-10.624 10.524-10.948 12.124-.677 21.502-4.631 29.516-12.442l.07-.064A6.755 6.755 0 0145.093 0a6.77 6.77 0 014.494 1.795l.069.064C57.67 9.67 67.049 13.624 79.173 14.301c5.815.324 10.438 5.134 10.524 10.948.017 1.192.047 2.263.095 3.277l.002.08c.235 12.388.526 27.803-4.639 41.81z"
        fill="#00DD80"
      />
      <path
        d="M85.155 70.416c-2.834 7.682-7.12 14.36-12.741 19.85-6.398 6.247-14.776 11.212-24.902 14.753-.332.116-.679.21-1.027.28-.46.092-.928.14-1.392.147V.001a6.765 6.765 0 014.494 1.794l.069.064C57.67 9.67 67.049 13.624 79.173 14.301c5.815.324 10.438 5.134 10.524 10.948.017 1.192.047 2.263.095 3.277l.002.08c.235 12.388.526 27.803-4.639 41.81z"
        fill="#00AA63"
      />
      <path
        d="M71.28 52.723c0 14.46-11.74 26.229-26.187 26.28H45c-14.49 0-26.28-11.79-26.28-26.28 0-14.49 11.79-26.28 26.28-26.28h.093c14.447.052 26.186 11.822 26.186 26.28z"
        fill="#FFF"
      />
      <path
        d="M71.28 52.723c0 14.46-11.74 26.229-26.187 26.28v-52.56c14.447.052 26.186 11.822 26.186 26.28z"
        fill="#E1EBF0"
      />
      <path
        d="M56.928 48.939L45.093 60.776l-2.558 2.558a3.085 3.085 0 01-2.188.905 3.087 3.087 0 01-2.19-.905l-5.498-5.502a3.093 3.093 0 114.375-4.374l3.313 3.313 12.206-12.207a3.093 3.093 0 114.375 4.375z"
        fill="#B4D2D7"
      />
      <path
        d="M56.928 48.939L45.093 60.776v-8.752l7.46-7.46a3.093 3.093 0 114.375 4.375z"
        fill="#6FA5AA"
      />
    </g>
  </svg>
)

export default ShieldIcon
