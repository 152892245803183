import React from "react"
import { Box, CircularProgress } from "@mui/material"

export const GlobalSpinner = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 16px)",
        display: "grid",
        placeItems: "center"
      }}
    >
      <CircularProgress />
    </Box>
  )
}
