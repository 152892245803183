import React from "react"
import GuestPage from "components/Page/GuestPage"
import Card from "components/Card/StatefulCard"
import { useParams } from "react-router-dom"
import ConfirmationDialog from "components/IncompleteOfferConfirmation/ConfirmationDialog"
import { translateText } from "components/IncompleteOfferConfirmation/Translate"
import useRecrumediaOffer from "queries/useRecrumediaOffer"
import makeStyles from "@mui/styles/makeStyles"
import getTheme, { orange } from "components/App/theme"
import {
  alpha,
  ThemeProvider,
  StyledEngineProvider
} from "@mui/material/styles"
import { isValidHexColor } from "shared"
import Notify from "components/Notify/Notify"

const useLogoStyles = makeStyles({
  customerLogo: {
    padding: "6px",
    "& img": {
      height: "42px",
      width: "unset"
    }
  }
})

const ConfirmationPageComponent = ({ offer, token }) => {
  const logoUrl = offer?.affiliate?.end_customer?.logo_url
  const logoStyles = useLogoStyles()
  return (
    <GuestPage>
      <div className={logoStyles.customerLogo}>
        <img src={logoUrl} alt="Con" className="logo" />
      </div>
      <Card
        id="offer-confirmation"
        defaultExpanded
        hideFilterBtn
        title={translateText(
          "incomplete-agency-offers.confirmation-by-link.title"
        )}
      >
        <ConfirmationDialog token={token} />
      </Card>
    </GuestPage>
  )
}

const ConfirmationPage = () => {
  /** @type {{token: string}} */
  const { token } = useParams()
  const { data: offer } = useRecrumediaOffer(token)
  const userColor = offer?.affiliate?.end_customer?.color_code
  const color = isValidHexColor(userColor) ? userColor : orange

  const customizedTheme = getTheme({
    palette: {
      primary: { main: color, dark: color, light: color },
      grey: { 300: color }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            "&:hover": {
              backgroundColor: `${alpha(color, 0.7)} !important`
            }
          },
          text: { color: color }
        }
      }
    }
  })
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customizedTheme}>
        <ConfirmationPageComponent offer={offer} token={token} />
        <Notify />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default ConfirmationPage
