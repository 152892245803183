import React, { useState } from "react"
import { Translate } from "react-redux-i18n"
import { Box, Stack, Divider, Typography, FormHelperText } from "@mui/material"
import { Field, getIn } from "formik"

import useLocale from "hooks/useLocale"
import { DefaultPreview, HorizontalStack, SubCard } from "shared/ui/berry-jass"
import { FilePicker, TextField } from "shared/ui/berry-jass-formik"
import { SupplierIcon } from "entities/supplier"
import {
  ADDITIONAL_AD_FILE_MIME_TYPES,
  createOrderSalesProductsText,
  deleteOrderSalesProductsText,
  getShowGoogleFontsWarning
} from "entities/order-text"
import { getProductNameByLocale } from "entities/sales-product"
import { GoogleFontsUsageAgreement } from "widgets/google-fonts-usage-agreement"
import { SpecificDynamicFields } from "entities/dynamic-field"

export const SalesProductWithDynamicFieldsOrderTexts = ({
  product,
  fields
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const locale = useLocale()
  const onUpload = (file) => {
    return createOrderSalesProductsText(file, product.id)
  }

  const onFileDelete = async (file) => {
    setIsDeleting(true)
    try {
      await deleteOrderSalesProductsText(file.id)
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <SubCard
      contentSX={{
        "&:last-child": { pb: 2 }
      }}
    >
      <Stack gap={2}>
        <HorizontalStack justifyContent="space-between" alignItems="center">
          <HorizontalStack gap={2} whiteSpace="nowrap">
            <SupplierIcon iconUrl={product.icon_url} />
            <Stack>
              <Typography variant="h6">{product.supplier}</Typography>
              <Typography variant="h6" fontWeight={400}>
                {getProductNameByLocale(product, locale)}
              </Typography>
            </Stack>
          </HorizontalStack>
          <Field
            name={`productsTextsFields._${product.id}`}
            component={FilePicker}
            noPreview
            fullWidth
            onUpload={onUpload}
            onDelete={onFileDelete}
            helperText={null}
            accept={ADDITIONAL_AD_FILE_MIME_TYPES}
          >
            <Translate value="booking-wizard.additional-products-fields-step.ad-upload" />
          </Field>
        </HorizontalStack>

        <Field
          name={`productsTextsFields._${product.id}`}
          render={({ form, field }) => {
            if (!field.value) {
              return null
            }

            const showGoogleFontsWarning = getShowGoogleFontsWarning(
              field.value
            )
            return (
              <Box>
                <DefaultPreview
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: "2px",
                    "& .MuiChip-deleteIcon": {
                      marginLeft: "auto"
                    }
                  }}
                  value={field.value}
                  isDeleting={isDeleting}
                  onDelete={async () => {
                    await onFileDelete(field.value)
                    form.setFieldValue(field.name, null)
                  }}
                />
                {showGoogleFontsWarning && (
                  <GoogleFontsUsageAgreement
                    entity={field.value}
                    onSuccess={(data) =>
                      form.setFieldValue(field.name, {
                        ...field.value,
                        google_fonts_usage_agreement: {
                          ...field.value.google_fonts_usage_agreement,
                          signing_date: data.signing_date
                        }
                      })
                    }
                    onCancel={async () => {
                      await onFileDelete(field.value)
                      form.setFieldValue(field.name, null)
                    }}
                    error
                  />
                )}
                {getIn(form.errors, field.name) && (
                  <FormHelperText error>
                    <Translate
                      value={`errors.${getIn(form.errors, field.name)}`}
                    />
                  </FormHelperText>
                )}
              </Box>
            )
          }}
        />

        <Divider />
        <Field
          name={`apply_urls[${product.id}]`}
          component={TextField}
          label={
            <Translate value="web-shop.create-order-wizard.step1.apply_url" />
          }
        />

        {fields.length > 0 && (
          <>
            <Divider />
            <SpecificDynamicFields fields={fields} />
          </>
        )}
      </Stack>
    </SubCard>
  )
}
