import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import qs from "qs"

import * as apiUtils from "utils/api"

const client = axios.create({
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: "brackets" })
    }
  }
})

client.interceptors.request.use((config) => {
  const token = apiUtils.readAuthCookie(null)
  config.headers.Authorization = `Bearer ${token}`
  config.headers["Content-Type"] ??= "application/json"

  return config
})

export const getCurrentJWT = async () => {
  try {
    const res = await client.get("/session_tokens.json")
    return res.data.auth_token
  } catch (error) {
    return null
  }
}

export const refreshAuth = async (failedRequest) => {
  const newToken = await getCurrentJWT()

  if (newToken) {
    failedRequest.response.config.headers.Authorization = "Bearer " + newToken
    apiUtils.saveAuthCookie(null, newToken)
    return Promise.resolve(newToken)
  }

  window.location.assign("/react/login")
  return Promise.reject("no token given")
}

createAuthRefreshInterceptor(client, refreshAuth, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: true
})

export default client
