import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/track-orders/LOAD_INVOICES_START"
const LOAD_SUCCESS = "jass/track-orders/LOAD_INVOICES_SUCCESS"
const LOAD_FAIL = "jass/track-orders/LOAD_INVOICES_FAIL"
const CLEAR = "jass/track-orders/CLEAR_INVOICES"

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result,
  [CLEAR]: () => []
})

export default combineReducers({ loading, items })

export const load = ({ id }) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`track_orders_new/${id}/invoices.json`)
})

export const clear = () => ({
  type: CLEAR
})
