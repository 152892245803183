import React, { useState } from "react"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { Editor } from "react-draft-wysiwyg"
import { ErrorBoundary } from "react-error-boundary"
import "theme/vendors/react-draft-wysiwyg-overrides.css"
import "theme/vendors/react-draft-wysiwyg.css"
import { useTheme } from "@mui/material"

const toolbarDefaultOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link"
  ],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true }
}

const createEditorState = (value) => {
  const draft = htmlToDraft(value ?? "")
  const content = ContentState.createFromBlockArray(draft)
  return EditorState.createWithContent(content)
}
const editorStateToHtml = (editorState) =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()))

export const Wysiwyg = ({
  name,
  label,
  sx = {},
  value,
  onChange,
  disabled,
  placeholder,
  toolbarOptions,
  toolbarHidden,
  errorText,
  onFocus,
  toolbarClassName = null,
  onBlur,
  charsLimit = 0
}) => {
  const theme = useTheme()
  const [editorState, setEditorState] = useState(createEditorState(value))
  const [focused, setFocused] = useState(false)

  const onEditorStateChange = (editorState) => {
    onChange(editorStateToHtml(editorState))
    return setEditorState(editorState)
  }
  const onBlurHandler = () => {
    setFocused(false)
    onBlur?.()
  }
  const onFocusHandler = () => {
    setFocused(true)
    onFocus?.()
  }
  const handlePastedText = (pastedText) => {
    if (charsLimit === 0) return false

    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText("").length
    const totalLength = currentContentLength + pastedText.length
    return totalLength >= charsLimit
  }

  return (
    <>
      {label && (
        <Box marginBottom="10px">
          <InputLabel>{label}</InputLabel>
        </Box>
      )}

      <Box
        data-testid={name}
        sx={{
          border: "1px solid",
          borderRadius: "8px",
          borderColor: focused ? theme.palette.primary.main : "#bdbdbd",
          outline: focused ? `1px solid ${theme.palette.primary.main}` : "none",
          overflow: "auto",
          "&:hover": {
            borderColor: focused ? "primary.main" : "black"
          },
          "&.focused .rdw-editor-toolbar": {
            borderBottomColor: "primary.main",
            outline: `1px solid ${theme.palette.primary.main}`
          },
          ...sx
        }}
        className={focused ? "focused" : ""}
      >
        <ErrorBoundary
          fallbackRender={() => {
            return <div>something is wrong</div>
          }}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{ ...toolbarDefaultOptions, ...toolbarOptions }}
            placeholder={placeholder}
            readOnly={disabled}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            toolbarClassName={toolbarClassName}
            handlePastedText={handlePastedText}
            toolbarHidden={toolbarHidden}
          />
        </ErrorBoundary>
      </Box>
      {errorText && <div>{errorText}</div>}
    </>
  )
}
