import React, { memo } from "react"
import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog as MuiDialog,
  DialogTitle,
  Toolbar,
  Stack,
  Grid,
  dialogClasses
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import IconButton from "@mui/material/IconButton"

export const TopbarDialog = memo(
  /**
   * @param {object} props
   * @param {React.ReactNode} [props.title]
   * @param {() => void} props.onClose
   * @param {React.ReactNode} [props.actionButtonText]
   * @param {boolean} [props.actionButtonDisabled]
   * @param {React.ReactNode} [props.secondaryActionButton]
   * @param {boolean} [props.isSubmitting]
   * @param {string} [props.formId]
   * @param {React.ReactNode} props.children
   * @param {boolean} [props.open]
   * @param {boolean} [props.fullScreen]
   * @param {import("@mui/material").DialogProps['maxWidth']} [props.maxWidth]
   * @param {import("@mui/material").SxProps} [props.sx]
   * @param {import("@mui/material").SxProps} [props.titleSx]
   * @param {import("@mui/material").SxProps} [props.toolbarSx]
   */
  ({
    title,
    onClose,
    actionButtonText,
    actionButtonDisabled,
    secondaryActionButton,
    isSubmitting,
    formId,
    children,
    sx = {},
    titleSx = {},
    toolbarSx = {},
    ...rest
  }) => {
    return (
      <MuiDialog
        onClose={onClose}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            padding: 0,
            borderRadius: 0
          },
          ...sx
        }}
        {...rest}
      >
        <Toolbar
          sx={{
            minHeight: "80px !important",
            ...toolbarSx
          }}
        >
          <Grid container justify="flex-end" alignItems="center">
            <Grid item xs={2}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <DialogTitle sx={{ p: 1, textAlign: "center", ...titleSx }}>
                {title ?? <div />}
              </DialogTitle>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="end">
              <Stack direction="row" spacing={2}>
                {secondaryActionButton}
                {actionButtonText ? (
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={actionButtonDisabled}
                    type="submit"
                    form={formId}
                    variant="contained"
                  >
                    {actionButtonText}
                  </LoadingButton>
                ) : (
                  <span />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
        {children}
      </MuiDialog>
    )
  }
)
