import React from "react"
import { Field } from "formik"
import { Translate } from "react-redux-i18n"
import { FormControlLabel, Grid } from "@mui/material"

import useLocale from "hooks/useLocale"
import { dataTransformer } from "shared"
import {
  PriceInput,
  RemoteAutocomplete,
  TextField,
  Checkbox,
  DatePicker
} from "shared/ui/berry-jass-formik"
import { getProductNameByLocale } from "entities/sales-product"
import { formatSalesProduct } from "../lib"

export const PriceForm = ({ formik: { values } }) => {
  const locale = useLocale()
  const isAddOnDisabled =
    values.type?.id !== "OnlineJobAd" && values.type?.id !== "AddOn"

  const showPromotionDetails = values.promotion_enabled

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          name="rrp"
          label={<Translate value="products.rrp.title" />}
          component={PriceInput}
        />
      </Grid>
      <Grid item md={3}>
        <Field
          fullWidth
          name="seller_lower_limit"
          label={<Translate value="product.lower-limit.title" />}
          component={PriceInput}
        />
      </Grid>
      <Grid item md={3}>
        <Field
          fullWidth
          name="tl_price"
          label={<Translate value="product.tl-price.title" />}
          component={PriceInput}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          name="cross_selling_ids"
          component={RemoteAutocomplete}
          mapResponseToDataSource={dataTransformer}
          label={<Translate value="product.cross-selling.title" />}
          getOptionLabel={(product) => formatSalesProduct(product, locale)}
          url="v2/sales_products.json"
          variant="ransack"
          searchProp="q.name_or_supplier_name_cont"
          params={{
            q: {
              active: true,
              without_ae: true,
              without_add_ons: true
            }
          }}
          multiple
        />
      </Grid>
      <Grid item md={6}>
        {values.type?.id === "AddOn" ? (
          <Field
            id="online_job_ads"
            name="online_job_ad_ids"
            component={RemoteAutocomplete}
            variant="ransack"
            searchProp="q.name_or_supplier_name_cont"
            mapResponseToDataSource={(response) => response.data}
            label={<Translate value="product.online-job-ad.title" />}
            getOptionLabel={(product) => formatSalesProduct(product, locale)}
            url="online_job_ads.json"
            disabled={!values.supplier_id?.id || isAddOnDisabled}
            params={
              values.generic
                ? null
                : { q: { supplier_id_eq: values.supplier?.id } }
            }
            multiple
            required
          />
        ) : (
          <Field
            id="add_ons"
            name="add_on_ids"
            component={RemoteAutocomplete}
            variant="ransack"
            searchProp="q.name_or_supplier_name_cont"
            mapResponseToDataSource={(response) => response.data}
            label={<Translate value="product.add-on.title" />}
            getOptionLabel={(addOn) => getProductNameByLocale(addOn, locale)}
            url="add_ons.json"
            disabled={!values.supplier_id?.id || isAddOnDisabled}
            params={{
              q: {
                g: [
                  {
                    m: "or",
                    generic_true: true,
                    supplier_id_eq: values.supplier_id?.id
                  }
                ]
              }
            }}
            multiple
          />
        )}
      </Grid>
      <Grid item md={6}>
        <Field
          required
          fullWidth
          name="points"
          label={<Translate value="products.points.title" />}
          component={TextField}
        />
      </Grid>
      <Grid item md={3}>
        <Field
          required
          fullWidth
          name="price_in_package"
          label={<Translate value="products.price-in-package.title" />}
          component={PriceInput}
        />
      </Grid>
      <Grid item md={3}>
        <Field
          fullWidth
          name="min_price_in_package"
          label={<Translate value="products.min-price-in-package.title" />}
          component={PriceInput}
        />
      </Grid>
      <Grid item md={6}>
        <FormControlLabel
          label={<Translate value="sales-products-new.promotion.title" />}
          control={
            <Field
              name="promotion_enabled"
              component={Checkbox}
              color="primary"
            />
          }
        />
      </Grid>
      {showPromotionDetails && (
        <>
          <Grid item md={3}>
            <Field
              label={
                <Translate value="sales-products-new.promotion.valid_until" />
              }
              name="promotion_valid_until"
              component={DatePicker}
            />
          </Grid>
          <Grid item md={3}>
            <Field
              required
              label={<Translate value="sales-products-new.promotion.price" />}
              fullWidth
              name="promotion_price"
              component={TextField}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
