import set from "lodash/set"
import get from "lodash/get"

/**
 * @deprecated Please use manual composition instead.
 */
export const extractAutocompleteValues = (
  values,
  { single = [], multiple = [] } = {}
) => {
  const singleValues = single.reduce((acc, key) => {
    const value = get(get(values, key), "value")
    if (value) {
      set(acc, key, value)
    }
    return acc
  }, {})

  const multipleValues = multiple.reduce((acc, key) => {
    const value = get(values, key)
    const vals = value && value.map((d) => d.value)
    if (value && vals) {
      set(acc, key, value)
    }
    return acc
  }, {})

  return { ...values, ...singleValues, ...multipleValues }
}
