import React, { useState, useEffect, useCallback } from "react"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import size from "lodash/size"
import trim from "lodash/trim"
import head from "lodash/head"
import isString from "lodash/isString"
// @ts-ignore
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import { I18n, Translate } from "react-redux-i18n"
import htmlToDraft from "html-to-draftjs"
import InputLabel from "@mui/material/InputLabel"
import Box from "@mui/material/Box"
import { orange } from "components/App/theme"
import classNames from "classnames"
import { ErrorBoundary } from "react-error-boundary"
import CodeEditor from "components/TouchDesign/Components/CodeEditor"
import Prism from "prismjs"
import makeStyles from "@mui/styles/makeStyles"

const useLabelStyles = makeStyles({
  asterisk: { color: "red" },
  root: {
    fontWeight: "bold",
    color: "black",
    fontSize: 13,
    transform: "translate(5px, 0) !important"
  }
})

const toolbarDefaultOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link"
  ],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true }
}

const useStyles = makeStyles({
  error: {
    position: "absolute",
    bottom: -15,
    fontSize: 12,
    lineHeight: "12px"
  },
  parseFailureError: {
    color: "red"
  },
  editor: {
    boxShadow: "0px 0px 5px 1px rgb(225, 225, 225)",
    padding: "0 20px",
    border: "1px solid #eee",
    borderRadius: 4,
    "&:hover": {
      borderColor: orange
    }
  },
  focusedEditor: {
    borderColor: orange,
    borderWidth: 2,
    margin: -1
  }
})

const createEditorState = (value) => {
  const draft = htmlToDraft(value ?? "")
  const content = ContentState.createFromBlockArray(draft)
  return EditorState.createWithContent(content)
}

const editorStateToHtml = (editorState) =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()))

const Wysiwyg = ({
  id,
  placeholder = "",
  disabled = false,
  label,
  error = {},
  errorLabels = { errorKey: "" },
  dirty = false,
  onChange,
  onFocus = null,
  onBlur = null,
  toolbarClassName = null,
  toolbarOptions = null,
  value,
  editorClassName,
  charsLimit = 0
}) => {
  const [editorState, setEditorState] = useState(null)
  const [focused, setFocused] = useState(false)
  const labelClasses = useLabelStyles()
  const classes = useStyles()
  const onHighlight = useCallback((code) => {
    return Prism.highlight(code ?? "", Prism.languages.html, "markup")
  }, [])

  // Updating Wysiwyg from on value prop update from outside.
  useEffect(() => {
    setEditorState(createEditorState(value))
  }, [value])

  const errors = Object.keys(error)
  /* eslint-disable no-nested-ternary */
  const errorKey =
    dirty && size(errors) ? (isString(error) ? error : head(errors)) : ""
  /* eslint-enable no-nested-ternary */
  const errorText =
    !errorKey ||
    (errorKey === "required" && size(errors) === 1 && trim(value)
      ? ""
      : errorLabels?.errorKey ?? I18n.t(`errors.${errorKey}`))

  const onEditorStateChange = (editorState) => {
    return setEditorState(editorState)
  }

  /**
   * Prevents text paste if it overflows chars limit.
   *
   * @param {string} pastedText
   * @returns {boolean} Returns the fact of overflowing chars limit.
   */
  const handlePastedText = (pastedText) => {
    if (charsLimit === 0) return false

    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText("").length
    const totalLength = currentContentLength + pastedText.length
    return totalLength >= charsLimit
  }

  const onBlurHandler = () => {
    onChange(editorStateToHtml(editorState))
    setFocused(false)
    onBlur?.()
  }

  const onFocusHandler = () => {
    setFocused(true)
    onFocus?.()
  }

  return (
    <div id={id}>
      <Box marginBottom="10px">
        <InputLabel classes={labelClasses}>{label}</InputLabel>
      </Box>

      <ErrorBoundary
        fallbackRender={() => {
          return (
            <div>
              <CodeEditor
                value={value}
                name={name}
                onChange={onChange}
                highlight={onHighlight}
              />
              <div className={classes.parseFailureError}>
                <Translate value="errors.invalid-wysiwyg-content" />
              </div>
            </div>
          )
        }}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{ ...toolbarDefaultOptions, ...toolbarOptions }}
          placeholder={placeholder}
          readOnly={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          toolbarClassName={toolbarClassName}
          handlePastedText={handlePastedText}
          editorClassName={classNames(classes.editor, editorClassName, {
            [classes.focusedEditor]: focused
          })}
        />
      </ErrorBoundary>
      <div className={classes.error}>{errorText}</div>
    </div>
  )
}

export default Wysiwyg
