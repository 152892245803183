export { default as reduceReducers } from "./reduce-reducers"
export { default as isNotEmpty } from "./is-not-empty"

export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    }

    return state
  }
