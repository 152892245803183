export const isJassDomain = () =>
  window.location.hostname.endsWith("jass.jobadpartner.de") ||
  window.location.hostname === "localhost" ||
  window.location.hostname === "frontend" ||
  window.location.hostname.startsWith("172.") // CI

export const isApplyRecrumediaDomain = () =>
  /apply\.recrumedia/.test(window.location.hostname)

export const isOffersRecrumediaDomain = () =>
  /offers\.recrumedia/.test(window.location.hostname)

const getWhiteLabelLoginFromLocalStorage = () => {
  try {
    return !!localStorage.getItem("white-label-login")
  } catch {
    return false
  }
}

export const isWhiteLabelLoginDomain = () => {
  return (
    getWhiteLabelLoginFromLocalStorage() ||
    (!isJassDomain() &&
      !isApplyRecrumediaDomain() &&
      !isOffersRecrumediaDomain())
  )
}
