import get from "lodash/get"
import { extractAutocompleteValues } from "utils/parsers/autocompletes"
import pick from "lodash/pick"
import { getAddOnIds } from "utils/product"

const getAdEditorValues = (data) =>
  get(data, "activeTab") === 0 &&
  extractAutocompleteValues(get(data, "adEditor"), {
    single: ["logo_alignment"]
  })

const prepareLocations = (country, locations) =>
  locations.map((location) => ({
    id: location.id,
    city: location.city?.split("|")?.[0]?.trim() ?? "",
    zip: location.zip,
    street: location.street,
    street_number: location.street_number,
    country: country,
    _destroy: location._destroy
  }))

export const dataPreparators = (data) => {
  const selectedProducts = get(data, "selectedProducts", [])
  return {
    product_ids:
      selectedProducts.length === 0 ? JSON.stringify([]) : selectedProducts,
    add_on_ids: pick(getAddOnIds(data.addOns), selectedProducts),
    contract_type_id: get(
      data,
      "contract_type_id.value",
      get(data, "contract_type_id", null)
    ),
    field_of_work_id: get(
      data,
      "field_of_work_id.value",
      get(data, "field_of_work_id", null)
    ),
    seniority_id: get(
      data,
      "seniority_id.value",
      get(data, "seniority_id", null)
    ),
    job_type_ids: get(data, "job_type_id.value", get(data, "job_type_ids", [])),
    industry_id: get(data, "industry_id.value", get(data, "industry_id", null)),
    keywords: get(data, "keywords", null),
    salary_minimum: data.salary_minimum,
    salary_maximum: data.salary_maximum,
    salary_interval: data.salary_interval?.value,
    salary_currency: data.salary_currency,
    headcount: get(data, "headcount", null),
    department: get(data, "department", null),
    locations_attributes: prepareLocations(
      get(data, "country", null),
      get(data, "locations", [])
    ),
    title: get(data, "title", null),
    state: get(data, "state"),
    token: get(data, "token", null),
    ad_editor: getAdEditorValues(data),
    base_info: {
      offer_price: get(data, "base_info.offer_price")
    }
  }
}
