import React from "react"
import { TableRow, TableCell, Box } from "@mui/material"

export const TableBlankSlate = ({ colSpan, children }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            py: 3.25,
            color: "rgba(67, 66, 67, 0.7)"
          }}
        >
          {children}
        </Box>
      </TableCell>
    </TableRow>
  )
}
