import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"
import { DesktopDateTimePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

const useStyles = makeStyles({
  picker: { width: "100%" }
})

const DateTimePicker = ({
  className,
  error,
  name,
  value,
  onChange,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(className, "fieldContainer")}>
      <DesktopDateTimePicker
        closeOnSelect
        {...rest}
        value={value}
        onChange={onChange}
        className={classNames(`${className}-component`, classes.picker)}
        renderInput={(props) => {
          return (
            <TextField
              {...props}
              fullWidth
              variant="standard"
              name={name}
              error={!!error}
            />
          )
        }}
      />
    </div>
  )
}

export default DateTimePicker
