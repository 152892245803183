"user strict"

import config from "../config.js"
import get from "lodash/get"

const rootPath = "/react"

export function local(path, includeDomain = false) {
  const preparedPath = typeof path === "string" ? path.replace(/^\/+/, "") : ""
  return `${includeDomain ? get(window, "location.host") : ""}${
    preparedPath.includes("react") ? "" : rootPath
  }/${preparedPath}`
}

export function emberPage(path = "", includeDomain = false) {
  return `${includeDomain ? apiDomainUrl() : ""}${path.replace(/^\/+/, "/")}`
}

function apiDomainUrl() {
  let domain = config.emberUrl || get(window, "location.hostname")

  if (domain === config.apiHost) {
    domain += ":" + config.apiPort
  }

  if (!domain) {
    domain = `${config.apiHost}:${config.apiPort}`
  }

  return `//${domain}`
}

export function socketUrl() {
  const hostname =
    window.location.hostname !== "localhost" &&
    process.env.CENTRIFUGO_HOST_FOR_FRONTEND
      ? process.env.CENTRIFUGO_HOST_FOR_FRONTEND
      : window.location.hostname

  const protocol = window.location.protocol
  const tls = protocol === "https:"

  const wsProtocol = tls ? "wss:" : "ws:"
  const port = tls ? "" : ":8000"

  return `${wsProtocol}//${hostname}${port}/connection/websocket`
}
