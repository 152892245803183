import React from "react"
import { Field } from "formik"
import {
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  Typography,
  TextField as MuiTextField
} from "@mui/material"
import { Translate } from "react-redux-i18n"
import { dataTransformer } from "shared"
import useLocale from "hooks/useLocale"
import {
  Checkbox,
  Autocomplete,
  RemoteAutocomplete,
  TextField,
  RadioGroup,
  Switch,
  PriceInput
} from "shared/ui/berry-jass-formik"
import { getProductTypes } from "../lib"

export const SalesProductForm = ({
  formik: { values, setFieldValue },
  disabledSupplier
}) => {
  const locale = useLocale()
  const handlePurchaseProductSupplierChange = () => {
    setFieldValue("purchase_product", null)
  }

  const purchaseProductSupplierSelected = values.ek_supplier_id?.id

  const isextendingDisabled = !values.extending

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      {values.id && (
        <Grid item md={12}>
          <MuiTextField
            fullWidth
            label={<Translate value="id" />}
            value={values.id}
            disabled
          />
        </Grid>
      )}
      <Grid item md={6}>
        <Field
          fullWidth
          required
          label={<Translate value="products.name_de.title" />}
          name="name"
          component={TextField}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          fullWidth
          name="name_en"
          label={<Translate value="products.name_en.title" />}
          component={TextField}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          component={RemoteAutocomplete}
          name="supplier_group_ids"
          variant="ransack"
          searchProp="q.name_cont"
          mapResponseToDataSource={dataTransformer}
          label={<Translate value="product.supplier-groups.title" />}
          url="supplier_groups.json"
          multiple
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          name="type"
          component={Autocomplete}
          label={<Translate value="product.product-type.title" />}
          options={getProductTypes()}
          disabled={values.id}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          name="supplier_id"
          variant="ransack"
          searchProp="q.name_cont"
          component={RemoteAutocomplete}
          mapResponseToDataSource={dataTransformer}
          label={<Translate value="product.vk-supplier" />}
          url="suppliers.json"
          disabled={disabledSupplier}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          name="ek_supplier_id"
          variant="ransack"
          searchProp="q.name_cont"
          component={RemoteAutocomplete}
          mapResponseToDataSource={dataTransformer}
          label={<Translate value="product.ek-supplier" />}
          url="suppliers.json"
          onChange={handlePurchaseProductSupplierChange}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          required
          id="purchase-product-autocomplete"
          name="purchase_product_id"
          variant="ransack"
          searchProp="q.name_cont"
          component={RemoteAutocomplete}
          mapResponseToDataSource={dataTransformer}
          label={<Translate value="product.purchase-product.title" />}
          url="purchase_products.json"
          params={{
            q: { supplier_id_eq: values.ek_supplier_id?.id }
          }}
          disabled={!purchaseProductSupplierSelected}
        />
      </Grid>
      <Grid item md={2}>
        <Field
          required
          name="runtime_in_days"
          label={<Translate value="products.runtime-in-days.title" />}
          type="number"
          component={TextField}
        />
      </Grid>
      <Grid item md={4}>
        <Field
          required
          id="countries-autocomplete"
          name="country"
          getOptionLabel={(country) => country[`name_${locale}`]}
          isOptionEqualToValue={(option, value) => {
            return Array.isArray(option)
              ? !!option.find((o) => o.id === value.id)
              : option.id === value.id
          }}
          component={RemoteAutocomplete}
          label={<Translate value="products.country.title" />}
          url="/countries.json"
          multiple
        />
      </Grid>
      <Grid item md={1.5}>
        <FormControlLabel
          control={<Field name="extending" component={Switch} />}
          label={
            <Typography variant="h6">
              <Translate value="products.extending.title" />
            </Typography>
          }
        />
      </Grid>
      <Grid item md={1.5}>
        <Field
          required
          fullWidth
          name="expiring_in_days"
          label={<Translate value="products.expiring-in-days.title" />}
          type="number"
          component={TextField}
          disabled={isextendingDisabled}
        />
      </Grid>
      <Grid item md={3}>
        <Field
          fullWidth
          required
          name="emails"
          label={<Translate value="products.emails.title" />}
          component={TextField}
          disabled={isextendingDisabled}
        />
      </Grid>

      <Grid item md={6}>
        <Typography variant="h5">
          <Translate value="additional-options" />
        </Typography>
        <Stack direction="row" columnGap={2}>
          <FormControlLabel
            control={<Field name="is_show" component={Checkbox} />}
            label={
              <Typography variant="h6">
                <Translate value="product.is-show.title" />
              </Typography>
            }
          />
          <FormControlLabel
            control={<Field name="top" component={Checkbox} />}
            label={
              <Typography variant="h6">
                <Translate value="product.top.title" />
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Field name="excluded_from_prediction" component={Checkbox} />
            }
            label={
              <Typography variant="h6">
                <Translate value="product.exclude-from-prediction.title" />
              </Typography>
            }
          />
        </Stack>

        <Typography variant="h5" mt={3}>
          <Translate value="sales-products-new.job_board.title" />
        </Typography>
        <Field
          row
          name="job_board"
          component={RadioGroup}
          sx={{ columnGap: 2 }}
        >
          <FormControlLabel
            value="specialist"
            control={<Radio />}
            label={
              <Typography variant="h6">
                <Translate value="sales-products-new.job_board.types.specialist" />
              </Typography>
            }
          />
          <FormControlLabel
            value="regionalist"
            control={<Radio />}
            label={
              <Typography variant="h6">
                <Translate value="sales-products-new.job_board.types.regionalist" />
              </Typography>
            }
          />
          <FormControlLabel
            value="generalist"
            control={<Radio />}
            label={
              <Typography variant="h6">
                <Translate value="sales-products-new.job_board.types.generalist" />
              </Typography>
            }
          />
        </Field>
      </Grid>
      <Grid item md={6}>
        {!values.id && (
          <div>
            <Grid container sx={{ mt: 3 }}>
              <Grid item md={6}>
                <FormControlLabel
                  label={
                    <Typography variant="h6">
                      <Translate value="sales-products-new.default-agency-price" />
                    </Typography>
                  }
                  control={
                    <Field
                      name="default_agency_price_enabled"
                      component={Switch}
                      color="primary"
                      onChange={(e, value) => {
                        setFieldValue("default_agency_price_enabled", value)
                        if (!value) setFieldValue("default_agency_price", "")
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  required
                  label={<Translate value="sales-products-new.agency-price" />}
                  name="default_agency_price"
                  component={PriceInput}
                  disabled={!values.default_agency_price_enabled}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 3 }}>
              <Grid item md={6}>
                <FormControlLabel
                  label={
                    <Typography variant="h6">
                      <Translate value="sales-products-new.default-partner-price" />
                    </Typography>
                  }
                  control={
                    <Field
                      name="default_partner_price_enabled"
                      component={Switch}
                      onChange={(e, value) => {
                        setFieldValue("default_partner_price_enabled", value)
                        if (!value) setFieldValue("default_partner_price", "")
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  required
                  label={<Translate value="sales-products-new.partner-price" />}
                  name="default_partner_price"
                  component={PriceInput}
                  disabled={!values.default_partner_price_enabled}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
      <Grid item md={6}>
        <Field
          fullWidth
          name="description"
          component={TextField}
          label={<Translate value="products.description_de.title" />}
          multiline
          rows={5}
        />
      </Grid>
      <Grid item md={6}>
        <Field
          fullWidth
          name="description_en"
          component={TextField}
          label={<Translate value="products.description_en.title" />}
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  )
}
