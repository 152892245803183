import merge from "lodash/merge"
import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const prefix = "jass/AD_EDITOR_TEMPLATES"

const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`
const GET_TEMPLATE_START = `${prefix}/GET_START`
const GET_TEMPLATE_SUCCESS = `${prefix}/GET_SUCCESS`
const GET_TEMPLATE_FAIL = `${prefix}/GET_FAIL`
const SET_PARAMS = `${prefix}/SET_PARAMS`
const SYNC_TEMPLATE_START = `${prefix}/SYNC_START`
const SYNC_TEMPLATE_SUCCESS = `${prefix}/SYNC_SUCCESS`
const SYNC_TEMPLATE_FAIL = `${prefix}/SYNC_FAIL`
const REMOVE_START = `${prefix}/REMOVE_START`
const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`
const REMOVE_FAIL = `${prefix}/REMOVE_FAIL`
const SELECT_TEMPLATE = `${prefix}/SELECT_TEMPLATE`
const SET_COPY = `${prefix}/SET_COPY`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false,
  [REMOVE_START]: () => true,
  [REMOVE_SUCCESS]: () => false,
  [REMOVE_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result]
    }
    return action.result.items
  },
  [REMOVE_SUCCESS]: (state, action) => {
    return state.filter((template) => template.id !== action.payload)
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) => action.result.count || state,
  [REMOVE_SUCCESS]: (state) => state - 1
})

const initialParams = {}
const params = createReducer(initialParams, {
  [LOAD_SUCCESS]: (state, action) => action.params,
  [SET_PARAMS]: (state, action) => merge({}, state, action.params)
})

const selectedTemplate = createReducer(null, {
  [SELECT_TEMPLATE]: (state, action) => action.payload
})

const copy = createReducer(false, {
  [SET_COPY]: (state, action) => action.payload.copy
})

export default combineReducers({
  items,
  count,
  params,
  loading,
  ui: combineReducers({
    selectedTemplate,
    copy
  })
})

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.getHybrid("ad_editor_templates", options)
})

const syncTemplate = (mode) => (data) => {
  return {
    types: [SYNC_TEMPLATE_START, SYNC_TEMPLATE_SUCCESS, SYNC_TEMPLATE_FAIL],
    promise: (client) =>
      client[mode]("ad_editor_templates.json", {
        data: { ad_editor_template: data }
      })
  }
}

export const createTemplate = syncTemplate("post")
export const updateTemplate = syncTemplate("put")

export const remove = (id) => ({
  types: [REMOVE_START, REMOVE_SUCCESS, REMOVE_FAIL],
  promise: (client) => client.del(`ad_editor_templates/${id}`),
  payload: id
})

export const getTemplate = (id) => ({
  types: [GET_TEMPLATE_START, GET_TEMPLATE_SUCCESS, GET_TEMPLATE_FAIL],
  promise: (client) => client.get(`ad_editor_templates/${id}`)
})

export const selectTemplate = (id) => ({
  type: SELECT_TEMPLATE,
  payload: id
})

export const setCopy = (copy) => ({
  type: SET_COPY,
  payload: { copy }
})

export const getSelectedTemplate = (state) =>
  state.adEditorTemplates.ui.selectedTemplate

export const getCopy = (state) => state.adEditorTemplates.ui.copy

export const getTemplateURL = (token) => {
  if (TEMPLATES_ORIGIN) {
    return `${TEMPLATES_ORIGIN}/${token}`
  }
  return `${window.origin}/ad_editor_templates/${token}`
}
