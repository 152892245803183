import { useTheme } from "@mui/material"
import React from "react"
import Avatar from "shared/ui/berry-jass/Avatar"

export const RectangularImagePreview = ({ value }) => {
  const theme = useTheme()

  return (
    <Avatar
      src={value.thumbnail_url || value.thumb.url}
      size="xl"
      sx={{
        borderRadius: "8px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[400]
      }}
    />
  )
}
