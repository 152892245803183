import React from "react"
import { Backdrop, CircularProgress } from "@mui/material"

export const BackdropLoader = () => {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.background.paper,
        zIndex: (theme) => theme.zIndex.modal + 1
      }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
