import size from "lodash/size"
import * as Yup from "yup"
import isArray from "lodash/isArray"
import isPlainObject from "lodash/isPlainObject"
import reduce from "lodash/reduce"
import indexOf from "lodash/indexOf"
import toString from "lodash/toString"
import trim from "lodash/trim"

function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    (isArray(value) || isPlainObject(value) ? !size(value) : false)
  )
}

const patterns = {
  email:
    /^(?:[\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/i,
  url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  iban: /^((NO)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(NO)[0-9A-Z]{13}|(BE)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(BE)[0-9A-Z]{14}|(DK|FO|FI|GL|NL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(DK|FO|FI|GL|NL)[0-9A-Z]{16}|(MK|SI)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(MK|SI)[0-9A-Z]{17}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{18}|(HR|LI|LV|CH)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{1}|(HR|LI|LV|CH)[0-9A-Z]{19}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{20}|(GI|IL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(GI|IL)[0-9A-Z]{21}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{22}|(PT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{1}|(PT)[0-9A-Z]{23}|(IS|TR)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(IS|TR)[0-9A-Z]{24}|(FR|GR|IT|MC|SM)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(FR|GR|IT|MC|SM)[0-9A-Z]{25}|(AL|CY|HU|LB|PL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(AL|CY|HU|LB|PL)[0-9A-Z]{26}|(MU)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(MU)[0-9A-Z]{28}|(MT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(MT)[0-9A-Z]{29})$/i,
  mailto:
    /mailto:(?:[\w!#$%&'*+-/=?^`{|}~]+\.)*[\w!#$%&'*+-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))/,
  commaSeparatedString: /^[A-Z0-9À-ž]+((, ?|-)[A-Z0-9À-ž]+)*[A-Z0-9À-ž]+$/i,
  domain:
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/
}

function wysiwygRequired(value = "") {
  return !!$(value).text().trim()
}

function checkboxGroupRequired(field) {
  return reduce(field, (result, value) => result || value, false)
}

function mail(value) {
  return patterns.email.test(value)
}

function urlWithIdParam(value) {
  return patterns.url.test(value) && value.includes("{id}")
}

export function url(value) {
  return patterns.url.test(value)
}

function commaSeparatedString(value) {
  return patterns.commaSeparatedString.test(value)
}

function urlOrEmail(value) {
  return url(value) || mail(value)
}

function domain(value) {
  return patterns.domain.test(value)
}

const mailto = (value) => {
  return patterns.mailto.test(value)
}

const urlOrMailto = (value) => {
  return url(value) || mailto(value)
}

function iban(value) {
  return patterns.iban.test(value)
}

function number(value) {
  if (isEmpty(value)) return true
  return /^[\d,.]+$/.test(toString(value).replace(",", "."))
}

export const videoURL = (url) => {
  return (
    /https:\/\/www.youtube.com\/watch\?v=/.test(url) ||
    /https:\/\/vimeo.com/.test(url)
  )
}

const EUForeignCountries = [
  "at",
  "be",
  "bg",
  "cy",
  "cz",
  "dk",
  "ee",
  "el",
  "gr",
  "es",
  "fi",
  "fr",
  "hr",
  "it",
  "lv",
  "lt",
  "lu",
  "hu",
  "ie",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "si",
  "sk",
  "se",
  "uk",
  "gb"
]

export function checkForEuropeCountry(country) {
  return indexOf(EUForeignCountries, country) === -1
}

Yup.addMethod(Yup.string, "wysiwygRequired", (message) => {
  return Yup.mixed().test("wysiwygRequired", message, wysiwygRequired)
})

Yup.addMethod(Yup.object, "checkboxGroupRequired", (message) => {
  return Yup.mixed().test(
    "checkboxGroupRequired",
    message,
    checkboxGroupRequired
  )
})

Yup.addMethod(Yup.number, "checkInteger", (message) => {
  return Yup.mixed().test(
    "checkInteger",
    message,
    (value) => !isNaN(Number(value))
  )
})

Yup.addMethod(Yup.string, "commaSeparatedString", (message) => {
  return Yup.string().test(
    "commaSeparatedString",
    message,
    (value) => !value || commaSeparatedString(value)
  )
})

Yup.addMethod(Yup.string, "urlOrEmail", (message) => {
  return Yup.string().test(
    "urlOrEmail",
    message,
    (value) => !value || urlOrEmail(value)
  )
})

Yup.addMethod(Yup.string, "urlWithIdParam", (message) => {
  return Yup.string().test(
    "urlWithIdParam",
    message,
    (value) => !value || urlWithIdParam(value)
  )
})

Yup.addMethod(Yup.string, "domain", (message) => {
  return Yup.string().test(
    "domain",
    message,
    (value) => !value || domain(value)
  )
})

Yup.addMethod(Yup.string, "urlOrMailto", (message) => {
  return Yup.string().test("urlOrMailto", message, (value) =>
    urlOrMailto(value ?? "")
  )
})

Yup.addMethod(Yup.string, "videoURL", (message) => {
  return Yup.string().test(
    "videoURL",
    message,
    (value) => !value || videoURL(value)
  )
})

Yup.addMethod(Yup.number, "price", (message) => {
  return Yup.string().test("price", message, number)
})

Yup.addMethod(Yup.string, "price", (message) => {
  return Yup.string().test("price", message, number)
})

Yup.addMethod(Yup.string, "moreThan", (limit, message) => {
  return Yup.string().test("moreThan", message, (value) => {
    return parseFloat(value) > limit
  })
})

Yup.addMethod(Yup.string, "iban", (message) => {
  return Yup.string().test("iban", message, iban)
})

Yup.addMethod(Yup.array, "unique", function unique(message, mapper = (a) => a) {
  return this.test("unique", message, function checkLength(list) {
    return list.length === new Set(list.map(mapper)).size
  })
})

Yup.addMethod(Yup.number, "emptyToNull", function emptyToNull() {
  // @ts-ignore
  return this.nullable().transform((value, original) =>
    original === "" || original === "-" ? null : value
  )
})

export const cityNameSchema = Yup.string()
  .transform((value) => trim(value, ",; "))
  .required("required")
  .matches(/^[^,;]+$/gi, "multiple-cities-input")
