import React, { forwardRef } from "react"
import classNames from "classnames"
import { I18n } from "react-redux-i18n"

import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import maxBy from "lodash/maxBy"

import CreatableSelect from "react-select/creatable"
import Select, { components as SelectComponents } from "react-select"

import CancelIcon from "@mui/icons-material/Cancel"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { emphasize } from "@mui/material/styles"

import withStyles from "@mui/styles/withStyles"
import createStyles from "@mui/styles/createStyles"

const AVERAGE_LETTER_SIZE = 8

const styles = (theme) =>
  createStyles({
    chip: {
      margin: `calc(${theme.spacing(1)} / 2) calc(${theme.spacing(1)} / 4)`
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.mode === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      )
    },
    chipError: {
      border: "2px solid red"
    },
    chipTruncate: {
      textOverflow: "ellipsis",
      maxWidth: 200,
      overflow: "hidden"
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto"
    },
    multiValueContainer: {
      flexWrap: "wrap"
    },
    noOptionsMessage: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    paper: {
      minWidth: "100%"
    },
    singleValue: {
      whiteSpace: "nowrap"
    },
    valueContainer: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      marginBottom: 4,
      marginTop: 4,
      minHeight: 20,
      overflow: "hidden"
    }
  })

const Control = (props) => {
  return (
    <TextField
      fullWidth
      variant="standard"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          children: props.children,
          innerRef: props.innerRef,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

const getMenuSize = (options) => {
  if (!options || !options.length) {
    return 0
  }
  const largestOption = maxBy(options, (option) =>
    get(option, "label.length", 0)
  )
  return get(largestOption, "label.length", 0)
}

const Menu = (props) => {
  return (
    <Paper
      square
      className={classNames(
        "autocomplete__menu-list",
        props.selectProps.classes.paper
      )}
      style={{ width: getMenuSize(props.options) * AVERAGE_LETTER_SIZE }}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const MultiValue = (props) => {
  return (
    <Chip
      size={props.selectProps.size}
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipError]:
          !!props.selectProps.errors?.[props.index],
        [props.selectProps.classes.chipFocused]: props.isFocused,
        [props.selectProps.classes.chipTruncate]:
          props.selectProps.truncateChipText ?? true
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <CancelIcon {...props.removeProps} title={I18n.t("remove")} />
      }
    />
  )
}

const NoOptionsMessage = () => null

const Option = (props) => {
  return (
    <MenuItem
      className="autocomplete__menu-item"
      component="div"
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        padding: "10px 16px"
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

const Placeholder = (props) => {
  return (
    <Typography color="textSecondary" {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

const SingleValue = (props) => {
  return (
    <div
      className={classNames(props.selectProps.classes.singleValue, "value")}
      {...props.innerProps}
    >
      {props.children}
    </div>
  )
}

const ValueContainer = (props) => {
  const classes = props.selectProps.classes

  return (
    <div
      className={classNames(classes.valueContainer, {
        [classes.multiValueContainer]: props.selectProps.isMulti
      })}
    >
      {props.children}
    </div>
  )
}

const inputComponent = forwardRef(({ innerRef, ...props }, _ref) => {
  return (
    <div
      ref={innerRef}
      {...props}
      className={classNames("input", props.className)}
    />
  )
})

const DropdownIndicator = () => null
const IndicatorSeparator = () => null

const CustomClearIndicator = (props) => {
  const { value: option } = props.selectProps

  return !!(option && !isNil(option.value)) ? (
    <SelectComponents.ClearIndicator {...props} className="clear-indicator" />
  ) : null
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  IndicatorSeparator,
  ClearIndicator: CustomClearIndicator
}

class StyledMaterialUIAutoComplete extends React.Component {
  state = {
    focused: false,
    dirty: false
  }

  handleFocus = (e) => {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus(e)
  }

  handleBlur = (e) => {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur(e)
  }

  handleChange = (value) => {
    this.setState({ dirty: true })
    this.props.onChange && this.props.onChange(value)
  }

  render() {
    const props = this.props
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: props.theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 })
    }

    let options = (props.dataSource ?? []).map((d) => ({
      ...d,
      label: props.translateLabels
        ? I18n.t(d[props.dataSourceConfig.text] ?? d.label)
        : d[props.dataSourceConfig.text] ?? d.label,
      value: d[props.dataSourceConfig.value] ?? d.value
    }))

    options = props.showPlaceholder
      ? [
          {
            label: props.placeholderValue || I18n.t("search.all"),
            value: ""
          },

          ...options
        ]
      : options

    const toValue = (object) => ({
      ...object,
      value: object[props.dataSourceConfig.value] || object.value,
      label: object[props.dataSourceConfig.text] || object.label
    })
    const getAutocompleteValue = (value) => {
      if (!value) {
        return null
      }
      return props.isMulti ? value.map(toValue) : toValue(value)
    }

    const autocompleteValue = getAutocompleteValue(props.value)
    const Component = props.creatable ? CreatableSelect : Select

    const inputId = props.inputId ?? (props.id ? `${props.id}-input` : null)

    return (
      <Component
        {...props}
        styles={selectStyles}
        options={options}
        classNamePrefix={props.className}
        menuPortalTarget={document.body}
        placeholder={null}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        components={components}
        value={autocompleteValue || null}
        inputId={inputId}
        textFieldProps={{
          label: props.label,
          error: props.error,
          helperText: props.helperText,
          errors: props.errors,
          disabled: props.isDisabled,
          autoComplete: "off",
          InputLabelProps: {
            htmlFor: inputId,
            shrink:
              this.state.focused ||
              !isEmpty(props.value) ||
              (!this.state.dirty && !isEmpty(props.defaultValue))
          }
        }}
      />
    )
  }
}

StyledMaterialUIAutoComplete.defaultProps = {
  isClearable: true
}

export default withStyles(styles, { withTheme: true })(
  StyledMaterialUIAutoComplete
)
