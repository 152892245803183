import get from "lodash/get"
import pickBy from "lodash/pickBy"
import { combineReducers } from "redux"
import { isNotEmpty, createReducer } from "store/helpers/reducers"
import { prepareParams, dataPreparators } from "./helpers"
import { RESET } from "store/modules/entities/customer"

const LOAD_START = "jass/order/LOAD_START"
export const LOAD_SUCCESS = "jass/order/LOAD_SUCCESS"
const LOAD_FAIL = "jass/order/LOAD_FAIL"
const LOAD_PRODUCTION_START = "jass/order/production/LOAD_START"
const LOAD_PRODUCTION_SUCCESS = "jass/order/production/LOAD_SUCCESS"
const LOAD_PRODUCTION_FAIL = "jass/order/production/LOAD_FAIL"
const CREATE_START = "jass/order/create/CREATE_START"
const CREATE_SUCCESS = "jass/order/create/CREATE_SUCCESS"
const CREATE_FAIL = "jass/order/create/CREATE_FAIL"
const LOAD_ORDER_DETAILS_START = "jass/order/loadOrderDetails/LOAD_START"
const LOAD_ORDER_DETAILS_SUCCESS = "jass/order/loadOrderDetails/LOAD_SUCCESS"
const LOAD_ORDER_DETAILS_FAIL = "jass/order/loadOrderDetails/LOAD_FAIL"
const EXPORT_ALL_ORDERS_START = "jass/order/export_all_orders/LOAD_START"
const EXPORT_ALL_ORDERS_SUCCESS = "jass/order/export_all_orders/LOAD_SUCCESS"
const EXPORT_ALL_ORDERS_FAIL = "jass/order/export_all_orders/LOAD_FAIL"
const UPDATE_START = "jass/order/update_start"
export const UPDATE_SUCCESS = "jass/order/update_success"
const UPDATE_FAIL = "jass/order/update_fail"
const UPDATE_MARKED_START = "jass/order/UPDATE_MARKED_START"
export const UPDATE_MARKED_SUCCESS = "jass/order/UPDATE_MARKED_SUCCESS"
const UPDATE_MARKED_FAIL = "jass/order/UPDATE_MARKED_FAIL"

export default combineReducers({
  list: combineReducers({
    loading: createReducer(false, {
      [LOAD_START]: () => true,
      [LOAD_SUCCESS]: () => false,
      [LOAD_FAIL]: () => false
    }),

    items: createReducer(null, {
      [LOAD_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...(state ?? []), ...action.result]
        }

        return action.result.items
      },
      [RESET]: () => null
    }),

    count: createReducer(0, {
      [LOAD_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state
    }),
    params: createReducer(
      {},
      {
        [LOAD_SUCCESS]: (state, action) => action.params
      }
    )
  }),
  orderDetails: createReducer(
    {},
    {
      [LOAD_ORDER_DETAILS_SUCCESS]: (state, action) => action.result
    }
  ),
  production: combineReducers({
    loading: createReducer(false, {
      [LOAD_PRODUCTION_START]: () => true,
      [LOAD_PRODUCTION_SUCCESS]: () => false,
      [LOAD_PRODUCTION_FAIL]: () => false
    }),

    items: createReducer([], {
      [LOAD_PRODUCTION_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...state, ...action.result]
        }

        return action.result.items
      },
      [RESET]: () => []
    }),

    count: createReducer(0, {
      [LOAD_PRODUCTION_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state
    }),
    params: createReducer(
      {},
      {
        [LOAD_PRODUCTION_SUCCESS]: (state, action) => action.params
      }
    )
  })
})

export const load = ({ params, from }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.getHybrid("web_shop_orders", {
        params: prepareParams(params),
        from
      })
  }
}

export const loadOrderDetails = ({ order_id }) => {
  return {
    types: [
      LOAD_ORDER_DETAILS_START,
      LOAD_ORDER_DETAILS_SUCCESS,
      LOAD_ORDER_DETAILS_FAIL
    ],
    promise: (client) =>
      client.get(`web_shop_orders/${order_id}/order_details.json`)
  }
}

export function updateList(eventData, params) {
  return load({ params })
}

export const create = ({ data, prepareDataFor = "any" }) => {
  return {
    types: [CREATE_START, CREATE_SUCCESS, CREATE_FAIL],
    promise: (client) => {
      return client.post("web_shop_orders", {
        data: dataPreparators[prepareDataFor](data)
      })
    }
  }
}

export const loadProduction = ({ params, from }) => {
  return {
    types: [
      LOAD_PRODUCTION_START,
      LOAD_PRODUCTION_SUCCESS,
      LOAD_PRODUCTION_FAIL
    ],
    promise: (client) =>
      client.getHybrid("orders", {
        params: {
          ...pickBy(params, isNotEmpty),
          additional_search: pickBy(
            get(params, "additional_search"),
            isNotEmpty
          )
        },
        from
      })
  }
}

export const exportAllOrders = ({ params }) => {
  return {
    types: [
      EXPORT_ALL_ORDERS_START,
      EXPORT_ALL_ORDERS_SUCCESS,
      EXPORT_ALL_ORDERS_FAIL
    ],
    promise: (client) =>
      client.get("web_shop_orders", { params: prepareParams(params) })
  }
}

export const updateOrder = (id, values) => {
  return {
    types: [UPDATE_START, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) =>
      client.put(`orders/${id}.json`, {
        data: { ...values }
      }),
    payload: { order: { ...values.order, id } }
  }
}

export const updateMarked = (id, value) => {
  return {
    types: [UPDATE_MARKED_START, UPDATE_MARKED_SUCCESS, UPDATE_MARKED_FAIL],
    promise: (client) =>
      client.put(`orders/${id}/update_mark.json`, { data: { marked: value } })
  }
}
