import React from "react"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import makeStyles from "@mui/styles/makeStyles"
import { withTouchDesign } from "components/App/JassThemeProvider"

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 16px)",
    display: "grid",
    placeItems: "center"
  }
})

const GlobalSpinner = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default withTouchDesign(GlobalSpinner)
