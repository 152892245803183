import React from "react"
import { I18n } from "react-redux-i18n"

import WarningIcon from "@mui/icons-material/Warning"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { Grid } from "@mui/material"

import useUpdateGoogleFontsUsageAgreement from "mutations/useUpdateGoogleFontsUsageAgreement"
import useConfirm from "hooks/useConfirm"
import Translate from "components/Translate/Translate"
import LoadingButton from "components/Buttons/LoadingButton"

const GoogleFontsUsageAgreement = ({
  entity,
  onSuccess,
  onCancel,
  sx = {},
  isLoading = false,
  error = false
}) => {
  const confirm = useConfirm()
  const updateGoogleFontsUsageAgreement = useUpdateGoogleFontsUsageAgreement()

  const confirmGoogleFontsUsageAgreement = () => {
    confirm(
      I18n.t("google-fonts-usage-agreements.confirm-text", {
        name: entity.file_name
      }),
      {
        confirmBtnText: (
          <Translate value="google-fonts-usage-agreements.confirm-button" />
        ),
        cancelBtnText: (
          <Translate value="google-fonts-usage-agreements.deny-button" />
        ),
        className: "confirmGoogleFontsUsage",
        onConfirm: () => {
          updateGoogleFontsUsageAgreement.mutate(
            {
              id: entity.google_fonts_usage_agreement.id
            },
            {
              onSuccess
            }
          )
        },
        onCancel
      }
    )
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      sx={{ pt: 1, ...sx }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <WarningIcon fontSize="small" color="warning" />
        <Typography color={error ? "error" : "orange"}>
          <Translate value="google-fonts-usage-agreements.contains-remote-fonts" />
        </Typography>
      </Stack>
      <Grid item>
        <LoadingButton
          id="confirm-google-fonts-agreement"
          loading={updateGoogleFontsUsageAgreement.isLoading || isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={confirmGoogleFontsUsageAgreement}
        >
          <Translate value="google-fonts-usage-agreements.confirm-button" />
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default GoogleFontsUsageAgreement
