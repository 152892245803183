import React from "react"
import Icon from "@mui/material/Icon"
import IconButton from "@mui/material/IconButton"
import omit from "lodash/omit"

const HeaderButton = ({ onClick, expanded, icon, className, ...rest }) => (
  <IconButton
    className={className}
    disableRipple
    onClick={onClick}
    {...omit(rest, ["primary"])}
    size="large"
  >
    {React.isValidElement(icon) ? (
      icon
    ) : (
      <Icon
        {...icon}
        style={{ fontSize: 18 }}
        color={expanded ? "primary" : "secondary"}
      />
    )}
  </IconButton>
)

export default HeaderButton
