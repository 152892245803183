import React from "react"
import omit from "lodash/omit"
import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import PropTypes from "prop-types"
import Autocomplete from "components/Autocomplete/Autocomplete"
import ApiClient from "utils/ApiClient"
import set from "lodash/set"
import deepmerge from "deepmerge"
import { error } from "store/modules/common/notify"
import { connect } from "react-redux"
import { I18n } from "react-redux-i18n"
import { serverError } from "utils/parsers/serverError"

const client = new ApiClient()

class RemoteAutocomplete extends React.PureComponent {
  state = {
    searchText: "",
    page: 1,
    dataSource: [],
    hasMoreItems: true,
    loading: false
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getSearchPart = (searchText) => {
    switch (this.props.variant) {
      case "term":
        return { term: searchText }
      case "search":
        return { search: searchText }
      case "ransack":
        return set({}, this.props.searchProp, searchText)
      default: {
        return { additional_search: { search_property: searchText } }
      }
    }
  }

  fetchSuggestions = (searchText) => {
    return (
      client
        // @ts-ignore
        .get(this.props.url, {
          params: deepmerge(this.props.params, {
            ...this.getSearchPart(searchText),
            page: this.state.page
          })
        })
        .sendRequest()
        .catch((response) => {
          const errors = JSON.parse(response.message || "{}")?.errors

          this.props.showError(
            serverError(errors ?? {}) || I18n.t("errors.server.500")
          )
        })
    )
  }

  _handleAPIRequest = async (searchText) => {
    this.setState({ loading: true })
    const response = await this.fetchSuggestions(searchText)
    const withTranslation = this.props.withTranslation
    const dataSource =
      this.props.mapResponseToDataSource(response, withTranslation) || []

    if (!(dataSource instanceof Array)) {
      throw new Error(
        `Response must be an array: please use mapResponseToDataSource function to fix that.
        Id: ${this.props.id}; ClassName: ${this.props.className}.
        Response: ${JSON.stringify(response)}.`
      )
    }

    if (this.mounted) {
      this.setState({
        loading: false,
        dataSource:
          this.state.page > 1
            ? this.state.dataSource.concat(dataSource)
            : dataSource,
        hasMoreItems: dataSource.length
      })
    }
  }

  throttledAPIRequest = throttle(this._handleAPIRequest, 500)
  debouncedAPIRequest = debounce(this._handleAPIRequest, 500)

  handleAPIRequest = (searchText) => {
    if (searchText === "" || searchText.endsWith(" ")) {
      return this.throttledAPIRequest(searchText)
    }
    return this.debouncedAPIRequest(searchText)
  }

  handleInput = (searchText, { action }) => {
    if (action === "input-change") {
      this.setState({ searchText, hasMoreItems: true, page: 1 }, () => {
        this.handleAPIRequest(searchText)
      })
    }
  }

  handleFocus = (e) => {
    this.handleAPIRequest("")
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  handleInfinityScroll = () => {
    if (!this.props.paged || this.state.loading || !this.state.hasMoreItems) {
      return
    }

    this.setState(
      ({ page }) => ({ page: page + 1 }),
      () => this.handleAPIRequest(this.state.searchText)
    )
  }

  render() {
    const { loading, dataSource } = this.state
    const rest = omit(this.props, ["mapResponseToDataSource", "url", "params"])

    return (
      <div className="new-jass-autocomplete">
        <Autocomplete
          {...rest}
          showClearButton={!loading}
          onFocus={this.handleFocus}
          onInputChange={this.handleInput}
          dataSource={dataSource}
          onMenuScrollToBottom={this.handleInfinityScroll}
          isLoading={loading}
        />
      </div>
    )
  }
}

RemoteAutocomplete.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
  multiple: PropTypes.bool
}

RemoteAutocomplete.defaultProps = {
  params: {},
  mapResponseToDataSource: (response) => response
}

export default connect(null, { showError: error })(RemoteAutocomplete)
