import React from "react"
import { Field } from "formik"
import last from "lodash/last"

const getClassName = (name) =>
  name.indexOf(".") < 0 ? name : last(name.split("."))

const AdField = ({ name, ...rest }) => (
  <Field
    path="adhoc_ad"
    name={name}
    className={`${getClassName(name)}-field`}
    {...rest}
  />
)

export default AdField
