import get from "lodash/get"
import isFunction from "lodash/isFunction"
import omit from "lodash/omit"
import { reduceReducers } from "store/helpers/reducers"

const INIT = "jass/components/filepicker/INIT"
const DESTROY = "jass/components/filepicker/DESTROY"
const UPLOAD_START = "jass/components/filepicker/UPLOAD_START"
const UPLOAD_SUCCESS = "jass/components/filepicker/UPLOAD_SUCCESS"
const UPLOAD_FAIL = "jass/components/filepicker/UPLOAD_FAIL"
const REMOVE_START = "jass/components/filepicker/REMOVE_START"
const REMOVE_SUCCESS = "jass/components/filepicker/REMOVE_SUCCESS"
const REMOVE_FAIL = "jass/components/filepicker/REMOVE_FAIL"

const initialState = {
  loading: false,
  error: null
}

export default reduceReducers([
  (state = {}, action = {}) => {
    switch (action.type) {
      case INIT:
        return {
          ...state,
          [action.id]: {
            ...initialState,
            ...get(state, action.id)
          }
        }
      case DESTROY:
        return { ...omit(state, [action.id]) }
      default:
        return state
    }
  },
  (state = {}, action = {}) => {
    switch (action.type) {
      case UPLOAD_START:
        return {
          ...state,
          [action.id]: {
            loading: true,
            loaded: false,
            params: get(action, "params", state.params),
            error: null
          }
        }
      case UPLOAD_SUCCESS:
        return {
          ...state,
          [action.id]: {
            ...initialState,
            loading: false,
            loaded: true,
            ...action.result
          }
        }
      case UPLOAD_FAIL:
        return {
          ...state,
          [action.id]: {
            loading: false,
            error: action.error
          }
        }
      default:
        return state
    }
  },
  (state = {}, action = {}) => {
    switch (action.type) {
      case REMOVE_START:
        return {
          ...state,
          [action.id]: {
            loading: true,
            loaded: false,
            params: get(action, "params", state.params),
            error: null
          }
        }
      case REMOVE_SUCCESS:
        return {
          ...state,
          [action.id]: {
            ...initialState,
            loading: false,
            loaded: true,
            ...(action.result, state || initialState)
          }
        }
      case REMOVE_FAIL:
        return {
          ...state,
          [action.id]: {
            loading: false,
            error: action.error
          }
        }
      default:
        return state
    }
  }
])

export function init({ id }) {
  return { type: INIT, id }
}

export function destroy(id) {
  return { type: DESTROY, id }
}

export const upload = ({
  url,
  attachments = null,
  params,
  method = "post"
}) => {
  return {
    types: [UPLOAD_START, UPLOAD_SUCCESS, UPLOAD_FAIL],
    options: {
      promiseTimeout: 0,
      ignoreConcurrency: true,
      useId: true
    },
    promise: (client) => client[method](url, { attachments, params })
  }
}

export const remove = (url, options) => {
  return {
    types: [REMOVE_START, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: (client) => client.del(isFunction(url) ? url(options) : url)
  }
}

export const getFiledState = (state, id) =>
  get(state, `components.filepicker[${id}]`)
