import { I18n } from "react-redux-i18n"
import get from "lodash/get"
import download from "downloadjs"
import ui from "store/modules/entities/adhocAd/ui"
import editor from "store/modules/entities/adhocAd/editor"
import { combineReducers } from "redux"
import createSelector from "re-reselect"

const prefix = "jass/adhoc-ad"

const CREATE_ZIP_AD_START = `${prefix}/CREATE_START`
const CREATE_ZIP_AD_SUCCESS = `${prefix}/CREATE_SUCCESS`
const CREATE_ZIP_AD_FAIL = `${prefix}/CREATE_FAIL`
const CREATE_PREVIEW_START = `${prefix}/LOAD_PREVIEW_START`
const CREATE_PREVIEW_SUCCESS = `${prefix}/LOAD_PREVIEW_SUCCESS`
const CREATE_PREVIEW_FAIL = `${prefix}/LOAD_PREVIEW_FAIL`
const GET_LAYOUT_START = `${prefix}/GET_LAYOUT_START`
const GET_LAYOUT_SUCCESS = `${prefix}/GET_LAYOUT_SUCCESS`
const GET_LAYOUT_FAIL = `${prefix}/GET_LAYOUT_FAIL`

export default combineReducers({
  ui,
  editor
})

export const createZippedAd = (body) => ({
  types: [CREATE_ZIP_AD_START, CREATE_ZIP_AD_SUCCESS, CREATE_ZIP_AD_FAIL],
  promise: () => {
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }

    const filename = `${body.title}.zip`

    return fetch("/adhoc_ads", config)
      .then((resp) => resp.blob())
      .then((resp) => download(resp, filename, "application/zip"))
  }
})

export const getLayout = (layout) => {
  return {
    types: [GET_LAYOUT_START, GET_LAYOUT_SUCCESS, GET_LAYOUT_FAIL],
    promise: (client) =>
      client.get(`/adhoc_ad_previews/${layout}?format=html`, { blob: true })
  }
}

export const createPreview = (params) => {
  return {
    types: [CREATE_PREVIEW_START, CREATE_PREVIEW_SUCCESS, CREATE_PREVIEW_FAIL],
    promise: (client) =>
      client.post("/adhoc_ad_previews", { data: params, blob: true })
  }
}

export const getInitialFormValues = (
  { vacancy = "", apply_url = "" } = { vacancy: "", apply_url: "" }
) => ({
  title: vacancy ?? "",
  intro_title: I18n.t("adhoc-ad.intro"),
  tasks_title: I18n.t("adhoc-ad.tasks"),
  profile_title: I18n.t("adhoc-ad.profile"),
  offer_title: I18n.t("adhoc-ad.offer"),
  contact_title: I18n.t("adhoc-ad.contact"),
  background_color: "#F5F5F5",
  title_color: "#000",
  apply_url: apply_url ?? "",
  apply_button_title: I18n.t("adhoc-ad.apply-title"),
  design: "layout1",
  logo_alignment: "left",
  logo: "",
  video_url: "",
  background_image: "",
  header_url: []
})

export const getInitialFormValuesWithApproval = (
  { vacancy = "", apply_url = "", approved = false } = {
    vacancy: "",
    apply_url: "",
    approved: false
  }
) => ({
  ...getInitialFormValues({ vacancy, apply_url }),
  approved: {
    intro: approved,
    title: approved,
    tasks: approved,
    profile: approved,
    offer: approved,
    contact: approved
  }
})

const prepareFormValues = (values) => {
  return {
    ...getInitialFormValues(),
    ...values
  }
}

export const getInitialValues = createSelector(
  (state) => get(state, "auth.user.settings.adhoc_ad.adEditor"),
  prepareFormValues
)(() => "adhoc-ad-form")

export const buildApplyUrl = (token) => {
  let host
  if (__DEVELOPMENT__) {
    host = "apply.recrumedia.local:4000"
  } else {
    host = window.location.host.replace(
      "jass.jobadpartner.de",
      "apply.recrumedia.com"
    )
  }

  return `${window.location.protocol}//${host}/${token}`
}

export const buildDirectUrl = (token) => {
  let host
  if (__DEVELOPMENT__) {
    host = "jobs.recrumedia.local:4000"
  } else {
    host = window.location.host.replace(
      "jass.jobadpartner.de",
      "jobs.recrumedia.com"
    )
  }

  return `${window.location.protocol}//${host}/${token}`
}
