import { I18n } from "react-redux-i18n"

import useNotifications from "hooks/useNotifications"
import { iconSchema } from "./schemas"
import { useIconGeneration } from "../api"

export const useHandleIconGeneration = (setFieldValue) => {
  const iconGeneration = useIconGeneration()
  const { showError } = useNotifications()

  return (values) => {
    const { icon_attributes } = values
    iconGeneration.mutate(
      {
        values: {
          ...iconSchema.cast(icon_attributes)
        }
      },
      {
        onSuccess: (response) => {
          setFieldValue("icon_id", response)
        },
        onError: () => {
          showError(I18n.t("suppliers.icon.error.message"))
        }
      }
    )
  }
}
