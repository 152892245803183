import { createReducer } from "store/helpers/reducers"

const prefix = "jass/statistics"
const LOAD_JOBS_START = `${prefix}/LOAD_JOBS_START`
const LOAD_JOBS_SUCCESS = `${prefix}/LOAD_JOBS_SUCCESS`
const LOAD_JOBS_FAIL = `${prefix}/LOAD_JOBS_FAIL`

export const loadJobs = () => {
  return {
    types: [LOAD_JOBS_START, LOAD_JOBS_SUCCESS, LOAD_JOBS_FAIL],
    promise: (client) => client.get("/statistics/jobs.json")
  }
}

export const jobsLoading = createReducer(false, {
  [LOAD_JOBS_START]: () => true,
  [LOAD_JOBS_SUCCESS]: () => false,
  [LOAD_JOBS_FAIL]: () => false
})

export const jobs = createReducer(
  {},
  {
    [LOAD_JOBS_SUCCESS]: (state, action) => action.result || state,
    [LOAD_JOBS_FAIL]: () => ({})
  }
)
