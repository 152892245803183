import React, { useCallback, useState } from "react"
import moment from "moment"
import { Typography, Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch } from "react-redux"
import Button from "components/TouchDesign/Components/Button"
import { Formik, Form, Field } from "formik"
import { DatePicker, Input } from "components/TouchDesign/Formik"
import * as Yup from "yup"
import useRecrumediaOffer from "queries/useRecrumediaOffer"
import useUpdateRecrumediaOffer from "mutations/useUpdateRecrumediaOffer"
import {
  info as infoNotify,
  error as errorNotify
} from "store/modules/common/notify"
import ShieldIcon from "components/Agency/IncompleteOffers/Confirmation/ShieldIcon"
import { defaultLanguage } from "utils/api"
import {
  Translate,
  translateText
} from "components/IncompleteOfferConfirmation/Translate"
import { Autocomplete } from "components/TouchDesign/Formik"
import Label from "components/FormControls/Label/Label"
import { I18n } from "react-redux-i18n"
import Price from "components/Prices/Price"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

const useStyles = makeStyles({
  content: {
    minHeight: 500,
    margin: "auto",
    width: "40%"
  },
  title: {
    margin: "20px 30px 0 30px",
    paddingBottom: 5,
    borderBottom: "1px solid rgb(230, 230, 230)"
  },
  actions: {
    gridRow: "1 / span 2",
    gridColumn: 2,
    padding: 30,
    paddingTop: 60,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    backgroundColor: "rgb(240, 240, 240)",
    justifyContent: "space-between"
  },
  actionsBox: {
    gridRow: 1,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    gridColumn: 2,
    paddingTop: 50,
    width: "366px",
    alignContent: "center",
    paddingBottom: 20
  },
  accept: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    position: "sticky",
    bottom: 32,
    width: "175px",
    lineHeight: 1
  },
  reject: {
    position: "sticky",
    bottom: 32,
    width: "175px"
  },
  icon: {
    fontSize: 130,
    color: "green"
  },
  validUntilOutdated: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  billingAddress: {
    marginBottom: 20
  },
  upsale: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  upsaleLabel: {
    fontSize: 13,
    fontWeight: 600,
    marginTop: 10,
    marginLeft: 3,
    marginBottom: 25
  },
  upsaleAmount: {
    fontSize: 13,
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 25
  }
})

const id = "confirm-incomplete-offer-dialog"
const getInitialValues = (offer) => {
  return {
    offer: offer,
    agency_subscription_start_date: initialSubscriptionStartDate(offer),
    upsale_amount: upsaleAmount(offer, moment().startOf("day")) || null
  }
}

const initialSubscriptionStartDate = (offer) => {
  if (offer.runtime) {
    if (
      offer?.available_start_date &&
      moment(offer?.available_start_date) > moment()
    ) {
      return moment(offer?.available_start_date).format()
    }
    return moment().format()
  }

  return null
}

const upsaleAmount = (offer, value) => {
  const customerAmount = offer?.price
  const endSubscriptionDate = moment(offer?.agency_subscription?.end_date)
    .startOf("day")
    .add(1, "days")
  const startSubscriptionDate = moment(
    offer?.agency_subscription?.start_date
  ).startOf("day")
  const upsaleStartDate = moment(value).startOf("day")
  const upsaleDurationInDays = Math.abs(
    Math.round(
      moment.duration(endSubscriptionDate.diff(upsaleStartDate)).asDays()
    )
  )
  const subscriptionDurationInDays = Math.abs(
    Math.round(
      moment.duration(endSubscriptionDate.diff(startSubscriptionDate)).asDays()
    )
  )
  return (customerAmount * upsaleDurationInDays) / subscriptionDurationInDays
}

const validationSchema = Yup.object({
  agency_subscription_start_date: Yup.date().when("offer.runtime", {
    is: true,
    then: Yup.date().required("required"),
    otherwise: Yup.date().nullable()
  })
})

const ConfirmationDialog = ({ token }) => {
  const dispatch = useDispatch()
  const [disableActions, setDisableActions] = useState(null)
  const { data: offer, isInitialLoading, isError } = useRecrumediaOffer(token)
  const { mutate: updateRecrumediaOffer } = useUpdateRecrumediaOffer()
  const runtime = offer?.runtime
  let availableDate
  if (offer?.available_start_date) {
    availableDate = moment(offer?.available_start_date)
  } else {
    availableDate = false
  }
  const classes = useStyles()
  const upsale = offer?.upsale
  const acceptingText = offer?.valid_until_outdated
    ? "incomplete-agency-offers.acceptance.actions.accept-anyway"
    : "incomplete-agency-offers.acceptance.actions.accept"
  const handleSubmit = useCallback(
    async (values) => {
      updateRecrumediaOffer(
        {
          id: offer.id,
          state_id: "accept",
          incomplete_offer: {
            agency_subscription_start_date:
              values.agency_subscription_start_date,
            confirmed_by_link: true,
            reference_number: values.reference_number,
            billing_address_id: values.offer.billing_address?.id,
            start_date: values.agency_subscription_start_date,
            upsale_amount: values.upsale_amount
          }
        },
        {
          onSuccess: () => {
            setDisableActions(true)
            dispatch(
              infoNotify(
                translateText(
                  "incomplete-agency-offers.confirmation-by-link.messages.accepting.success"
                )
              )
            )
          },
          onError: () => {
            dispatch(
              errorNotify(
                translateText(
                  "incomplete-agency-offers.confirmation-by-link.messages.accepting.failure"
                )
              )
            )
          }
        }
      )
    },
    [dispatch, offer, updateRecrumediaOffer]
  )

  const handleReject = useCallback(async () => {
    updateRecrumediaOffer(
      {
        id: offer.id,
        state_id: "reject"
      },
      {
        onSuccess: () => {
          setDisableActions(true)
          dispatch(
            infoNotify(
              translateText(
                "incomplete-agency-offers.confirmation-by-link.messages.rejection.success"
              )
            )
          )
        },
        onError: () => {
          dispatch(
            errorNotify(
              translateText(
                "incomplete-agency-offers.confirmation-by-link.messages.rejection.failure"
              )
            )
          )
        }
      }
    )
  }, [offer, updateRecrumediaOffer, dispatch])

  if (isInitialLoading) {
    return null
  }
  return (
    <div id={id}>
      {(isError || offer?.confirmation_token_invalid) && (
        <Typography variant="h5" align="center">
          <Translate value="incomplete-agency-offers.confirmation-by-link.messages.confirmation-token-is-invalid" />
        </Typography>
      )}
      {!isError && offer.state === "accepted" && (
        <Typography variant="h5" align="center">
          <Translate value="incomplete-agency-offers.confirmation-by-link.messages.already-accepted" />
        </Typography>
      )}
      {!isError && offer.state === "rejected" && (
        <Typography variant="h5" align="center">
          <Translate value="incomplete-agency-offers.confirmation-by-link.messages.already-rejected" />
        </Typography>
      )}
      {!isError &&
        offer.confirmation_token_expired &&
        !offer.confirmation_token_invalid && (
          <Typography variant="h5" align="center">
            <Translate value="incomplete-agency-offers.confirmation-by-link.messages.confirmation-token-is-expired" />
          </Typography>
        )}
      {!isError &&
        offer.state === "created" &&
        !offer.confirmation_token_expired &&
        !offer.confirmation_token_invalid && (
          <Formik
            onSubmit={handleSubmit}
            isInitialValid={true}
            initialValues={getInitialValues(offer)}
            validationSchema={validationSchema}
            render={({ setFieldValue, values }) => (
              <Form>
                <div className={classes.content}>
                  <section className={classes.fields}>
                    <Field
                      name="reference_number"
                      fullWidth
                      label={
                        <Translate value="incomplete-agency-offers.acceptance.reference-number.title" />
                      }
                      component={Input}
                    />

                    {runtime && (
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={defaultLanguage()}
                      >
                        <Field
                          name="agency_subscription_start_date"
                          fullWidth
                          minDate={availableDate}
                          label={
                            <Translate
                              value="incomplete-agency-offers.acceptance.start-date.title"
                              mandatory
                            />
                          }
                          placeholder={translateText(
                            "incomplete-agency-offers.acceptance.start-date.title"
                          )}
                          onChange={(value) => {
                            if (upsale) {
                              return setFieldValue(
                                "upsale_amount",
                                upsaleAmount(offer, value)
                              )
                            }
                            return null
                          }}
                          component={DatePicker}
                        />
                      </LocalizationProvider>
                    )}
                    {upsale && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.upsale}
                      >
                        <Box className={classes.upsaleLabel}>
                          <Translate value="incomplete-agency-offers.acceptance.upsale-amount.title" />
                          :
                        </Box>

                        <Box
                          id="upsale-amount"
                          className={classes.upsaleAmount}
                        >
                          <Price
                            value={values.upsale_amount}
                            format={
                              defaultLanguage() === "en" ? "0,0.00$" : "0.00 €"
                            }
                          />
                        </Box>
                      </Box>
                    )}
                    <Field
                      placeholder={I18n.t(
                        "incomplete-agency-offers.acceptance.billing-address"
                      )}
                      className={classes.billingAddress}
                      name="offer.billing_address"
                      id="select-billing-address"
                      component={Autocomplete}
                      options={offer.billing_addresses}
                      label={<Label value="search.billing-address" />}
                    />
                  </section>
                  <div className={classes.actions}>
                    <Box textAlign="center">
                      <Box marginBottom={3}>
                        <ShieldIcon />
                      </Box>
                      <Typography variant="h5" align="center">
                        <Translate value="incomplete-agency-offers.acceptance.info" />
                      </Typography>
                    </Box>
                    {offer.valid_until_outdated && (
                      <Box className={classes.validUntilOutdated}>
                        <Typography variant="h5" align="center">
                          <Translate
                            value="incomplete-agency-offers.confirmation-by-link.messages.valid-until-outdated"
                            insertedData={moment(offer.valid_until).format(
                              defaultLanguage() === "en"
                                ? "DD.MM.YYYY"
                                : "YYYY-MM-DD"
                            )}
                          />
                        </Typography>
                      </Box>
                    )}
                    <Box textAlign="center" className={classes.actionsBox}>
                      <Box>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleReject}
                          disabled={disableActions}
                          className={`${classes.reject} reject-button`}
                        >
                          <Translate value="incomplete-agency-offers.acceptance.actions.reject" />
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          disabled={disableActions}
                          type="submit"
                          color="primary"
                          className={`${classes.accept} accept-button`}
                          fullWidth
                          variant="contained"
                          size="large"
                        >
                          <Translate value={acceptingText} />
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Form>
            )}
          />
        )}
    </div>
  )
}

export default ConfirmationDialog
