/**
 * @param {string} baseUrl
 * @param {string} asset
 *
 * @returns {string}
 */
export const getAbsoluteUrl = (baseUrl, asset) => {
  if (asset.startsWith("http")) {
    return asset
  }

  const path = baseUrl.split("/").slice(0, -1).join("/")

  return `${path}/${asset}`
}
