import ButtonBase from "@mui/material/ButtonBase"
import Paper from "@mui/material/Paper"
import React from "react"
import classNames from "classnames"
import { CircularProgress } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "transparent",
    color: theme.palette.action.disabled,
    fill: theme.palette.action.disabled
  },
  selected: { backgroundColor: "#0d2251", color: "#fff", fill: "#fff" },
  paper: {
    position: "relative",
    height: "81px",
    width: "84px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "0 7px 17px 0 rgba(218, 217, 217, 0.42)"
  }
}))

const PanelButton = ({
  selected = false,
  loading = false,
  children,
  ...buttonProps
}) => {
  const classes = useStyles()

  return (
    <ButtonBase {...buttonProps} disabled={loading || buttonProps.disabled}>
      <Paper
        square
        className={classNames(classes.paper, {
          [classes.disabled]: buttonProps.disabled || loading,
          [classes.selected]: selected
        })}
      >
        {children}
        {loading && (
          <CircularProgress sx={{ position: "absolute" }} size={30} />
        )}
      </Paper>
    </ButtonBase>
  )
}

export default PanelButton
