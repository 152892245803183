import { Avatar, useTheme } from "@mui/material"
import React from "react"

const getAvatarSx = (size) => {
  switch (size) {
    case "medium":
      return { width: 32, height: 32 }
    default:
      return {}
  }
}

/**
 * @param {object} props
 * @param {import("@mui/material").SxProps} [props.sx]
 * @param {'medium'} [props.size]
 * @param {React.ReactNode} props.children
 */
export const IconContainer = ({ sx = {}, size = "medium", children }) => {
  const theme = useTheme()

  return (
    <Avatar
      variant="rounded"
      sx={{
        ...theme.typography.commonAvatar,
        ...getAvatarSx(size),
        backgroundColor: "#FF8533",
        color: "white",
        ...sx
      }}
    >
      {children}
    </Avatar>
  )
}
