import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill"
import React from "react"
import ReactDOM from "react-dom"
import "./utils/TestUtils"
import mockGooglePlacesAPI from "utils/mockGooglePlacesAPI"
import "url-search-params-polyfill"
import Root from "components/App/Root"
import { store } from "./store"
import { I18n } from "react-redux-i18n"

I18n.setHandleMissingTranslation((key) => {
  if (!key) return null

  if (key.startsWith("errors.")) {
    return key.slice("errors.".length)
  }

  return key.slice(key.lastIndexOf(".") + 1)
})

// @ts-ignore
global.EventSource = NativeEventSource || EventSourcePolyfill
const dest = document.getElementById("content")

// @ts-ignore
if (__NO_GEOCODER__) {
  mockGooglePlacesAPI()
}

// Do feature detection, to figure out which polyfills needs to be imported.
const loadPolyfills = () => {
  const polyfills = []

  if (!supportsResizeObserver()) {
    polyfills.push(import("resize-observer-polyfill"))
  }

  return Promise.all(polyfills)
}

const supportsResizeObserver = () => {
  return (
    "ResizeObserver" in global &&
    "ResizeObserverEntry" in global &&
    // @ts-ignore
    "contentRect" in ResizeObserverEntry.prototype
  )
}

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render")
  // @ts-ignore
  whyDidYouRender(React)
}

const render = (Component) => {
  ReactDOM.render(<Component store={store} history={history} />, dest)
}

loadPolyfills().then(() => render(Root))
