import React from "react"
import { Translate } from "react-redux-i18n"
import ErrorIcon from "@mui/icons-material/Error"
import { Grid, Typography } from "@mui/material"

export const SalaryWarning = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" minHeight={205}>
      <Grid item textAlign="center">
        <ErrorIcon color="error" sx={{ fontSize: 70, mb: 1 }} />
        <Typography variant="h3" lineHeight={1.5}>
          <Translate value="salary.leave-salary-estimation-to-job-portals" />
        </Typography>
        <Typography>
          <Translate value="salary.no-changes-possible-afterwards" />
        </Typography>
      </Grid>
    </Grid>
  )
}
