import omit from "lodash/omit"
import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const prefix = "jass/offerCommissions/"
const LOAD_OFFER_COMMISSIONS_TOTALS_START = `${prefix}/LOAD_OFFER_COMMISSIONS_TOTALS_START`
const LOAD_OFFER_COMMISSIONS_TOTALS_SUCCESS = `${prefix}/LOAD_OFFER_COMMISSIONS_TOTALS_SUCCESS`
const LOAD_OFFER_COMMISSIONS_TOTALS_FAIL = `${prefix}/LOAD_OFFER_COMMISSIONS_TOTALS_FAIL`

const loading = createReducer(false, {
  [LOAD_OFFER_COMMISSIONS_TOTALS_START]: () => true,
  [LOAD_OFFER_COMMISSIONS_TOTALS_SUCCESS]: () => false,
  [LOAD_OFFER_COMMISSIONS_TOTALS_FAIL]: () => false
})

const data = createReducer(
  { online_job_ads: {}, ae_products: {}, new_customers: {} },
  {
    [LOAD_OFFER_COMMISSIONS_TOTALS_SUCCESS]: (state, action) =>
      action.result.overview.commission_overview
  }
)

const totals = createReducer(
  {},
  {
    [LOAD_OFFER_COMMISSIONS_TOTALS_SUCCESS]: (state, action) =>
      omit(action.result, "overview")
  }
)

export default combineReducers({
  loading,
  data,
  totals
})

export const loadOfferCommissionsOverview = (params) => {
  return {
    types: [
      LOAD_OFFER_COMMISSIONS_TOTALS_START,
      LOAD_OFFER_COMMISSIONS_TOTALS_SUCCESS,
      LOAD_OFFER_COMMISSIONS_TOTALS_FAIL
    ],
    promise: (client) => client.get("commissions/totals.json", params)
  }
}

export const getOfferCommissionsOverviewLoading = (state) =>
  state.offerCommissions.overview.loading

export const getOfferCommissionsOverviewData = (state) =>
  state.offerCommissions.overview.data

export const getOfferCommissionsTotals = (state) =>
  state.offerCommissions.overview.totals
