import React from "react"
import { Avatar } from "@mui/material"

const SupplierIconComponent = ({ iconUrl, name = null }) => {
  const abbriviation = name
    ?.split(" ")
    ?.map((word) => word.charAt(0))
    ?.join("")
  return (
    <Avatar
      variant="rounded"
      sx={{
        width: 32,
        height: 32,
        borderRadius: "4px",
        backgroundColor: "#EDEDED",
        color: "#434243",
        fontSize: 14
      }}
      src={iconUrl}
    >
      {abbriviation}
    </Avatar>
  )
}

export const SupplierIcon = React.memo(SupplierIconComponent)
