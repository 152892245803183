import keyBy from "lodash/keyBy"
import map from "lodash/map"
import { combineReducers } from "redux"
import sort from "./sort"

const LOAD_WEBSHOP_OFFERS_POSITIONS_START =
  "jass/webshop-offers-positions/LOAD_START"
const LOAD_WEBSHOP_OFFERS_POSITIONS_SUCCESS =
  "jass/webshop-offers-positions/LOAD_SUCCESS"
const LOAD_WEBSHOP_OFFERS_POSITIONS_FAILURE =
  "jass/webshop-offers-positions/LOAD_FAILURE"

const byId = (state = {}, action) => {
  switch (action.type) {
    case LOAD_WEBSHOP_OFFERS_POSITIONS_SUCCESS:
      return keyBy(action.result, "id")
    default:
      return state
  }
}

const ids = (state = [], action) => {
  switch (action.type) {
    case LOAD_WEBSHOP_OFFERS_POSITIONS_SUCCESS:
      return map(action.result, "id")
    default:
      return state
  }
}

export const load = (id) => {
  return {
    types: [
      LOAD_WEBSHOP_OFFERS_POSITIONS_START,
      LOAD_WEBSHOP_OFFERS_POSITIONS_SUCCESS,
      LOAD_WEBSHOP_OFFERS_POSITIONS_FAILURE
    ],
    promise: (client) =>
      client.get(`/web_shop_orders/${id}/offers_positions.json`),
    id
  }
}

export default combineReducers({
  byId,
  ids,
  sort
})
