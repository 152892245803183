import React from "react"
import { Form } from "formik"
import { ScrollToError } from "shared/ui/berry-jass-formik"

export const FormWithScrollToError = ({ children, ...props }) => {
  return (
    <Form noValidate {...props}>
      <ScrollToError />
      {children}
    </Form>
  )
}
