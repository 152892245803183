import React from "react"
import { Box } from "@mui/material"

import "flag-icons/css/flag-icons.min.css"

const FlagIcon = ({ countryCode }) => {
  return (
    <Box
      sx={{
        width: "22px !important",
        lineHeight: "16.5px !important",
        borderRadius: "2px"
      }}
      className={`fi fi-${countryCode?.toLowerCase()} fib`}
    />
  )
}

export default FlagIcon
