import { useEffect, useState } from "react"

export const useDelayedMounted = (delay, ...deps) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    let mounted = true
    setTimeout(() => {
      if (mounted) {
        setReady(true)
      }
    }, delay)
    return () => {
      mounted = false
      setReady(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return ready
}
