import classnames from "classnames"
import React, { useEffect, useState } from "react"
import {
  useIsFetching as useIsQuerying,
  useIsMutating
} from "@tanstack/react-query"

const ReactQuerySeleniumTools = () => {
  const queriesCount = useIsQuerying()
  const mutationsCount = useIsMutating()
  const requestsCount = queriesCount + mutationsCount

  const [isIdle, setIsIdle] = useState(requestsCount === 0)

  useEffect(() => {
    let timer

    if (requestsCount > 0) {
      setIsIdle(false)
    } else if (!isIdle) {
      timer = setTimeout(() => {
        setIsIdle(true)
      }, 250)
    }

    return () => clearTimeout(timer)
  }, [isIdle, requestsCount])

  return (
    <div
      className={classnames("react-query-selenium-tools", {
        "react-query-busy": !isIdle,
        "react-query-idle": isIdle
      })}
    />
  )
}

export default ReactQuerySeleniumTools
