import TestUtils from "react-dom/test-utils"

global.TestUtils = TestUtils

global.testMethods = {
  scrollTable(tableId, data) {
    return tableId
      ? global.$(global).trigger(`table-scroll.${tableId}`, [data])
      : global
  }
}
