import reduce from "lodash/reduce"

export default (reducers) => (state, action) =>
  reduce(
    reducers,
    (state, reducer) => ({
      ...state,
      ...reducer(state, action)
    }),
    state
  )
