import React, { useRef } from "react"
import { Formik } from "formik"
import { Translate } from "react-redux-i18n"
import take from "lodash/take"
import isEqual from "lodash/isEqual"
import {
  Dialog,
  dialogClasses,
  Step,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  IconButton,
  DialogTitle,
  Stack,
  Button,
  DialogContent,
  Grid,
  Box
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { CustomStepperIcon } from "./CustomStepperIcon"
import { WizardProvider } from "./WizardProvider"
import { LoadingButton } from "@mui/lab"

export const FORM_ID = "formik-wizard-form"

const WizardStepper = ({ activeStep, invalidSteps, steps, handleJump }) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      sx={{
        maxWidth: 900,
        width: "100%",
        [`.${stepConnectorClasses.root}`]: { top: "15px" }
      }}
    >
      {steps.map(
        /**
         * @param {{ label: string; }} step
         * @param {number} index
         */
        (step, index) => {
          const labelProps = { optional: <div>&nbsp;</div> }

          if (invalidSteps[index]) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                <Translate value="error" />
              </Typography>
            )

            labelProps.error = true
          }

          return (
            <Step key={index}>
              <StepLabel
                {...labelProps}
                onClick={() => handleJump(index)}
                StepIconComponent={CustomStepperIcon}
              >
                {step.label}
              </StepLabel>
            </Step>
          )
        }
      )}
    </Stepper>
  )
}

const MemoizedWizardStepper = React.memo(WizardStepper)

const WizardToolbar = ({
  onClose,
  title,
  activeStep,
  handleBack,
  backLabel,
  isSubmitting,
  steps,
  hideSubmit,
  submitLabel,
  nextLabel,
  additionalButtons = []
}) => {
  return (
    <Toolbar
      sx={{
        minHeight: "80px !important"
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={3}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item md={6}>
          <DialogTitle sx={{ p: 1, textAlign: "center" }}>{title}</DialogTitle>
        </Grid>
        <Grid item md={3}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            {activeStep > 0 && (
              <Button variant="outlined" onClick={handleBack}>
                {backLabel}
              </Button>
            )}
            {additionalButtons
              .map((button) => {
                if (!button.props.restrictedSteps.includes(activeStep)) {
                  return button
                }
                return null
              })
              .filter(Boolean)}
            {!hideSubmit && steps.length === activeStep + 1 && (
              <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                type={steps[activeStep].onSubmit ? "button" : "submit"}
                onClick={steps[activeStep].onSubmit}
                form={FORM_ID}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                {submitLabel}
              </LoadingButton>
            )}

            {steps.length !== activeStep + 1 && (
              <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                type={steps[activeStep].onSubmit ? "button" : "submit"}
                onClick={steps[activeStep].onSubmit}
                form={FORM_ID}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                {nextLabel}
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

const MemoizedWizardToolbar = React.memo(WizardToolbar)

export const FormikWizardStepper = ({
  id,
  initialValues,
  activeStep = 0,
  steps,
  title,
  onSubmit,
  hideSubmit = false,
  submitLabel = <Translate value="submit" />,
  nextLabel = <Translate value="next" />,
  backLabel = <Translate value="back" />,
  onClose,
  additionalButtons = []
}) => {
  const submitter = useRef("")

  return (
    <WizardProvider
      initialState={{ stepsCount: steps.length, activeStep }}
      render={({
        invalidSteps,
        lastVisitedStep,
        setInvalidSteps,
        activeStep,
        handleBack,
        handleNext,
        handleJump
      }) => {
        const validationSchema = steps[activeStep].validationSchema
        const handleSubmit = async (values, formik) => {
          const isLastStep = steps.length === activeStep + 1
          if (isLastStep) {
            onSubmit({ ...values, submitter: submitter.current }, formik)
          } else {
            await handleNext()
            formik.setSubmitting(false)
            formik.setTouched({})
            if (lastVisitedStep > activeStep) {
              formik.validateForm()
            }
          }
        }
        return (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            render={(formik) => {
              const back = async () => {
                await handleBack()
                await formik.validateForm()
              }

              /** @param {number} step */
              const jump = async (step) => {
                if (formik.isValid || step < activeStep) {
                  await handleJump(step)
                }
                await formik.validateForm()
              }

              return (
                <Dialog
                  fullScreen
                  open
                  id={id}
                  onClose={onClose}
                  sx={{
                    [`& .${dialogClasses.paper}`]: {
                      padding: 0,
                      borderRadius: 0
                    }
                  }}
                >
                  <MemoizedWizardToolbar
                    onClose={onClose}
                    title={title}
                    activeStep={activeStep}
                    handleBack={back}
                    backLabel={backLabel}
                    isSubmitting={formik.isSubmitting}
                    steps={steps}
                    hideSubmit={hideSubmit}
                    submitLabel={submitLabel}
                    nextLabel={nextLabel}
                    additionalButtons={additionalButtons}
                  />
                  <DialogContent
                    dividers
                    sx={{
                      py: 2,
                      pl: 2,
                      pr: 1,
                      overflowY: "scroll",
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "none"
                    }}
                  >
                    <Box width="100%">
                      <form
                        noValidate
                        id={FORM_ID}
                        onSubmit={async (e) => {
                          e.preventDefault()
                          submitter.current = e.nativeEvent?.submitter
                          const newInvalidSteps = take(
                            steps,
                            lastVisitedStep + 1
                          ).map((step) => {
                            return (
                              step.validationSchema &&
                              !step.validationSchema.isValidSync(formik.values)
                            )
                          })
                          setInvalidSteps(
                            /** @param {boolean[]} prevState */
                            (prevState) => {
                              return isEqual(prevState, newInvalidSteps)
                                ? prevState
                                : newInvalidSteps
                            }
                          )

                          formik.handleSubmit(e)
                        }}
                      >
                        <Stack alignItems="center" gap={2}>
                          <MemoizedWizardStepper
                            activeStep={activeStep}
                            invalidSteps={invalidSteps}
                            handleJump={jump}
                            steps={steps}
                          />
                          {steps[activeStep].component}
                        </Stack>
                      </form>
                    </Box>
                  </DialogContent>
                </Dialog>
              )
            }}
          />
        )
      }}
    />
  )
}
