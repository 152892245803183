import React, { useState } from "react"
import { Box, Divider, Popover, Stack, Typography } from "@mui/material"
import Info from "@mui/icons-material/Info"
import { Translate } from "react-redux-i18n"

import { SubCard } from "shared/ui/berry-jass"
import useLocale from "hooks/useLocale"
import { getProductNameByLocale } from "entities/sales-product"

export const SalesProductInfoPopover = ({ product }) => {
  const locale = useLocale()
  const [anchorEl, setAnchorEl] = useState(null)

  const productName = getProductNameByLocale(product, locale)
  const supplierGroups = product.supplier_groups.length
    ? product.supplier_groups.map((group) => group.name).join(", ")
    : "—"

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: "flex",
          cursor: "pointer",
          "&:hover": { color: "primary.main" }
        }}
        data-testid={`product-info-${product.id}`}
      >
        <Info fontSize="inherit" />
      </Typography>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none", width: "25rem" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <SubCard
          sx={{ border: "unset" }}
          headSx={{ p: 2, pb: 0, height: "3rem", width: "25rem" }}
          contentSX={{ p: 0, px: 2, pb: 0, pt: 1 }}
          title={
            product.image_url ? (
              <img src={product.image_url} alt={product.supplier} height="32" />
            ) : (
              product.supplier
            )
          }
          withDivider={false}
        >
          <Stack gap={2}>
            <Box>
              <Typography fontWeight={500}>{productName}</Typography>

              <Typography sx={{ pt: 1 }}>{product.description}</Typography>
            </Box>

            <Divider />

            <Box>
              <Translate value="booking-wizard.product-info.supplier-groups" />

              <Typography
                color={supplierGroups !== "—" ? "primary.main" : "text.primary"}
                sx={{ pt: 1 }}
              >
                {supplierGroups}
              </Typography>
            </Box>
          </Stack>
        </SubCard>
      </Popover>
    </>
  )
}
