import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/key-account/list/LOAD_START"
const LOAD_SUCCESS = "jass/key-account/list/LOAD_SUCCESS"
const LOAD_FAIL = "jass/key-account/list/LOAD_FAIL"
const RESET = "jass/key-account/reset"

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer(null, {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page > 1) {
      return [...state, ...action.result]
    }

    return action.result.items
  },
  [RESET]: () => []
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state
})

const params = createReducer(
  {
    page: 1,
    sort: "",
    ascending: false,
    search: "",
    additional_search: {
      search_property: ""
    }
  },
  {
    [LOAD_SUCCESS]: (state, action) => action.params
  }
)

export default combineReducers({
  loading,
  items,
  count,
  params
})

export const load = (data) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.getHybrid("key_accounts", data)
  }
}

export const reset = () => {
  return { type: RESET }
}
