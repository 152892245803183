import React, { useEffect, useState } from "react"
import { DatePicker } from "./DatePicker"

export const DatePickerCell = ({
  name,
  initialValue,
  onChangeAsync,
  format,
  disabled = false,
  ...datePickerProps
}) => {
  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setValue(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format(initialValue)])

  const handleUpdate = (value) => {
    if (initialValue !== value) {
      setValue(value)
      setLoading(true)
      onChangeAsync(format(value))
        .catch(() => {
          setError(true)
          setValue(initialValue)
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <DatePicker
      {...datePickerProps}
      name={name}
      disabled={disabled || loading}
      value={value}
      error={error}
      onChange={setValue}
      onAccept={handleUpdate}
    />
  )
}
