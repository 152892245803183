import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import { Field } from "formik"
import { Grid } from "@mui/material"

import { Autocomplete, PriceInput } from "shared/ui/berry-jass-formik"
import currencies from "utils/currencies"

const intervals = ["annually", "monthly"]

export const SalaryFields = () => {
  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          required
          name="salary_minimum"
          component={PriceInput}
          decimalScale={0}
          fullWidth
          label={<Translate value="webshop.vacancy.salary.minimum" />}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          required
          name="salary_maximum"
          component={PriceInput}
          decimalScale={0}
          fullWidth
          label={<Translate value="webshop.vacancy.salary.maximum" />}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          required
          getOptionLabel={(currency) => currency}
          isOptionEqualToValue={(x, y) => x === y}
          id="salary_currency"
          name="salary_currency"
          component={Autocomplete}
          options={currencies}
          fullWidth
          label={<Translate value="webshop.vacancy.salary.currency" />}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          required
          id="salary_interval"
          name="salary_interval"
          options={intervals}
          getOptionLabel={(interval) =>
            I18n.t(`webshop.vacancy.salary.intervals.${interval}`)
          }
          isOptionEqualToValue={(x, y) => x === y}
          component={Autocomplete}
          fullWidth
          label={<Translate value="webshop.vacancy.salary.interval" />}
        />
      </Grid>
    </Grid>
  )
}
