import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import { createSelector } from "reselect"
import { createGetSort } from "store/helpers/reducers/load"
import qs from "qs"

const prefix = "jass/offerCommissions/"
const LOAD_OFFER_COMMISSIONS_START = `${prefix}/LOAD_OFFER_COMMISSIONS_START`
const LOAD_OFFER_COMMISSIONS_SUCCESS = `${prefix}/LOAD_OFFER_COMMISSIONS_SUCCESS`
const LOAD_OFFER_COMMISSIONS_FAIL = `${prefix}/LOAD_OFFER_COMMISSIONS_FAIL`

const SET_OFFER_COMMISSIONS_PARAMS = `${prefix}/SET_OFFER_COMMISSIONS_PARAMS`

const EXPORT_OFFER_COMMISSIONS_START = `${prefix}/EXPORT_OFFER_COMMISSIONS_START`
const EXPORT_OFFER_COMMISSIONS_SUCCESS = `${prefix}/EXPORT_OFFER_COMMISSIONS_SUCCESS`
const EXPORT_OFFER_COMMISSIONS_FAIL = `${prefix}/EXPORT_OFFER_COMMISSIONS_FAIL`

const loading = createReducer(false, {
  [LOAD_OFFER_COMMISSIONS_START]: () => true,
  [LOAD_OFFER_COMMISSIONS_SUCCESS]: () => false,
  [LOAD_OFFER_COMMISSIONS_FAIL]: () => false
})

const byId = createReducer(
  {},
  {
    [LOAD_OFFER_COMMISSIONS_SUCCESS]: (state, action) => {
      if (action.params.page !== 1) {
        return { ...state, ...action.result.byId }
      }
      return action.result.byId
    }
  }
)

const ids = createReducer([], {
  [LOAD_OFFER_COMMISSIONS_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }

    return action.result.ids
  }
})

const params = createReducer(
  {},
  {
    [SET_OFFER_COMMISSIONS_PARAMS]: (state, action) => action.payload
  }
)

const count = createReducer(0, {
  [LOAD_OFFER_COMMISSIONS_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state
})

export default combineReducers({
  loading,
  params,
  byId,
  ids,
  count
})

export const loadOfferCommissions = (params) => {
  return {
    types: [
      LOAD_OFFER_COMMISSIONS_START,
      LOAD_OFFER_COMMISSIONS_SUCCESS,
      LOAD_OFFER_COMMISSIONS_FAIL
    ],
    promise: (client) =>
      client.getNormalizedPagedResponse("commissions/invoices", params)
  }
}

export const setOfferCommissionsParams = (payload) => {
  return {
    type: SET_OFFER_COMMISSIONS_PARAMS,
    payload
  }
}

export const changeOfferCommissionsParams = (payload) => {
  return (dispatch, getState) => {
    const params = getOfferCommissionsParams(getState())

    return dispatch(setOfferCommissionsParams({ ...params, ...payload }))
  }
}

export const changeOfferCommissionsAdditionalSearchParams = (payload) => {
  return (dispatch, getState) => {
    const params = getOfferCommissionsParams(getState())

    return dispatch(
      changeOfferCommissionsParams({
        additional_search: { ...params.additional_search, ...payload }
      })
    )
  }
}

export const exportOfferCommissions = (params) => {
  return {
    types: [
      EXPORT_OFFER_COMMISSIONS_START,
      EXPORT_OFFER_COMMISSIONS_SUCCESS,
      EXPORT_OFFER_COMMISSIONS_FAIL
    ],
    promise: (client) =>
      client.get(`commissions/exports.json?${qs.stringify(params)}`)
  }
}

export const getOfferCommissions = createSelector(
  (state) => state.offerCommissions.list.ids,
  (state) => state.offerCommissions.list.byId,
  (ids, byId) => ids.map((id) => byId[id])
)

export const getOfferCommissionsCount = (state) =>
  state.offerCommissions.list.count

export const getOfferCommissionsParams = (state) =>
  state.offerCommissions.list.params

export const getOfferCommissionsSort = createGetSort("offerCommissions.list")

export const getOfferCommissionsLoading = (state) =>
  state.offerCommissions.list.loading
