import { inputBaseClasses } from "@mui/material"

export const outOfCardInputSx = {
  [`& .${inputBaseClasses.root}`]: {
    backgroundColor: "background.paper"
  },
  [`& .${inputBaseClasses.input}`]: {
    backgroundColor: "background.paper"
  }
}

export const outOfCardTableSx = {
  "& table td": {
    backgroundColor: "white"
  }
}

export const borderlessTableSx = {
  "& table": {
    "& tbody td": {
      border: "none",
      backgroundColor: "white",
      height: "58px",
      paddingY: 1,
      boxShadow: "none"
    }
  }
}

export const borderlessTableWithRelativeTrSx = {
  "& table": {
    "& tbody tr": { position: "relative" },
    "& tbody td": {
      border: "none",
      backgroundColor: "white",
      height: "58px",
      paddingY: 1,
      boxShadow: "none"
    }
  }
}

export const cardsTableSx = {
  background: "#f8f8f8",
  "& table": {
    "& th:first-of-type": {
      borderRadius: "8px 0 0 8px"
    },
    "& th:last-child": {
      borderRadius: "8px 0 0 8px"
    },
    "& tbody td": {
      border: "none",
      backgroundColor: "white",
      height: "16px",
      paddingY: 1
    },
    "& tbody:hover td": {
      backgroundColor: "#FEF7F2"
    },
    "& tbody tr:first-of-type td:first-of-type": {
      borderTopLeftRadius: "8px"
    },
    "& tbody tr:first-of-type td:last-child": {
      borderTopRightRadius: "8px"
    },
    "& tbody tr:nth-of-type(2) td:first-of-type": {
      borderBottomLeftRadius: "8px"
    },
    "& tbody tr:nth-of-type(2) td:last-child": {
      borderBottomRightRadius: "8px"
    },
    "& thead:after": {
      content: "'.'",
      lineHeight: "4px",
      color: "#f8f8f8",
      display: "block"
    },
    "& tbody:before": {
      content: "'.'",
      lineHeight: "4px",
      color: "#f8f8f8",
      display: "block"
    },
    "& tbody:after": {
      content: "'.'",
      lineHeight: "4px",
      color: "#f8f8f8",
      display: "block"
    },
    "& th span": {
      lineHeight: 2
    }
  }
}

/** @param {number} verticalOffset */
export const getFullHeightSx = (verticalOffset = 185) => {
  return {
    height: `max(200px, calc(100vh - ${verticalOffset}px))`,
    display: "flex",
    flexDirection: "column"
  }
}

export const tableActionFiltersSx = { width: 257 }
