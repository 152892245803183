import React, { forwardRef } from "react"
import NumberFormat from "react-number-format"
import numeral from "numeral"

const PriceInput = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  const decimalSeparator = numeral.languageData("de").delimiters.decimal
  const thousandSeparator = numeral.languageData("de").delimiters.thousands

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      allowedDecimalSeparators={[".", ","]}
      fixedDecimalScale
      decimalScale={2}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      isNumericString
      suffix=" €"
      {...other}
    />
  )
})

export default PriceInput
