import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import { prepareParams } from "./helpers"
import { extractAutocompleteValues } from "utils/parsers/autocompletes"
import qs from "qs"
import get from "lodash/get"
import pick from "lodash/pick"
import { getAddOnIds } from "utils/product"
import moment from "moment"

const LOAD_START = "jass/offer/LOAD_START"
const LOAD_SUCCESS = "jass/offer/LOAD_SUCCESS"
const LOAD_FAIL = "jass/offer/LOAD_FAIL"
const LOAD_TOTAL_START = "jass/offer/total/LOAD_TOTAL_START"
const LOAD_TOTAL_SUCCESS = "jass/offer/total/LOAD_TOTAL_SUCCESS"
const LOAD_TOTAL_FAIL = "jass/offer/total/LOAD_TOTAL_FAIL"
const BOOKOUT_START = "jass/offer/bookout_start"
const BOOKOUT_SUCCESS = "jass/offer/bookout_success"
const BOOKOUT_FAIL = "jass/offer/bookout_fail"
const EXPORT_ALL_CUSTOMER_ORDERS_START =
  "jass/offer/export_all_customer_orders/START"
const EXPORT_ALL_CUSTOMER_ORDERS_SUCCESS =
  "jass/offer/export_all_customer_orders/SUCCESS"
const EXPORT_ALL_CUSTOMER_ORDERS_FAIL =
  "jass/offer/export_all_customer_orders/FAIL"
const LOAD_PREVIEW_STATS_START = "jass/offer/LOAD_PREVIEW_STATS_START"
const LOAD_PREVIEW_STATS_SUCCESS = "jass/offer/LOAD_PREVIEW_STATS_SUCCESS"
const LOAD_PREVIEW_STATS_FAIL = "jass/offer/LOAD_PREVIEW_STATS_FAIL"
const CREATE_OFFER_PDF_PREVIEW_START =
  "jass/offer/CREATE_OFFER_PDF_PREVIEW_START"
const CREATE_OFFER_PDF_PREVIEW_SUCCESS =
  "jass/offer/CREATE_OFFER_PDF_PREVIEW_SUCCESS"
const CREATE_OFFER_PDF_PREVIEW_FAIL = "jass/offer/CREATE_OFFER_PDF_PREVIEW_FAIL"
const CREATE_OFFER_START = "jass/offer/CREATE_OFFER_START"
const CREATE_OFFER_SUCCESS = "jass/offer/CREATE_OFFER_SUCCESS"
const CREATE_OFFER_FAIL = "jass/offer/CREATE_OFFER_FAIL"
const SET_PARAMS = "jass/offer/SET_PARAMS"

const initialPreviewStats = {
  unit_price: 0,
  offer_price: 0,
  commission_price: 0,
  master_contribution: 0,
  master_commission: [
    {
      level: 1,
      offer_value: "2.000,00",
      margin: "400,00",
      rate: "0%",
      offer_commission: "0,00"
    },
    {
      level: 2,
      offer_value: "10.000,00",
      margin: "2.000,00",
      rate: "7.5%",
      offer_commission: "0,00"
    },
    {
      level: 3,
      offer_value: "15.000,00",
      margin: "3.000,00",
      rate: "12.5%",
      offer_commission: "0,00"
    },
    {
      level: 4,
      offer_value: "20.000,00",
      margin: "4.000,00",
      rate: "15%",
      offer_commission: "0,00"
    },
    {
      level: 5,
      offer_value: "25.000,00",
      margin: "5.000,00",
      rate: "20%",
      offer_commission: "0,00"
    },
    {
      level: 6,
      offer_value: "30.000,00",
      margin: "6.000,00",
      rate: "22.5%",
      offer_commission: "0,00"
    },
    {
      level: 7,
      offer_value: "30.000,00",
      margin: "6.000,00",
      rate: "25%",
      offer_commission: "0,00"
    }
  ],
  ae_commission: 0.0,
  offer_price_with_partner_discount: null
}

export default combineReducers({
  list: combineReducers({
    loading: createReducer(false, {
      [LOAD_START]: () => true,
      [LOAD_SUCCESS]: () => false,
      [LOAD_FAIL]: () => false
    }),
    items: createReducer([], {
      [LOAD_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...state, ...action.result]
        }

        return action.result.items
      }
    }),
    count: createReducer(0, {
      [LOAD_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state
    }),
    params: createReducer(
      {},
      {
        [LOAD_SUCCESS]: (state, action) => action.params,
        [SET_PARAMS]: (state, action) => action.params
      }
    )
  }),
  total: createReducer(
    {},
    {
      [LOAD_TOTAL_SUCCESS]: (state, action) => action.result
    }
  ),
  previewStats: createReducer(
    { loading: false, data: initialPreviewStats },
    {
      [LOAD_PREVIEW_STATS_START]: (state) => ({ ...state, loading: true }),
      [LOAD_PREVIEW_STATS_FAIL]: (state) => ({ ...state, loading: false }),
      [LOAD_PREVIEW_STATS_SUCCESS]: (state, action) => ({
        ...state,
        data: action.result,
        loading: false
      })
    }
  )
})

export const load = ({ params, from }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.getHybrid("offers", {
        params: prepareParams({ ...params }),
        from
      })
  }
}

export const exportAllCustomerOrders = ({ params }) => {
  return {
    types: [
      EXPORT_ALL_CUSTOMER_ORDERS_START,
      EXPORT_ALL_CUSTOMER_ORDERS_SUCCESS,
      EXPORT_ALL_CUSTOMER_ORDERS_FAIL
    ],
    promise: (client) =>
      client.get("all_customer_orders/export.json", {
        params: prepareParams(params)
      })
  }
}

export const loadTotal = ({ params }) => {
  return {
    types: [LOAD_TOTAL_START, LOAD_TOTAL_SUCCESS, LOAD_TOTAL_FAIL],
    promise: (client) =>
      client.get("offers/total.json", { params: prepareParams(params) })
  }
}

export const bookout = (id, data) => {
  const config = { single: ["reason_id"] }
  return {
    types: [BOOKOUT_START, BOOKOUT_SUCCESS, BOOKOUT_FAIL],
    promise: (client) =>
      client.put(`offers/${id}/bookout`, {
        data: extractAutocompleteValues(data, config)
      })
  }
}

export const loadPreviewStats = ({
  ids,
  price,
  amount,
  customerId,
  slotSubscription,
  addOns,
  aeOnlineProducts = [],
  aePrintProducts = []
}) => {
  const params = qs.stringify(
    {
      online_products_ids: ids,
      amount,
      jap_customer_id: customerId,
      offer_wizard: true,
      add_on_ids: pick(getAddOnIds(addOns), ids),
      slots_subscription: slotSubscription,
      offer_price: price,
      ae_products: aeOnlineProducts.concat(aePrintProducts)
    },
    { arrayFormat: "brackets" }
  )

  return {
    types: [
      LOAD_PREVIEW_STATS_START,
      LOAD_PREVIEW_STATS_SUCCESS,
      LOAD_PREVIEW_STATS_FAIL
    ],
    promise: (client) => client.get(`/offers/preview_stats.json?${params}`),
    params
  }
}

const prepareValues = (values) => {
  return {
    reference_number: values.reference_number,
    amount: values.amount,
    billing_type: values.billing_type.value,
    product_ids: values.products,
    add_on_ids: pick(getAddOnIds(values.addOns), values.products),
    offer_price: values.offer_price,
    calculated_offer_price: values.calculated_offer_price,
    vacancy_title: values.vacancy_title,
    industry_id: values.industry?.id,
    notes: values.offer_notes,
    accounting_offer: values.accounting_offer,
    location_attributes: {
      city: values.city?.city,
      zip: values.zip?.zip_code,
      street: values.street,
      street_number: values.street_number,
      country: values.country?.id
    },
    budget_offer: !!values.budget_products_ids?.filter((value) =>
      values.products.includes(value)
    )?.length,
    slot_offer: !!values.markedProducts?.filter((value) => value.slot_product)
      ?.length,
    sales_expected_until:
      values.sales_expected_until &&
      moment(values.sales_expected_until).format("DD.MM.YYYY"),
    chance: values.chance,
    ae_online_products: (values.ae_online_products || []).map((product) => ({
      supplier_id: product.supplier.id,
      supplier_name: product.supplier.name,
      name: product.name,
      description: product.description,
      runtime_in_days: product.runtime_in_days,
      rrp: product.rrp,
      purchase_price: product.purchase_price,
      retail_price: product.retail_price
    })),
    ae_print_products: (values.ae_print_products || []).map((product) => ({
      supplier_id: product.supplier.id,
      supplier_name: product.supplier.name,
      name: product.name,
      description: product.description,
      publishing_date: product.publishing_date,
      closing_date: product.closing_date,
      colors: product.colors.value,
      height: product.height,
      width: product.width,
      price_mm2: product.price_mm2,
      rrp: product.rrp,
      ae_percent: product.ae_percent,
      purchase_price: product.purchase_price,
      retail_price: product.retail_price
    })),
    running_time_in_months: values.running_time_in_months,
    slots_count: values.slots_count,
    use_slots_for_sub_customers: values.use_for_sub_customers,
    slots_payment_type: get(values.slots_payment_type, "id"),
    partner_id: get(values.partner, "id"),
    jap_customer_id: values.customer.id,
    billing_address_id: values.billing_address.id,
    contact_id: values.contact.id,
    unit_price: values.unit_price,
    contribution_margin: values.contribution_margin,
    save_only: values?.save_only,
    recommended_sales_product_ids: values.recommended_sales_product_ids,
    email:
      "Wie besprochen erhalten Sie anbei Ihr individuelles Angebot. Falls Sie weitere Informationen benötigen oder Fragen haben, stehe ich Ihnen jederzeit gerne persönlich zur Verfügung.<br/><br/>Das Angebot können Sie direkt per Fax oder einfach Online bestätigen<br/><br/>Mit freundlichem Gruß,<br/>",
    fixated_contact_datum: values.fixated_contact_datum,
    offer_base_datum: values.offer_base_datum,
    offers_positions: values.offers_positions?.reduce((a, e, i) => {
      if (e) {
        a.push({ ...e, id: i })
      }
      return a
    }, [])
  }
}

export const createPreview = (data) => {
  return {
    types: [
      CREATE_OFFER_PDF_PREVIEW_START,
      CREATE_OFFER_PDF_PREVIEW_SUCCESS,
      CREATE_OFFER_PDF_PREVIEW_FAIL
    ],
    promise: (client) =>
      client.post("/offer_pdf_previews", {
        data: { offer: prepareValues(data) }
      })
  }
}
export const create = (data) => {
  return {
    types: [CREATE_OFFER_START, CREATE_OFFER_SUCCESS, CREATE_OFFER_FAIL],
    promise: (client) =>
      client.post("/offers", {
        data: { offer: prepareValues(data) }
      })
  }
}
