import React from "react"
import { Translate } from "react-redux-i18n"
import {
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Box,
  Typography,
  TextField as ReadOnlyTextField
} from "@mui/material"
import { connect, FieldArray, Field } from "formik"
import { PriceInput } from "shared/ui/berry-jass-formik"
import { getPriceInputAdornmentProps } from "shared"

export const NewGradedPricingForm = connect(({ salesProduct, prefix }) => {
  return (
    <Box>
      <Typography variant="h5">
        <Translate value="sales-products-new.graded-pricing.title" />
      </Typography>
      <FieldArray
        name={prefix}
        render={() => {
          return (
            <Box mt={1.5}>
              <Table sx={{ tableLayout: "fixed" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="90px">
                      <Translate value="product-prices.from.title" />
                    </TableCell>
                    <TableCell width="90px">
                      <Translate value="product-prices.to.title" />
                    </TableCell>
                    <TableCell width="150px">
                      <Translate value="product-prices.mp.title" />
                    </TableCell>
                    <TableCell width="150px">
                      <Translate value="product-prices.common-price.title" />
                    </TableCell>
                    <TableCell width="150px">
                      <Translate value="product-prices.ll.title" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesProduct.product_prices.map((product_price, index) => {
                    return (
                      <TableRow
                        className={`product-price-${index}`}
                        key={product_price.id ?? product_price.virtualId}
                      >
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <ReadOnlyTextField
                            disabled
                            value={product_price.from}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <ReadOnlyTextField
                            disabled
                            value={product_price.to}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            name={`${prefix}.${index}.new_market_price`}
                            component={PriceInput}
                            {...getPriceInputAdornmentProps(
                              product_price.market_price
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            name={`${prefix}.${index}.new_price`}
                            component={PriceInput}
                            {...getPriceInputAdornmentProps(
                              product_price.price
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            name={`${prefix}.${index}.new_lower_limit`}
                            component={PriceInput}
                            {...getPriceInputAdornmentProps(
                              product_price.lower_limit
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          )
        }}
      />
    </Box>
  )
})
