import React from "react"
import Input from "components/FormControls/Input/Input"
import LinkIcon from "@mui/icons-material/Link"
import { url } from "utils/validation"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  input: {
    paddingRight: 30,
    textOverflow: "ellipsis"
  },
  link: {
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0
  }
})

const LinkInput = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Input {...props} inputClassName={classes.input} />
      <a
        target="_blank"
        href={url(props.value) ? props.value : null}
        className={classNames(classes.link)}
        rel="noreferrer"
      >
        <LinkIcon />
      </a>
    </div>
  )
}

export default LinkInput
