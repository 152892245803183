import auth from "./auth"
import notify from "./notify"
import utils from "./utils"
import maintenance from "./maintenance"

export default {
  auth,
  notify,
  utils,
  maintenance
}
