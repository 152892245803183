import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const prefix = "jass/jap-customers/versions"
const LOAD_LAST_VERSION_START = `${prefix}/LOAD_LAST_VERSION_START`
const LOAD_LAST_VERSION_SUCCESS = `${prefix}/LOAD_LAST_VERSION_SUCCESS`
const LOAD_LAST_VERSION_FAIL = `${prefix}/LOAD_LAST_VERSION_FAIL`

const items = createReducer([], {
  [LOAD_LAST_VERSION_START]: () => [],
  [LOAD_LAST_VERSION_SUCCESS]: (state, action) => [action.result]
})

export const loadLastVersion = (customerId) => {
  return {
    types: [
      LOAD_LAST_VERSION_START,
      LOAD_LAST_VERSION_SUCCESS,
      LOAD_LAST_VERSION_FAIL
    ],
    promise: (client) =>
      client.get(`/jap_customers/${customerId}/versions/last`)
  }
}

export default combineReducers({ items })
