import { flatten } from "lodash"

export const getNextPageParam = (lastPage) => {
  const { page, total_pages: totalPages } = lastPage.meta
  return totalPages >= page + 1 ? page + 1 : undefined
}

export const getDataTableSortsParam = (sorts) => {
  return sorts
    .map((sort) => `${sort.id} ${sort.desc ? "desc" : "asc"}`)
    .join(", ")
}

export const getTableProps = (query) => {
  const allData = flatten(query?.data?.pages?.map((page) => page.data)) ?? []

  return {
    data: allData,
    fetchMore: query.fetchNextPage,
    canFetchMore: !query.isFetching && query.hasNextPage,
    isLoading: query.isLoading,
    isFetching: query.isLoading
  }
}

export const getTableMeta = (query) => {
  if (!query?.data?.pages) {
    return {}
  }

  return query.data.pages[query.data.pages.length - 1]?.meta ?? {}
}
