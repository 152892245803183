import React from "react"
import { Chip } from "@mui/material"
import { I18n } from "react-redux-i18n"

/**
 * @param {import("@mui/material").ChipProps & {backgroundColor: string}} props
 */
export const StateTag = ({ backgroundColor, ...chipProps }) => {
  return (
    <Chip
      sx={{
        fontWeight: 500,
        color: "grey.600",
        backgroundColor
      }}
      {...chipProps}
      size="small"
    />
  )
}

/**
 * @param {import("@mui/material").ChipProps & {active: boolean}} props
 */
export const ActiveTag = ({ active, ...chipProps }) => {
  const backgroundColor = active ? "success.light" : "error.light"
  const label = active ? I18n.t("active") : I18n.t("inactive")

  return (
    <StateTag {...chipProps} backgroundColor={backgroundColor} label={label} />
  )
}
