import useIconStyles from "hooks/useIconStyles"
import usePopup from "hooks/usePopup"
import React from "react"
import PanelButton from "components/TouchDesign/Components/PanelButton"
import Popover from "@mui/material/Popover"
import Box from "@mui/material/Box"
import AdField from "components/AdEditor/AdField"
import Button from "@mui/material/Button"
import { URLFilePicker } from "components/TouchDesign/Formik"
import last from "lodash/last"
import classNames from "classnames"
import makeStyles from "@mui/styles/makeStyles"
import Translate from "components/Translate/Translate"
import { Formik } from "formik"

const imagesEndpoint = {
  upload: {
    url: "images",
    params: { external: true },
    prepareAttachments: (file) => [file],
    extractValue: (file) => file?.image?.url
  },
  extractFileName: (url) => last(url.split("/"))
}

const useActionButtonStyles = makeStyles({
  root: {
    textTransform: "none",
    fontSize: 16,
    fontFamily: "Roboto Slab"
  },
  primary: {
    backgroundColor: "#34384e"
  }
})

const PopoverURLFilePicker = ({
  api = imagesEndpoint,
  name,
  icon,
  title,
  label,
  value,
  onChange
}) => {
  const iconClasses = useIconStyles()
  const actionButtonClasses = useActionButtonStyles()
  const { anchorEl, handleOpen, handleClose } = usePopup()
  const shortName = last(name.split("."))

  const onSubmit = (values) => {
    const result = onChange(values[shortName])
    if (result instanceof Promise) {
      return result.then(handleClose)
    }

    handleClose()
    return result
  }

  return (
    <React.Fragment>
      <PanelButton id={`${shortName}-btn`} onClick={handleOpen}>
        {icon}
        <span className={iconClasses.title}>{title}</span>
      </PanelButton>

      <Popover
        id={`${shortName}-popover`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Formik
          initialValues={{ [shortName]: value }}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <Box
              id={`${shortName}-form`}
              component="form"
              padding={2}
              width="320px"
              onSubmit={(e) => {
                e.stopPropagation()
                // @ts-ignore
                return handleSubmit(e)
              }}
            >
              <AdField
                id={shortName}
                name={shortName}
                component={URLFilePicker}
                api={api}
                label={label}
              />

              <Box
                marginTop="36px"
                display="grid"
                gridTemplateColumns="1fr 1fr"
                columnGap="10px"
              >
                <Button
                  onClick={handleClose}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  className={actionButtonClasses.root}
                >
                  <Translate value="cancel" />
                </Button>
                <Button
                  className={classNames(
                    actionButtonClasses.root,
                    actionButtonClasses.primary
                  )}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  <Translate value="save" />
                </Button>
              </Box>
            </Box>
          )}
        />
      </Popover>
    </React.Fragment>
  )
}

export default PopoverURLFilePicker
