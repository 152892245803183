import { createReducer } from "store/helpers/reducers"

const prefix = "jass/statistics/published-ads/count"

const LOAD_FAIL = `${prefix}/LOAD_FAIL`
const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`

export default createReducer(null, {
  [LOAD_SUCCESS]: (_, action) => action.result.published_ads.count,
  [LOAD_FAIL]: () => null
})

export const loadCount = () => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get("/statistics/published_ads/count.json")
})

export const getCount = (state) => state.statistics.publishedAds.count
