import React from "react"
import { fieldToTextField } from "./TextField"
import { Wysiwyg as JBWysiwyg } from "shared/ui/berry-jass/Wysiwyg"

export const Wysiwyg = (props) => {
  const onChange = (...args) => {
    props.field.onChange(props.field.name)(...args)
    if (props.onChange) {
      props.onChange(...args)
    }
  }

  return <JBWysiwyg {...fieldToTextField(props)} onChange={onChange} />
}
