import React from "react"

class BodyClassName extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.className)
  }

  componentWillUnmount() {
    document.body.classList.remove(this.props.className)
  }

  render() {
    return this.props.children ? React.Children.only(this.props.children) : null
  }
}

export default BodyClassName
