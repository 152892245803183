import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const prefix = "jass/history"
const LOAD_VERSIONS_START = `${prefix}/agencyCustomer/LOAD_VERSIONS_START`
const LOAD_VERSIONS_SUCCESS = `${prefix}/agencyCustomer/LOAD_VERSIONS_SUCCESS`
const LOAD_VERSIONS_FAIL = `${prefix}/agencyCustomer/LOAD_VERSIONS_FAIL`
const CLEAR_VERSIONS = `${prefix}/agencyCustomer/CLEAR_VERSIONS`

const loading = createReducer(false, {
  [LOAD_VERSIONS_START]: () => true,
  [LOAD_VERSIONS_SUCCESS]: () => false,
  [LOAD_VERSIONS_FAIL]: () => false
})

const items = createReducer(null, {
  [LOAD_VERSIONS_SUCCESS]: (_, action) => action.result,
  [CLEAR_VERSIONS]: () => null
})

export default combineReducers({ items, loading })

export const load = ({ id }) => {
  return {
    types: [LOAD_VERSIONS_START, LOAD_VERSIONS_SUCCESS, LOAD_VERSIONS_FAIL],
    promise: (client) => client.get(`agency_customers/${id}/versions`)
  }
}

export const clearVersions = () => ({ type: CLEAR_VERSIONS })

export const isLoading = (state) => state.history.agencyCustomer.loading
