import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const prefix = "jass/prices-matrix/ui"
const SELECT = `${prefix}/SELECT`
const SET_INVALID_COLUMN_INDEXES = `${prefix}/SET_INVALID_COLUMN_INDEXES`
const RESET_INVALID_COLUMN_INDEXES = `${prefix}/RESET_INVALID_COLUMN_INDEXES`

const selection = createReducer([], {
  [SELECT]: (_, action) => action.payload
})

const invalidColumnIndexes = createReducer([], {
  [SET_INVALID_COLUMN_INDEXES]: (_, action) => action.payload,
  [RESET_INVALID_COLUMN_INDEXES]: () => ({})
})

export default combineReducers({
  selection,
  invalidColumnIndexes
})

export const select = (payload) => ({
  type: SELECT,
  payload
})

export const setInvalidColumnIndexes = (payload) => ({
  type: SET_INVALID_COLUMN_INDEXES,
  payload
})

export const resetInvalidColumnIndexes = () => ({
  type: RESET_INVALID_COLUMN_INDEXES
})

export const getSelection = (state) => state.pricesMatrix.ui.selection

export const getInvalidColumnIndexes = (state) =>
  state.pricesMatrix.ui.invalidColumnIndexes
