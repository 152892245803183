import React from "react"
import { Translate } from "react-redux-i18n"
import { Box } from "@mui/material"

import { formatPrice } from "utils/parsers/prices"
import { localizedDateTime } from "shared/lib/format"
import { ExpandCell, TooltipCell } from "shared/ui/berry-jass"

export const expandColumn = {
  id: "expand",
  size: 50,
  cell: (info) => <ExpandCell row={info.row} />
}

export const orderNoColumn = {
  accessorKey: "order_no",
  header: () => <Translate value="web-shop.job-orders-table.order-no" />,
  cell: (info) => <TooltipCell value={info.getValue()} />,
  enableSorting: true
}

export const referenceNumberColumn = {
  id: "offer_reference_number",
  accessorFn: (row) => row.reference_number,
  header: () => (
    <Translate value="web-shop.job-orders-table.reference-number" />
  ),
  cell: (info) => <TooltipCell value={info.getValue()} />,
  enableSorting: true
}

export const agencyColumn = {
  accessorKey: "agency",
  header: () => <Translate value="web-shop.job-orders-table.agency.name" />,
  cell: (info) => <TooltipCell value={info.getValue()} />
}

export const customerColumn = {
  id: "offer_jap_customer_company_name",
  accessorFn: (row) => row.customer_company_name,
  header: () => <Translate value="web-shop.job-orders-table.customer" />,
  cell: (info) => <TooltipCell value={info.getValue()} />,
  enableSorting: true
}

export const partnerCustomerColumn = {
  id: "offer_jap_customer_company_name",
  accessorFn: (row) => row.partner_customer_company_name,
  header: () => <Translate value="web-shop.job-orders-table.customer" />,
  cell: (info) => <TooltipCell value={info.getValue()} />,
  enableSorting: true
}

export const partnerSubCustomerColumn = {
  accessorKey: "partner_sub_customer_company_name",
  header: () => <Translate value="web-shop.job-orders-table.sub-customer" />,
  cell: (info) => <TooltipCell value={info.getValue()} />
}

export const vacancyTitleColumn = {
  accessorKey: "vacancy_title",
  header: () => <Translate value="web-shop.job-orders-table.vacancy" />,
  cell: (info) => <TooltipCell value={info.getValue()} />,
  enableSorting: true
}

export const salesProductsCountColumn = {
  accessorKey: "sales_products_count",
  header: () => <Translate value="web-shop.job-orders-table.products" />,
  cell: (info) => (
    <Box data-testid="sales-product-count">{info.getValue() || "—"}</Box>
  )
}

export const offerPriceColumn = {
  accessorKey: "offer_price",
  header: () => <Translate value="web-shop.job-orders-table.offer-price" />,
  cell: (info) => formatPrice(info.getValue())
}

export const priceColumn = {
  accessorKey: "price",
  header: () => <Translate value="web-shop.job-orders-table.offer-price" />,
  cell: (info) => formatPrice(info.getValue())
}

export const keyAccountColumn = {
  accessorKey: "key_account",
  header: () => <Translate value="web-shop.job-orders-table.key-account" />,
  cell: (info) => <TooltipCell value={info.getValue()} />
}

export const createdAtColumn = {
  accessorKey: "created_at",
  header: () => <Translate value="web-shop.job-orders-table.created-at" />,
  cell: (info) => <TooltipCell value={localizedDateTime(info.getValue())} />,
  enableSorting: true
}

export const creatorNameColumn = {
  accessorKey: "creator_name",
  header: () => <Translate value="web-shop.job-orders-table.created-by" />,
  cell: (info) => <TooltipCell value={info.getValue()} />
}

export const stateColumn = {
  accessorKey: "state",
  header: () => <Translate value="web-shop.job-orders-table.state" />,
  cell: (info) => (
    <TooltipCell
      value={
        <Translate
          value={`web-shop.job-orders-table.states.${info.getValue()}`}
        />
      }
    />
  )
}
