import React, { useMemo } from "react"
import map from "lodash/map"
import { I18n } from "react-redux-i18n"
import Autocomplete from "components/Autocomplete/Autocomplete"

/**
 *
 * @param {object} props
 * @param {string} props.i18nKey
 */
const LocalI18nAutocomplete = ({ i18nKey, ...rest }) => {
  const dataSource = useMemo(
    () =>
      map(I18n.t(i18nKey), (label, value) => ({
        label: label,
        value: value
      })),
    [i18nKey]
  )

  return <Autocomplete dataSource={dataSource} {...rest} />
}

export default LocalI18nAutocomplete
