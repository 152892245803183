const SET_EDITOR = "jass/adhocAd/SET_EDITOR"
const SET_PREVIEW = "jass/adhocAd/SET_PREVIEW"
const SET_LOADING = "jass/adhocAd/SET_LOADING"
const SET_EXTRACTION_LOADING = "jass/adhocAd/SET_EXTRACTION_LOADING"

const initialState = {
  editor: true,
  preview: false,
  source: false,
  loading: false,
  extractionLoading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITOR:
      return { ...state, editor: true, preview: false, source: false }
    case SET_PREVIEW:
      return { ...state, preview: true, editor: false, source: false }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_EXTRACTION_LOADING:
      return { ...state, extractionLoading: action.payload }
    default:
      return state
  }
}

export const setEditor = () => ({ type: SET_EDITOR })
export const setPreview = () => ({ type: SET_PREVIEW })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setExtractionLoading = (payload) => ({
  type: SET_EXTRACTION_LOADING,
  payload
})

export default reducer
