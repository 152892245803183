import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import compact from "lodash/compact"

const prefix = "jass/PRODUCTION_OVERVIEW/STATE_TRANSITIONS"

const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`

const UPDATE_STATE_START = `${prefix}/UPDATE_STATE_START`
const UPDATE_STATE_SUCCESS = `${prefix}/UPDATE_STATE_SUCCESS`
const UPDATE_STATE_FAIL = `${prefix}/UPDATE_STATE_FAIL`

const ASSIGN_START = `${prefix}/ASSIGN_START`
const ASSIGN_SUCCESS = `${prefix}/ASSIGN_SUCCESS`
const ASSIGN_FAIL = `${prefix}/ASSIGN_FAIL`

const UNASSIGN_START = `${prefix}/UNASSIGN_START`
const UNASSIGN_SUCCESS = `${prefix}/UNASSIGN_SUCCESS`
const UNASSIGN_FAIL = `${prefix}/UNASSIGN_FAIL`

const SEND_PUBLISHING_REPORT_START = `${prefix}/SEND_PUBLISHING_REPORT_START`
const SEND_PUBLISHING_REPORT_SUCCESS = `${prefix}/SEND_PUBLISHING_REPORT_SUCCESS`
const SEND_PUBLISHING_REPORT_FAIL = `${prefix}/SEND_PUBLISHING_REPORT_FAIL`

export const UPDATE = `${prefix}/UPDATE`
export const CREATE = `${prefix}/CREATE`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false,
  [ASSIGN_START]: () => true,
  [ASSIGN_SUCCESS]: () => false,
  [ASSIGN_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_START]: () => [],
  [LOAD_SUCCESS]: (state, action) => action.result
})

export default combineReducers({
  items,
  loading
})

export const loadTransitions = ({ id }) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`orders/${id}/workflow_states`)
})

export const updateState = ({ ids, state, unpublish_ad }) => ({
  types: [UPDATE_STATE_START, UPDATE_STATE_SUCCESS, UPDATE_STATE_FAIL],
  promise: (client) =>
    client.put("orders/update_state", {
      data: { ids: ids, state_id: state, unpublish_ad }
    })
})

export const update = (payload, meta = {}) => ({
  type: UPDATE,
  payload,
  meta
})

export const create = (payload) => ({
  type: CREATE,
  payload
})

export const assign =
  ({ ids, producer, previousProducer }) =>
  (dispatch, getState) =>
    dispatch({
      types: [ASSIGN_START, ASSIGN_SUCCESS, ASSIGN_FAIL],
      promise: (client) =>
        client.post("orders/assign", {
          data: { ids: ids, producer_id: producer.id }
        }),
      payload: {
        id: ids[0],
        producer,
        currentUser: getState().auth.user.id,
        previousProducer
      }
    })

export const unassign = ({ ids }) => ({
  types: [UNASSIGN_START, UNASSIGN_SUCCESS, UNASSIGN_FAIL],
  promise: (client) =>
    client.post("orders/unassign", {
      data: { ids }
    }),
  payload: { id: ids[0] }
})

export const sendPublishingReport = (id, data) => ({
  types: [
    SEND_PUBLISHING_REPORT_START,
    SEND_PUBLISHING_REPORT_SUCCESS,
    SEND_PUBLISHING_REPORT_FAIL
  ],
  promise: (client) =>
    client.post(`orders/${id}/send_publishing_report`, { data })
})

const getParams = (key) => (state) => state.overview[key].params
const getCount = (key) => (state) => state.overview[key].count

export const getNewOrdersParams = getParams("newOrders")
export const getMyWorkOrdersParams = getParams("myWorkOrders")
export const getProductionOrdersParams = getParams("productionOrders")
export const getCustomerFeedbackOrdersParams = getParams(
  "customerFeedbackOrders"
)

export const getNewOrdersCount = getCount("newOrders")
export const getMyWorkOrdersCount = getCount("myWorkOrders")
export const getProductionOrdersCount = getCount("productionOrders")
export const getCustomerFeedbackOrdersCount = getCount("customerFeedbackOrders")

export const getStateTransitions = (state) =>
  state.overview.stateTransitions.items
export const getStateTransitionsLoading = (state) =>
  state.overview.stateTransitions.loading

export const getNewOrders = (state) =>
  compact(state.overview.newOrders.ids.map((id) => state.overview.byId[id]))

export const getMyWorkOrders = (state) =>
  compact(state.overview.myWorkOrders.ids.map((id) => state.overview.byId[id]))

export const getProductionOrders = (state) =>
  compact(
    state.overview.productionOrders.ids.map((id) => state.overview.byId[id])
  )

export const getCustomerFeedbackOrders = (state) =>
  compact(
    state.overview.customerFeedbackOrders.ids.map(
      (id) => state.overview.byId[id]
    )
  )
