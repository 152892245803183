import React, { memo } from "react"
import { useTheme } from "@mui/material/styles"
import { Grid } from "@mui/material"
import MainCard from "./MainCard"

export const GridCard = memo(
  /**
   * @param {object} props
   * @param {React.ReactNode[]} props.children
   */
  ({ children, ...rest }) => {
    const theme = useTheme()

    const blockSX = {
      p: 2,
      pb: 1.8,
      borderLeft: "1px solid ",
      borderBottom: "1px solid ",
      borderLeftColor: theme.palette.grey[200],
      borderBottomColor: theme.palette.grey[200]
    }

    return (
      <Grid container alignItems="center" spacing={3} {...rest}>
        <Grid item xs={12}>
          <MainCard
            sx={{
              "& >div": {
                padding: "0px !important"
              }
            }}
          >
            <Grid container alignItems="center" spacing={0}>
              {React.Children.map(children, (item, i) => {
                return (
                  <Grid item xs={12} sm={6} sx={blockSX} key={i}>
                    <Grid container spacing={1} justifyContent="space-between">
                      {item}
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    )
  }
)
