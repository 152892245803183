import React from "react"
import { Translate } from "react-redux-i18n"
import { Typography, Stack, Paper, Box } from "@mui/material"

import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined"

/**
 * @param {{text?: React.ReactNode, subText?: string}} props
 */
export const EmptyState = ({ text, subText, sx = {} }) => (
  <Paper
    variant="outlined"
    component={Stack}
    direction="column"
    justifyContent="center"
    className="empty-state"
    sx={{
      height: "100%",
      minHeight: 205,
      m: 2,
      mt: 0,
      borderColor: "#DFDFDF",
      ...sx
    }}
  >
    <Box textAlign="center">
      <PageviewOutlinedIcon sx={{ fontSize: 48, mb: -1.5 }} />
      <Typography
        variant="h2"
        align="center"
        my={1}
        sx={{
          fontSize: "1rem",
          color: "grey.600",
          fontWeight: 500
        }}
      >
        {text ? text : <Translate value="no-data" />}
      </Typography>
      <Typography
        sx={{
          fontSize: "0.75rem",
          color: "grey.600",
          fontWeight: 400
        }}
        align="center"
      >
        {subText}
      </Typography>
    </Box>
  </Paper>
)
