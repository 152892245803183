import { combineReducers } from "redux"

import { createReducer } from "store/helpers/reducers"

const prefix = "jass/api-credentials"

const LOAD_CREDENTIALS_START = `${prefix}/LOAD_CREDENTIALS_START`
const LOAD_CREDENTIALS_SUCCESS = `${prefix}/LOAD_CREDENTIALS_SUCCESS`
export const LOAD_CREDENTIALS_FAIL = `${prefix}/LOAD_CREDENTIALS_FAIL`

const loading = createReducer(false, {
  [LOAD_CREDENTIALS_START]: () => true,
  [LOAD_CREDENTIALS_SUCCESS]: () => false,
  [LOAD_CREDENTIALS_FAIL]: () => false
})

const partnerCredentials = createReducer(
  {},
  {
    [LOAD_CREDENTIALS_SUCCESS]: (state, action) => action.result || state,
    [LOAD_CREDENTIALS_FAIL]: (state) => state
  }
)

export default combineReducers({
  partnerCredentials,
  loading
})

export const loadCredentials = (id) => {
  return {
    types: [
      LOAD_CREDENTIALS_START,
      LOAD_CREDENTIALS_SUCCESS,
      LOAD_CREDENTIALS_FAIL
    ],
    promise: (client) => client.get(`/admin/api_credentials/${id}.json`)
  }
}

export const getApiKey = (state) => state.admin.partnerCredentials.api_key
export const getAuthToken = (state) => state.admin.partnerCredentials.auth_token
