import { object, string, boolean, mixed, array, number } from "yup"

export const vacancySchema = object({
  primary_contact: object().nullable().required("required"),
  zip: mixed()
    .transform((value) => value?.zip_code)
    .nullable()
    .required("required"),
  city: mixed()
    .transform((value) => value?.city)
    .nullable()
    .required("required")
})

export const designSchema = object({
  vacancy_title: string().required("required"),
  ad_link: string().url("url")
})

export const dynamicFieldsSchema = object()

export const orderWizardSchema = object({
  contact: number().nullable(),
  customer: string(),
  primary_contact: mixed()
    .nullable()
    .required("required")
    .transform((value) => value?.id),
  editorial: mixed()
    .transform((value) => value?.id)
    .oneOf(["big", "yes", "no"]),
  new_design: boolean(),
  offer_id: number(),
  billing_type: mixed()
    .transform((value) => value?.id)
    .oneOf(["email", "paper"])
    .required("required"),
  description: string().nullable(),
  editorial_comment: string().nullable(),
  design_comment: string().nullable(),
  guaranteed_service: boolean(),
  design_customer_type: mixed().transform((value) => value?.id),
  design_layout_type: mixed().transform((value) => value?.id),
  vacancy_title: string().required("required"),
  sub_customer: mixed().transform((value) => value?.id),
  zip: mixed()
    .transform((value) => value?.zip_code)
    .nullable()
    .required("required"),
  city: mixed()
    .transform((value) => value?.city)
    .nullable()
    .required("required"),
  keywords: string().nullable(),
  reference_number: string().nullable(),
  ad_link: string().url("url"),
  order_texts_ids: mixed()
    .nullable()
    .transform((value) => value?.map((orderText) => orderText.id) || []),
  product_fields: object().nullable(),
  orders_products_df_values_attributes: array().of(object())
})
