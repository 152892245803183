import get from "lodash/get"
import React from "react"

const prefixes = {
  fontAwesome: "fa"
}

export default ({ type = "", className = "", style = {} }) => {
  const iconProps = {
    style,
    className: `${get(prefixes, type, "")} ${className}`
  }

  return <i {...iconProps} />
}
