import React from "react"
import InputComponent from "components/TouchDesign/Components/Input"
import AutocompleteComponent from "components/TouchDesign/Components/Autocomplete"
import RemoteAutocompleteComponent from "components/TouchDesign/Components/RemoteAutocomplete"
import PlacesAutocompleteComponent from "components/TouchDesign/Components/GooglePlacesAutocomplete"
import DatePickerComponent from "components/TouchDesign/Components/DatePicker"
import NumberInputComponent from "components/TouchDesign/Components/NumberInput"
import { wrapComponent } from "components/FormControls/Formik"
import ColorPickerComponent from "components/TouchDesign/Components/ColorPicker"
import ButtonInputComponent from "components/TouchDesign/Components/ButtonInput"
import FilePickerComponent from "components/FormControls/FilePicker/FilePicker"
import URLFilePickerComponent from "components/TouchDesign/Components/URLFilePicker"
import PopoverInputComponent from "components/TouchDesign/Components/PopoverInput"
import PopoverURLFilePickerComponent from "components/TouchDesign/Components/PopoverURLFilePicker"
import WysiwygComponent from "components/TouchDesign/Components/Wysiwyg"
import DesignDrawerComponent from "components/AdEditor/DesignDrawer"
import CheckboxComponent from "components/Checkbox/Checkbox"
import RadioComponent from "@mui/material/Radio"
import SalesProductsAutocompleteComponent from "components/AffiliateProductPackages/SalesProductsAutocomplete"
import ApproveSectionComponent from "components/TouchDesign/Components/ApproveSection"

export const Input = wrapComponent(InputComponent)
export const Autocomplete = wrapComponent(AutocompleteComponent)
export const RemoteAutocomplete = wrapComponent(RemoteAutocompleteComponent)
export const SalesProductsAutocomplete = wrapComponent(
  SalesProductsAutocompleteComponent
)
export const GooglePlacesAutocomplete = wrapComponent(
  PlacesAutocompleteComponent
)
export const DatePicker = wrapComponent(DatePickerComponent)
export const ColorPicker = wrapComponent(ColorPickerComponent)
export const NumberInput = wrapComponent(NumberInputComponent)
export const ButtonInput = wrapComponent(ButtonInputComponent)
export const FilePicker = wrapComponent(FilePickerComponent)
export const URLFilePicker = wrapComponent(URLFilePickerComponent)

export const PopoverURLFilePicker = wrapComponent(PopoverURLFilePickerComponent)
export const PopoverInput = wrapComponent(PopoverInputComponent)
export const Wysiwyg = wrapComponent(WysiwygComponent)

export const DesignDrawer = wrapComponent(DesignDrawerComponent)

export const ApproveSection = wrapComponent(ApproveSectionComponent)

export const Checkbox = ({ field, form: _form, ...props }) => {
  return (
    <CheckboxComponent
      {...props}
      name={field.name}
      checked={field.value}
      value={field.value}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e.target.checked)
        }

        return field.onChange(field.name)(e)
      }}
    />
  )
}

export const Radio = ({ field, form, ...props }) => {
  return (
    <RadioComponent
      {...field}
      {...props}
      checked={props.value === form.values[field.name]}
      onChange={(e) => {
        props.onChange?.(e.target.checked)

        return field.onChange(field.name)(e)
      }}
    />
  )
}
