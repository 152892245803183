import React, { memo } from "react"
import { Field } from "formik"
import { Translate } from "react-redux-i18n"
import { FormControlLabel, Grid, Stack, Typography } from "@mui/material"
import { Autocomplete, Checkbox } from "shared/ui/berry-jass-formik"
import { getLocationCities } from "../lib"

export const MetadataForm = memo(() => {
  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid item md={12}>
        <Typography variant="h4" sx={{ mb: 1.5 }}>
          <Translate value="product.settings.locations.max-number" />
        </Typography>
        <Field
          disableClearable
          name="product_setting_attributes.cities"
          label={
            <Translate value="product.settings.locations.options.cities" />
          }
          options={getLocationCities()}
          component={Autocomplete}
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">
          <Translate value="product.settings.design.title" />
        </Typography>
        <Stack direction="row" columnGap={2}>
          <FormControlLabel
            label={<Translate value="product.settings.design.options.html" />}
            control={
              <Field
                name="product_setting_attributes.design_html"
                component={Checkbox}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={<Translate value="product.settings.design.options.text" />}
            control={
              <Field
                name="product_setting_attributes.design_text"
                component={Checkbox}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={
              <Translate value="product.settings.design.options.structured" />
            }
            control={
              <Field
                name="product_setting_attributes.design_structured"
                component={Checkbox}
                color="primary"
              />
            }
          />
        </Stack>
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">
          <Translate value="product.settings.tracking.title" />
        </Typography>
        <Stack direction="row" columnGap={2}>
          <FormControlLabel
            label={
              <Translate value="product.settings.tracking.options.views" />
            }
            control={
              <Field
                name="product_setting_attributes.tracking_views"
                component={Checkbox}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={
              <Translate value="product.settings.tracking.options.clicks" />
            }
            control={
              <Field
                name="product_setting_attributes.tracking_clicks"
                component={Checkbox}
                color="primary"
              />
            }
          />
        </Stack>
      </Grid>
    </Grid>
  )
})
