import React from "react"
import FlagIcon from "./FlagIcon"
import { Box } from "@mui/material"

export const MultipleCountryIconCell = ({ value, onCLick }) => {
  const showMoreCount = value.length - 2
  return value.length > 2 ? (
    <Box display="flex" justifyContent="space-between">
      {value.slice(0, 2).map((country) => (
        <FlagIcon key={country} countryCode={country} />
      ))}
      <Box
        sx={{
          width: "30px !important",
          lineHeight: "16.5px !important",
          textAlign: "center",
          padding: "2px 3px",
          borderRadius: "4px",
          cursor: "pointer",
          backgroundColor: (theme) => theme.palette.secondary.light
        }}
        onClick={onCLick}
      >
        + {showMoreCount}
      </Box>
    </Box>
  ) : (
    <Box display="flex" gap={1}>
      {value.map((country) => (
        <FlagIcon key={country} countryCode={country} />
      ))}
    </Box>
  )
}
