import React from "react"
import { Link } from "react-router-dom"

export default ({
  className = "",
  linkClassName = "",
  icon = <i className="large mdi-content-add" />,
  to = "",
  ...rest
}) => (
  <div className={`fixed-action-btn top actionCardBtn ${className}`}>
    <Link
      {...rest}
      className={`btn-floating btn-large orange ${linkClassName}`}
      to={rest.disabled === true ? "" : to}
    >
      {icon}
    </Link>
  </div>
)
