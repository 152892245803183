import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import {
  UPDATE,
  CREATE
} from "store/modules/entities/overview/stateTransitions"

const prefix = "jass/PRODUCTION_OVERVIEW/NEW_ORDERS"

const LOAD_START = `${prefix}/LOAD_START`
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`

const REMOVE_ORDER = -1
const ADD_ORDER = 1
const NO_CHANGES = 0

const getOrderAction = (state, action) => {
  const { order: oneOrder, orders } = action.payload
  const order = oneOrder ?? orders[0]
  const [prevProducer, nextProducer] = order.producer_changes
  const [prevState, nextState] = order.state_changes

  const pendingStateChanged = prevState === "pending" && nextState !== "pending"
  const assignedToProducer = !prevProducer && nextProducer

  if (pendingStateChanged || assignedToProducer) {
    return REMOVE_ORDER
  }

  const stateChangedToPending =
    prevState !== "pending" && nextState === "pending"
  const unassignedFromProducer = prevProducer && !nextProducer

  if (stateChangedToPending || unassignedFromProducer) {
    return ADD_ORDER
  }

  return NO_CHANGES
}

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const ids = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }
    return action.result.ids
  },
  [CREATE]: (state, action) => {
    return [action.payload.order.id.toString(), ...state]
  },
  [UPDATE]: (state, action) => {
    const { order: oneOrder, orders } = action.payload
    const order = oneOrder ?? orders[0]
    switch (getOrderAction(state, action)) {
      case ADD_ORDER:
        return [order.id.toString(), ...state]
      case REMOVE_ORDER:
        return state.filter((id) => id !== order.id.toString())
      default:
        return state
    }
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state,
  [UPDATE]: (state, action) => {
    const changes = getOrderAction(state, action)
    return state + changes
  },
  [CREATE]: (state) => state + 1
})

const params = createReducer(
  { page: 1 },
  {
    [LOAD_SUCCESS]: (state, action) => action.params || state
  }
)

export default combineReducers({
  ids,
  count,
  params,
  loading
})

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) =>
    client.getNormalizedPagedResponse("overview/new_orders", options)
})
