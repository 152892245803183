import { combineReducers } from "redux"
import newOrders from "store/modules/entities/overview/newOrders"
import myWorkOrders from "store/modules/entities/overview/myWorkOrders"
import productionOrders from "store/modules/entities/overview/productionOrders"
import customerFeedbackOrders from "store/modules/entities/overview/customerFeedbackOrders"
import stateTransitions from "store/modules/entities/overview/stateTransitions"

import { createReducer } from "store/helpers/reducers"
import { LOAD_SUCCESS as LOAD_NEW_SUCCESS } from "store/modules/entities/overview/newOrders"
import { LOAD_SUCCESS as LOAD_MY_WORK_SUCCESS } from "store/modules/entities/overview/myWorkOrders"
import { LOAD_SUCCESS as LOAD_PRODUCTION_SUCCESS } from "store/modules/entities/overview/productionOrders"
import { LOAD_SUCCESS as LOAD_CUSTOMER_FEEDBACK_SUCCESS } from "store/modules/entities/overview/customerFeedbackOrders"
import {
  UPDATE,
  CREATE
} from "store/modules/entities/overview/stateTransitions"
import cardDetails from "store/modules/entities/overview/orderDetails"
import comments from "store/modules/entities/overview/comments"
import billingAddresses from "store/modules/entities/overview/billingAddresses"
import assets from "store/modules/entities/overview/assets"
import {
  UPDATE_MARKED_SUCCESS,
  UPDATE_SUCCESS
} from "store/modules/entities/order"

const initialByIds = {}
const byId = createReducer(initialByIds, {
  [LOAD_NEW_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [LOAD_MY_WORK_SUCCESS]: (state, action) => ({
    ...state,
    ...action.result.byId
  }),
  [LOAD_PRODUCTION_SUCCESS]: (state, action) => ({
    ...state,
    ...action.result.byId
  }),
  [LOAD_CUSTOMER_FEEDBACK_SUCCESS]: (state, action) => ({
    ...state,
    ...action.result.byId
  }),
  [UPDATE]: (state, action) => {
    const { order: oneOrder, orders } = action.payload
    const order = oneOrder ?? orders[0]
    return {
      ...state,
      [order.id.toString()]: { ...order, id: order.id.toString() }
    }
  },
  [CREATE]: (state, action) => {
    const { order } = action.payload
    return {
      ...state,
      [order.id.toString()]: { ...order, id: order.id.toString() }
    }
  },
  [UPDATE_SUCCESS]: (state, action) => {
    const { order } = action.payload
    return {
      ...state,
      [order.id.toString()]: { ...state[order.id], ...order }
    }
  },
  [UPDATE_MARKED_SUCCESS]: (state, action) => {
    const {
      order: { order } // Don't ask ;(
    } = action.result
    return {
      ...state,
      [order.id.toString()]: { ...state[order.id], ...order }
    }
  }
})

export default combineReducers({
  newOrders,
  myWorkOrders,
  productionOrders,
  customerFeedbackOrders,
  byId,
  stateTransitions,
  cardDetails,
  comments,
  billingAddresses,
  assets
})
