import { combineReducers } from "redux"

import { budget, budgetLoading } from "./budget"
import { jobAds, jobAdsLoading } from "./job-ads"
import { jobs, jobsLoading } from "./jobs"
import productData from "./productData"
import publishedAds from "./publishedAds"
import totalData from "./totalData"
import ui from "./ui"

export default combineReducers({
  budget,
  budgetLoading,
  jobAds,
  jobAdsLoading,
  jobs,
  jobsLoading,
  productData,
  publishedAds,
  totalData,
  ui
})
