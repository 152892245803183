import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import deepmerge from "deepmerge"
import { getAgencyId } from "store/modules/common/auth"

const LOAD_START = "jass/subscriptionProduct/LOAD_START"
const LOAD_SUCCESS = "jass/subscriptionProduct/LOAD_SUCCESS"
const LOAD_FAIL = "jass/subscriptionProduct/LOAD_FAIL"
const RESET_PRODUCT_LIST = "jass/subscriptionProduct/RESET"
const SET_HAS_PRODUCTS = "jass/subscriptionProduct/SET_HAS_PRODUCTS"

export default combineReducers({
  list: combineReducers({
    loading: createReducer(false, {
      [LOAD_START]: () => true,
      [LOAD_SUCCESS]: () => false,
      [LOAD_FAIL]: () => false
    }),
    items: createReducer([], {
      [LOAD_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...state, ...action.result]
        }

        return action.result.items
      },
      [RESET_PRODUCT_LIST]: () => []
    }),
    count: createReducer(0, {
      [LOAD_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state,
      [RESET_PRODUCT_LIST]: () => 0
    }),
    params: createReducer(
      {
        page: 1
      },
      {
        [LOAD_SUCCESS]: (state, action) => action.params,
        [RESET_PRODUCT_LIST]: () => ({ page: 1 })
      }
    )
  }),
  hasProducts: createReducer(false, {
    [SET_HAS_PRODUCTS]: (state, action) => action.payload
  })
})

export const load = (data) => {
  return (dispatch, getState) => {
    const agencyId = getAgencyId(getState())

    const params = {
      additional_search: {
        affiliate_type: "JapCustomer",
        affiliate_id: data?.customerId,
        single_package_price: true,
        agency_id: agencyId,
        only_subs: true
      },
      affiliate_products: true,
      prices_page: false,
      page: 1,
      ...data?.params
    }

    return dispatch({
      types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.getHybrid("web_shop_prices", { params })
    })
  }
}

export const filterBy = (filter) => {
  return (dispatch, getState) => {
    const params = getState().subscriptionProduct.list.params
    dispatch(
      load({
        params: deepmerge(params, { additional_search: filter })
      })
    )
  }
}

export function resetProductList() {
  return { type: RESET_PRODUCT_LIST }
}

export const setHasProducts = () => {
  return (dispatch) => {
    dispatch(load()).then((response) => {
      return dispatch({
        type: SET_HAS_PRODUCTS,
        payload: !!response?.count
      })
    })
  }
}

export const hasProducts = (state) => state.subscriptionProduct.hasProducts
