import React from "react"

export default ({ color = "#fff" }) => (
  <svg width="20" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} fillRule="nonzero">
      <path d="M10.354 11.33c.662.662.662 1.796 0 2.457l-1.37 1.37c-.661.662-1.795.662-2.456 0l-6-6.047c-.662-.661-.662-1.795 0-2.456l1.37-1.37c.661-.662 1.795-.662 2.456 0l6 6.047z" />
      <path d="M15.598.89c.662-.662 1.796-.662 2.457 0l1.37 1.37c.662.661.662 1.795 0 2.457L9.031 15.063c-.66.661-1.795.661-2.456 0l-1.37-1.37c-.662-.662-.662-1.795 0-2.457L15.598.89z" />
    </g>
  </svg>
)
