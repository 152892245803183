import React from "react"
import NumberFormat from "components/TouchDesign/Components/PriceInput"
import { TextField } from "./TextField"
import { InputAdornment } from "@mui/material"

/**
 * @param {import("@mui/material").TextFieldProps & import("formik").FieldProps & {euro?: boolean; decimalScale?: number;}} props
 */
export const PriceInput = ({ InputProps, euro, decimalScale = 2, ...rest }) => {
  const euroProps = {
    endAdornment: (
      <InputAdornment
        position="end"
        sx={{
          height: "100%",
          paddingLeft: "12px",
          borderLeft: "1px solid #DFDFDF"
        }}
      >
        Euro
      </InputAdornment>
    )
  }

  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        ...(euro ? euroProps : {}),
        inputComponent: NumberFormat,
        inputProps: {
          decimalScale,
          allowEmptyFormatting: false,
          suffix: ""
        }
      }}
    />
  )
}
