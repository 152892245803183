import React from "react"
import { useSelector } from "react-redux"
import { getUser } from "store/modules/common/auth"
import { Redirect, Route } from "react-router-dom"
import { hasAnyOfRoles } from "utils/user"
import { isWhiteLabelLoginDomain } from "shared"

/**
 * @type {React.FunctionComponent<import('react-router').RouteProps & {roles?: Array<string>, authFn?: (user: any) => boolean }>}
 */
const PrivateRoute = ({ children = null, roles, authFn, ...rest }) => {
  const user = useSelector(getUser)

  return (
    <Route
      {...rest}
      render={() => {
        if (!user) {
          if (isWhiteLabelLoginDomain()) {
            return <Redirect to={`/r/login?location=${window.location}`} />
          }
          return <Redirect to={`/react/login?location=${window.location}`} />
        }

        if (
          (roles && !hasAnyOfRoles(user, roles)) ||
          (authFn && !authFn(user))
        ) {
          return <Redirect to="/" />
        }

        return children
      }}
    />
  )
}

export default PrivateRoute
