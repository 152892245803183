import React from "react"
import { useCallback } from "react"
import { Translate } from "react-redux-i18n"

export const useDownloadAdditionalInvoiceAction = () => {
  return useCallback((order) => {
    return {
      label: <Translate value="web-shop.job-ads-table.agency-invoice" />,
      onClick: () => window.open(order.additional_invoice_pdf, "_blank")
    }
  }, [])
}
