import get from "lodash/get"
import { isWhiteLabelLoginDomain } from "shared/lib/domains"

const defaultApiRequestTimeout = 30

const environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  },
  test: {
    isProduction: false
  }
}[process.env.NODE_ENV || "development"]

const host = get(process, "env.HOST", "127.0.0.1")

const config = Object.assign(
  {
    apiHost: get(process, "env.APIHOST", host),
    apiPort: get(process, "env.APIPORT", 3000),
    apiSSL: !!get(process, "env.APISSL", false),
    apiRequestTimeout:
      +get(process, "env.API_REQUEST_TIMEOUT", defaultApiRequestTimeout) ||
      defaultApiRequestTimeout,
    emberUrl: get(
      process,
      "env.EMBER_URL",
      environment.isProduction ? "" : `${host}:3000`
    ),
    app: {
      title: "Jass",
      description: "Jass",
      head: {
        titleTemplate: isWhiteLabelLoginDomain() ? "%s" : "Jass: %s",
        meta: [
          { name: "description", content: "All the modern best practices." },
          { charset: "utf-8" }
        ]
      }
    },

    logger: {
      // { error: 0, warn: 1, info: 2, verbose: 3, debug: 4, silly: 5 }
      level: get(
        process,
        "env.JASS_FRONT_END_LOGS_LEVEL",
        environment.isProduction ? "error" : "debug"
      ),
      fileNames: {
        all: get(process, "env.JASS_FRONT_END_LOGS_ALL_FILENAME", "all")
      }
    },

    tooltip: {
      mouseEnterDelay: 0.3
    },

    isTest: !!(process.env.TESTS && JSON.parse(process.env.TESTS))
  },
  environment
)

export default config
