import { flatten } from "lodash"

/**
 * @param {import("@tanstack/react-query").UseQueryResult<number>} countQuery
 * @returns {import("@tanstack/react-query").GetNextPageParamFunction}
 */
export const createGetNextPageParam = (countQuery) => (_, pages) => {
  return pages.length * 35 < (countQuery.data ?? 0)
    ? pages.length + 1
    : undefined
}

export const getDataTableSortsParam = (sorts) => {
  if (!sorts || !sorts.length) {
    return {}
  }

  return {
    sort: sorts[0].id,
    ascending: !sorts[0].desc
  }
}

export const getTableProps = (query) => {
  const allData = flatten(query?.data?.pages) ?? []

  return {
    data: allData,
    fetchMore: query.fetchNextPage,
    canFetchMore: !query.isFetching && query.hasNextPage,
    isLoading: query.isLoading,
    isFetching: query.isLoading
  }
}
