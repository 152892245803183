import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "utils/client"

/**
 * @return {import("@tanstack/react-query").UseMutationResult<any, unknown, {id: number}, unknown>}
 */
const useUpdateGoogleFontsUsageAgreement = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id }) => {
      const response = await client.put(
        `/google_fonts_usage_agreements/${id}.json`
      )

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["customAdDesigns"])
      }
    }
  )
}

export default useUpdateGoogleFontsUsageAgreement
