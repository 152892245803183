import React from "react"
import { getIn } from "formik"
import { Chip, IconButton, TextField, Typography } from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"

import useLocale from "hooks/useLocale"
import { formatPrice } from "utils/parsers/prices"
import { RemoteAutocomplete } from "shared"
import { getErrorText } from "shared/ui/berry-jass-formik/helpers"
import { getProductNameByLocale } from "entities/sales-product"

const getLabel = (sp, locale) => {
  const { budget, sales_product: product } = sp
  const productName = getProductNameByLocale(product, locale)
  return `${product?.supplier_name} | ${productName} (${
    product?.runtime_in_days
  }) | ${formatPrice(product?.rrp)} ${budget ? "[Budget]" : ""}`
}

export const SalesProductsAutocomplete = ({
  tagProps,
  affiliateProductPackageId,
  field,
  form,
  ...rest
}) => {
  const fieldError = getIn(form.errors, field.name)
  const showError = !!getIn(form.touched, field.name) && !!fieldError
  const locale = useLocale()

  return (
    <RemoteAutocomplete
      {...rest}
      multiple
      variant="ransack"
      searchProp="q.formatted_supplier_name_name_runtime_cont"
      getOptionLabel={(product) => getLabel(product, locale)}
      value={field.value}
      getOptionKey={(x) => x.sales_product_id}
      renderInput={(props) => (
        <TextField
          required={rest.required}
          name={rest.name}
          error={showError}
          helperText={showError ? getErrorText(fieldError) : props.helperText}
          label={rest.label}
          placeholder={rest.placeholder}
          {...props}
        />
      )}
      isOptionEqualToValue={(option, value) => {
        if (value._destroy) return false

        return Array.isArray(option)
          ? !!option.find((o) => o.sales_product_id === value.sales_product_id)
          : option.sales_product_id === value.sales_product_id
      }}
      onChange={(e, newValue, reason) => {
        if (reason === "selectOption") {
          const addedOption = newValue[newValue.length - 1]
          const removedOption = newValue.find(
            (product) =>
              product.sales_product_id === addedOption.sales_product_id
          )
          const nextValue = newValue.filter(
            (product) =>
              product.sales_product_id !== addedOption.sales_product_id
          )
          nextValue.push({ ...addedOption, id: removedOption?.id })
          field.onChange(field.name)(nextValue, reason)
        } else {
          field.onChange(field.name)(newValue, reason)
        }
      }}
      onBlur={(event) => {
        field.onBlur(event ?? field.name)
        form.setFieldTouched(field.name)
      }}
      mapResponseToDataSource={(response) =>
        response?.map((product) => ({
          affiliate_product_package_id: affiliateProductPackageId,
          budget: product.budget,
          sales_product_id: product.id,
          sales_product: product
        })) ?? []
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          if (option._destroy) return null
          return (
            <Chip
              key={option.id}
              label={getLabel(option, locale)}
              icon={option.sales_product?.unlocked ? null : <LockIcon />}
              {...getTagProps({ index })}
              onDelete={() => {
                const newValue = field.value.slice()
                if (newValue[index].id) {
                  newValue[index]._destroy = true
                } else newValue.splice(index, 1)
                field.onChange(field.name)(newValue, "removeOption")
              }}
            />
          )
        })
      }
      renderOption={(props, option) => (
        <li
          {...props}
          key={
            option?.id ??
            // @ts-ignore
            props.key
          }
        >
          <Typography noWrap>
            {option.sales_product?.unlocked ? null : (
              <IconButton title="Locked" size="large">
                <LockIcon />
              </IconButton>
            )}
            {getLabel(option, locale)}
          </Typography>
        </li>
      )}
      url="/affiliates/sales_products"
    />
  )
}
