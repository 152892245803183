import { createReducer } from "store/helpers/reducers"

const prefix = "jass/statistics"

const LOAD_JOB_ADS_START = `${prefix}/LOAD_JOB_ADS_START`
const LOAD_JOB_ADS_SUCCESS = `${prefix}/LOAD_JOB_ADS_SUCCESS`
const LOAD_JOB_ADS_FAIL = `${prefix}/LOAD_JOB_ADS_FAIL`

export const loadJobAds = () => {
  return {
    types: [LOAD_JOB_ADS_START, LOAD_JOB_ADS_SUCCESS, LOAD_JOB_ADS_FAIL],
    promise: (client) => client.get("/statistics/job_ads.json")
  }
}

export const jobAdsLoading = createReducer(false, {
  [LOAD_JOB_ADS_START]: () => true,
  [LOAD_JOB_ADS_SUCCESS]: () => false,
  [LOAD_JOB_ADS_FAIL]: () => false
})

export const jobAds = createReducer(
  {},
  {
    [LOAD_JOB_ADS_SUCCESS]: (state, action) => action.result || state,
    [LOAD_JOB_ADS_FAIL]: () => ({})
  }
)
