import { createStore, applyMiddleware } from "redux"
import createMiddleware from "./middleware/clientMiddleware"
import { syncTranslationWithStore } from "react-redux-i18n"
import reducer from "./modules"

export default (client, preloadedState = null) => {
  // Sync dispatched route actions to the history
  const middleware = [createMiddleware(client)]

  const finalCreateStore = applyMiddleware(...middleware)(createStore)

  const store = finalCreateStore(
    reducer,
    // @ts-ignore
    preloadedState ?? window.__REDUX_DEVTOOLS_EXTENSION__?.()
  )

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept("./modules", () => {
      store.replaceReducer(reducer)
    })
  }

  syncTranslationWithStore(store)

  return store
}
