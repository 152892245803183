import { combineReducers } from "redux"
import previewLinks from "store/modules/entities/overview/assets/previewLinks"
import liveLinks from "store/modules/entities/overview/assets/liveLinks"
import files from "store/modules/entities/overview/assets/files"

export default combineReducers({
  previewLinks,
  liveLinks,
  files
})
