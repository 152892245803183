const LOAD_INCOMPLETE_OFFER_START =
  "jass/incompleteOffer/LOAD_INCOMPLETE_OFFER_START"
const LOAD_INCOMPLETE_OFFER_SUCCESS =
  "jass/incompleteOffer/LOAD_INCOMPLETE_OFFER_SUCCESS"
const LOAD_INCOMPLETE_OFFER_FAILURE =
  "jass/incompleteOffer/LOAD_INCOMPLETE_OFFER_FAILURE"

const byId = (state = {}, action) => {
  switch (action.type) {
    case LOAD_INCOMPLETE_OFFER_SUCCESS:
      return { ...state, [action.result.id]: action.result }
    default:
      return state
  }
}

export const loadOffer = (id) => {
  return {
    types: [
      LOAD_INCOMPLETE_OFFER_START,
      LOAD_INCOMPLETE_OFFER_SUCCESS,
      LOAD_INCOMPLETE_OFFER_FAILURE
    ],
    promise: (client) => client.get(`incomplete_agency_offers/${id}`),
    id
  }
}

export default byId
