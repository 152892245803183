import client from "utils/client"

export const createImage = async (file, params = {}) => {
  const formData = new FormData()
  formData.append("file", file)

  return await client
    .post("/images.json", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      params
    })
    .then((response) => response.data.image)
}

export const createExternalImage = async (file) => {
  return createImage(file, { external: true })
}

/** @param {number} id */
export const deleteImage = async (id) => {
  return await client.delete(`/images/${id}.json`)
}
