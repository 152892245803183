import axios from "axios"
import deepmerge from "deepmerge"
import uniqBy from "lodash/uniqBy"
import useNotifications from "hooks/useNotifications"
import set from "lodash/set"
import React, { useEffect, useRef, useState } from "react"
import client from "utils/client"
import { serverError } from "utils/parsers/serverError"
import { Autocomplete, defaultGetOptionKey } from "./berry-jass/Autocomplete"

import { I18n } from "react-redux-i18n"
import { useDebouncedCallback } from "use-debounce"

const EDGE_OFFSET = 20

/**
 * @param {Omit<import("@mui/material").AutocompleteProps, 'options' | 'renderInput'> & {url: string, params?: object, paginated?: boolean, searchProp?: string, label?: React.ReactNode, variant?: string, renderInput?: (any) => React.ReactNode, getOptionKey?: (any) => string, initialValue?: object, additionalOptions?: any[], mapResponseToDataSource?: (any) => any, name?: string}} props
 */
export const RemoteAutocomplete = ({
  url,
  params,
  paginated,
  variant = "search_property",
  additionalOptions = [],
  value,
  mapResponseToDataSource = (result) => result,
  searchProp,
  getOptionKey = defaultGetOptionKey,
  onInputChange,
  leading = true,
  ...rest
}) => {
  const { showError } = useNotifications()
  const previousRequest = useRef(null)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState(() => {
    if (rest.multiple) {
      return uniqBy([...additionalOptions, ...value], getOptionKey)
    }

    return value
      ? uniqBy([...additionalOptions, value], getOptionKey)
      : additionalOptions
  })
  const [hasMore, setHasMore] = useState(true)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const [position, setPosition] = useState(0)
  const [listboxNode, setListboxNode] = useState(null)

  // workaround for issue: https://github.com/mui/material-ui/issues/30249
  useEffect(() => {
    if (paginated && listboxNode) {
      listboxNode.scrollTop = position
    }
  }, [paginated, listboxNode, position])

  const getSearchPart = (searchText) => {
    switch (variant) {
      case "search_property":
        return { additional_search: { search_property: searchText } }
      case "term":
        return { term: searchText }
      case "search":
        return { search: searchText }
      case "ransack":
        return set({}, searchProp, searchText)
      default:
        return {}
    }
  }

  const fetchOptions = (searchText = "", page) => {
    if (previousRequest.current) {
      previousRequest.current.cancel("Cancel previous request")
    }
    previousRequest.current = axios.CancelToken.source()

    let path = url
    if (!path.startsWith("/")) {
      path = `/${path}`
    }
    if (!path.endsWith(".json")) {
      path = `${path}.json`
    }

    return client.get(path, {
      params: deepmerge(params, {
        ...getSearchPart(searchText),
        page: paginated ? page : undefined
      }),
      cancelToken: previousRequest.current.token
    })
  }

  const handleAPIRequest = async (searchText = "", page = 1) => {
    setSearch(searchText)
    setPage(page)
    setHasMore(true)
    if (page === 1) {
      setLoading(true)
    }
    setFetching(true)

    fetchOptions(searchText, page)
      .then(({ data: response }) => {
        const newOptions = mapResponseToDataSource(response)
        if (page > 1) {
          if (newOptions?.length) {
            setOptions((options) =>
              uniqBy(options.concat(newOptions), getOptionKey)
            )
          } else {
            setHasMore(false)
          }
        } else {
          setOptions(
            uniqBy([...additionalOptions, ...newOptions], getOptionKey)
          )
        }

        if (listboxNode && paginated) {
          setPosition(listboxNode.scrollTop)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          showError(
            serverError(error.response.data.errors ?? {}) ||
              I18n.t("errors.server.500")
          )
        }
      })
      .finally(() => {
        setLoading(false)
        setFetching(false)
        previousRequest.current = null
      })
  }

  const handleInputChange = async (e, searchText, reason) => {
    if (onInputChange) {
      onInputChange(e, searchText, reason)
    }

    if (reason !== "input" && reason !== "clear") {
      return Promise.resolve()
    }

    return handleAPIRequest(searchText)
  }

  const onScroll = (event) => {
    const listboxNode = event.currentTarget
    const entryPoint =
      listboxNode.scrollTop + listboxNode.clientHeight + EDGE_OFFSET >=
      listboxNode.scrollHeight
    setListboxNode(listboxNode)

    if (paginated && entryPoint && !loading && !fetching && hasMore) {
      handleAPIRequest(search, page + 1)
    }
  }

  const [handleInputChangeDebounced, , callPending] = useDebouncedCallback(
    handleInputChange,
    500,
    { leading }
  )

  const handleBlur = (event) => {
    // this function will execute debounced input change instantly on blur
    callPending()

    if (rest.onBlur) {
      rest.onBlur(event)
    }
  }

  return (
    <Autocomplete
      autoComplete
      loading={loading}
      fetching={fetching}
      options={options}
      onOpen={() => {
        !previousRequest.current && handleAPIRequest()
      }}
      onInputChange={handleInputChangeDebounced}
      ListboxProps={{ onScroll }}
      value={value}
      getOptionKey={getOptionKey}
      {...rest}
      onBlur={handleBlur}
    />
  )
}

RemoteAutocomplete.displayName = "BerryRemoteAutocomplete"
