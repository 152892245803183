const MARK_AS_READ_START = "jass/notifications/markAsRead/START"
export const MARK_AS_READ_SUCCESS = "jass/notifications/markAsRead/SUCCESS"
const MARK_AS_READ_FAIL = "jass/notifications/markAsRead/FAIL"
const MARK_ALL_AS_READ_START = "jass/notifications/markAllAsRead/START"
export const MARK_ALL_AS_READ_SUCCESS =
  "jass/notifications/markAllAsRead/SUCCESS"
const MARK_ALL_AS_READ_FAIL = "jass/notifications/markAllAsRead/FAIL"
export const CLEAR_READ = "jass/notifications/clearRead"
export const NEW_FROM_WEBSOCKETS = "jass/notifications/formWebSockets"

export const markAsRead = ({ notificationId }) => {
  return {
    types: [MARK_AS_READ_START, MARK_AS_READ_SUCCESS, MARK_AS_READ_FAIL],
    promise: (client) =>
      client.put(`notifications/${notificationId}/read.json`),
    notificationId: notificationId
  }
}

export const markAllAsRead = () => {
  return {
    types: [
      MARK_ALL_AS_READ_START,
      MARK_ALL_AS_READ_SUCCESS,
      MARK_ALL_AS_READ_FAIL
    ],
    promise: (client) => client.post("notifications/read_all.json")
  }
}

export const clearRead = () => {
  return { type: CLEAR_READ }
}

export const addFromWebSocket = (notification) => {
  return {
    type: NEW_FROM_WEBSOCKETS,
    notification
  }
}
