import omit from "lodash/omit"
import keys from "lodash/keys"
import pickBy from "lodash/pickBy"
import { combineReducers } from "redux"
import exportData from "store/modules/common/maintenance/exportData"

const CREATE_NOTIFICATION_START = "jass/maintenance/notifications/create/START"
const CREATE_NOTIFICATION_SUCCESS =
  "jass/maintenance/notifications/create/SUCCESS"
const CREATE_NOTIFICATION_FAIL = "jass/maintenance/notifications/create/FAIL"

export default combineReducers({ exportData })

export const createNotification = ({ data }) => {
  return {
    types: [
      CREATE_NOTIFICATION_START,
      CREATE_NOTIFICATION_SUCCESS,
      CREATE_NOTIFICATION_FAIL
    ],
    promise: (client) =>
      client.post("notifications", {
        data: {
          notification: omit(data, ["role_types"]),
          role_types: keys(pickBy(data.role_types, (value) => value))
        }
      })
  }
}
