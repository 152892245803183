import React from "react"

// Need to use React.forwardRef here because this component is used
// as a child for <ActionGroup /> that uses <Menu /> from Material UI
const TitleSpinner = React.forwardRef(
  ({ className = "", style = {} }, _ref) => {
    return (
      <span className={`title-spinner ${className}`} style={style}>
        <i
          aria-hidden="true"
          className="loading-spinner fa fa-spinner fa-pulse fa-fw"
        />

        <span className="sr-only">Loading...</span>
      </span>
    )
  }
)

export default TitleSpinner
