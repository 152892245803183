import compact from "lodash/compact"
import get from "lodash/get"
import identity from "lodash/identity"
import isArray from "lodash/isArray"
import isBoolean from "lodash/isBoolean"
import isEmpty from "lodash/isEmpty"
import isPlainObject from "lodash/isPlainObject"
import pickBy from "lodash/pickBy"

export function prepareParams(params) {
  const sort = get(params, "sort", "")
  const search_property = get(
    params,
    "search",
    get(params, "additional_search.search_property", "")
  )
  const customer_id = get(
    params,
    "customer_id",
    get(params, "additional_search.customer_id")
  )
  const manager_id = get(
    params,
    "manager_id",
    get(params, "additional_search.manager_id")
  )
  const product_id = get(
    params,
    "product_id",
    get(params, "additional_search.product_id")
  )
  const created_start_date = get(
    params,
    "created_start_date",
    get(params, "additional_search.created_start_date")
  )
  const created_end_date = get(
    params,
    "created_end_date",
    get(params, "additional_search.created_end_date")
  )
  const switch_orders = get(
    params,
    "switch_orders",
    get(params, "additional_search.switch_orders")
  )
  const search_customer_company_name = get(
    params,
    "search_customer_company_name",
    get(params, "additional_search.search_customer_company_name")
  )

  return pickBy(
    {
      page: get(params, "page", 1),
      sort,
      ascending: get(params, "ascending"),
      additional_search: pickBy(
        {
          search_property,
          manager_id,
          customer_id,
          product_id,
          switch_orders,
          created_start_date,
          created_end_date,
          search_customer_company_name
        },
        isNotEmpty
      ),
      filters: compact(get(params, "filters"))
    },
    isNotEmpty
  )
}

function isNotEmpty(value) {
  return isPlainObject(value) || isArray(value)
    ? identity(value) && !isEmpty(value)
    : isBoolean(value) || !!value
}
