import qs from "qs"
import map from "lodash/map"
import keyBy from "lodash/keyBy"
import superagent from "superagent"
import * as apiUtils from "utils/api"
import forEach from "lodash/forEach"
import get from "lodash/get"
import keys from "lodash/keys"
import size from "lodash/size"
import pick from "lodash/pick"
import isNil from "lodash/isNil"
import max from "lodash/max"
import times from "lodash/times"
import flatten from "lodash/flatten"
import { getCurrentJWT } from "utils/client"

require("superagent-auth-bearer")(superagent)

const methods = ["get", "post", "put", "patch", "del"]
const qsOptions = {
  arrayFormat: "brackets"
}

function formatUrl(path) {
  const adjustedPath = path[0] !== "/" ? "/" + path : path
  return adjustedPath
}

function createRequest(method, path) {
  const url = formatUrl(path)
  return superagent[method](url)
}

const normalizeResponse = (items) => ({
  ids: map(items, "id"),
  byId: keyBy(items || {}, "id")
})

function sendRequest({
  request,
  req,
  params,
  data,
  attachments,
  onProgress,
  onClientStatus,
  headers,
  blob,
  includeHeaders = false,
  retryCount = 0
} = {}) {
  return new Promise((resolve, reject) => {
    if (params) {
      request.query(qs.stringify(params, qsOptions))
    }

    if (data) {
      request.send(qs.stringify(data, qsOptions))
    }

    forEach(headers, (header, key) => {
      request.set(key, header)
    })

    request
      .set(
        "Accept",
        get(headers, "Accept", get(headers, "accept", "application/json"))
      )
      .authBearer(apiUtils.readAuthCookie(req))

    if (attachments) {
      if (attachments.file) {
        const file = get(attachments, keys(attachments)[0])
        request.attach("file", file, get(file, "name"))
      } else {
        forEach(attachments, (file, i) => {
          request.attach(`files[${i}]`, file, get(file, "name"))
        })
      }
    }

    request.on("progress", (e) => {
      onClientStatus && onClientStatus(request, e)
      onProgress && onProgress(e)
    })

    if (blob) {
      request.responseType("blob")
    }

    return request.end(async (err, response = {}) => {
      const headers = get(req, "headers", get(response, "headers", {}))
      const body = get(response, "body")

      headers.status = response?.status

      if (
        response?.req?.url !== "/session_tokens" &&
        response?.status === 401
      ) {
        if (retryCount < 1) {
          const token = await getCurrentJWT()
          apiUtils.saveAuthCookie(null, token)

          return resolve(
            sendRequest({
              request: createRequest(request.method.toLowerCase(), request.url),
              req,
              params,
              data,
              attachments,
              onProgress,
              onClientStatus,
              headers,
              blob,
              includeHeaders,
              retryCount: retryCount + 1
            })
          )
        }

        apiUtils.deleteAuthCookie()
        window.location.assign("/react/login")
      }

      if (body && body.auth_token) {
        apiUtils.saveAuthCookie(req, body.auth_token)
      }

      return err
        ? reject(
            new Error(
              JSON.stringify({
                ...(includeHeaders ? { body, headers } : body || err),
                errorType: "xhr"
              })
            )
          )
        : resolve(includeHeaders ? { body, headers } : body)
    })
  })
}

function defaultGetItems(response) {
  return get(response, "items", get(response, "models", response))
}

export default class ApiClient {
  constructor(req) {
    methods.forEach((method) => {
      this[method] = (path, requestOptions) => {
        if (!size(path)) {
          throw new Error("Please provide the url for API request")
        }

        const request = createRequest(method, path)

        return {
          sendRequest: () => sendRequest({ ...requestOptions, request, req }),
          meta: {
            request,
            method,
            ...pick(requestOptions, ["url", "params"])
          }
        }
      }
    })
  }

  getNormalizedPagedResponse(url, options) {
    if (!options.params) {
      // eslint-disable-next-line no-console
      console.error(url, JSON.stringify(options, null, 2))
      throw new Error("It looks like you forgot to pass the params")
    }
    if (options.params.page === 1) {
      return this.getListWithCount(url, {
        ...options,
        from: 1,
        transformResponse: ({ items, ...rest }) => ({
          ...normalizeResponse(items),
          ...rest
        })
      })
    }

    return this.getPages(url, {
      ...options,
      transformResponse: normalizeResponse
    })
  }

  getHybrid(url, options) {
    if (!options.params) {
      // eslint-disable-next-line no-console
      console.error(url, JSON.stringify(options, null, 2))
      throw new Error("It looks like you forgot to pass the params")
    }

    if (options.params.page === 1) {
      return this.getListWithCount(url, {
        ...options,
        from: 1
      })
    }
    return this.getPages(url, options)
  }

  getPages(
    url,
    {
      params,
      from,
      getItems,
      transformResponse = (x) => x,
      addPaged = true,
      ...rest
    }
  ) {
    const delta = isNil(from) ? 1 : from
    const to = max([get(params, "page", 0) - delta, 0])

    if (!to) {
      // eslint-disable-next-line no-console
      console.error(url, JSON.stringify(params, null, 2))
      throw new Error("It looks like you forgot to pass the page")
    }

    const urlSuffix = addPaged ? "/paged.json" : ""
    const getRequests = map(times(to), (i) =>
      this.get(`${url}${urlSuffix}`, {
        params: {
          ...params,
          page: +i + delta + 1
        }
      })
    )
    const parseResponse = getItems || defaultGetItems

    return {
      sendRequest: () =>
        Promise.all(
          map(getRequests, (getRequest) => getRequest.sendRequest())
        ).then((loads) =>
          transformResponse(flatten(map(loads, parseResponse)))
        ),
      meta: {
        method: "get",
        url,
        params,
        from,
        ...rest
      }
    }
  }

  getListWithCount(
    url,
    { params, from, transformResponse = (x) => x, ignoreOutdateParams } = {}
  ) {
    return {
      sendRequest: () =>
        Promise.all([
          this.get(`${url}/paged.json`, { params }).sendRequest(),
          this.get(`${url}/count.json`, { params }).sendRequest()
        ]).then(([items, count]) => transformResponse({ items: items, count })),
      meta: {
        method: "get",
        url,
        params,
        from,
        ignoreOutdateParams
      }
    }
  }
}

export const client = new ApiClient()
