import map from "lodash/map"
import get from "lodash/get"
import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/billing-address/LOAD_START"
const LOAD_SUCCESS = "jass/billing-address/LOAD_SUCCESS"
const LOAD_FAIL = "jass/billing-address/LOAD_FAIL"

const SET_BILLING_ADDRESSES = "jass/billing-address/SET_BILLING_ADDRESSES"

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result,
  [SET_BILLING_ADDRESSES]: (state, action) => action.payload
})

export default combineReducers({ items })

export const load = ({ customerId, params }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.get(`partner_customers/${customerId}/billing_addresses`, {
        params
      }),
    postProcess: (res) => {
      if (!res) {
        return null
      }
      return map(res.billing_addresses, (address) => ({
        id: get(address, "id"),
        addressId: get(address, "id"),
        name: get(address, "name"),
        contactLastName: get(address, "last_name"),
        contactFirstName: get(address, "first_name"),
        contactEMail: get(address, "email"),
        contactSalutation: get(address, "salutation"),
        contactTitle: get(address, "title"),
        contactCompany: get(address, "company"),
        contactCountry: get(address, "country"),
        contactCity: get(address, "city"),
        contactStreet: get(address, "street"),
        contactStreetNumber: get(address, "street_number"),
        contactZip: get(address, "zip"),
        isDefault: get(address, "is_default")
      }))
    }
  }
}

export const setBillingAddresses = (items) => {
  return { type: SET_BILLING_ADDRESSES, payload: items }
}
