import React from "react"
import { Grid } from "@mui/material"

import DynamicField from "features/order-create/ui/DynamicField"

export const CommonDynamicFields = ({ fields }) => {
  return (
    <Grid container rowSpacing={2}>
      {fields.map((field) => {
        return (
          <Grid item md={12} key={field.id}>
            <DynamicField
              name={`product_fields.${field.technical_name}`}
              field={field}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
