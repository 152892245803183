import get from "lodash/get"
import isEqual from "lodash/isEqual"
import omit from "lodash/omit"
import size from "lodash/size"
import union from "lodash/union"
import uniqWith from "lodash/uniqWith"

const INIT = "jass/components/autocomplete/INIT"
const DESTROY = "jass/components/autocomplete/DESTROY"
const UPDATE_PARAMS = "jass/components/autocomplete/UPDATE_PARAMS"
const UPDATE_SEARCH_TEXT = "jass/components/autocomplete/UPDATE_SEARCH_TEXT"
const LOAD = "jass/components/autocomplete/LOAD"
const LOAD_SUCCESS = "jass/components/autocomplete/LOAD_SUCCESS"
const LOAD_FAIL = "jass/components/autocomplete/LOAD_FAIL"
const LOAD_MORE = "jass/components/autocomplete/LOAD_MORE"
const LOAD_MORE_SUCCESS = "jass/components/autocomplete//LOAD_MORE_SUCCESS"
const LOAD_MORE_FAIL = "jass/components/autocomplete//LOAD_MORE_FAIL"
const LOAD_PREVIEW = "jass/components/autocomplete/LOAD_PREVIEW"
const LOAD_PREVIEW_SUCCESS = "jass/components/autocomplete/LOAD_PREVIEW_SUCCESS"

const initialState = {
  initialized: false,
  list: null,
  count: 0,
  preview: {
    list: null
  },
  params: {
    page: 1
  },
  error: null,
  loading: false,
  searchText: ""
}

export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        [action.id]: {
          ...initialState,
          initialized: true,
          ...(state[action.id] || {}),
          ...omit(action, ["type", "id"])
        }
      }
    case LOAD_PREVIEW: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: true
        }
      }
    }
    case LOAD_PREVIEW_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          preview: {
            list: defaultGetItems(action.result)
          },
          loading: false
        }
      }
    case DESTROY: {
      return omit(state, [action.id])
    }
    case UPDATE_PARAMS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          params: get(action, "params", initialState.params)
        }
      }
    case UPDATE_SEARCH_TEXT:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          searchText: get(action, "searchText", initialState.searchText)
        }
      }
    case LOAD:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          params: get(action, "params", initialState.params),
          count: 0,
          loading: true
        }
      }
    case LOAD_SUCCESS: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          list: unionWithPreviewList(
            defaultGetItems(action.result),
            get(state, `["${action.id}"].preview.list`, [])
          ),
          count: +get(action, "result.count", size(action.result)) || 0,
          loading: false
        }
      }
    }
    case LOAD_FAIL:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          error: get(action, "error", new Error("unknown")),
          loading: false
        }
      }
    case LOAD_MORE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: true,
          params: get(action, "params", get(state, `[${action.id}].params`))
        }
      }
    case LOAD_MORE_SUCCESS: {
      const currentList = get(state, `[${action.id}].list`, [])
      const list = union(currentList, defaultGetItems(action.result))

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false,
          loaded: true,
          list: unionWithPreviewList(
            list,
            get(state, `["${action.id}"].preview.list`, [])
          )
        }
      }
    }
    case LOAD_MORE_FAIL:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false,
          loaded: false,
          error: get(action, "error", new Error("unknown"))
        }
      }
    default:
      return state
  }
}

export function load({
  id,
  url,
  params,
  list = null,
  makeItems = (v) => v,
  getItems
}) {
  const newParams = {
    ...params,
    page: 1
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: list || ((client) => client.get(url, { params: newParams })),
    postProcess: (res) => makeItems((getItems || defaultGetItems)(res)),
    params: newParams,
    id
  }
}

function unionWithPreviewList(list, previewList) {
  return uniqWith(union(list, previewList), isEqual)
}

function defaultGetItems(response) {
  return get(response, "items", get(response, "models", response))
}
