import CloseIcon from "@mui/icons-material/Close"
import { Dialog as MuiDialog, DialogTitle } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import React, { memo } from "react"

export const Dialog = memo(
  /**
   * @param {Omit<import("@mui/material").DialogProps, 'title'> & {title?: React.ReactNode, titleSx?: import("@mui/material").SxProps}} props
   */
  ({ title, onClose, children, titleSx = {}, ...rest }) => {
    return (
      <MuiDialog onClose={onClose} {...rest}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ...titleSx
          }}
        >
          {title ?? <div />}
          <IconButton
            aria-label="close"
            onClick={(e) => onClose(e, "backdropClick")}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {children}
      </MuiDialog>
    )
  }
)
