import React from "react"
import { IconButton, InputAdornment } from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import useToggle from "hooks/useToggle"
import { TextField } from "./TextField"

export const PasswordTextField = (props) => {
  const [passwordHidden, togglePasswordHidden] = useToggle(true)

  return (
    <TextField
      type={passwordHidden ? "password" : "text"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordHidden}
              edge="end"
            >
              {passwordHidden ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}
