import React from "react"
import { DialogContent } from "@mui/material"

import { Dialog } from "shared/ui/berry-jass"

const URLPreviewComponent = ({ open, name, src, onClose }) => {
  if (!open) {
    return null
  }

  return (
    <Dialog
      fullScreen
      title={`Preview of ${name}`}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <iframe style={{ width: "100%", height: "98%", border: 0 }} src={src} />
      </DialogContent>
    </Dialog>
  )
}

export const URLPreview = React.memo(URLPreviewComponent)
