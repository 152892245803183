export const LOAD_FOR_PRODUCT_START = "jass/statistics/LOAD_FOR_PRODUCT_START"
const LOAD_FOR_PRODUCT_SUCCESS = "jass/statistics/LOAD_FOR_PRODUCT_SUCCESS"
const LOAD_FOR_PRODUCT_FAILURE = "jass/statistics/LOAD_FOR_PRODUCT_FAILURE"
export const CLEAR_PRODUCT_DATA = "jass/statistics/CLEAR_PRODUCT_DATA"

const productData = (state = null, action) => {
  switch (action.type) {
    case CLEAR_PRODUCT_DATA:
      return null
    case LOAD_FOR_PRODUCT_SUCCESS:
      return action.result || []
    default:
      return state
  }
}

export const loadForProduct = (position) => {
  return {
    types: [
      LOAD_FOR_PRODUCT_START,
      LOAD_FOR_PRODUCT_SUCCESS,
      LOAD_FOR_PRODUCT_FAILURE
    ],
    promise: (client) =>
      client.get(
        `orders_positions/${position.value}/ad_tracking_chart_data.json`
      ),
    position
  }
}

export const clearProductData = () => {
  return { type: CLEAR_PRODUCT_DATA }
}

export default productData
