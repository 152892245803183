import { combineReducers } from "redux"
import offer from "./offer"
import ui from "./ui"

const LOAD_SLOTS_SUBSCRIPTION_START =
  "jass/campaigns/LOAD_SLOTS_SUBSCRIPTION_START"
const LOAD_SLOTS_SUBSCRIPTION_SUCCESS =
  "jass/campaigns/LOAD_SLOTS_SUBSCRIPTION_SUCCESS"
const LOAD_SLOTS_SUBSCRIPTION_FAILURE =
  "jass/campaigns/LOAD_SLOTS_SUBSCRIPTION_FAILURE"

export const loadSlotsSubscription = (slotsSubscriptionId) => {
  return {
    types: [
      LOAD_SLOTS_SUBSCRIPTION_START,
      LOAD_SLOTS_SUBSCRIPTION_SUCCESS,
      LOAD_SLOTS_SUBSCRIPTION_FAILURE
    ],
    promise: (client) =>
      client.get(`campaigns/slots_subscriptions/${slotsSubscriptionId}`)
  }
}

const UPDATE_SLOTS_SUBSCRIPTION_START =
  "jass/campaigns/UPDATE_SLOTS_SUBSCRIPTION_START"
const UPDATE_SLOTS_SUBSCRIPTION_SUCCESS =
  "jass/campaigns/UPDATE_SLOTS_SUBSCRIPTION_SUCCESS"
const UPDATE_SLOTS_SUBSCRIPTION_FAILURE =
  "jass/campaigns/UPDATE_SLOTS_SUBSCRIPTION_FAILURE"

export const updateSlotsSubscription = (
  slotsSubscriptionId,
  subSlotsSubscription
) => {
  return {
    types: [
      UPDATE_SLOTS_SUBSCRIPTION_START,
      UPDATE_SLOTS_SUBSCRIPTION_SUCCESS,
      UPDATE_SLOTS_SUBSCRIPTION_FAILURE
    ],
    promise: (client) =>
      client.patch(`campaigns/slots_subscriptions/${slotsSubscriptionId}`, {
        data: { slots_subscription: subSlotsSubscription }
      })
  }
}

export default combineReducers({
  offer,
  ui
})
