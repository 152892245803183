import { createReducer } from "store/helpers/reducers"
import union from "lodash/union"
import {
  createNotification,
  setIsRead
} from "store/modules/components/notifications/helpers"
import {
  CLEAR_READ,
  NEW_FROM_WEBSOCKETS,
  MARK_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_SUCCESS
} from "store/modules/components/notifications/common"
import { combineReducers } from "redux"

const prefix = "jass/notifications/ALL"
const LOAD_ALL_NOTIFICATIONS_START = `${prefix}/LOAD_START`
const LOAD_ALL_NOTIFICATIONS_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_ALL_NOTIFICATIONS_FAIL = `${prefix}/LOAD_FAIL`

const loading = createReducer(false, {
  [LOAD_ALL_NOTIFICATIONS_START]: () => true,
  [LOAD_ALL_NOTIFICATIONS_SUCCESS]: () => false,
  [LOAD_ALL_NOTIFICATIONS_FAIL]: () => false
})

const params = createReducer(
  {},
  {
    [LOAD_ALL_NOTIFICATIONS_SUCCESS]: (state, action) => action.params
  }
)

const hasMore = createReducer(false, {
  [LOAD_ALL_NOTIFICATIONS_SUCCESS]: (state, action) =>
    !!action.result && action.result.length >= 35
})

const items = createReducer([], {
  [LOAD_ALL_NOTIFICATIONS_SUCCESS]: (state, action) => {
    return union(action.params.more ? state : [], action.result)
  },
  [CLEAR_READ]: (state) => {
    return state.filter((notification) => !notification.is_read)
  },
  [NEW_FROM_WEBSOCKETS]: (state, action) => {
    return [...state, createNotification(action.notification)]
  },
  [MARK_AS_READ_SUCCESS]: (state, action) => {
    return setIsRead(state, true, action.notificationId)
  },
  [MARK_ALL_AS_READ_SUCCESS]: (state) => {
    return setIsRead(state, true)
  }
})

export default combineReducers({
  loading,
  params,
  hasMore,
  items
})

export const load = (data) => {
  return {
    types: [
      LOAD_ALL_NOTIFICATIONS_START,
      LOAD_ALL_NOTIFICATIONS_SUCCESS,
      LOAD_ALL_NOTIFICATIONS_FAIL
    ],
    promise: (client) => client.get("notifications.json", data)
  }
}
