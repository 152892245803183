import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/history/endCustomerPrices/LOAD_START"
const LOAD_SUCCESS = "jass/history/endCustomerPrices/LOAD_SUCCESS"
const LOAD_FAIL = "jass/history/endCustomerPrices/LOAD_FAIL"
const CLEAR_PRICES = "jass/history/endCustomerPrices/CLEAR_PRICES"

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result,
  [CLEAR_PRICES]: () => []
})

export default combineReducers({ loading, items })

export const loadPrices = (params) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.get("products_prices/end_customer/versions", { params })
  }
}

export const loadPackages = ({ id }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`affiliate_product_packages/${id}/versions`)
  }
}

export const clearPrices = () => {
  return {
    type: CLEAR_PRICES
  }
}
