import React, { useCallback, useMemo } from "react"
import Autocomplete from "components/Autocomplete/Autocomplete"
import usePlacesAutocomplete from "use-places-autocomplete"
import { DENYLIST } from "components/TouchDesign/Components/GooglePlacesAutocomplete"

export const cityMapper = (response) => {
  if (!response || !response.length) return []
  return response.map((item) => {
    const city = item.terms?.[0]?.value
    return {
      id: city,
      name: city,
      value: city,
      label: city,
      placeId: item.place_id
    }
  })
}

export const getCityParams = (country) => ({
  types: ["(cities)"],
  componentRestrictions: { country }
})

/**
 *
 * @param {object} props
 * @param {(value: any[]) => any[]} [props.mapResponseToDataSource]
 * @param {import('use-places-autocomplete').RequestOptions} [props.params]
 */
const GooglePlacesAutocomplete = ({
  mapResponseToDataSource = (result) => result,
  params,
  ...rest
}) => {
  const {
    ready,
    setValue,
    suggestions: { data }
  } = usePlacesAutocomplete({
    requestOptions: params,
    debounce: 1000
  })

  const handleOpen = useCallback(() => setValue(""), [setValue])

  const handleInputChange = useCallback(
    (searchText, { action }) => {
      if (action === "input-change" && searchText?.length > 2) {
        setValue(searchText)
      }
    },
    [setValue]
  )

  const dataSource = useMemo(
    () =>
      mapResponseToDataSource(
        data.filter((c) => !DENYLIST.includes(c.description))
      ),
    [data, mapResponseToDataSource]
  )

  return (
    <div className="new-jass-autocomplete">
      <Autocomplete
        {...rest}
        loading={!ready}
        onFocus={handleOpen}
        onInputChange={handleInputChange}
        dataSource={dataSource}
      />
    </div>
  )
}

export default GooglePlacesAutocomplete
