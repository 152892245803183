import React, { useRef, useState, useEffect } from "react"
import useEffectOnce from "hooks/useEffectOnce"
import * as PropTypes from "prop-types"
import axios from "axios"

import Autocomplete from "components/TouchDesign/Components/Autocomplete"
import { useDebouncedCallback } from "use-debounce"
import set from "lodash/set"
import deepmerge from "deepmerge"
import client from "utils/client"
import { serverError } from "utils/parsers/serverError"
import useNotifications from "hooks/useNotifications"
import { I18n } from "react-redux-i18n"

/**
 *
 * @param {import('@mui/material').AutocompleteProps & {url: string, variant: string, mapResponseToDataSource?: (any) => any[], params?: object, initialValue?: any, paginated?: boolean, searchProp: string?}} props
 * @returns
 */
const RemoteAutocomplete = ({
  url = null,
  variant = "search_property",
  mapResponseToDataSource = (result) => result,
  params = null,
  initialValue = null,
  paginated = false,
  searchProp = null,
  leading = true,
  ...rest
}) => {
  const { showError } = useNotifications()
  const previousRequest = useRef(null)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(initialValue ? [initialValue] : [])
  const [hasMore, setHasMore] = useState(true)
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  const [position, setPosition] = useState(0)
  const [listboxNode, setListboxNode] = useState(null)

  useEffectOnce(() => {
    if (rest.open) {
      handleAPIRequest()
    }
  })

  // workaround for issue: https://github.com/mui/material-ui/issues/30249
  useEffect(() => {
    if (paginated && listboxNode) {
      listboxNode.scrollTop = position
    }
  }, [paginated, listboxNode, position])

  const getSearchPart = (searchText) => {
    switch (variant) {
      case "search_property":
        return { additional_search: { search_property: searchText } }
      case "term":
        return { term: searchText }
      case "search":
        return { search: searchText }
      case "ransack":
        return set({}, searchProp, searchText)
      default:
        return {}
    }
  }

  const fetchOptions = (searchText = "", page) => {
    if (previousRequest.current) {
      previousRequest.current.cancel("Cancel previous request")
    }
    previousRequest.current = axios.CancelToken.source()

    let path = url
    if (!path.startsWith("/")) {
      path = `/${path}`
    }
    if (!path.endsWith(".json")) {
      path = `${path}.json`
    }

    return client.get(path, {
      params: deepmerge(params, {
        ...getSearchPart(searchText),
        page: paginated ? page : undefined
      }),
      cancelToken: previousRequest.current.token
    })
  }

  const handleAPIRequest = async (searchText = "", page = 1) => {
    setSearch(searchText)
    setPage(page)
    setLoading(true)
    setHasMore(true)

    fetchOptions(searchText, page)
      .then(({ data: response }) => {
        const newOptions = mapResponseToDataSource(response)
        if (page > 1) {
          if (!newOptions?.length) {
            setHasMore(false)
          }
          setOptions(options.concat(newOptions))
        } else {
          setOptions(newOptions)
        }

        if (listboxNode && paginated) {
          setPosition(listboxNode.scrollTop)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          showError(
            serverError(error.response.data?.errors ?? {}) ||
              I18n.t("errors.server.500")
          )
        }
      })
      .finally(() => {
        setLoading(false)
        previousRequest.current = null
      })
  }

  const handleInputChange = async (e, searchText, reason) => {
    if (rest.onInputChange) {
      rest.onInputChange(e, searchText, reason)
    }

    if (reason !== "input") {
      return Promise.resolve()
    }

    return handleAPIRequest(searchText)
  }

  const onScroll = (event) => {
    const listboxNode = event.currentTarget
    const entryPoint =
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight
    setListboxNode(listboxNode)

    if (paginated && entryPoint && !loading && hasMore) {
      handleAPIRequest(search, page + 1)
    }
  }

  const [handleInputChangeDebounced] = useDebouncedCallback(
    handleInputChange,
    500,
    { leading }
  )

  return (
    <Autocomplete
      loading={loading}
      options={options}
      onOpen={() => {
        !previousRequest.current && handleAPIRequest()
      }}
      onInputChange={handleInputChangeDebounced}
      ListboxProps={{ onScroll }}
      {...rest}
    />
  )
}

RemoteAutocomplete.propTypes = {
  variant: PropTypes.oneOf(["term", "search_property", "search", "ransack"])
}

export default RemoteAutocomplete
