import makeStyles from "@mui/styles/makeStyles"
import CheckedIcon from "components/Icon/Checked"
import ButtonBase from "@mui/material/ButtonBase"
import React from "react"
import { Box } from "@mui/material"

const DEFAULT_BG_IMAGE_SRC = "/react/assets/img/editor_layouts/layout1.png"

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  button: {
    width: 166,
    height: 212,
    marginBottom: 12,
    fontSize: 16,
    fontWeight: "bold",
    position: "relative"
  },
  selected: {
    backgroundColor: "#32ba7c",
    borderRadius: "100%",
    padding: "8px 5px 2px 5px",
    position: "absolute",
    right: -5,
    top: -10
  },
  img: {
    position: "absolute",
    zIndex: -1,
    opacity: 0.5,
    width: "100%",
    height: "100%"
  }
})

const CustomDesign = ({
  name,
  onClick,
  backgroundImgSrc = DEFAULT_BG_IMAGE_SRC,
  selected = false
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {selected && (
        <div className={classes.selected}>
          <CheckedIcon />
        </div>
      )}
      <ButtonBase
        onClick={onClick}
        className={classes.button}
        disabled={selected}
      >
        <img className={classes.img} src={backgroundImgSrc} />
        <Box sx={{ wordBreak: "break-word", px: 3 }}>{name}</Box>
      </ButtonBase>
    </div>
  )
}
export default CustomDesign
