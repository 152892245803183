import get from "lodash/get"
import createSelector from "re-reselect"

export const createGetParams = (path) => (state) =>
  get(state, `${path}.params`, {})

export const createGetSort = (path) =>
  createSelector(
    (state) => createGetParams(path)(state).sort,
    (state) => createGetParams(path)(state).ascending,
    (columnKey, ascending) => ({
      columnKey: columnKey || "",
      ascending: ascending || false
    })
  )((path) => `get-sort-${path}`)
