import includes from "lodash/includes"
import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import { UPDATE } from "store/modules/entities/overview/stateTransitions"

const prefix = "jass/PRODUCTION_OVERVIEW/MY_WORK_ORDERS"

const LOAD_START = `${prefix}/LOAD_START`
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`

const myWorkStates = [
  "pending",
  "editorial",
  "editorial_changes",
  "html_realisation",
  "order_validation",
  "publication",
  "error_validation",
  "waiting_for_customer_send",
  "waiting_for_send_to_customer",
  "wait_for_text_clearance",
  "wait_for_publication_approval"
]

const REMOVE_ORDER = -1
const ADD_ORDER = 1
const NO_CHANGES = 0

const getOrderAction = (state, action) => {
  const { order: oneOrder, orders } = action.payload
  const order = oneOrder ?? orders[0]
  const { currentUser } = action.meta
  const [prevState, nextState] = order.state_changes
  const [, nextProducer] = order.producer_changes

  const prevStateIncluded = includes(myWorkStates, prevState)
  const nextStateIncluded = includes(myWorkStates, nextState)
  const assignedToCurrentUser =
    order.producer_changes.length && nextProducer === currentUser
  const unassignedFromCurrentUser =
    order.producer_changes.length && nextProducer !== currentUser

  if (unassignedFromCurrentUser || (prevStateIncluded && !nextStateIncluded)) {
    return REMOVE_ORDER
  }

  if (assignedToCurrentUser || (nextStateIncluded && !prevStateIncluded)) {
    return ADD_ORDER
  }

  return NO_CHANGES
}

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const ids = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }
    return action.result.ids
  },
  [UPDATE]: (state, action) => {
    const { order: oneOrder, orders } = action.payload
    const order = oneOrder ?? orders[0]
    switch (getOrderAction(state, action)) {
      case ADD_ORDER:
        return [order.id.toString(), ...state]
      case REMOVE_ORDER:
        return state.filter((id) => id !== order.id.toString())
      default:
        return state
    }
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state,
  [UPDATE]: (state, action) => {
    const changes = getOrderAction(state, action)
    return state + changes
  }
})

const params = createReducer(
  { page: 1 },
  {
    [LOAD_SUCCESS]: (state, action) => action.params || state
  }
)

export default combineReducers({
  ids,
  count,
  params,
  loading
})

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) =>
    client.getNormalizedPagedResponse("overview/my_work_orders", options)
})
