import get from "lodash/get"
import reduce from "lodash/reduce"
import moment from "moment"

const LOAD_MENU = "jass/utils/LOAD_MENU"
const LOAD_MENU_SUCCESS = "jass/utils/LOAD_MENU_SUCCESS"
const LOAD_MENU_FAIL = "jass/utils/LOAD_MENU_FAIL"
const LOAD_LOCALE = "jass/utils/LOAD_LOCALE"
const LOAD_LOCALE_SUCCESS = "jass/utils/LOAD_LOCALE_SUCCESS"
const LOAD_LOCALE_FAIL = "jass/utils/LOAD_LOCALE_FAIL"
const SWITCH_LOCALE = "jass/utils/SWITCH_LOCALE"
const SWITCH_LOCALE_SUCCESS = "jass/utils/SWITCH_LOCALE_SUCCESS"
const SWITCH_LOCALE_FAIL = "jass/utils/SWITCH_LOCALE_FAIL"
const START_UPLOAD_PROGRESS = "jass/utils/START_UPLOAD_PROGRESS"
const STOP_UPLOAD_PROGRESS = "jass/utils/STOP_UPLOAD_PROGRESS"

const initialState = {
  loaded: false,
  loadedLocale: false,
  locale: "en",
  translations: {
    en: {
      login: {
        title: "Login"
      }
    }
  },
  menu: [],
  theme: {
    sidebar: {
      size: "full"
    }
  },
  uploadStarted: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_MENU:
      return {
        ...state,
        loading: true
      }
    case LOAD_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        menu: get(action, "result", [])
      }
    case LOAD_MENU_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        menu: []
      }
    case LOAD_LOCALE:
      return {
        ...state,
        loadingLocale: true
      }
    case LOAD_LOCALE_SUCCESS: {
      const locale = get(action, "result.locale", initialState.locale)
      moment.locale(locale)
      return {
        ...state,
        loadedLocale: true,
        loadingLocale: false,
        locale,
        translations: get(
          action,
          "result.translations",
          initialState.translations
        )
      }
    }
    case LOAD_LOCALE_FAIL:
      return {
        ...state,
        loadingLocale: false,
        loadedLocale: false
      }
    case SWITCH_LOCALE:
      return {
        ...state,
        loadingLocale: true
      }
    case SWITCH_LOCALE_SUCCESS: {
      const locale = get(action, "result.locale", initialState.locale)
      moment.locale(locale)
      return {
        ...state,
        loadedLocale: true,
        loadingLocale: false,
        locale
      }
    }
    case SWITCH_LOCALE_FAIL:
      return {
        ...state,
        loadingLocale: false,
        loadedLocale: false
      }
    case START_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadStarted: true
      }
    case STOP_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadStarted: false
      }
    default:
      return state
  }
}

export function loadMenu() {
  return {
    types: [LOAD_MENU, LOAD_MENU_SUCCESS, LOAD_MENU_FAIL],
    promise: (client) => client.get("menu_items.json")
  }
}

export function loadLocaleFromBackEnd(globalState) {
  return {
    types: [LOAD_LOCALE, LOAD_LOCALE_SUCCESS, LOAD_LOCALE_FAIL],
    promise: (client) =>
      client.get("translations.json", { includeHeaders: true }),
    postProcess: ({ body, headers }) => ({
      locale: get(
        globalState,
        "utils.locale",
        get(headers, "accept-language", "en").slice(0, 2)
      ),
      translations: reduce(
        body,
        (result, translations, lang) => {
          result[lang] = get(translations, "js", {})
          return result
        },
        {}
      )
    })
  }
}

export function switchLocale(locale) {
  return {
    types: [SWITCH_LOCALE, SWITCH_LOCALE_SUCCESS, SWITCH_LOCALE_FAIL],
    promise: (client) => client.get(`locales/${locale}`),
    postProcess: () => ({ locale })
  }
}
