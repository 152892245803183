import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import { UPDATE } from "store/modules/entities/candidatesBoard/stageTransitions"

const prefix = "jass/CANDIDATES_BOARD/DISQUALIFIED"

const LOAD_START = `${prefix}/LOAD_START`
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`

export const disqualifiedStages = ["disqualified"]

const REMOVE_ORDER = -1
const ADD_ORDER = 1
const NO_CHANGES = 0

const getCandidateAction = (state, action) => {
  const { candidate } = action.payload
  const [prevStage, nextStage] = candidate.stage_changes

  const stageChanged =
    prevStage === "disqualified" && nextStage !== "disqualified"

  if (stageChanged) {
    return REMOVE_ORDER
  }

  const stageChangedToNew =
    prevStage !== "disqualified" && nextStage === "disqualified"

  if (stageChangedToNew) {
    return ADD_ORDER
  }

  return NO_CHANGES
}

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const ids = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }
    return action.result.ids
  },
  [UPDATE]: (state, action) => {
    const { candidate } = action.payload
    switch (getCandidateAction(state, action)) {
      case ADD_ORDER:
        return [candidate.id.toString(), ...state]
      case REMOVE_ORDER:
        return state.filter((id) => id !== candidate.id.toString())
      default:
        return state
    }
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state,
  [UPDATE]: (state, action) => {
    const changes = getCandidateAction(state, action)
    return state + changes
  }
})

const params = createReducer(
  { page: 1 },
  {
    [LOAD_SUCCESS]: (state, action) => action.params || state
  }
)

export default combineReducers({
  ids,
  count,
  params,
  loading
})

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.getNormalizedPagedResponse("candidates", options)
})
