import React, { memo } from "react"

import useLocale from "hooks/useLocale"
import { TooltipCell } from "shared/ui/berry-jass"
import { getProductNameByLocale } from "entities/sales-product"

const SalesProductNameCellComponent = ({ product, sx = {} }) => {
  const locale = useLocale()
  const productName = getProductNameByLocale(product, locale)

  return <TooltipCell value={productName} sx={{ fontSize: "0.75rem", ...sx }} />
}

export const SalesProductNameCell = memo(SalesProductNameCellComponent)
