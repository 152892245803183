import * as React from "react"
import MuiAutocomplete from "@mui/material/Autocomplete"
import { CircularProgress, TextField } from "@mui/material"

export const defaultGetOptionSelected = (a, b) => a?.id === b?.id
export const defaultGetOptionLabel = (option) => option.name
export const defaultGetOptionKey = (option) => option?.id

/**
 * @param {import("utils").PartialBy<import("@mui/material").AutocompleteProps, 'renderInput'> & {name?: string; required?: boolean, label?: React.ReactNode, variant?: string, fetching?: boolean; getOptionKey?: (any) => string}} props
 */
export const Autocomplete = ({
  renderInput,
  label,
  variant,
  required,
  isOptionEqualToValue = defaultGetOptionSelected,
  getOptionLabel = defaultGetOptionLabel,
  getOptionKey = defaultGetOptionKey,
  renderOption,
  loading,
  fetching,
  options,
  placeholder,
  name,
  ...props
}) => {
  const defaultRenderInput = (props) => {
    return (
      <TextField
        required={required}
        label={label}
        name={name}
        placeholder={placeholder}
        variant={variant}
        {...props}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={14} /> : null}
              {props.InputProps.endAdornment}
            </React.Fragment>
          )
        }}
      />
    )
  }

  const defaultRenderOption = (props, option, _state) => (
    // workaround for the issue: https://github.com/mui/material-ui/issues/26492
    <li
      {...props}
      key={
        getOptionKey(option) ??
        // @ts-ignore
        props.key
      }
    >
      {getOptionLabel(option)}
    </li>
  )

  return (
    <MuiAutocomplete
      loading={loading || fetching}
      options={loading ? [] : options}
      renderInput={renderInput ?? defaultRenderInput}
      renderOption={renderOption ?? defaultRenderOption}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  )
}

Autocomplete.displayName = "BerryAutocomplete"
