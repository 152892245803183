import React from "react"
import { Chip, Avatar } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"
import AttachmentIcon from "@mui/icons-material/Attachment"

const useAvatarStyles = makeStyles({
  root: { height: 24, width: 24 }
})

const DefaultAvatar = () => {
  const classes = useAvatarStyles()

  return (
    <Avatar className={classes.root}>
      <AttachmentIcon />
    </Avatar>
  )
}

const useStyles = makeStyles({
  root: {
    marginRight: 5,
    marginTop: 2,
    maxWidth: "100%"
  }
})
const FileChip = ({ avatar = <DefaultAvatar />, className, ...props }) => {
  const classes = useStyles()

  return (
    <Chip
      className={classNames(classes.root, className, "chip")}
      color="secondary"
      avatar={avatar}
      {...props}
    />
  )
}

export default FileChip
