import identity from "lodash/identity"
import isArray from "lodash/isArray"
import isBoolean from "lodash/isBoolean"
import isEmpty from "lodash/isEmpty"
import isPlainObject from "lodash/isPlainObject"

export default function isNotEmpty(value) {
  return isPlainObject(value) || isArray(value)
    ? identity(value) && !isEmpty(value)
    : isBoolean(value) || !!value
}
