import pick from "lodash/pick"
import merge from "lodash/merge"

export const updatedCandidate = (state, action) =>
  pick(merge(state[action.result.id], action.result), [
    "id",
    "name",
    "vacancy_title",
    "email",
    "department",
    "stage",
    "score",
    "creator_name",
    "created_at"
  ])

export const updatedCandidateDetails = (state, action) => {
  const { result } = action
  if (!result.id) {
    return state
  }

  return {
    ...state,
    score: result.score,
    stage: result.stage,
    stage_transitions: result.stage_transitions,
    updated_at: result.updated_at
  }
}

export const updatedCandidateCv = (state, action) => {
  const { result } = action
  if (!result.id) {
    return state
  }

  return {
    ...state,
    cv_file: result.cv_file,
    updated_at: result.updated_at
  }
}
