import React from "react"
import CheckIcon from "@mui/icons-material/Check"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import { v4 as uuidv4 } from "uuid"

const styles = {
  icon: { height: 18, width: 18, color: "white" },
  label: { cursor: "pointer" },
  checked: { backgroundColor: "#388e3c" },
  unchecked: { backgroundColor: "#e57373" }
}

/**
 *
 * @param {object} props
 * @param {string} [props.id]
 * @param {boolean} [props.value]
 * @param {boolean} [props.defaultValue]
 * @param {object} [props.style]
 * @param {(value: boolean) => void} [props.onChange]
 */
const ApproveSection = ({
  id = uuidv4(),
  value,
  style,
  onChange = (_value) => {}
}) => {
  return (
    <label
      style={{
        ...style,
        ...(value ? styles.checked : styles.unchecked)
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <input
        id={id}
        type="checkbox"
        hidden
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />

      <label htmlFor={id} style={styles.label}>
        {value ? (
          <DoneAllIcon style={styles.icon} />
        ) : (
          <CheckIcon style={styles.icon} />
        )}
      </label>
    </label>
  )
}

export default ApproveSection
