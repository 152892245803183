import useNotifications from "hooks/useNotifications"
import { I18n } from "react-redux-i18n"
import qs from "qs"

const useExportHandler = (url, exportFormat, params) => {
  const { showInfo } = useNotifications()

  return (response) => {
    if (response.direct_export_allowed) {
      const formattedParams = qs.stringify(params, {
        arrayFormat: "brackets"
      })
      window.location.href = `${url}.${exportFormat}?${formattedParams}`
    } else {
      showInfo(I18n.t("export.report-too-big"))
    }
  }
}

export default useExportHandler
