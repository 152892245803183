import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const prefix = "jass/overview/orderDetails"
const CREATE_COMMENT_START = `${prefix}/CREATE_COMMENT_START`
const CREATE_COMMENT_SUCCESS = `${prefix}/CREATE_COMMENT_SUCCESS`
const CREATE_COMMENT_FAIL = `${prefix}/CREATE_COMMENT_FAIL`

const loading = createReducer(false, {
  [CREATE_COMMENT_START]: () => true,
  [CREATE_COMMENT_SUCCESS]: () => false,
  [CREATE_COMMENT_FAIL]: () => false
})

export default combineReducers({
  loading
})

export const createComment = (id, data) => ({
  types: [CREATE_COMMENT_START, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAIL],
  promise: (client) => client.put(`/orders/${id}/update_comment`, { data })
})

export const isLoading = (state) => state.overview.comments.loading
