import makeStyles from "@mui/styles/makeStyles"
import Divider from "@mui/material/Divider"
import React from "react"

const useDividerStyles = makeStyles({
  root: {
    position: "relative",
    height: "20px",
    padding: "7px 0"
  },
  label: {
    position: "absolute",
    top: 0,
    left: "calc(50% - 13px)",
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: 500,
    background: "#fff",
    paddingLeft: 5,
    paddingRight: 5
  }
})

const LabeledDivider = ({ label = "OR" }) => {
  const classes = useDividerStyles()

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <Divider variant="fullWidth" />
    </div>
  )
}

export default LabeledDivider
