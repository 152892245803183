export const REFERENCE_FILE_MIME_TYPES = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ],
  "application/pdf": [".pdf"],
  "application/zip": [".zip"],
  "text/html": [".html", ".htm"]
}

export const AD_FILE_MIME_TYPES = {
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ],
  "application/pdf": [".pdf"],
  "application/zip": [".zip"]
}

export const ADDITIONAL_AD_FILE_MIME_TYPES = {
  "application/zip": [".zip"]
}

export const getShowGoogleFontsWarning = (orderText) => {
  if (!orderText) {
    return false
  }

  const fontsAgreement = orderText.google_fonts_usage_agreement
  return fontsAgreement && !fontsAgreement.signing_date
}
