import client from "utils/client"

export const getImageUploadProps = () => {
  return {
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onUpload: (file) => {
      const formData = new FormData()
      formData.append("file", file)

      return client
        .post("/images.json", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then((response) => response.data.image)
    }
  }
}

export const IMAGE_FILE_MIME_TYPES = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"]
}
