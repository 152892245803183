import { useMutation } from "@tanstack/react-query"
import client from "utils/client"

const useUpdateRecrumediaOffer = () => {
  return useMutation(async (params) => {
    const result = await client.put(
      `/recrumedia/api/v1/offers/${params.id}.json`,
      params
    )
    return result.data
  })
}

export default useUpdateRecrumediaOffer
