import makeStyles from "@mui/styles/makeStyles"
import { alpha } from "@mui/material"

export const useDrawerStyles = makeStyles({
  title: {
    padding: "0 20px 5px 20px",
    marginBottom: "0px",
    fontFamily: "Roboto",
    fontSize: 36,
    color: "#100e0e"
  },
  description: {
    padding: "0 20px",
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#100e0e"
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "row",
    width: 403,
    "& .loading-button-wrapper": {
      display: "block"
    },
    "& .preview-link-container": {
      marginTop: 5,
      "& .dropzone": { width: 363 },
      "& .chips-container": {
        width: "100%"
      },
      "& .attached-file-block": {
        padding: "23px 0",
        fontSize: 16,
        width: "100%",
        borderRadius: "2px"
      }
    },
    "& .reference-files": {
      marginTop: 40
    },
    "& .dropzone button": {
      backgroundColor: "#565656",
      margin: "0 0 5px 0",
      width: "100%",
      height: 50,
      borderRadius: 4,
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#464646"
      }
    }
  },
  block: {
    backgroundColor: "#fff",
    width: "100%",
    padding: "20px"
  },
  close: {
    padding: 20,
    textAlign: "right",
    "& > span": {
      fontSize: 12,
      fontFamily: "Avenir",
      fontWeight: 500,
      color: "#9b9a9a"
    },
    "& button": {
      padding: 3,
      margin: "0 10px",
      backgroundColor: "#efefef",
      "& .close-icon": {
        fondSize: 16,
        color: "#9b9a9a"
      }
    }
  },
  furtherBtn: {
    width: "100%",
    padding: "20px",
    textAlign: "right",
    "& button": {
      padding: 15,
      fontSize: 13,
      fontWeight: "bold"
    }
  }
})

export const useButtonStyles = makeStyles({
  root: {
    backgroundColor: "#565656",
    margin: "0 0 5px 0",
    width: "100%",
    height: 50,
    borderRadius: 4,
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: alpha("#565656", 0.8)
    }
  },
  disabled: {
    color: "#ddd !important"
  }
})

export const useChipStyles = makeStyles({
  root: {
    width: "100%"
  },
  label: {
    width: "100%",
    maxWidth: "initial",
    padding: "23px 18px",
    fontSize: 16
  },
  deleteIcon: {
    marginRight: 14,
    fontSize: 12,
    width: 12,
    height: 12
  }
})
