import HtmlToReact from "html-to-react"

const htmlToReactParser = HtmlToReact.Parser()
const processNodeDefinitions = HtmlToReact.ProcessNodeDefinitions()

const ReactFromHTML = ({ html }) => {
  if (!html) {
    return null
  }

  return htmlToReactParser.parseWithInstructions(html ?? "", () => true, [
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ])
}

export default ReactFromHTML
