import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/user/edit/members/LOAD_START"
const LOAD_SUCCESS = "jass/user/edit/members/LOAD_SUCCESS"
const LOAD_FAIL = "jass/user/edit/members/LOAD_FAIL"

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result
})

const params = createReducer(
  {},
  {
    [LOAD_SUCCESS]: (state, action) => action.params
  }
)

export default combineReducers({ loading, items, params })

export const load = (data) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get("users.json", data)
})
