import { useButtonStyles, useChipStyles } from "components/BookingWizard/styles"
import Input from "components/TouchDesign/Components/Input"
import Box from "@mui/material/Box"
import LabeledDivider from "components/TouchDesign/Components/LabeledDivider"
import FilePicker from "components/FormControls/FilePicker/FilePicker"
import ClearIcon from "@mui/icons-material/Clear"
import { MAX_FILE_SIZE } from "constants.js"
import React, { useState, useCallback } from "react"
import { useDispatch } from "react-redux"
import { upload } from "store/modules/components/filepicker"
import { url as isURLValid } from "utils/validation"
import { I18n } from "react-redux-i18n"
import makeStyles from "@mui/styles/makeStyles"

const useInputStyles = makeStyles({
  root: { marginBottom: 0 }
})

const URLFilePicker = ({
  id,
  api,
  api: {
    upload: { url: endpoint, extractValue = (x) => x, params }
  },
  onFileClick,
  onUploadError,
  onReject,
  label,
  onChange,
  value,
  name,
  disabled = false,
  className,
  onUpload
}) => {
  const [url, setURL] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)

  const dispatch = useDispatch()
  const buttonClasses = useButtonStyles()
  const chipClasses = useChipStyles()
  const inputClasses = useInputStyles()

  const handleUpload = useCallback(
    (url) => {
      return dispatch(
        upload({
          url: endpoint,
          params: { ...params, remote_file_url: url }
        })
      ).then((file) => {
        if (file instanceof Error) {
          const error = JSON.parse(file?.message ?? "{}")?.[0]?.error
          setError(error)
          return
        }
        const uploaded = extractValue(file)
        onChange(uploaded)
        onUpload && onUpload(uploaded)
      })
    },
    [dispatch, endpoint, extractValue, onChange, onUpload, params]
  )

  const handleBlur = useCallback(() => {
    setError(null)
    if (url && !isURLValid(url)) {
      setError(I18n.t("errors.url"))
      return
    }

    if (url) {
      setLoading(true)
      handleUpload(url).then(() => {
        setLoading(false)
      })
    }
  }, [handleUpload, url])

  const handleChange = useCallback((url) => {
    setURL(url)
  }, [])

  const handleFocus = useCallback(() => {
    setError(null)
  }, [])

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        e.preventDefault()
        handleBlur()
      }
    },
    [handleBlur]
  )

  const handleUploadStart = useCallback(() => setLoading(true), [])

  const handleFileUpload = useCallback(
    (value) => {
      onChange(value)
      setURL("")
      setLoading(false)
    },
    [onChange]
  )

  const handleUploadError = useCallback(() => {
    setLoading(false)
    return onUploadError?.()
  }, [onUploadError])

  const handleFileRemove = useCallback(() => {
    setURL("")
    onChange(null)
  }, [onChange])

  const isDisabled = disabled || loading || !!value

  return (
    <Box display="flex" flexDirection="column" className={className}>
      <Input
        id={id}
        fullWidth
        placeholder={I18n.t("enter-url")}
        label={label}
        className={inputClasses.root}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        helperText={error}
        error={error}
        value={url}
        disabled={isDisabled}
      />
      <Box marginTop="4px" marginBottom="0">
        <LabeledDivider />
      </Box>
      <FilePicker
        id="upload-order-ad"
        name={name}
        nosm
        fullWidth
        variant="loading-button"
        buttonProps={{ disabled: isDisabled, classes: buttonClasses }}
        disableClick={isDisabled}
        chipClasses={chipClasses}
        chipAvatar={null}
        deleteIcon={<ClearIcon />}
        label={I18n.t("choose-file")}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        component={FilePicker}
        onUploadStart={handleUploadStart}
        onUpload={handleFileUpload}
        onFileRemove={handleFileRemove}
        value={value}
        api={api}
        onFileClick={onFileClick}
        onUploadError={handleUploadError}
        onReject={onReject}
      />
    </Box>
  )
}

export default URLFilePicker
