import { Field, connect } from "formik"
import React from "react"
import { Translate } from "react-redux-i18n"
import { Button, Grid } from "@mui/material"
import {
  PreviewableFilePicker,
  TextField,
  ColorPicker,
  ColorPreview
} from "shared/ui/berry-jass-formik"
import { SupplierLogoPreview } from "./SupplierLogoPreview"
import { SupplierIconPreview } from "./SupplierIconPreview"
import { getImageUploadProps } from "entities/image"
import {
  getIconUploadProps,
  useHandleIconGeneration,
  defaultIconText,
  DEFAULT_ICON_TEXT_LENGTH
} from "entities/icon"
import Divider from "@mui/material/Divider"

export const SupplierForm = connect(({ formik: { values, setFieldValue } }) => {
  const handleIconGeneration = useHandleIconGeneration(setFieldValue)

  return (
    <Grid container rowGap={3} columnSpacing={1}>
      <Grid item md={6}>
        <Field
          fullWidth
          name="icon_id"
          label={<Translate value="suppliers.icon.title" />}
          component={PreviewableFilePicker}
          variant={"rounded"}
          preview={SupplierIconPreview}
          avatarSx={{ marginLeft: 0 }}
          previewSx={{ marginLeft: 0 }}
          sx={{ justifyContent: "flex-start" }}
          {...getIconUploadProps()}
        >
          <Translate value="suppliers.icon.upload-icon" />
        </Field>
      </Grid>
      <Grid item md={6}>
        <Field
          fullWidth
          name="image_id"
          label={<Translate value="suppliers.logo.title" />}
          component={PreviewableFilePicker}
          variant={"rounded"}
          preview={SupplierLogoPreview}
          avatarSx={{ marginLeft: 0 }}
          sx={{ justifyContent: "flex-start" }}
          {...getImageUploadProps()}
        />
      </Grid>
      {values.icon_id?.invalid_image_dimensions && (
        <>
          <Grid
            item
            md={6}
            sx={{
              marginLeft: "9px",
              minWidth: "50%",
              color: "error.main",
              paddingTop: "8px",
              paddingBottom: "8px",
              borderRadius: "6px",
              backgroundColor: "error.light",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              alignSelf: "stretch",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.3px"
            }}
          >
            <Translate value="suppliers.icon.error.invalid-dimensions" />
          </Grid>
          <Grid item md={5} />
        </>
      )}
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={3}>
        <Field
          fullWidth
          name="icon_attributes.text"
          label={<Translate value="suppliers.icon.text.title" />}
          component={TextField}
        />
      </Grid>
      <Grid item md={1}>
        <Field
          name="icon_attributes.background_color"
          component={ColorPreview}
          fullWidth
          minHeight={51}
        />
      </Grid>
      <Grid item md={3}>
        <Field
          name="icon_attributes.background_color"
          label={<Translate value="suppliers.icon.background-color.title" />}
          component={ColorPicker}
          fullWidth
        />
      </Grid>
      <Grid item md={1}>
        <Field
          name="icon_attributes.font_color"
          component={ColorPreview}
          minHeight={50}
          fullWidth
        />
      </Grid>
      <Grid item md={3}>
        <Field
          name="icon_attributes.font_color"
          label={<Translate value="suppliers.icon.font-color.title" />}
          component={ColorPicker}
          fullWidth
        />
      </Grid>
      <Grid item md={1}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          onClick={() => handleIconGeneration(values)}
        >
          <Translate value="suppliers.icon.generate.title" />
        </Button>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          fontSize: "12px",
          alignItems: "flex-start",
          marginTop: "-12px",
          paddingBottom: "4px"
        }}
      >
        <Translate value="suppliers.icon.message" />
      </Grid>

      <Grid item md={12}>
        <Field
          required
          fullWidth
          name="name"
          label={<Translate value="suppliers.name.title" />}
          component={TextField}
          onChange={(_, value) => {
            if (
              !values.icon_attributes?.text ||
              values.icon_attributes?.text.length < DEFAULT_ICON_TEXT_LENGTH
            ) {
              setFieldValue("icon_attributes.text", defaultIconText(value))
            }
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          fullWidth
          name="web_address"
          label={<Translate value="suppliers.web-address.title" />}
          component={TextField}
        />
      </Grid>
      <Grid item md={12}>
        <Field
          fullWidth
          name="wiki_link"
          label={<Translate value="suppliers.wiki-link.title" />}
          component={TextField}
        />
      </Grid>
    </Grid>
  )
})
