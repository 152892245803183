import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const SET_CONTACTS = "jass/contact/for-end-customer/SET_CONTACTS"

const list = createReducer([], {
  [SET_CONTACTS]: (state, action) => action.payload
})

export const setContacts = (items) => {
  return { type: SET_CONTACTS, payload: items }
}

export default combineReducers({ list })
