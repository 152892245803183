import React from "react"
import { getIn } from "formik"
import { getErrorText } from "./helpers"
import { ColorPicker as ColorPickerUI } from "shared/ui/berry-jass"

/**
 * @param {import("react-color").ColorPickerProps & import("formik").FieldProps & { disabled?: boolean, helperText?: string, onBlur, hideError?: boolean }} props
 */
const fieldToColorPicker = ({
  disabled,
  field: { onChange: fieldOnChange, onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  hideError,
  ...props
}) => {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    error: showError,
    helperText: !hideError && showError ? getErrorText(fieldError) : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function onBlur(e) {
        fieldOnBlur(e ?? field.name)
      },
    onChange: function onChange(color) {
      fieldOnChange(field.name)(color)
      if (props.onChange) {
        props.onChange(color)
      }
    },
    ...field,
    ...props
  }
}

/**
 * @param {import("react-color").ColorPickerProps & import("formik").FieldProps & { disabled?: boolean, helperText?: string, onBlur, hideError?: boolean }}} props
 * @returns
 */
export const ColorPicker = (props) => {
  return <ColorPickerUI {...fieldToColorPicker(props)} />
}

ColorPicker.displayName = "ColorPicker"
