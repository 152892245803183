import MUIButton from "@mui/material/Button"
import React from "react"
import makeStyles from "@mui/styles/makeStyles"

const useButtonStyles = makeStyles({
  sizeSmall: {
    height: 50
  },
  sizeLarge: {
    height: 59,
    fontFamily: "Roboto Slab"
  },
  root: {
    height: 56,
    textTransform: "none",
    fontSize: 18,
    margin: 0
  },
  outlined: {
    backgroundColor: "white"
  }
})

/**
 *
 * @param {import('@mui/material').ButtonProps} props
 */
const TouchButton = (props) => {
  const buttonClasses = useButtonStyles()

  return <MUIButton classes={buttonClasses} {...props} />
}

export default TouchButton
