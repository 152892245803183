import config from "../../config.js"
import * as Promise from "bluebird"
import isFunction from "lodash/isFunction"
import get from "lodash/get"
import indexOf from "lodash/indexOf"
import set from "lodash/set"
import isEqual from "lodash/isEqual"
import omit from "lodash/omit"

export const TIMEOUT = "jass/notify/XHR_COMMON_TIMEOUT"
const IS_OUTDATED = "IGNORE/PROMISE/iS_OUTDATED"
const REQUEST_HAS_SENT = "IGNORE/PROMISE/REQUEST_HAS_SENT"
const activePromises = {}
const timeOutMiliseconds = config.apiRequestTimeout * 1000

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return (next) =>
      (action = {}) => {
        if (isFunction(action)) {
          return action(dispatch, getState)
        }

        const {
          promise,
          types,
          id = "id",
          postProcess = (v) => v,
          options,
          options: { ignoreConcurrency = false } = {},
          ...rest
        } = action

        if (!promise) {
          return next(action)
        }

        const [REQUEST, SUCCESS, FAILURE] = types
        const requestKey = `${REQUEST}/${id}`
        const activePromiseKey = `${SUCCESS}/${id}`
        const timeOut = get(
          action,
          "options.promiseTimeout",
          get(action, "promiseTimeout", timeOutMiliseconds)
        )

        const promiseResult = isFunction(promise)
          ? promise(client)
          : { sendRequest: async () => promise }

        if (!promiseResult) {
          return null
        }

        const {
          sendRequest = Promise.resolve,
          meta: {
            request,
            method = "",
            ignoreOutdateParams = false,
            ...clientOptions
          } = {}
        } = promiseResult
        const checkforPreventingRequest =
          !ignoreConcurrency && indexOf(["post", "put"], method) !== -1

        if (checkforPreventingRequest) {
          const requestHasSent = get(activePromises, requestKey)

          if (requestHasSent) {
            return Promise.resolve(
              next({
                ...rest,
                id,
                type: REQUEST_HAS_SENT,
                originalType: REQUEST
              })
            )
          }

          set(activePromises, requestKey, { data: rest.data, request })
        } else {
          if (!ignoreOutdateParams && rest.params) {
            set(activePromises, activePromiseKey, {
              params: rest.params,
              request
            })
          }
        }

        next({ ...rest, id, type: REQUEST })
        return Promise.resolve(sendRequest())
          .then((response) => postProcess(response, { client, options }))
          .tap((result) => {
            const activePromise = activePromises[activePromiseKey]
            const isLatest =
              !rest.params ||
              !activePromise ||
              isEqual(activePromise.params, rest.params)

            next({
              result,
              id,
              ...(isLatest
                ? { type: SUCCESS }
                : { type: IS_OUTDATED, originalType: SUCCESS }),
              ...rest,
              ...clientOptions
            })
          })
          [timeOut > 0 ? "timeout" : "tap"](timeOut) // eslint-disable-line no-unexpected-multiline
          .catch(Promise.TimeoutError, (error) => {
            const state = getState()

            if (global.Rollbar) {
              global.Rollbar.error("Front.React.Timeout", {
                ...error,
                reduxState: {
                  action: REQUEST,
                  user: state.auth.user
                }
              })
            }

            next({
              ...rest,
              id,
              error,
              type: TIMEOUT
            })

            return error
          })
          .catch((error) => {
            let errorMessage = ""

            try {
              errorMessage = JSON.parse(error.message)
            } catch (e) {
              errorMessage = error.message
            }

            if (errorMessage?.errorType !== "xhr") {
              // eslint-disable-next-line no-console
              console.error(error)
              throw new Error(error)
            }

            next({
              ...rest,
              id,
              error: omit(errorMessage, ["errorType"]),
              type: FAILURE
            })
            return error
          })
          .finally(() => {
            if (checkforPreventingRequest) {
              set(activePromises, requestKey, undefined)
            }
          })
      }
  }
}
