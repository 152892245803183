import merge from "lodash/merge"
import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"

const LOAD_INVOICES_START = "jass/invoices/LOAD_INVOICES_START"
const LOAD_INVOICES_SUCCESS = "jass/invoices/LOAD_INVOICES_SUCCESS"
const LOAD_INVOICES_FAIL = "jass/invoices/LOAD_INVOICES_FAIL"
const SET_INVOICES_PARAMS = "jass/invoices/SET_INVOICES_PARAMS"
const SET_INVOICE = "jass/invoices/SET_INVOICE"
const UPDATE_INVOICE_START = "jass/invoices/UPDATE_INVOICE_START"
const UPDATE_INVOICE_SUCCESS = "jass/invoices/UPDATE_INVOICE_SUCCESS"
export const UPDATE_INVOICE_FAIL = "jass/invoices/UPDATE_INVOICE_FAIL"
const LOAD_INVOICE_START = "jass/invoices/LOAD_INVOICE_START"
const LOAD_INVOICE_SUCCESS = "jass/invoices/LOAD_INVOICE_SUCCESS"
const LOAD_INVOICE_FAIL = "jass/invoices/LOAD_INVOICE_FAIL"
const LOAD_INVOICES_TOTALS_START = "jass/invoices/LOAD_INVOICES_TOTALS_START"
const LOAD_INVOICES_TOTALS_SUCCESS =
  "jass/invoices/LOAD_INVOICES_TOTALS_SUCCESS"
const LOAD_INVOICES_TOTALS_FAIL = "jass/invoices/LOAD_INVOICES_TOTALS_FAIL"

const loading = createReducer(false, {
  [LOAD_INVOICES_START]: () => true,
  [LOAD_INVOICES_SUCCESS]: () => false,
  [LOAD_INVOICES_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_INVOICES_SUCCESS]: (state, action) => {
    if (action.params.page > 1) {
      return [...state, ...action.result]
    }

    return action.result.items
  },
  [UPDATE_INVOICE_SUCCESS]: (state, action) => {
    return state.map((invoice) =>
      invoice.id !== action.result.id ? invoice : action.result
    )
  }
})

const totals = createReducer(
  {},
  {
    [LOAD_INVOICES_TOTALS_SUCCESS]: (state, action) => action.result
  }
)

const invoice = createReducer(
  {},
  {
    [LOAD_INVOICE_SUCCESS]: (state, action) => action.result,
    [SET_INVOICE]: (state, action) => {
      return action.payload
    }
  }
)

const count = createReducer(0, {
  [LOAD_INVOICES_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state
})

const params = createReducer(
  {},
  {
    [LOAD_INVOICES_SUCCESS]: (_, action) => action.params,
    [SET_INVOICES_PARAMS]: (state, action) => merge({}, state, action.params) // supports deep merging for additional_search params
  }
)

export default combineReducers({
  list: combineReducers({ loading, items, params, count, invoice, totals })
})

export const load = (data) => ({
  types: [LOAD_INVOICES_START, LOAD_INVOICES_SUCCESS, LOAD_INVOICES_FAIL],
  promise: (client) => client.getHybrid("reports/invoices", data)
})

export const reload = () => {
  return (dispatch, getState) => {
    const state = getState()
    const params = state.invoices.list.params

    dispatch(load({ params }))
  }
}

export const setParams = (params) => {
  return { type: SET_INVOICES_PARAMS, params }
}

export const setInvoice = (invoice) => {
  return { type: SET_INVOICE, payload: invoice }
}

export const setSearch = (params) => {
  return {
    type: SET_INVOICES_PARAMS,
    params: { additional_search: params }
  }
}

export const update = (id, data) => ({
  types: [UPDATE_INVOICE_START, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_FAIL],
  promise: (client) => client.put(`/reports/invoices/${id}`, { data })
})

export const loadInvoice = (invoiceId) => {
  return {
    types: [LOAD_INVOICE_START, LOAD_INVOICE_SUCCESS, LOAD_INVOICE_FAIL],
    promise: (client) => client.get(`reports/invoices/${invoiceId}.json`)
  }
}

export const loadTotals = (data) => {
  return {
    types: [
      LOAD_INVOICES_TOTALS_START,
      LOAD_INVOICES_TOTALS_SUCCESS,
      LOAD_INVOICES_TOTALS_FAIL
    ],
    promise: (client) => client.get("reports/invoices/totals.json", data)
  }
}

export const reloadTotals = () => {
  return (dispatch, getState) => {
    const state = getState()
    const params = state.invoices.list.params

    dispatch(loadTotals({ params }))
  }
}
