import { TextField } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import NumberFormat from "components/TouchDesign/Components/PriceInput"

export const PriceInputCell = ({ name, initialValue, onChangeAsync }) => {
  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setValue(initialValue)
      setError(false)
    }

    return () => {
      mounted = false
    }
  }, [initialValue])

  const handleUpdate = () => {
    if (initialValue !== value) {
      setLoading(true)
      setError(false)
      onChangeAsync(value)
        .catch(() => {
          setError(true)
          setValue(initialValue)
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <TextField
      name={name}
      inputRef={ref}
      disabled={loading}
      error={error}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onInput={() => setError(false)}
      onKeyUp={(e) => {
        if (e.key === "Enter" && ref.current?.blur) {
          ref.current.blur()
        }
      }}
      onBlur={handleUpdate}
      InputProps={{
        inputComponent: NumberFormat,
        inputProps: {
          sx: { textAlign: "right" },
          allowEmptyFormatting: false,
          suffix: " €"
        }
      }}
    />
  )
}
