import merge from "lodash/merge"
import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import { updatedCandidate } from "./helpers"
import { updatedCandidateDetails } from "./helpers"
import { updatedCandidateCv } from "./helpers"
import { createGetNormalizedData } from "store/helpers/selectors"

const prefix = "jass/CANDIDATES"

const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`
const SET_PARAMS = `${prefix}/SET_PARAMS`
const REMOVE_START = `${prefix}/REMOVE_START`
const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`
const REMOVE_FAIL = `${prefix}/REMOVE_FAIL`
const DETAILS_SHOW_START = `${prefix}/DETAILS_SHOW_START`
const DETAILS_SHOW_SUCCESS = `${prefix}/DETAILS_SHOW_SUCCESS`
const DETAILS_SHOW_FAIL = `${prefix}/DETAILS_SHOW_FAIL`
const DETAILS_SHOW_CLEAR = `${prefix}/DETAILS_SHOW_CLEAR`
const UPDATE_START = `${prefix}/UPDATE_START`
const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
const UPDATE_FAIL = `${prefix}/UPDATE_FAIL`
const FILE_UPLOAD_START = `${prefix}/FILE_UPLOAD_START`
const FILE_UPLOAD_SUCCESS = `${prefix}/FILE_UPLOAD_SUCCESS`
const FILE_UPLOAD_FAIL = `${prefix}/FILE_UPLOAD_FAIL`

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false,
  [REMOVE_START]: () => true,
  [REMOVE_SUCCESS]: () => false,
  [REMOVE_FAIL]: () => false,
  [DETAILS_SHOW_START]: () => true,
  [DETAILS_SHOW_SUCCESS]: () => false,
  [DETAILS_SHOW_FAIL]: () => false
})

const ids = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return [...state, ...action.result.ids]
    }
    return action.result.ids
  },
  [REMOVE_SUCCESS]: (state, action) =>
    state.filter((id) => id !== action.payload)
})

const initialByIds = {}
const byId = createReducer(initialByIds, {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page !== 1) {
      return { ...state, ...action.result.byId }
    }
    return action.result.byId
  },
  [UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      [action.result.id]: updatedCandidate(state, action)
    }
  }
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) => action.result.count || state
})

const initialParams = { additional_search: {} }
const params = createReducer(initialParams, {
  [LOAD_SUCCESS]: (_state, action) => action.params,
  [SET_PARAMS]: (state, action) => merge({}, state, action.params)
})

const candidateDetails = createReducer(
  {},
  {
    [DETAILS_SHOW_SUCCESS]: (state, action) => action.result || state,
    [DETAILS_SHOW_CLEAR]: () => {
      return {}
    },
    [UPDATE_SUCCESS]: (state, action) => updatedCandidateDetails(state, action),
    [FILE_UPLOAD_SUCCESS]: (state, action) => updatedCandidateCv(state, action)
  }
)

const pdfLoading = createReducer(false, {
  [FILE_UPLOAD_START]: () => true,
  [FILE_UPLOAD_SUCCESS]: () => false,
  [FILE_UPLOAD_FAIL]: () => false
})

export default combineReducers({
  ids,
  byId,
  count,
  params,
  loading,
  candidate: combineReducers({
    details: candidateDetails,
    pdfLoading
  })
})

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.getNormalizedPagedResponse("candidates", options)
})

export const setParams = (params) => ({ type: SET_PARAMS, params })

export const setSearchParams = (params) => {
  return {
    type: SET_PARAMS,
    params: { additional_search: params }
  }
}

export const remove = (id) => ({
  types: [REMOVE_START, REMOVE_SUCCESS, REMOVE_FAIL],
  promise: (client) => client.del(`candidates/${id}.json`),
  payload: id
})

export const details = (id) => ({
  types: [DETAILS_SHOW_START, DETAILS_SHOW_SUCCESS, DETAILS_SHOW_FAIL],
  promise: (client) => client.get(`candidates/${id}.json`)
})

export const clearDetails = () => ({ type: DETAILS_SHOW_CLEAR })

export const update = (id, data) => ({
  types: [UPDATE_START, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.put(`candidates/${id}.json`, { data: data })
})

export const uploadFile = (id, file) => ({
  types: [FILE_UPLOAD_START, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAIL],
  promise: (client) => {
    return client.put(`candidates/${id}.json`, {
      attachments: { file }
    })
  }
})

export const getCandidates = createGetNormalizedData("candidates")
