import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import byId from "./byId"
import ui from "./ui"
import { prepareParams } from "store/modules/entities/importedVacancies/helpers"

const LOAD_START = "jass/imported-vacancies/LOAD_START"
const LOAD_SUCCESS = "jass/imported-vacancies/LOAD_SUCCESS"
const LOAD_FAIL = "jass/imported-vacancies/LOAD_FAIL"
const IMPORT_START = "jass/imported-vacancies/import/START"
const IMPORT_SUCCESS = "jass/imported-vacancies/import/SUCCESS"
const IMPORT_FAIL = "jass/imported-vacancies/import/FAIL"
const UPDATE = "jass/imported-vacancies/UPDATE"
const SET_PARAMS = "jass/imported-vacancies/SET_PARAMS"

const updating = createReducer(false, {
  [IMPORT_START]: () => true,
  [IMPORT_SUCCESS]: () => false,
  [IMPORT_FAIL]: () => false
})

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page > 1) {
      return [...state, ...action.result]
    }

    return action.result.items
  },
  [UPDATE]: (state, action) =>
    state.map((vacancy) =>
      vacancy.id === action.payload.id ? action.payload : vacancy
    )
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state
})

const params = createReducer(
  {},
  {
    [LOAD_SUCCESS]: (state, action) => action.params,
    [SET_PARAMS]: (state, action) => action.params
  }
)

export const load = ({ params, from } = {}) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.getHybrid("imported_vacancies", {
        params: prepareParams(params),
        from: from
      })
  }
}

export const updateVacancy = ({ imported_vacancy }) => ({
  type: UPDATE,
  payload: imported_vacancy
})

export const updateVacanciesList = (data) => {
  return {
    types: [IMPORT_START, IMPORT_SUCCESS, IMPORT_FAIL],
    promise: (client) => client.get("imported_vacancies/update_list", data)
  }
}

export default combineReducers({
  list: combineReducers({
    loading,
    items,
    count,
    params
  }),
  updating,
  byId,
  ui
})
