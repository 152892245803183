import React from "react"
import { I18n } from "react-redux-i18n"
import { TitleSpinner } from "components"
import MaterialUiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import classNames from "classnames"
import { Box } from "@mui/material"

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} [props.className]
 * @param {React.ReactNode} [props.children]
 * @param {React.ReactNode} [props.title]
 * @param {boolean} props.open
 * @param {any} props.onClose
 * @param {import('@mui/material').DialogProps['maxWidth']} props.maxWidth
 * @param {boolean} [props.fullWidth]
 * @param {boolean} [props.loading]
 * @param {boolean} [props.hideTitle]
 * @param {React.ReactNode} [props.center]
 * @param {import('@mui/material').DialogProps['transitionDuration']} [props.transitionDuration]
 */
const Dialog = ({
  id,
  className,
  children = null,
  title = "",
  open = false,
  onClose,
  maxWidth,
  fullWidth = false,
  loading = false,
  hideTitle = false,
  center,
  transitionDuration,
  sx = {}
}) => {
  return (
    <MaterialUiDialog
      id={id}
      scroll="paper"
      maxWidth={maxWidth}
      className={classNames(className, "dialog", { "dialog-opened": open })}
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      transitionDuration={transitionDuration}
      sx={sx}
    >
      {!hideTitle && (
        <DialogTitle sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            {title}
            {loading && <TitleSpinner />}
          </Box>
          {center}
          {onClose ? (
            <Box sx={{ flex: center ? 1 : 0, textAlign: "right" }}>
              <IconButton
                aria-label="Close"
                onClick={onClose}
                title={I18n.t("actions.close")}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : null}
        </DialogTitle>
      )}
      {children}
    </MaterialUiDialog>
  )
}

Dialog.defaultProps = {
  maxWidth: "lg",
  fullWidth: true
}

export default Dialog
