import React from "react"
import { I18n, Translate } from "react-redux-i18n"
import {
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Fab,
  Typography
} from "@mui/material"
import { FieldArray } from "formik"
import { Field } from "formik"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import { Alert } from "@mui/material"
import { PriceInput, TextField } from "shared/ui/berry-jass-formik"
import { createBlankProductPrice } from "../lib"

const FIELD_NAME = "product_prices_attributes"

export const GradedPricingForm = ({
  formik: { setFieldValue, values, errors },
  isUpdating,
  disabled
}) => {
  const data = values[FIELD_NAME].filter((price) => !price._destroy)

  return (
    <Box>
      <Typography variant="h5">
        <Translate value="sales-products-new.graded-pricing.title" />
      </Typography>
      <FieldArray
        name={FIELD_NAME}
        render={(formHelpers) => {
          const handleAddProductPrice = () => {
            return formHelpers.push(createBlankProductPrice())
          }

          return (
            <Box mt={1.5}>
              {typeof errors[FIELD_NAME] === "string" && (
                <Alert severity="error">{errors[FIELD_NAME]}</Alert>
              )}
              {disabled && (
                <Alert severity="info">
                  <Translate value="sales-products-new.messages.price-change-is-active" />
                </Alert>
              )}
              <Table sx={{ tableLayout: "fixed" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="90px">
                      <Translate value="product-prices.from.title" />
                    </TableCell>
                    <TableCell width="90px">
                      <Translate value="product-prices.to.title" />
                    </TableCell>
                    <TableCell width="150px">
                      <Translate value="product-prices.mp.title" />
                    </TableCell>
                    <TableCell width="150px">
                      <Translate value="product-prices.common-price.title" />
                    </TableCell>
                    <TableCell width="150px">
                      <Translate value="product-prices.ll.title" />
                    </TableCell>
                    <TableCell width="60px" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((product_price, index) => {
                    const handleDeleteProductPrice = () => {
                      if (product_price.id) {
                        setFieldValue(`${FIELD_NAME}.${index}._destroy`, true)
                      } else {
                        formHelpers.remove(index)
                      }
                    }

                    return (
                      <TableRow
                        className={`product-price-${index}`}
                        key={product_price.id ?? product_price.virtualId}
                      >
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            disabled={disabled}
                            name={`${FIELD_NAME}.${index}.from`}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            disabled={disabled}
                            name={`${FIELD_NAME}.${index}.to`}
                            component={TextField}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            name={`${FIELD_NAME}.${index}.market_price`}
                            component={PriceInput}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            name={`${FIELD_NAME}.${index}.price`}
                            component={PriceInput}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <Field
                            fullWidth
                            name={`${FIELD_NAME}.${index}.lower_limit`}
                            component={PriceInput}
                          />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <IconButton
                            disabled={disabled || index === 0}
                            title={I18n.t("actions.delete")}
                            onClick={handleDeleteProductPrice}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Box marginTop="24px" display="flex" justifyContent="flex-end">
                <Fab
                  size="small"
                  color="primary"
                  disabled={disabled || isUpdating}
                  onClick={handleAddProductPrice}
                  title={I18n.t("add")}
                >
                  <AddIcon />
                </Fab>
              </Box>
            </Box>
          )
        }}
      />
    </Box>
  )
}
