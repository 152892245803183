import { combineReducers } from "redux"

const SET_CAMPAIGN_OFFER_PARAMS = "jass/campaigns/SET_CAMPAIGN_OFFER_PARAMS"
const campaignOfferParams = (state = null, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_OFFER_PARAMS:
      return {
        slots_subscription_id: action.slotsSubscriptionId,
        customer_id: action.endCustomerId,
        offer_id: action.offerId
      }
    default:
      return state
  }
}

export const setCampaignOfferParams = (
  slotsSubscriptionId,
  endCustomerId,
  offerId
) => {
  return {
    type: SET_CAMPAIGN_OFFER_PARAMS,
    slotsSubscriptionId,
    endCustomerId,
    offerId
  }
}

export default combineReducers({
  campaignOfferParams
})
