import { useMutation, useQueryClient } from "@tanstack/react-query"

import client from "utils/client"

/**
 * @param {File} file
 * @param {'reference' | 'ad'} type
 */
export const createOrderText = async (file, type) => {
  const formData = new FormData()
  formData.append("file_type", type)
  formData.append("file", file)
  const res = await client.post("/web_shop_order_texts.json", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  })

  return res.data.order_text
}

/** @param {File} file */
export const createReferenceOrderText = async (file) => {
  return createOrderText(file, "reference")
}

/** @param {File} file */
export const createAdOrderText = async (file) => {
  return createOrderText(file, "ad")
}

/** @param {File} file */
export const createOrderSalesProductsText = async (file, productId) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("sales_product_id", productId)
  const res = await client.post(
    "/web_shop_order_sales_product_texts.json",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  )

  return res.data.order_sales_product_text
}

/** @param {number} id */
export const copyOrderText = async (id) => {
  const res = await client.get(`/web_shop_order_texts/${id}/copy_file.json`)

  return res.data.order_text
}

/** @param {number} id */
export const deleteOrderText = async (id) => {
  return await client.delete(`/web_shop_order_texts/${id}.json`)
}

/** @param {number} id */
export const deleteOrderSalesProductsText = async (id) => {
  return await client.delete(`/web_shop_order_sales_product_texts/${id}.json`)
}

export const useUpdateCustomDesignAdEditorOrderText = () => {
  const queryClient = useQueryClient()

  return useMutation({
    /**
     * @param {object} params
     * @param {number} params.id
     * @param {number} params.customAdDesignId
     * @param {object} params.values
     */
    mutationFn: async ({ id, customAdDesignId, values }) => {
      const response = await client.put(
        `/custom_design_ad_editor/order_texts/${id}.json`,
        {
          custom_ad_design_id: customAdDesignId,
          order_text: { ad_content_attributes: { values } }
        }
      )
      return response.data
    },
    onSuccess: (_data, { id }) => {
      queryClient.invalidateQueries(["orderFiles"])
      queryClient.invalidateQueries(["offers"])
      queryClient.invalidateQueries(["order_texts", id.toString()])
      queryClient.invalidateQueries([
        "v2",
        "web_shops",
        "orders",
        "files",
        "index"
      ])
      queryClient.invalidateQueries(["v2", "orders", "files", "index"])
    }
  })
}

export const useUpdateOrderAd = () => {
  const queryClient = useQueryClient()

  return useMutation({
    /**
     * @param {object} props
     * @param {number | string} props.orderId
     * @param {number | string} props.orderTextId
     * @param {object} props.values
     */
    mutationFn: async ({ orderId, orderTextId, values }) => {
      const response = await client.put(
        `/orders/${orderId}/order_texts/${orderTextId}.json`,
        { order_id: orderId, ...values }
      )
      return response.data
    },
    onSuccess: (_data, { orderId, orderTextId }) => {
      const roles = [
        "agencies",
        "agency_customers",
        "end_customers",
        "partners"
      ]

      queryClient.invalidateQueries(["orderFiles"])
      queryClient.invalidateQueries(["offers"])
      queryClient.invalidateQueries(["orderTexts", orderTextId])
      roles.forEach((role) =>
        queryClient.invalidateQueries([
          "v2",
          "web_shops",
          "orders",
          "files",
          "index",
          role,
          orderId
        ])
      )
      queryClient.invalidateQueries(["v2", "orders", "files", "index"])
    }
  })
}
