import { getIn } from "formik"
import React from "react"
import { PreviewableFilePicker as JBFilePicker } from "shared/ui/berry-jass"
import { getErrorText } from "./helpers"

const fieldToFilePicker = ({
  disabled,
  field,
  onChange = null,
  form: {
    errors,
    variant,
    touched,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    setFieldError,
    setFieldTouched
  },
  ...props
}) => {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  const {
    onChange: _onChange,
    onBlur: _onBlur,
    multiple: _multiple,
    ...fieldSubselection
  } = field

  return {
    error: showError,
    helperText: showError ? getErrorText(fieldError) : null,
    disabled: disabled ?? isSubmitting,
    variant: variant,
    onUploadStart: () => {
      setFieldTouched(field.name)
      setSubmitting(true)
    },
    onUploadSettled: () => {
      setSubmitting(false)
    },
    onUploadError: (error) => {
      setFieldError(field.name, error.response?.data?.file)
    },
    onChange: function handleChange(_event, value) {
      if (onChange) {
        onChange(_event, value)
      }

      setFieldValue(field.name, value)
      setFieldTouched(field.name)
    },
    ...fieldSubselection,
    ...props
  }
}

export const PreviewableFilePicker = (props) => {
  return <JBFilePicker {...fieldToFilePicker(props)} />
}
