import merge from "lodash/merge"
import keyBy from "lodash/keyBy"
import { combineReducers } from "redux"
import { LOAD_SUCCESS as GENERAL_LOAD_SUCCESS } from "../order"

const LOAD_ORDER_START = "jass/orders/LOAD_ORDER_START"
const LOAD_ORDER_SUCCESS = "jass/orders/LOAD_ORDER_SUCCESS"
const LOAD_ORDER_FAILURE = "jass/orders/LOAD_ORDER_FAILURE"

const LOAD_VERSIONS_START = "jass/orders/files_versions/LOAD_START"
const LOAD_VERSIONS_SUCCESS = "jass/orders/files_versions/LOAD_SUCCESS"
const LOAD_VERSIONS_FAIL = "jass/orders/files_versions/LOAD_FAIL"

const LOAD_ORDER_PRODUCT_ZIPS_START =
  "jass/orders/products_zips/LOAD_PRODUCT_ZIPS_START"
const LOAD_ORDER_PRODUCT_ZIPS_SUCCESS =
  "jass/orders/products_zips/LOAD_PRODUCT_ZIPS_SUCCESS"
const LOAD_ORDER_PRODUCT_ZIPS_FAIL =
  "jass/orders/products_zips/LOAD_PRODUCT_ZIPS_FAIL"

const byId = (state = {}, action) => {
  switch (action.type) {
    case GENERAL_LOAD_SUCCESS: {
      const orders = keyBy(action.result || {}, "id")
      return merge(state, orders)
    }
    case LOAD_ORDER_SUCCESS:
      return { ...state, [action.result.id]: action.result }
    default:
      return state
  }
}

export const loadOrder = (id) => {
  return {
    types: [LOAD_ORDER_START, LOAD_ORDER_SUCCESS, LOAD_ORDER_FAILURE],
    promise: (client) => client.get(`web_shop_orders/${id}`),
    id
  }
}

export const loadProductZips = ({ orderId }) => {
  return {
    types: [
      LOAD_ORDER_PRODUCT_ZIPS_START,
      LOAD_ORDER_PRODUCT_ZIPS_SUCCESS,
      LOAD_ORDER_PRODUCT_ZIPS_FAIL
    ],
    promise: (client) => {
      return client.get(`orders/${orderId}/order_sales_product_texts`)
    }
  }
}

export const loadSourceZipVersions = ({ orderId }) => {
  return {
    types: [LOAD_VERSIONS_START, LOAD_VERSIONS_SUCCESS, LOAD_VERSIONS_FAIL],
    promise: (client) => {
      return client.get(`orders/${orderId}/source_zip_versions`)
    }
  }
}

export default combineReducers({
  byId
})
