import React from "react"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    display: "inline-block"
  },
  fullWidth: {
    width: "100%"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
})

/**
 *
 * @param {import('@mui/material').ButtonProps & {loading: boolean}} props
 * @returns
 */
const LoadingButton = ({
  loading,
  fullWidth = false,
  disabled = false,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.wrapper, "loading-button-wrapper", {
        [classes.fullWidth]: !!fullWidth
      })}
    >
      <Button disabled={loading || disabled} {...rest} />
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  )
}

export default LoadingButton
