import numeral from "numeral"
import isNil from "lodash/isNil"

export const formatPrice = (value, placeholder = "") =>
  !isNil(value) ? numeral(value).format("0,0.00 $") : placeholder

export const formatPriceWithoutCurrency = (value, placeholder = "") =>
  !isNil(value) ? numeral(value).format("0,0.00") : placeholder

export const parsePrice = (value) => numeral(value).value()

export const unformatPrice = (value) => numeral().unformat(value)
