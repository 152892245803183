import compact from "lodash/compact"
import find from "lodash/find"
import union from "lodash/union"

const authCookieName = "auth_token"
const maxAge = 3600000 * 24

export const saveAuthCookie = (req, value) => {
  const date = new Date(Date.now() + maxAge)
  document.cookie = `${authCookieName}=${value}; path=/; expires=${date.toUTCString()}`
}

export const deleteAuthCookie = (req, key) => {
  const date = new Date(Date.now() - 1)
  document.cookie = `${
    key || authCookieName
  }=; path=/; expires=${date.toUTCString()}`
}

export const readAuthCookie = (req) => {
  if (__TEST__) {
    return ""
  }
  const cookie = document.cookie

  if (req && req.session && req.session.token) {
    return req.session.token
  }

  if (cookie) {
    const matches = cookie.match(
      new RegExp(`(?:^|; )${authCookieName}=([^;]*)`)
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  return ""
}

export const mergeEntityAttributes = (oldArray, newArray) => {
  return compact(
    union(
      oldArray.map(
        (oldArray) =>
          find(newArray, { id: oldArray.id }) || {
            ...oldArray,
            _destroy: true
          }
      ),
      newArray
    )
  )
}

export const defaultLanguage = () => {
  const browserLang = navigator.language ?? navigator.userLanguage
  if (browserLang.includes("de")) {
    return "de"
  }
  return "en"
}
