import React, { useMemo } from "react"
import { Stack, Typography } from "@mui/material"
import { parsePrice } from "utils/parsers/prices"

const PriceCellComponent = ({ rrp, discountPrice }) => {
  const parsedRRP = useMemo(() => parsePrice(rrp), [rrp])
  const parsedDiscountPrice = useMemo(
    () => parsePrice(discountPrice),
    [discountPrice]
  )

  return (
    <Stack alignItems="flex-end">
      {parsedRRP > parsedDiscountPrice && (
        <Typography
          variant="subtitle2"
          color="error"
          sx={{
            textDecoration: "line-through"
          }}
        >
          {rrp}&nbsp;€
        </Typography>
      )}
      <Typography data-testid="discount-price" variant="h4" fontWeight={500}>
        {discountPrice}&nbsp;€
      </Typography>
    </Stack>
  )
}

export const PriceCell = React.memo(PriceCellComponent)
