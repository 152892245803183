const initialState = {
  opened: false,
  props: {}
}

const OPEN_CONFIRMATION_DIALOG = "jass/components/confirmation-dialog/OPEN"
const CLOSE_CONFIRMATION_DIALOG = "jass/components/confirmation-dialog/CLOSE"

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONFIRMATION_DIALOG:
      return { props: action.payload, opened: true }
    case CLOSE_CONFIRMATION_DIALOG:
      return initialState
    default:
      return state
  }
}

export const close = () => {
  return {
    type: CLOSE_CONFIRMATION_DIALOG
  }
}

export const open = (props) => {
  return {
    type: OPEN_CONFIRMATION_DIALOG,
    payload: props
  }
}
