const LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_START =
  "jass/campaigns/LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_START"
const LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS =
  "jass/campaigns/LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS"
const LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_FAILURE =
  "jass/campaigns/LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_FAILURE"

export const loadOffer = (
  slotsSubscriptionId,
  endCustomerId,
  offerId,
  customerType
) => {
  return {
    types: [
      LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_START,
      LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS,
      LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_FAILURE
    ],
    promise: (client) =>
      client.get(
        `campaigns/${customerType}/${endCustomerId}/slots_subscriptions/${slotsSubscriptionId}/offers/${offerId}`
      )
  }
}

const LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_START =
  "jass/campaigns/LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_START"
const LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS =
  "jass/campaigns/LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS"
const LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_FAILURE =
  "jass/campaigns/LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_FAILURE"

export const loadPartnerCustomerOffer = (
  slotsSubscriptionId,
  partnerCustomerId,
  offerId
) => {
  return {
    types: [
      LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_START,
      LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS,
      LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_FAILURE
    ],
    promise: (client) =>
      client.get(
        `campaigns/partner_customers/${partnerCustomerId}/slots_subscriptions/${slotsSubscriptionId}/offers/${offerId}`
      )
  }
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case LOAD_END_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS:
      return { ...state, [action.result.id]: action.result }
    case LOAD_PARTNER_CUSTOMER_CAMPAIGNS_OFFER_SUCCESS:
      return { ...state, [action.result.id]: action.result }
    default:
      return state
  }
}

export default byId
