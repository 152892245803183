import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query"
import { getNextPageParam } from "shared/lib/ransack"
import client from "utils/client"

export const useSuppliers = (params) => {
  return useInfiniteQuery({
    queryKey: ["suppliers", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/suppliers.json", {
        params: { ...params, page: pageParam }
      })
      return res.data
    },
    getNextPageParam
  })
}

export const useSupplier = (id) => {
  return useQuery(
    ["suppliers", id],
    async () => {
      const res = await client(`/suppliers/${id}.json`)
      return res.data
    },
    {
      enabled: !!id
    }
  )
}

/**
 * @return {import("@tanstack/react-query").UseMutationResult}
 */
export const useCreateSupplier = () => {
  const queryClient = useQueryClient()

  return useMutation(
    /**
     * @param {{values: import('yup').InferType<typeof import('../lib').supplierSchema>}}
     */
    async ({ values }) => {
      const response = await client.post("/suppliers.json", {
        supplier: values
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["suppliers"])
      }
    }
  )
}

/**
 * @return {import("@tanstack/react-query").UseMutationResult}
 */
export const useUpdateSupplier = () => {
  const queryClient = useQueryClient()

  return useMutation(
    /**
     * @param {{id: number, values: import('yup').InferType<typeof import('../lib').supplierSchema>}}
     */
    async ({ id, values }) => {
      const response = await client.put(`/suppliers/${id}.json`, {
        supplier: values
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["suppliers"])
      }
    }
  )
}

/**
 * @return {import("@tanstack/react-query").UseMutationResult<any, unknown, number, unknown>}
 */
export const useDeleteSupplier = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (id) => {
      const response = await client.delete(`/suppliers/${id}.json`)
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["suppliers"])
      }
    }
  )
}
