import React, { Component } from "react"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { I18n } from "react-redux-i18n"
import withStyles from "@mui/styles/withStyles"
import get from "lodash/get"
import noop from "lodash/noop"
import keys from "lodash/keys"
import size from "lodash/size"
import isString from "lodash/isString"
import head from "lodash/head"

const styles = (theme) => ({
  root: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    marginBottom: "10px"
  },
  focused: {
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 2
  }
})

const toolbarDefaultOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link"
  ],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true }
}

const defaultErrorStyle = {
  position: "absolute",
  bottom: -15,
  fontSize: 12,
  lineHeight: "12px"
}

const createEditorState = (value) => {
  const contentBlock = htmlToDraft(value || "")
  const { contentBlocks, entityMap } = contentBlock
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  )

  return EditorState.createWithContent(contentState)
}

const editorStateToHtml = (editorState) =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()))

class Wysiwyg extends Component {
  constructor(props) {
    super(props)
    this.toolbarOptions = Object.assign(
      {},
      toolbarDefaultOptions,
      props.toolbarOptions
    )
    this.onFocusHandler = this.onFocusHandler.bind(this)
    this.onBlurHandler = this.onBlurHandler.bind(this)

    this.state = {
      charsLimit: get(props, "charsLimit", 0),
      isFocused: false,
      touched: get(props, "touched", false),
      editorState: createEditorState(this.props.value)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      // eslint-disable-next-line
      this.setState({ editorState: createEditorState(this.props.value) })
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState })
  }

  handleBeforeInput = () => {
    const { charsLimit, editorState } = this.state
    if (charsLimit === 0) {
      return
    }

    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText("").length
    if (currentContentLength >= charsLimit) {
      // eslint-disable-next-line
      return "handled"
    }
  }

  handlePastedText = (pastedText) => {
    const { charsLimit, editorState } = this.state
    if (charsLimit === 0) {
      return
    }

    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText("").length
    if (currentContentLength + pastedText.length >= charsLimit) {
      // eslint-disable-next-line
      return "handled"
    }
  }

  onFocusHandler() {
    const { onFocus = noop } = this.props
    this.setState({
      isFocused: true,
      touched: true
    })
    onFocus()
  }

  onBlurHandler() {
    const { onBlur = noop, onChange } = this.props
    const { editorState } = this.state
    this.setState({
      isFocused: false
    })
    onChange(editorStateToHtml(editorState))
    onBlur()
  }

  render() {
    const { editorState, touched, isFocused = false, contentState } = this.state
    const {
      placeholder,
      disabled,
      label,
      // underlineDisabledStyle,
      className,
      error = {},
      errorLabels = {},
      dirty = false,
      useVisitedInsteadOfTouched,
      visited,
      errorStyle,
      toolbarOnFocus,
      toolbarHiddenByDefault = false,
      value,
      classes,
      style
    } = this.props
    const errors = keys(error)
    const touchedOrVisited = useVisitedInsteadOfTouched ? visited : touched
    /* eslint-disable no-nested-ternary */
    const errorKey =
      (dirty || touchedOrVisited) && size(errors) && !isFocused
        ? isString(error)
          ? error
          : head(errors)
        : ""
    /* eslint-enable no-nested-ternary */
    const errorText =
      !errorKey ||
      (errorKey === "required" && size(errors) === 1 && $(value).text().trim())
        ? ""
        : get(errorLabels, errorKey, I18n.t(`errors.${errorKey}`))
    const preparedErrorStyle = Object.assign({}, defaultErrorStyle, errorStyle)

    return (
      <div
        className={`${classes.root} ${
          isFocused ? classes.focused : ""
        } ${className} ${isFocused ? "focused" : ""} fieldContainer multiLine`}
      >
        <label className="wysiwyg-label">{label}</label>
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={this.toolbarOptions}
          placeholder={placeholder}
          readOnly={disabled}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
          contentState={contentState}
          toolbarOnFocus={toolbarOnFocus}
          toolbarHidden={toolbarHiddenByDefault ? !isFocused : false}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          editorStyle={style}
        />
        <div style={preparedErrorStyle}>{errorText}</div>
      </div>
    )
  }
}

const StyledWysiwyg = withStyles(styles)(Wysiwyg)

export default StyledWysiwyg
