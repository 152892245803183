export default {
  LOG_APP_START: {
    app: "start"
  },
  LOG_API_REQ: {
    api: "request"
  },
  LOG_API_REQ_TIMEOUT: {
    api: "timeout"
  },
  LOG_CLIENT_REQ: {
    client: "request"
  }
}

export const urls = {
  agencies: "agencies/filter_agency_autocomplete.json",
  bookOutReasons: {
    choice: "book_out_reasons/choice.json"
  },
  pricesAutoComplete: "sales_products/filter_autocomplete.json",
  packageProductsChoice: "sales_products/choice.json",
  customersForAgencyManager: "jap_customers/paged.json",
  partnerCustomersAutoComplete: "partner_customers/autocomplete.json",
  partnerShopCustomersAutoComplete: "partner_customers.json",
  partnerCustomerParents: "partner_customers/parents.json",
  partnersAutoComplete: "users/partners.json",
  allPartnersAutoComplete: "autocomplete/partners.json",
  agencyCustomersAutoComplete: "agency_customers/autocomplete.json",
  uploadOrderAd: "web_shop_order_texts",
  uploadOrderSalesProductAd: "web_shop_order_sales_product_texts.json",
  order: {
    states: "web_shop_orders/orders_states.json",
    types: "orders/used_types.json",
    customerCompanyNames: "orders/customer_company_names.json",
    customers: "orders/customers.json",
    managers: "orders/managers.json",
    products: "orders/sales_products.json",
    contract_types: "web_shop_orders/contract_types.json",
    job_types: "web_shop_orders/job_types.json",
    field_of_work_types: "web_shop_orders/field_of_work_types.json",
    seniority_types: "web_shop_orders/seniority_types.json"
  },
  offer: {
    types: "offers/used_types.json",
    managers: "offers/managers_filter_autocomplete.json",
    customerCompanyNames: "offers/customer_company_filter_autocomplete.json",
    customers: "offers/customers_filter_autocomplete.json",
    products: "offers/sales_products_filter_autocomplete.json"
  },
  trackOrders: {
    offerNumbers: "track_orders_new/offer_numbers.json",
    orderNumbers: "track_orders_new/order_numbers.json",
    invoiceNumbers: "track_orders_new/invoice_numbers.json",
    types: "track_orders_new/used_types.json",
    orderOrigins: "track_orders_new/order_origins.json",
    customerOrderStates: "track_orders_new/customer_order_states.json",
    productionOrderStates: "track_orders_new/production_order_states.json",
    salesAgents: "track_orders_new/sales_agents.json",
    customerCompanyNames: "track_orders_new/customer_company_names.json",
    subCustomerCompanyNames: "track_orders_new/sub_customer_company_names.json",
    salesProducts: "track_orders_new/sales_products.json",
    partners: "track_orders_new/partners.json",
    agencies: "track_orders_new/agencies.json"
  },
  affiliateProducts: {
    suppliersAutocomplete: "suppliers/autocomplete.json"
  },
  datev_filter_customer_autocomplete: "datev_export/customers",
  endCustomersAutoComplete: "end_customers/filter_autocomplete.json",
  incompleteOffers: {
    states: "incomplete_agency_offers/states_autocomplete.json"
  },
  endCustomerParents: "end_customers/choice.json",
  agencyShopCustomersAutoComplete: "agency_customers.json",
  agencyShopCustomersAutoCompleteLegacy:
    "agency_customers/web_shop_autocomplete.json",
  agencyShopIncompleteOffersAutoComplete:
    "incomplete_agency_offers/autocomplete.json",
  packagePricesAutoComplete:
    "affiliate_product_packages/filter_autocomplete.json",
  packageProductsByIds: "sales_products/paged",
  supplierGroupsAutoComplete: "supplier_groups/choice.json",
  usersAutoComplete: "users/choice.json",
  advisors: "users/choice.json",
  keyAccounts: "key_accounts",
  keyAccountsAutoComplete: "key_accounts/autocomplete.json",
  keyAccountsChoice: "key_accounts/choice.json",
  hrPortals: "/hr_portals/choice.json",
  endCustomerImportedVacanciesAutocomplete:
    "imported_vacancies/autocomplete.json",
  candidate: {
    vacanciesAutocomplete: "candidate_form/vacancies.json"
  }
}

export const MAX_FILE_SIZE = 52428800
export const MIN_FILE_SIZE = 1
