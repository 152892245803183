import React, { Component } from "react"
import get from "lodash/get"
import { connect } from "react-redux"
import Dropzone from "react-dropzone"
import {
  upload,
  remove,
  init as initStore,
  destroy as destroyStore,
  getFiledState
} from "store/modules/components/filepicker"
import Button from "./Button"
import TouchButton from "components/TouchDesign/Components/Button"
import Chip from "./Chip"
import Box from "@mui/material/Box"
import Fab from "@mui/material/Fab"
import MUIIconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import LoadingFab from "components/Spinner/LoadingFab"
import LoadingButton from "components/Buttons/LoadingButton"
import classNames from "classnames"
import uniqueId from "lodash/uniqueId"
import map from "lodash/map"
import union from "lodash/union"
import noop from "lodash/noop"
import filter from "lodash/filter"
import camelCase from "lodash/camelCase"
import set from "lodash/set"
import toPlainObject from "lodash/toPlainObject"
import omit from "lodash/omit"
import pick from "lodash/pick"
import size from "lodash/size"

const IconButton = ({ loading: _, ...props }) => <MUIIconButton {...props} />

const defaultExtractFileId = (file) => get(file, "id", uniqueId())
const defaultExtractFileName = (file) =>
  get(file, "file_name") ||
  get(file, "image.name") ||
  get(file, "file.file_name")

const getComponent = (variant) => {
  switch (variant) {
    case "loading-fab":
      return LoadingFab
    case "loading-button":
      return LoadingButton
    case "fab":
      return Fab
    case "icon":
      return IconButton
    case "touch-button":
      return TouchButton
    default:
      return Button
  }
}

class FilePicker extends Component {
  constructor(props) {
    super(props)

    this.id = get(props, "id", uniqueId("autocomplete_"))
    this.state = {
      progress: {},
      uploading: false
    }
  }

  componentDidMount() {
    this.props.initStore({ id: this.id })
  }

  componentWillUnmount() {
    const { id, destroyStore } = this.props
    if (!id) destroyStore(this.id)
  }

  onDropHandler = (files) => {
    const {
      onChange = () => {},
      onUploadStart,
      onUpload,
      upload,
      api: {
        upload: {
          url,
          params,
          method = "post",
          prepareAttachments = (file) => ({ file }),
          extractValue = (x) => x
        } = {}
      } = {},
      multiple = true
    } = this.props

    this.setState({ uploading: true })
    onUploadStart?.()
    return Promise.all(
      map(files, (file) =>
        upload({
          id: this.id,
          url,
          attachments: prepareAttachments(file),
          params,
          bulkUpload: false,
          method,
          onClientStatus: (...args) => this.onClientStatusHandler(file, ...args)
        }).then((file) => {
          const { value, onUploadError } = this.props

          this.setState({ uploading: false })
          if (file instanceof Error) {
            if (onUploadError && typeof onUploadError === "function") {
              const json = JSON.parse(file.message ?? "{}")
              const error = get(json, "[0].error") ?? json.errors
              onUploadError(error)
            }
            return
          }

          const uploaded = extractValue(
            multiple ? union(value || [], [file]) : file
          )

          onChange(uploaded)
          onUpload?.(uploaded)
        })
      )
    )
  }

  onDropRejectedHandler = (files) => {
    const { onReject = noop } = this.props
    onReject(files)
  }

  clickFileHandler = (file) => {
    const { onFileClick = noop } = this.props
    onFileClick(file)
  }

  removeFileHandler = (file) => {
    const {
      onFileRemove,
      remove,
      onChange = noop,
      api,
      multiple = true,
      value,
      api: { extractFileId = defaultExtractFileId } = {}
    } = this.props

    onFileRemove && onFileRemove(file)
    onChange(
      multiple
        ? filter(value, (value) => extractFileId(value) !== extractFileId(file))
        : null
    )
    const url = api?.remove?.url ?? api?.upload?.url
    const options = { ...api?.remove, file }

    return remove(url, options)
  }

  onClientStatusHandler = (file, request, e) => {
    const { progress } = this.state
    const percent = e.percent
    const index = `[${camelCase(file.name)}_${file.lastModified}]`

    if (percent < 100) {
      set(progress, index, {
        name: file.name,
        percent,
        request
      })
      this.setState({ progress: toPlainObject(progress) })
    } else {
      this.setState({ progress: omit(progress, index) })
    }
  }

  cancelFileUploadHandler = (file) => {
    file.request.abort()
  }

  render() {
    const {
      name = "",

      id,
      className = "",
      multiple = true,
      disableClick = false,
      disablePreview = false,
      activeClassName = "active",
      rejectClassName = "reject",
      accept = {},
      minSize = 0,
      maxSize = Infinity,
      api: {
        extractFileId = defaultExtractFileId,
        extractFileName = defaultExtractFileName
      } = {},
      rootStyle,
      nosm,
      noChipsContainer,
      value,
      classes = {},
      chipClasses = null,
      chipAvatar = undefined,
      deleteIcon = null,
      variant = "button",
      hideChips,
      hideButton = false,
      buttonProps = {},
      buttonClassName = "",
      fullWidth = false,
      disableRemoval = false,
      helperText
    } = this.props
    const style = {
      display: "inline-block",
      borderStyle: "none",
      width: fullWidth ? "100%" : "auto"
    }
    const { progress, uploading } = this.state
    const activeStyle = { ...style, ...get(this.props, "activeStyle", {}) }
    const rejectStyle = { ...style, ...get(this.props, "rejectStyle", {}) }
    const buttonPropValues = {
      ...buttonProps,
      ...pick(this.props, ["label", "progress", "classes", "fullWidth"]),
      loading: uploading
    }
    const containerStyle = rootStyle ? rootStyle : {}

    if (multiple && (size(value) > 1 || size(progress) > 1)) {
      containerStyle.height = "auto"
    }
    const progressBarStyle = { width: `${progress}%` }

    if (!progress) {
      progressBarStyle.height = 0
    }

    const renderFileChip = (file) => {
      const handleDelete = disableRemoval
        ? null
        : () => this.removeFileHandler(file)
      return (
        <Chip
          avatar={chipAvatar}
          classes={chipClasses}
          deleteIcon={deleteIcon}
          className={classNames(classes.chip, "attached-file-block")}
          key={`filepicker_${this.id}_${extractFileId(file)}`}
          onClick={() => this.clickFileHandler(file)}
          onDelete={handleDelete}
          label={extractFileName(file)}
          multiple={multiple}
        />
      )
    }

    const renderProgressChip = (file) => (
      <Chip
        className="is-loading"
        key={`filepicker_${this.id}_${file.name}`}
        deleteIcon={deleteIcon}
        tooltip={file.name}
        progress={file.percent}
        onRequestDelete={() => this.cancelFileUploadHandler(file)}
        multiple={multiple}
      />
    )

    const Component = getComponent(variant)

    return (
      <div id={id} className={`${className} filepicker`} style={containerStyle}>
        {!(hideButton && value) && (
          <div
            style={{ paddingLeft: 0 }}
            className={`${
              nosm || multiple ? "" : "col-sm-6"
            } ${buttonClassName}`}
          >
            <Dropzone
              {...{
                style,
                activeStyle,
                rejectStyle,
                activeClassName,
                rejectClassName,
                disablePreview,
                disableClick,
                multiple,
                minSize,
                maxSize,
                accept,
                name
              }}
              onDrop={this.onDropHandler}
              onDropRejected={this.onDropRejectedHandler}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <div {...getRootProps()}>
                    <Component color="secondary" {...buttonPropValues}>
                      {buttonPropValues.label}
                    </Component>
                    {helperText && (
                      <Typography
                        variant="subtitle2"
                        color="error"
                        className="upload-error-text"
                      >
                        {helperText}
                      </Typography>
                    )}
                    <div className="progressBar" style={progressBarStyle} />

                    <Box display="none">
                      <label htmlFor={id ?? `${id}-input`}>
                        {this.props.label}
                      </label>
                      <input
                        {...getInputProps({ id: id ?? `${id}-input`, name })}
                      />
                    </Box>
                  </div>
                )
              }}
            </Dropzone>
          </div>
        )}

        {!hideChips && value && (
          <div
            style={nosm || multiple ? null : { textAlign: "right" }}
            className={classNames({
              "chips-container": !noChipsContainer && (nosm || multiple),
              "col-sm-6": !nosm && !multiple
            })}
          >
            {!!size(value) && map(multiple ? value : [value], renderFileChip)}
            {map(progress, renderProgressChip)}
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  (state, { id }) => ({
    store: getFiledState(state, id)
  }),
  { upload, remove, initStore, destroyStore }
)(FilePicker)
