import { combineReducers } from "redux"
import { default as autocomplete } from "./autocomplete"
import { default as filepicker } from "./filepicker"
import { default as dialog } from "./dialog"
import { default as notifications } from "./notifications"
import confirmDialog from "./confirmDialog"

export default combineReducers({
  autocomplete,
  filepicker,
  dialog,
  notifications,
  confirmDialog
})
