import React, { Component } from "react"
import sumBy from "lodash/sumBy"
import compact from "lodash/compact"
import Translate from "components/Translate/Translate"
import { strToBoolean } from "utils/parsers/boolean"
import MaterialUiCard from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Collapse from "@mui/material/Collapse"
import { TitleSpinner } from "components"
import HeaderButton from "./HeaderButton"
import ActionButton from "./ActionButton"
import IconButton from "@mui/material/IconButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import CardActions from "@mui/material/CardActions"
import classNames from "classnames"
import withStyles from "@mui/styles/withStyles"
import { Box } from "@mui/material"
import isString from "lodash/isString"
import size from "lodash/size"
import omit from "lodash/omit"
import map from "lodash/map"
import isArray from "lodash/isArray"
import uniqueId from "lodash/uniqueId"

const filtersContainerStyle = {
  maxHeight: 70
}

const styles = (theme) => ({
  root: {
    marginBottom: 20
  },
  header: {
    borderBottom: "1px solid #DEE1EC",
    padding: 0
  },
  expand: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  collapseWrapper: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden"
  },
  collapseContainer: {
    display: "flex",
    flexGrow: 1
  }
})

const Title = ({ title, loading, cardMessage }) => (
  <div className="title">
    {title && isString(title) ? (
      <h5>
        <Translate value={title} className="text" />

        {loading && <TitleSpinner />}
      </h5>
    ) : (
      title
    )}

    {cardMessage && isString(cardMessage) && (
      <span className="card-message">{cardMessage}</span>
    )}
  </div>
)

class Card extends Component {
  state = {
    collapsed: false
  }

  componentDidMount() {
    this.height = this.containerEl?.offsetHeight
  }

  height = 0

  toggleCollapsed = () => {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }))
  }

  toggleExpanded = (filterId) => {
    if (this.props.onToggleExpand) {
      this.props.onToggleExpand(isString(filterId) ? filterId : this.props.id)
    }
  }

  render() {
    const {
      cardMessage,
      title,
      loading,
      children,
      actionButtons = [],
      className = "",
      filtersStyle = {},
      topActionButtons,
      panels,
      hideFilterBtn,
      headerFilter,
      multilineFilters,
      classes,
      onToggleExpand,
      fixed,
      fullHeight,
      noContentClass = false,
      sx,
      ...rest
    } = this.props
    const expanded = strToBoolean(this.props.expanded, true)
    const filters = compact(this.props.filters)
    const topActionButtonList = compact(topActionButtons)
    const visibleFiltersBlocks = filters.filter(
      (filter) => panels?.[filter.id] ?? true
    )
    const isFiltersBlockVisible = !!size(visibleFiltersBlocks)

    const filtersLength = sumBy(
      visibleFiltersBlocks,
      (filter) => (filter.block && compact(filter.block).length) || 1
    )

    return (
      <MaterialUiCard
        sx={sx}
        ref={(container) => (this.containerEl = container)}
        className={`card ${className} ${classes.root} ${
          expanded ? "expanded" : "collapsed"
        }`}
        {...omit(rest, ["filters", "expanded", "onToggleExpand"])}
      >
        <CardHeader
          classes={{ root: classes.header }}
          action={
            <CardActions
              style={{ marginTop: 10 }}
              className="show-filters-button"
            >
              <div className="custom-filters-container">{headerFilter}</div>
              {((!hideFilterBtn && !!size(filters)) ||
                !!size(topActionButtonList)) && (
                <React.Fragment>
                  {map(topActionButtonList, (buttonProps, i) =>
                    React.isValidElement(buttonProps) ? (
                      buttonProps
                    ) : (
                      <HeaderButton
                        {...buttonProps}
                        key={`card-action-button-${i}`}
                      />
                    )
                  )}
                  {map(filters, (filter, i) => {
                    const expanded =
                      filter?.expanded ?? panels?.[filter.id] ?? true

                    const expandedClass = expanded ? "expanded" : "collapsed"

                    return (
                      !!filter.block && (
                        <HeaderButton
                          {...filter}
                          onClick={() => this.toggleExpanded(filter.id)}
                          expanded={expanded}
                          key={`card-filter-button-${i}`}
                          className={`${
                            filter?.className ?? ""
                          } filters-${expandedClass}`}
                        />
                      )
                    )
                  })}
                </React.Fragment>
              )}
              <IconButton
                disableRipple
                color="secondary"
                onClick={() => onToggleExpand(this.props.id)}
                classes={{
                  root: classNames("card-expand-button", classes.expand)
                }}
                title="Expand card"
                style={{
                  marginLeft: 0,
                  marginRight: 15
                }}
                size="large"
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </CardActions>
          }
          title={
            <Title title={title} loading={loading} cardMessage={cardMessage} />
          }
        />

        <Collapse
          in={expanded}
          timeout="auto"
          classes={{
            wrapper: classes.collapseWrapper,
            root: classes.collapseContainer
          }}
        >
          <CardContent
            className={classNames({
              "custom-card-content": fullHeight,
              "card-fixed": fixed
            })}
          >
            <Box
              height="100%"
              className={classNames({ content: !noContentClass })}
            >
              {isFiltersBlockVisible && (
                <div
                  className="filtersBlock"
                  style={{ ...filtersContainerStyle, ...filtersStyle }}
                >
                  {map(visibleFiltersBlocks, (filter) => {
                    const fields = isArray(filter.block)
                      ? compact(filter.block)
                      : [filter.block]
                    return map(fields, (field, i) => (
                      <div
                        className={`${
                          isArray(filter.block) ? "" : filter.className
                        } filterInput`}
                        style={{
                          maxWidth: multilineFilters
                            ? "initial"
                            : `${100.0 / filtersLength}%`
                        }}
                        key={`${filter.id}-${i}`}
                      >
                        {field}
                      </div>
                    ))
                  })}
                </div>
              )}

              <div className="card-body" style={{ height: "100%" }}>
                {children}
              </div>
            </Box>

            {map(
              actionButtons,
              (buttonProps) =>
                buttonProps && (
                  <ActionButton
                    {...buttonProps}
                    key={uniqueId("card-action-button-")}
                  />
                )
            )}
          </CardContent>
        </Collapse>
      </MaterialUiCard>
    )
  }
}

Card.defaultProps = {
  fullHeight: true
}

export default withStyles(styles)(Card)
