import React, { useCallback, useRef } from "react"

import classNames from "classnames"

import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"

import MUIAutocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"

const useAutocompleteStyles = makeStyles({
  option: {
    fontSize: 12,
    padding: "8px 16px"
  },
  popupIndicator: {
    display: "none"
  },
  endAdornment: {
    right: "20px !important",
    top: "calc(50% - 12px)"
  }
})

const useInputStyles = makeStyles(
  createStyles((theme) => ({
    root: {
      paddingRight: "30px !important",
      backgroundColor: ({ filled }) => (filled ? "#fbfbfb" : "white"),
      "&.Mui-disabled": {
        backgroundColor: "rgb(249, 249, 249)"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "label + &": {
        marginTop: 25
      }
    },
    input: {
      padding: "11px 8px !important",
      fontSize: 12
    },
    disabled: {},
    notchedOutline: {
      borderColor: "white",
      boxShadow: "0px 0px 5px 1px rgb(225, 225, 225) !important",
      "& legend": { width: "0 !important" }
    }
  }))
)

const useInputLabelStyles = makeStyles({
  asterisk: { color: "red" },
  root: {
    fontWeight: "bold",
    color: "black",
    fontSize: 13,
    transform: "translate(3px, 0) !important"
  }
})

const defaultGetOptionSelected = (a, b) => a?.id === b?.id

const Autocomplete = ({
  label = null,
  required = false,
  placeholder = null,
  getOptionLabel = (option) => option.name,
  getOptionKey = (option) => option?.id,
  error = false,
  helperText = "",
  freeSolo = false,
  inputValueMapper = (x) => x,
  onInputChange = null,
  onBlur = null,
  onChange,
  options,
  additionalOptions,
  id = null,
  InputProps = { endAdornment: null },
  filled = false,
  inputClasses: inputClassesFromProps,
  inputLabelClasses: inputLabelClassesFromProps,
  textFieldClasses: textFieldClassesFromProps,
  autocompleteClasses: autocompleteClassesFromProps,
  className,
  multiple,
  isOptionEqualToValue = defaultGetOptionSelected,
  ...rest
}) => {
  const inputValue = useRef(null)

  const autocompleteClasses = useAutocompleteStyles()
  const inputLabelClasses = useInputLabelStyles()
  const inputClasses = useInputStyles({ filled })
  const extendedOptions =
    !!additionalOptions && !!options
      ? [...additionalOptions, ...options]
      : options

  const handleInputValueChange = useCallback(
    (e, value, reason) => {
      onInputChange && onInputChange(e, value, reason)
      if (freeSolo) {
        inputValue.current = value
      }
    },
    [freeSolo, onInputChange]
  )

  const handleBlur = useCallback(
    (e) => {
      onBlur && onBlur(e, inputValue.current)
      if (freeSolo && inputValue.current) {
        onChange(inputValueMapper(inputValue.current))
      }
    },
    [freeSolo, inputValue, inputValueMapper, onBlur, onChange]
  )

  const handleOnChange = useCallback(
    (_, value, action) => {
      if (action === "createOption") {
        onChange(inputValueMapper(value), action)
      } else {
        onChange(value, action)

        if (freeSolo) {
          inputValue.current = null
        }
      }
    },
    [freeSolo, inputValueMapper, onChange]
  )

  return (
    <MUIAutocomplete
      renderOption={(props, option, _state) => (
        // workaround for issue: https://github.com/mui/material-ui/issues/26492
        <li
          {...props}
          key={
            getOptionKey(option) ??
            // @ts-ignore
            props.key
          }
        >
          {getOptionLabel(option)}
        </li>
      )}
      {...rest}
      id={id || rest.name}
      freeSolo={freeSolo}
      blurOnSelect
      includeInputInList
      classes={autocompleteClassesFromProps || autocompleteClasses}
      options={extendedOptions}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleOnChange}
      onInputChange={handleInputValueChange}
      onBlur={handleBlur}
      className={classNames("jass-autocomplete", className)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            classes={textFieldClassesFromProps}
            label={label}
            variant="outlined"
            fullWidth
            required={required}
            error={!!error}
            helperText={helperText}
            InputLabelProps={{
              shrink: true,
              classes: inputLabelClassesFromProps || inputLabelClasses
            }}
            inputProps={{
              ...params.inputProps,
              form: {
                autocomplete: "off"
              } // custom value to disable autofill/autocomplete in chrome
            }}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              placeholder: placeholder,
              classes: inputClassesFromProps || inputClasses,
              endAdornment: (
                <InputAdornment position="end">
                  {params.InputProps?.endAdornment}
                  {InputProps.endAdornment}
                </InputAdornment>
              )
            }}
          />
        )
      }}
    />
  )
}

export default Autocomplete
