import React, { useCallback, useState, useMemo, useContext } from "react"

const WizardContext = React.createContext({
  next: () => {},
  back: () => {}
})

export const WizardProvider = ({ initialState, render }) => {
  const [{ stepsCount, activeStep }, setWizardState] = useState(initialState)
  const [invalidSteps, setInvalidSteps] = useState([])
  const [lastVisitedStep, setLastVisitedStep] = useState(0)

  const handleNext = useCallback(() => {
    if (stepsCount > activeStep + 1) {
      setWizardState((oldState) => ({
        ...oldState,
        activeStep: activeStep + 1
      }))
      setLastVisitedStep(activeStep + 1)
      return
    }
  }, [activeStep, setWizardState, stepsCount])

  const handleBack = useCallback(() => {
    if (activeStep > 0) {
      setWizardState((oldState) => ({
        ...oldState,
        activeStep: activeStep - 1
      }))
    }
  }, [activeStep, setWizardState])

  const handleJump = useCallback(
    (step) => {
      if (step > lastVisitedStep) {
        return
      }
      setWizardState((oldState) => ({ ...oldState, activeStep: step }))
    },
    [lastVisitedStep]
  )

  const renderProps = useMemo(() => {
    return {
      activeStep,
      stepsCount,
      handleNext,
      handleBack,
      invalidSteps,
      setInvalidSteps,
      lastVisitedStep,
      handleJump
    }
  }, [
    activeStep,
    stepsCount,
    handleNext,
    handleBack,
    invalidSteps,
    lastVisitedStep,
    handleJump
  ])

  const providerValues = useMemo(() => {
    return {
      next: handleNext,
      back: handleBack
    }
  }, [handleNext, handleBack])

  return (
    <WizardContext.Provider value={providerValues}>
      {render(renderProps)}
    </WizardContext.Provider>
  )
}

export const useWizardActions = () => {
  return useContext(WizardContext)
}
