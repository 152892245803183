import React from "react"
import moment from "moment"
import { InputAdornment } from "@mui/material"
import { formatPriceWithoutCurrency } from "utils/parsers/prices"

const getInputAdornmentProps = (currentValue) => {
  return {
    InputProps: {
      endAdornment: (
        <InputAdornment
          position="end"
          sx={{
            height: "100%",
            paddingLeft: "12px",
            borderLeft: "1px solid #DFDFDF"
          }}
        >
          {currentValue}
        </InputAdornment>
      )
    }
  }
}

export const getDateInputAdornmentProps = (currentValue) => {
  return getInputAdornmentProps(moment(currentValue).format("DD.MM.YYYY"))
}

export const getPriceInputAdornmentProps = (currentValue) => {
  return getInputAdornmentProps(formatPriceWithoutCurrency(currentValue))
}
