import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import newCandidates, {
  LOAD_SUCCESS as LOAD_NEW_SUCCESS
} from "store/modules/entities/candidatesBoard/newCandidates"
import interviewed, {
  LOAD_SUCCESS as LOAD_INTERVIEWED_SUCCESS
} from "store/modules/entities/candidatesBoard/interviewed"
import feedbackGiven, {
  LOAD_SUCCESS as LOAD_FEEDBACK_GIVEN_SUCCESS
} from "store/modules/entities/candidatesBoard/feedbackGiven"
import jobOffered, {
  LOAD_SUCCESS as LOAD_JOB_OFFERED_SUCCESS
} from "store/modules/entities/candidatesBoard/jobOffered"
import disqualified, {
  LOAD_SUCCESS as LOAD_DISQUALIFIED_SUCCESS
} from "store/modules/entities/candidatesBoard/disqualified"
import hired, {
  LOAD_SUCCESS as LOAD_HIRED_SUCCESS
} from "store/modules/entities/candidatesBoard/hired"
import { UPDATE } from "store/modules/entities/candidatesBoard/stageTransitions"

const initialByIds = {}
const byId = createReducer(initialByIds, {
  [LOAD_NEW_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [LOAD_INTERVIEWED_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [LOAD_FEEDBACK_GIVEN_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [LOAD_JOB_OFFERED_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [LOAD_DISQUALIFIED_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [LOAD_HIRED_SUCCESS]: (state, action) => {
    return { ...state, ...action.result.byId }
  },
  [UPDATE]: (state, action) => {
    const { candidate } = action.payload
    return {
      ...state,
      [candidate.id.toString()]: { ...candidate, id: candidate.id.toString() }
    }
  }
})

export default combineReducers({
  newCandidates,
  interviewed,
  feedbackGiven,
  jobOffered,
  disqualified,
  hired,
  byId
})
