import React from "react"
import Editor from "react-simple-code-editor"
import classNames from "classnames"
import makeStyles from "@mui/styles/makeStyles"
import { FormHelperText } from "@mui/material"

const useStyles = makeStyles({
  editor: {
    border: "1px solid black",
    fontSize: 16,
    fontFamily: "monospace",
    marginBottom: 10
  },
  error: {
    borderColor: "red"
  }
})

const CodeEditor = ({
  value,
  onChange,
  highlight,
  name,
  padding,
  ...props
}) => {
  const classes = useStyles()

  return (
    <>
      <Editor
        name={name}
        padding={padding}
        className={classNames(classes.editor, {
          [classes.error]: !!props.error
        })}
        value={value}
        onValueChange={onChange}
        highlight={highlight}
      />
      {!!props.error && (
        <FormHelperText error={!!props.error}>
          {props.helperText}
        </FormHelperText>
      )}
    </>
  )
}

export default CodeEditor
