import client from "utils/client"
import { subscribeKey } from "valtio/utils"

import { getDisableFiltersAutosave } from "./router"

const updateUserFilters = (value) => {
  return client.put("/user_settings.json", {
    user_settings: { filters: value }
  })
}

export const autosaveFilter = (store, page, key) => {
  subscribeKey(store, key, async () => {
    if (getDisableFiltersAutosave()) {
      return null
    }

    return updateUserFilters({
      [page]: { [key]: store[key] }
    })
  })
}
