import React, { memo } from "react"
import { I18n } from "react-redux-i18n"
import { useHistory } from "react-router-dom"
import { Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"

export const CreateOrderFab = memo(
  /**
   * @param {object} props
   * @param {string} props.url
   */
  ({ url }) => {
    const history = useHistory()

    return (
      <Fab
        color="primary"
        size="small"
        id="create-order-btn"
        data-testid="create-order-btn"
        onClick={() => history.push(url)}
        title={I18n.t("web-shop.create-order-wizard.title")}
      >
        <AddIcon sx={{ fontSize: 16 }} />
      </Fab>
    )
  }
)
