import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"
import { useHistory } from "react-router-dom"

export const useStatisticsAction = () => {
  const history = useHistory()

  return useCallback(
    (order) => {
      return {
        label: <Translate value="web-shop.job-ads-table.statistics" />,
        onClick: () => {
          history.push(`/r/orders/${order.id}`)
        }
      }
    },
    [history]
  )
}
