import { connect } from "formik"
import { useEffect } from "react"

export const ScrollToError = connect(({ formik }) => {
  const submitting = formik.isSubmitting

  useEffect(() => {
    const el = document.querySelector(".Mui-error")

    return (el?.parentElement ?? el)?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    })
  }, [submitting])

  return null
})
