import * as React from "react"
import MuiRadioGroup from "@mui/material/RadioGroup"

/**
 * @param {import("formik").FieldProps & Omit<import("@mui/material").RadioGroupProps, "name" | "value">} props
 * @returns {import("@mui/material").RadioGroupProps}
 */
export function fieldToRadioGroup({
  field: { onBlur: fieldOnBlur, ...field },
  form,
  onBlur,
  ...props
}) {
  return {
    onBlur: function handleBlur(e) {
      if (onBlur) {
        onBlur(e)
      }
      fieldOnBlur(e ?? field.name)
    },
    ...field,
    ...props
  }
}

/**
 * @param {import("formik").FieldProps & Omit<import("@mui/material").RadioGroupProps, "name" | "value">} props
 */
export function RadioGroup(props) {
  return <MuiRadioGroup {...fieldToRadioGroup(props)} />
}

RadioGroup.displayName = "FormikMaterialUIRadioGroup"
