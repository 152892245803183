import React, { useCallback, useMemo } from "react"
import { getIn } from "formik"
import { Checkbox, Switch } from "@mui/material"

const CheckboxGroupComponent = ({
  field,
  form,
  onBeforeChange = null,
  variant = "checkbox",
  ...props
}) => {
  const { values, setFieldValue, setFieldTouched } = form
  const groupValue = getIn(values, field.name)
  const checkboxValue = props.value
  const customOnChange = props.onChange

  const handleChange = useCallback(
    async (_, checked) => {
      let change = false
      try {
        if (onBeforeChange) {
          await onBeforeChange(checked)
        }
        change = true
      } catch {
        change = false
      }

      if (change) {
        if (checked) {
          setFieldValue(
            field.name,
            Array.from(new Set(groupValue).add(checkboxValue))
          )
        } else {
          setFieldValue(
            field.name,
            groupValue.filter(
              /** @param {string | number} v */
              (v) => v !== checkboxValue
            )
          )
        }
        setFieldTouched(field.name, true)
        if (customOnChange) {
          customOnChange(checked)
        }
      }
    },
    [
      setFieldTouched,
      field.name,
      setFieldValue,
      groupValue,
      checkboxValue,
      customOnChange,
      onBeforeChange
    ]
  )

  const checked = useMemo(() => {
    return (groupValue && groupValue.includes(checkboxValue)) ?? false
  }, [groupValue, checkboxValue])

  const Component = variant === "switch" ? Switch : Checkbox

  return <Component {...props} checked={checked} onChange={handleChange} />
}

export const CheckboxGroup = React.memo(CheckboxGroupComponent)
