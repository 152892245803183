import React from "react"
import isString from "lodash/isString"
import isPlainObject from "lodash/isPlainObject"
import isArray from "lodash/isArray"
import map from "lodash/map"
import { Link } from "react-router-dom"
import * as makeUrl from "utils/url"
import { Icon } from "components"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
  link: {
    marginLeft: 5,
    top: -1,
    position: "relative"
  }
})

const PageTitle = ({ title, breadCrumb = null }) => {
  const classes = useStyles()

  return (
    <div className="page-title">
      {title && <h1>{title}</h1>}

      {breadCrumb && isString(breadCrumb) && <h1>{breadCrumb}</h1>}

      {((breadCrumb && isArray(breadCrumb)) || isPlainObject(breadCrumb)) && (
        <ul className="breadcrumb">
          <li>
            <Link to={makeUrl.local()} className={classes.link}>
              <Icon className="fa fa-home" />
            </Link>
          </li>

          {map(
            breadCrumb,
            (path, i) =>
              path && (
                <li key={`breadcrumb-${i}`}>
                  <Link to={makeUrl.local(path.url)}>{path.name}</Link>
                </li>
              )
          )}
        </ul>
      )}
    </div>
  )
}

export default PageTitle
