import palette from "theme/material-ui-overrides"

// LEGACY: This file should be removed in future.

export const onWindowResize = (func) => {
  if (window.addEventListener) {
    window.addEventListener("resize", func, false)
  } else if (window.attachEvent) {
    window.attachEvent("resize", func)
  } else {
    window.onresize = func
  }
}

export const onEvent = (eventName, ...args) => {
  global.$ &&
    $(window)
      .off(eventName)
      .on(eventName, ...args)
}

export const on = (event, element, handler, ...args) => {
  if (global.$) {
    $(element)
      .off(event)
      .on(event, handler, ...args)
  }
}

export const off = (event, element) => {
  if (global.$) {
    $(element).off(event)
  }
}

export const blink = (selector) => {
  if (!global.$) {
    return
  }

  $(selector).effect("highlight", { color: palette.primary1Color })
}

export const markDeleted = (selector) => {
  $(selector).addClass("deletedBlock")
}
