const SET_SORT = "jass/webshop-offers-positions/SET_SORT"

const intialState = { columnKey: null, ascending: false }

export default (state = intialState, action) => {
  switch (action.type) {
    case SET_SORT:
      return action.payload
    default:
      return state
  }
}

export const setSort = (sort) => {
  return { type: SET_SORT, payload: sort }
}
