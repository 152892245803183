import { Box } from "@mui/material"
import React from "react"
import Avatar from "shared/ui/berry-jass/Avatar"

export const SupplierIconPreview = ({ value }) => {
  return (
    <Box
      sx={{
        display: "inline-block",
        minWidth: "100%",
        aspectRatio: "1",
        position: "relative",
        img: { height: "100%" },
        overflow: "hidden"
      }}
    >
      <Avatar
        src={
          value.thumbnail_url ||
          value.file?.thumbnail_url ||
          value.file?.thumb.url
        }
        size="xl"
        variant="rounded"
        sx={{
          borderStyle: "solid",
          borderColor: (theme) => theme.palette.grey[400]
        }}
      />
    </Box>
  )
}
