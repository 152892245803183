import React, { useState } from "react"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

/**
 *
 * @param {Omit<import('@mui/x-date-pickers').DesktopDatePickerProps, 'renderInput'> & {error?: string, name?: string, id?: string}} props
 * @returns
 */
const DatePicker = ({
  error = null,
  value,
  onAccept,
  onClose,
  id,
  name,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  const handleAccept = (val) => {
    if (onAccept && !isNaN(val)) {
      onAccept(val)
    }

    setOpen(false)
  }

  const handleClose = () => {
    onClose?.()
    setOpen(false)
  }

  return (
    <DesktopDatePicker
      {...rest}
      open={open}
      closeOnSelect
      onAccept={handleAccept}
      onClose={handleClose}
      value={value}
      showToolbar
      PopperProps={{ id: "date-picker" }}
      renderInput={(props) => {
        return (
          <TextField
            id={id}
            data-testid={rest["data-testid"]}
            fullWidth
            variant="standard"
            name={name}
            onClick={() => setOpen(true)}
            {...props}
            onBlur={(e) => {
              props.onBlur?.(e)
              if (onAccept && !isNaN(value)) {
                onAccept(value)
              }
            }}
            error={!!error || (value && props.error)}
          />
        )
      }}
    />
  )
}

export default DatePicker
