import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"

const LOAD_START = "jass/contact/for-agency/contacts/LOAD_START"
const LOAD_SUCCESS = "jass/contact/for-agency/contacts/LOAD_SUCCESS"
const LOAD_FAIL = "jass/contact/for-agency/contacts/LOAD_FAIL"

const list = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.result.contacts.contacts
})

export default combineReducers({ list })

export const load = ({ id }) => {
  return {
    types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`agencies/${id}`)
  }
}
