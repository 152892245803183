import React, { useState } from "react"
import TextField from "components/TouchDesign/Components/TextField"

import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"

import CloseIcon from "@mui/icons-material/Close"

import { DesktopDatePicker } from "@mui/x-date-pickers"

/**
 *
 * @param {Omit<import('@mui/x-date-pickers').DesktopDatePickerProps, 'renderInput'> & {sx?: import('@mui/material').SxProps, error?: string, name?: string, clearable?: boolean, id?: string, InputLabelProps?: import('@mui/material').InputLabelProps}} props
 * @returns
 */
const DatePicker = ({
  value,
  error,
  onChange,
  onAccept,
  onClose,
  id,
  label,
  name,
  clearable,
  sx,
  InputLabelProps,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  const handleAccept = (val) => {
    if (onAccept && !isNaN(val)) {
      onAccept(val)
    }

    setOpen(false)
  }

  const handleClose = () => {
    onClose?.()
    setOpen(false)
  }

  return (
    <DesktopDatePicker
      {...rest}
      open={open}
      closeOnSelect
      showToolbar
      value={value}
      label={label}
      onChange={onChange}
      onAccept={handleAccept}
      onClose={handleClose}
      PopperProps={{ id: "date-picker" }}
      renderInput={(props) => {
        return (
          <TextField
            {...props}
            sx={sx}
            id={id}
            name={name}
            error={error ?? (value && props.error) ? "invalid" : ""}
            fullWidth
            onClick={() => setOpen(true)}
            onBlur={(e) => {
              props.onBlur?.(e)
              if (onAccept && !isNaN(value)) {
                onAccept(value)
              }
            }}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...rest.InputProps,
              endAdornment:
                clearable && value ? (
                  <>
                    {props.InputProps?.endAdornment}
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          onChange(null)
                          onAccept?.(null)
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ) : (
                  props.InputProps.endAdornment
                )
            }}
          />
        )
      }}
    />
  )
}

export default DatePicker
