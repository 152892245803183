import React from "react"
import { Translate } from "react-redux-i18n"
import { Field } from "formik"
import { Stack, Typography } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime"

import { HorizontalStack } from "shared/ui/berry-jass"
import { CheckboxGroup } from "shared/ui/berry-jass-formik/CheckboxGroup"
import { SupplierIcon } from "entities/supplier"
import { SalesProductInfoPopover } from "./SalesProductInfoPopover"

const runtimeIconSx = { fontSize: 16 }

const SalesProductCellComponent = ({ product, disabled, onBeforeChange }) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={2}>
      <Field
        disabled={disabled}
        component={CheckboxGroup}
        name="selected_products"
        onBeforeChange={(checked) => onBeforeChange(checked, product)}
        value={product.id}
      />
      <SupplierIcon iconUrl={product.icon_url} />
      <Stack sx={{ flexGrow: 1 }}>
        <HorizontalStack gap={0.5}>
          <Typography variant="h6">{product.supplier}</Typography>
          <SalesProductInfoPopover product={product} />
        </HorizontalStack>
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
          <AccessTimeIcon sx={runtimeIconSx} />
          <Typography variant="subtitle2">
            <Translate value="plurals.days" count={product.runtime_in_days} />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export const SalesProductCell = React.memo(SalesProductCellComponent)
