export const orange = "#EC6708"
export const labelFontSize = ".8em"

const getOverrides = (config) => {
  const color = (config && config.color) || orange
  return {
    userAgent: "",

    spacing: {
      desktopToolbarHeight: "1.5rem"
    },

    palette: {
      primary1Color: color,
      primary2Color: color,
      primary3Color: color,
      accent1Color: color,
      accent2Color: color,
      accent3Color: color,
      textColor: "#000"
    },

    dropDownMenu: {
      accentColor: "red"
    },

    checkbox: {
      checkedColor: color,
      requiredColor: color
    },

    snackbar: {
      backgroundColor: color
    },

    inkBar: "red",
    datePicker: {
      headerColor: color
    }
  }
}

export default getOverrides()
