import React, { useCallback, useState } from "react"
import { Tabs as MaterialUiTabs, Tab } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import map from "lodash/map"

const Tabs = ({ persistTabs = false, children: tabs = [] }) => {
  const [step, changeStep] = useState(0)
  const handleTabClick = useCallback((_, value) => changeStep(value), [])

  if (tabs?.length === 1 && !persistTabs) {
    return tabs[0].content
  }

  return (
    <div>
      <AppBar position="sticky">
        <MaterialUiTabs
          value={step}
          onChange={handleTabClick}
          indicatorColor="secondary"
          textColor="inherit"
        >
          {map(tabs, (tab, i) => (
            <Tab key={i} label={tab.label} />
          ))}
        </MaterialUiTabs>
      </AppBar>
      {tabs?.[step]?.content}
    </div>
  )
}

export default Tabs
