import React, { useEffect, useMemo, useRef, useState } from "react"
import { Grid, Box, Typography, Skeleton } from "@mui/material"
import Chart from "react-apexcharts"
import MainCard from "./MainCard"
import deepmerge from "deepmerge"
import useLocale from "hooks/useLocale"
import ApexCharts from "apexcharts"
import { formatPrice } from "utils/parsers/prices"

/**
 * @type {import('apexcharts').ApexOptions}
 */
const defaultOptions = {
  chart: {
    defaultLocale: "en",
    locales: [
      {
        name: "en",
        options: {
          months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          shortMonths: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          days: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        }
      },
      {
        name: "de",
        options: {
          months: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember"
          ],
          shortMonths: [
            "Jan",
            "Feb",
            "Mär",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dez"
          ],
          days: [
            "Sonntag",
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag",
            "Samstag"
          ],
          shortDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
        }
      }
    ],
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    sparkline: {
      enabled: true
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "smooth",
    width: 2
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 0.8,
      opacityFrom: 1,
      opacityTo: 0,
      stops: [0, 100]
    }
  },
  legend: {
    show: true,
    floating: true,
    position: "top",
    horizontalAlign: "right",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    offsetY: -50,
    inverseOrder: true,
    formatter: (seriesName, opts) => {
      return [
        seriesName,
        "<div style='margin-top: 3px' />",
        formatPrice(opts.w.globals.seriesTotals[opts.seriesIndex])
      ]
    },
    markers: {
      width: 10,
      height: 10,
      radius: 2,
      offsetX: -4
    },
    itemMargin: {
      horizontal: 14
    }
  },
  tooltip: { theme: "light" },
  colors: ["#FF6600", "#37374C", "#5180dc", "#00e676", "#f44336", "#9e9e9e"],
  xaxis: {
    labels: {
      show: true,
      showDuplicates: false
    }
  },
  yaxis: {
    min: 0,
    show: false
  }
}

/**
 *
 * @param {Pick<import('react-apexcharts').Props, 'series' | 'height' | 'options'> & {id: string; title: React.ReactNode; loading?: boolean}} props
 */
const AreaChartCard = ({
  id,
  title,
  series,
  height,
  loading,
  options = {}
}) => {
  const [chartSeries, setChartSeries] = useState([])
  const chart = useRef()
  // This is needed to fix the chart width issue:
  // https://github.com/apexcharts/apexcharts.js/issues/1077#issuecomment-1191066513
  useEffect(() => {
    if (series) {
      setChartSeries(series)
    }
  }, [series])

  const locale = useLocale()

  useEffect(() => {
    if (chart.current) {
      ApexCharts.exec(id, "setLocale", locale)
    }
  }, [id, locale])

  const chartOptions = useMemo(() => {
    const defaultOptionsWithIdAndLocale = deepmerge(defaultOptions, {
      chart: { id, defaultLocale: locale }
    })
    return deepmerge(defaultOptionsWithIdAndLocale, options)
  }, [id, locale, options])

  return (
    <MainCard
      border={false}
      content={false}
      sx={{
        "& foreignObject, & svg": { overflow: "unset" },
        "& .apexcharts-xaxis-texts-g text:first-of-type": {
          translate: 20
        },
        "& .apexcharts-xaxis-texts-g text:last-child": {
          translate: -20
        },
        "& .apexcharts-legend-series": {
          textAlign: "end"
        },
        pb: 5
      }}
    >
      <Box
        sx={{
          px: 2,
          pt: 2.5,
          pb: 3.5
        }}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={height} />
      ) : (
        <Chart
          ref={chart}
          options={chartOptions}
          series={chartSeries}
          height={height}
          type="area"
        />
      )}
    </MainCard>
  )
}
export default AreaChartCard
