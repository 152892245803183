import { combineReducers } from "redux"

const SET_VACANCY = "jass/imported-vacancies/SET_VACANCY"
const vacancyId = (state = null, action) => {
  switch (action.type) {
    case SET_VACANCY:
      return action.vacancyId
    default:
      return state
  }
}

export const setVacancy = (vacancyId) => {
  return { type: SET_VACANCY, vacancyId }
}

export default combineReducers({
  vacancyId
})
