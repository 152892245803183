import { useQuery } from "@tanstack/react-query"
import client from "utils/client"

const useRecrumediaOffer = (token) =>
  useQuery(["recrumediaOffer", token], async () => {
    const res = await client(`/recrumedia/api/v1/offers/${token}.json`)
    return res.data
  })

export default useRecrumediaOffer
