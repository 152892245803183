import makeStyles from "@mui/styles/makeStyles"
import Input from "components/TouchDesign/Components/Input"
import React from "react"

const useButtonStyles = makeStyles({
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 0,
    height: "unset",
    alignSelf: "stretch",
    zIndex: 2,
    textTransform: "none"
  }
})

const useInputStyles = makeStyles({
  input: {
    padding: "12px 20px"
  },
  adornedEnd: {
    paddingRight: 0
  }
})

const ButtonInput = ({ button, onChange, buttonDisabled, ...props }) => {
  const buttonClasses = useButtonStyles()
  const inputClasses = useInputStyles()
  return (
    <Input
      {...props}
      onChange={onChange}
      InputProps={{
        ...props.InputProps,
        classes: inputClasses,
        endAdornment: React.cloneElement(button, {
          ...button.props,
          disabled: buttonDisabled,
          classes: { ...button.props.classes, ...buttonClasses }
        })
      }}
    />
  )
}

export default ButtonInput
