import React from "react"
import { Divider, ListItem, ListItemText, Typography } from "@mui/material"
import { ActionsCell } from "shared/ui/berry-jass"
import { formatSalesProductShort } from "../lib"
import useLocale from "hooks/useLocale"

export const SalesProductsList = ({ products, getActions }) => {
  const locale = useLocale()

  return (
    <>
      {products.map((product, i) => {
        return (
          <React.Fragment key={product.id}>
            <ListItem sx={{ p: 2 }}>
              <ListItemText
                sx={{ pr: 4 }}
                primary={
                  <Typography variant="h4" fontWeight="500" color="grey.900">
                    {formatSalesProductShort(product, locale)}
                  </Typography>
                }
              />
              <ActionsCell
                id={`${product.id}_actions`}
                actions={getActions(product)}
              />
            </ListItem>
            {i !== products.length - 1 && <Divider />}
          </React.Fragment>
        )
      })}
    </>
  )
}
