import React from "react"
import { getIn } from "formik"
import { ColorPreview as ColorPreviewUI } from "shared/ui/berry-jass"

/**
 * @param {import("formik").FieldProps & { fullWidth?: boolean }} props
 */
const fieldToColorPreview = ({ field, form: { values }, ...props }) => {
  return {
    value: getIn(values, field.name),
    ...props
  }
}

/**
 * @param {import("formik").FieldProps & { fullWidth?: boolean }} props
 */
export const ColorPreview = (props) => {
  return <ColorPreviewUI {...fieldToColorPreview(props)} />
}

ColorPreview.displayName = "ColorPreview"
