import { Link } from "@mui/material"
import React, { memo } from "react"

export const LinkCell = memo(
  /**
   * @param {{href: string, text?: string}} props
   */
  ({ text, href }) => {
    return (
      <Link sx={{ wordWrap: "break-word" }} href={href}>
        {text ?? href}
      </Link>
    )
  }
)
