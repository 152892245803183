import React from "react"
import get from "lodash/get"
import { defaultLanguage } from "utils/api"

const clientTranslations = {
  de: {
    "incomplete-agency-offers": {
      "confirmation-by-link": {
        title: "Bestätigung des Angebots",
        messages: {
          "confirmation-token-is-invalid": "Bestätigungs-Token ist ungültig",
          "already-accepted": "Das Angebot wurde bereits angenommen",
          "already-rejected": "Angebot wurde bereits abgelehnt",
          "confirmation-token-is-expired":
            "Das Bestätigungs-Token ist abgelaufen",
          "valid-until-outdated": "Angebot abgelaufen am ",
          rejection: {
            success:
              "Angebot erfolgreich abgelehnt und Bestätigungs-E-Mail wird gesendet",
            failure:
              "Ablehnung des Angebots gescheitert. Verbinden Sie sich mit dem Support"
          },
          accepting: {
            success:
              "Angebot erfolgreich angenommen und Bestätigungs-E-Mail wird gesendet",
            failure:
              "Das Angebot wurde nicht angenommen. Verbinden Sie sich mit dem Support"
          }
        }
      },
      acceptance: {
        "start-date": {
          title: "Anfangsdatum"
        },
        "upsale-amount": {
          title: "Upsale-Betrag"
        },
        info: "Angebot akzeptieren und Rechnung erstellen",
        "reference-number": {
          title: "Referenznummer"
        },
        "sub-customer-has-subscription":
          "Unterkunde hat Slot-Abonnement aktiviert",
        "parent-has-subscription":
          "Der Elternteil hat das Abonnement von Spielautomaten aktiviert",
        actions: {
          reject: "Ablehnen",
          accept: "Akzeptieren",
          "accept-anyway": "Trotzdem akzeptieren"
        }
      }
    }
  },
  en: {
    "incomplete-agency-offers": {
      "confirmation-by-link": {
        title: "Offer confirmation",
        messages: {
          "confirmation-token-is-invalid": "Confirmation token is invalid",
          "already-accepted": "Offer has been already accepted",
          "already-rejected": "Offer has been already rejected",
          "confirmation-token-is-expired": "Confirmation token is expired",
          "valid-until-outdated": "Offer expired at ",
          rejection: {
            success:
              "Offer rejected successfully and confirmation email is sent",
            failure: "Offer rejection failed. Connect with the support"
          },
          accepting: {
            success:
              "Offer accepted successfully and confirmation email is sent",
            failure: "Offer accepting failed. Connect with the support"
          }
        }
      },
      acceptance: {
        "start-date": {
          title: "Start date"
        },
        "upsale-amount": {
          title: "Upsale amount"
        },
        info: "Confirm offer and generate invoice",
        "reference-number": {
          title: "Reference number"
        },
        "sub-customer-has-subscription":
          "Sub customer has enabled slots subscription",
        "parent-has-subscription": "Parent has enabled slots subscription",
        actions: {
          reject: "Reject",
          accept: "Accept",
          "accept-anyway": "Accept anyway"
        }
      }
    }
  }
}

export const translateText = (value) =>
  get(clientTranslations, `${defaultLanguage()}.${value}`, value)

export const Translate = ({
  value,
  mandatory = false,
  insertedData = undefined
}) => (
  <React.Fragment>
    {translateText(value) +
      (mandatory ? " *" : "") +
      (insertedData ? ` ${insertedData}` : "")}
  </React.Fragment>
)
