import { alpha } from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import last from "lodash/last"
import useIconStyles from "hooks/useIconStyles"
import usePopup from "hooks/usePopup"
import React from "react"
import PanelButton from "components/TouchDesign/Components/PanelButton"
import Popover from "@mui/material/Popover"
import Box from "@mui/material/Box"
import AdField from "components/AdEditor/AdField"
import { ButtonInput } from "components/TouchDesign/Formik"
import Button from "@mui/material/Button"
import { Formik } from "formik"
import Translate from "components/Translate/Translate"

const useButtonStyles = makeStyles({
  root: {
    backgroundColor: "#0d2251",
    "&:hover": {
      backgroundColor: alpha("#0d2251", 0.8)
    }
  }
})

const PopoverInput = ({
  name,
  icon,
  title,
  label,
  placeholder = "",
  value,
  onChange,
  validation
}) => {
  const iconClasses = useIconStyles()
  const buttonClasses = useButtonStyles()
  const { anchorEl, handleOpen, handleClose } = usePopup()
  const shortName = last(name.split("."))

  const onSubmit = (values) => {
    const result = onChange(values[shortName])
    if (result instanceof Promise) {
      result.then(handleClose)
    }

    handleClose()
    return result
  }

  return (
    <React.Fragment>
      <PanelButton id={`${shortName}-btn`} onClick={handleOpen}>
        {icon}
        <span className={iconClasses.title}>{title}</span>
      </PanelButton>

      <Popover
        id={`${shortName}-popover`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Formik
          initialValues={{ [shortName]: value }}
          onSubmit={onSubmit}
          render={({ isSubmitting, handleSubmit }) => {
            return (
              <Box
                component="form"
                onSubmit={(e) => {
                  e.stopPropagation()
                  // @ts-ignore
                  handleSubmit(e)
                }}
                paddingLeft={2}
                paddingRight={2}
                paddingTop={2}
                width="450px"
              >
                <AdField
                  id={shortName}
                  name={shortName}
                  component={ButtonInput}
                  label={label}
                  placeholder={placeholder}
                  validate={validation}
                  fullWidth
                  buttonDisabled={isSubmitting}
                  button={
                    <Button
                      type="submit"
                      className={buttonClasses.root}
                      variant="contained"
                    >
                      <Translate value="save" />
                    </Button>
                  }
                />
              </Box>
            )
          }}
        />
      </Popover>
    </React.Fragment>
  )
}

export default PopoverInput
