import { combineReducers } from "redux"
import { CLEAR_PRODUCT_DATA, LOAD_FOR_PRODUCT_START } from "./productData"

const CHANGE_SHARE_MODE = "jass/statistics/ui/CHANGE_SHARE_MODE"
const SET_STATISTICS_PDF_LOADING =
  "jass/statistics/ui/SET_STATISTICS_PDF_LOADING"

const selectedProduct = (state = null, action) => {
  switch (action.type) {
    case CLEAR_PRODUCT_DATA:
      return null
    case LOAD_FOR_PRODUCT_START:
      return action.position
    default:
      return state
  }
}

const sharesMode = (state = "views", action) => {
  switch (action.type) {
    case CHANGE_SHARE_MODE:
      return action.mode
    default:
      return state
  }
}

const pdfLoading = (state = false, action) => {
  switch (action.type) {
    case SET_STATISTICS_PDF_LOADING:
      return action.value
    default:
      return state
  }
}

export const changeShareMode = (mode) => {
  return {
    type: CHANGE_SHARE_MODE,
    mode
  }
}

export const setPdfLoading = (value) => {
  return {
    type: SET_STATISTICS_PDF_LOADING,
    value
  }
}

export default combineReducers({
  selectedProduct,
  sharesMode,
  pdfLoading
})
