import findIndex from "lodash/findIndex"
import set from "lodash/set"
import map from "lodash/map"

export function setIsRead(list, state, id) {
  if (id) {
    const index = findIndex(list, ["id", id])
    const newList = [...list]
    set(newList, index, {
      ...list[index],
      is_read: state
    })
    return newList
  }

  return map(list, (notification) => ({
    ...notification,
    is_read: state
  }))
}

export function createNotification(notification) {
  return {
    ...notification,
    is_read: false
  }
}
