import { useCallback, useEffect, useState } from "react"

/**
 * @param {boolean} open
 * @param {number | string} [initialValue]
 * @returns {[any, (event: React.SyntheticEvent<Element, Event>, value: any) => void]}
 */
export const useTabsInDialog = (open, initialValue = 0) => {
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    if (open) {
      // reset selected tab on open
      setValue(initialValue)
    }
  }, [initialValue, open])

  const handleChange = useCallback(
    /**
     * @param {React.SyntheticEvent<Element, Event>} event
     * @param {any} newValue
     */
    (event, newValue) => {
      setValue(newValue)
    },
    []
  )

  return [value, handleChange]
}
