import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import React, { useState, useCallback } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { SketchPicker } from "react-color"
import Box from "@mui/material/Box"
import classNames from "classnames"
import { Popover } from "@mui/material"

const useStyles = makeStyles({
  content: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  sample: {
    height: 31,
    width: 35,
    borderRadius: 4,
    border: "1px solid black"
  },
  description: {
    marginLeft: 7,
    fontFamily: "Roboto Slab",
    color: "#00031c",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "uppercase"
  },
  label: {
    marginLeft: 1,
    fontFamily: "Roboto Slab",
    fontSize: 12,
    color: "#00031c",
    opacity: 0.4,
    marginBottom: 5
  }
})

const ColorPicker = ({
  classes: externalClasses = { label: null },
  label,
  value,
  onChange
}) => {
  const [val, setVal] = useState(value)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const classes = useStyles()

  const handleOpen = useCallback((e) => setAnchorEl(e.currentTarget), [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
    onChange(val)
  }, [onChange, val])
  const handleChange = useCallback((value) => setVal(value.hex), [])

  return (
    <FormControl>
      <FormLabel className={classNames(classes.label, externalClasses.label)}>
        {label}
      </FormLabel>
      <section className={classes.content} onClick={handleOpen}>
        <Box bgcolor={value} className={classes.sample} />
        <div className={classes.description}>{value}</div>
      </section>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <SketchPicker disableAlpha color={val} onChange={handleChange} />
      </Popover>
    </FormControl>
  )
}

export default React.memo(ColorPicker)
