import { combineReducers } from "redux"
import { createReducer } from "store/helpers/reducers"
import versions from "store/modules/entities/customer/versions"
export const LOAD_START = "jass/customer/list/LOAD_START"
export const LOAD_SUCCESS = "jass/customer/list/LOAD_SUCCESS"
export const LOAD_FAIL = "jass/customer/list/LOAD_FAIL"
export const LOAD_FOR_EDIT_START = "jass/customer/list/LOAD_FOR_EDIT_START"
export const LOAD_FOR_EDIT_SUCCESS = "jass/customer/list/LOAD_FOR_EDIT_SUCCESS"
export const LOAD_FOR_EDIT_FAIL = "jass/customer/list/LOAD_FOR_EDIT_FAIL"
export const LOAD_BILLINGS_AND_CONTACTS_START =
  "jass/customer/list/LOAD_BILLINGS_AND_CONTACTS_START"
export const LOAD_BILLINGS_AND_CONTACTS_SUCCESS =
  "jass/customer/list/LOAD_BILLINGS_AND_CONTACTS_SUCCESS"
export const LOAD_BILLINGS_AND_CONTACTS_FAIL =
  "jass/customer/list/LOAD_BILLINGS_AND_CONTACTS_FAIL"
export const RESET = "jass/customer/list/reset"
export const LOAD_EXCEL_START = "jass/customer/excel/LOAD_START"
export const LOAD_EXCEL_SUCCESS = "jass/customer/excel/LOAD_SUCCESS"
export const LOAD_EXCEL_FAIL = "jass/customer/excel/LOAD_FAIL"
const RESET_CUSTOMER_EDIT_DATA = "jass/customer/edit/reset-data"

const GET_CUSTOMER_START = "jass/customer/get-start"
const GET_CUSTOMER_SUCCESS = "jass/customer/get-success"
const GET_CUSTOMER_FAIL = "jass/customer/get-fail"

const CLEAR_PARAMS = "jass/customer/list/CLEAR_PARAMS"

const defaultParams = { page: 1 }

const loading = createReducer(false, {
  [LOAD_START]: () => true,
  [LOAD_SUCCESS]: () => false,
  [LOAD_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => {
    if (action.params.page > 1) {
      return [...state, ...action.result]
    }

    return action.result.items
  },
  [RESET]: () => []
})

const count = createReducer(0, {
  [LOAD_SUCCESS]: (state, action) =>
    "count" in action.result ? action.result.count : state
})

const params = createReducer(defaultParams, {
  [LOAD_SUCCESS]: (state, action) => action.params,
  [CLEAR_PARAMS]: () => defaultParams
})

const editData = createReducer(null, {
  [RESET_CUSTOMER_EDIT_DATA]: () => null,
  [LOAD_FOR_EDIT_SUCCESS]: (state, action) => action.result
})

const excelLoading = createReducer(false, {
  [LOAD_EXCEL_START]: () => true,
  [LOAD_EXCEL_SUCCESS]: () => false,
  [LOAD_EXCEL_FAIL]: () => false
})

const billingsAndContactsItems = createReducer([], {
  [LOAD_BILLINGS_AND_CONTACTS_SUCCESS]: (state, action) => action.result
})

export default combineReducers({
  list: combineReducers({ loading, items, count, params }),
  edit: combineReducers({ data: editData }),
  excel: combineReducers({ loading: excelLoading }),
  billingsAndContacts: combineReducers({ items: billingsAndContactsItems }),
  versions
})

export const getCustomer = (id) => {
  return {
    types: [GET_CUSTOMER_START, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
    promise: (client) => client.get(`/jap_customers/${id}.json`)
  }
}

export function reset() {
  return { type: RESET }
}

export const resetCustomerEditData = () => ({
  type: RESET_CUSTOMER_EDIT_DATA
})

export const clearParams = () => ({
  type: CLEAR_PARAMS
})
