import set from "lodash/set"
import mapKeys from "lodash/mapKeys"

export const modelsTransformer = (response) => response.models

export const dataTransformer = (response) => response.data

export const itemsTransformer = (response) => response.items

export const dataStringArrayTranformer = (response) => {
  return (
    response?.data?.map((value) => ({
      id: value,
      name: value
    })) ?? []
  )
}

export const toDotNotation = (response) => {
  const result = {}
  Object.keys(response).forEach((key) => set(result, key, response[key]))
  return result
}

export const transformNestedAttributes = (values, keys) => {
  const keysSet = new Set(keys)

  return mapKeys(values, (_, key) => {
    return keysSet.has(key) ? key + "_attributes" : key
  })
}
