import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import last from "lodash/last"

const prefix = "jass/customerImports"
const LOAD_LAST_START = `${prefix}/LOAD_LAST_START`
const LOAD_LAST_SUCCESS = `${prefix}/LOAD_LAST_SUCCESS`
const LOAD_LAST_FAIL = `${prefix}/LOAD_LAST_FAIL`
const UPDATE = `${prefix}/UPDATE`

const loading = createReducer(false, {
  [LOAD_LAST_START]: () => true,
  [LOAD_LAST_SUCCESS]: () => false,
  [LOAD_LAST_FAIL]: () => false
})

const items = createReducer([], {
  [LOAD_LAST_START]: () => [],
  [LOAD_LAST_SUCCESS]: (state, action) => [action.result],
  [UPDATE]: (state, action) => [action.payload]
})

export const updateCustomerImport = (payload) => ({
  type: UPDATE,
  payload
})

export const loadLastCustomerImport = () => ({
  types: [LOAD_LAST_START, LOAD_LAST_SUCCESS, LOAD_LAST_FAIL],
  promise: (client) => client.get("customer_imports/last")
})

export default combineReducers({
  items,
  loading
})

export const getLastCustomerImport = (state) =>
  last(state.customerImports.items)

export const getCustomerImportLoading = (state) => state.customerImports.loading
