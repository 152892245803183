import React from "react"
import { useCallback } from "react"
import { Translate } from "react-redux-i18n"

export const useDownloadAdditionalCancellationInvoiceAction = () => {
  return useCallback((order) => {
    return {
      label: (
        <Translate value="web-shop.job-ads-table.cancellation-agency-invoice" />
      ),
      onClick: () =>
        window.open(order.cancellation_additional_invoice_pdf, "_blank")
    }
  }, [])
}
