import { useCallback } from "react"

export const useDownloadInvoiceAction = () => {
  return useCallback((order) => {
    return {
      label: `invoice_${order.invoice_number}.pdf`,
      onClick: () => window.open(order.invoice_pdf, "_blank")
    }
  }, [])
}
