import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { Box, Chip, CircularProgress, Grid, Link, Stack } from "@mui/material"
import AttachmentIcon from "@mui/icons-material/Attachment"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import { Typography } from "@mui/material"
import { I18n } from "react-redux-i18n"
import { LoadingButton } from "@mui/lab"

const DefaultPreview = ({ value, onDelete, downloadable, ...rest }) => {
  if (!value) {
    return null
  }

  return (
    <Chip
      variant="outlined"
      avatar={<AttachmentIcon />}
      label={
        downloadable ? (
          <Link href={value.file.url}>{value.file_name}</Link>
        ) : (
          value.file_name ?? value.id
        )
      }
      onDelete={onDelete}
      {...rest}
      sx={{
        backgroundColor: "#FAFAFA",
        py: 3,
        px: 1,
        ...rest.sx
      }}
    />
  )
}

/**
 *
 * @param {{ onChange?: Function, onUpload: (File) => Promise, onDelete?: Function, showEmpty?: boolean, preview: React.FC<{value: any, onDelete: Function}>} & import("react-dropzone").DropzoneProps} props
 * @returns
 */
export const FlatFilePicker = ({
  onUpload,
  onDelete,
  showEmpty = false,
  preview: Preview = DefaultPreview,
  multiple,
  name,
  value,
  label,
  children,
  onUploadStart,
  onUploadSettled,
  onUploadError,
  onChange,
  helperText,
  downloadable,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const disabled = isLoading || rest.disabled

  const { getRootProps, getInputProps, open } = useDropzone({
    ...rest,
    disabled,
    multiple,
    onDrop: (acceptedFiles, _rejectedFiles, e) => {
      if (!acceptedFiles.length) {
        return Promise.resolve()
      }

      setIsLoading(true)
      if (onUploadStart) {
        onUploadStart()
      }

      if (multiple) {
        return Promise.all(
          acceptedFiles.map((file) => {
            return onUpload(file)
          })
        )
          .then((data) => {
            return onChange(e, [...value, ...data])
          })
          .catch(onUploadError)
          .finally(() => {
            if (onUploadSettled) {
              onUploadSettled()
            }
            setIsLoading(false)
          })
      }

      const file = acceptedFiles[0]
      return onUpload(file)
        .then((data) => onChange(e, data))
        .catch(onUploadError)
        .finally(() => {
          if (onUploadSettled) {
            onUploadSettled()
          }
          return setIsLoading(false)
        })
    }
  })

  const handleDelete = (file) => {
    if (multiple) {
      onChange(
        null,
        value.filter((val) => val !== file)
      )
    } else {
      onChange(null, null)
    }

    if (onDelete) {
      onDelete(file)
    }
  }

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box>
              <Typography variant="h5">{label}</Typography>
            </Box>
            <Box>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={open}
              >
                {children ?? I18n.t("upload")}
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12}>
          {(showEmpty || !value) && (
            <Box {...getRootProps()}>
              {isLoading && <CircularProgress size={14} />}
            </Box>
          )}
          {multiple && (
            <Stack rowGap={2}>
              {value.map((file, i) => {
                return (
                  <Preview
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      "& .MuiChip-deleteIcon": {
                        marginLeft: "auto"
                      }
                    }}
                    key={i}
                    value={file}
                    onDelete={() => handleDelete(file)}
                  />
                )
              })}
            </Stack>
          )}

          {!multiple && value && (
            <Preview
              sx={{
                display: "flex",
                justifyContent: "space-between",
                "& .MuiChip-deleteIcon": {
                  marginLeft: "auto"
                }
              }}
              downloadable
              value={value}
              onDelete={() => handleDelete(value)}
            />
          )}
          <input {...getInputProps({ name })} />
        </Grid>
      </Grid>

      {helperText && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <FormControl error variant="standard">
              <FormHelperText id="component-error-text">
                {helperText}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  )
}
