import omit from "lodash/omit"

const DESTROY = "jass/components/dialog/DESTROY"
const UPDATE = "jass/components/dialog/UPDATE"
const CLOSE = "jass/components/dialog/CLOSE"

const initialState = {
  open: false
}

export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case UPDATE: {
      const data = action.data ?? initialState
      const { id } = data

      return {
        ...state,
        [id]: { ...state[id], ...data }
      }
    }
    case CLOSE: {
      const { id } = action

      return {
        ...state,
        [id]: { id, open: false }
      }
    }
    case DESTROY:
      return omit(state, [action.id])
    default:
      return state
  }
}

export function init(data) {
  return { type: UPDATE, data }
}

export function destroy(id) {
  return { type: DESTROY, id }
}

export function update(data) {
  return { type: UPDATE, data }
}

export function open(id) {
  return update({ id, open: true })
}

export function close(id) {
  return { type: CLOSE, id }
}

export const getData = (id) => (state) => state.components.dialog[id] ?? {}
