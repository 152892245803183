const CHANGE_EXPORT_DATA_PARTNER = "jass/exportData/CHANGE_PARTNER"
const CHANGE_EXPORT_DATA_AGENCY = "jass/exportData/CHANGE_AGENCY"
const CHANGE_EXPORT_DATA_CUSTOMER = "jass/exportData/CHANGE_CUSTOMER"
const EXPORT_CUSTOMER_DATA_START = "jass/exportData/EXPORT_DATA_START"
const EXPORT_CUSTOMER_DATA_SUCCESS = "jass/exportData/EXPORT_DATA_SUCCESS"
const EXPORT_CUSTOMER_DATA_FAIL = "jass/exportData/EXPORT_DATA_FAIL"

const exportDataReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_EXPORT_DATA_AGENCY:
      return { id: action.payload, type: "Agency" }
    case CHANGE_EXPORT_DATA_PARTNER:
      return { id: action.payload, type: "Partner" }
    case CHANGE_EXPORT_DATA_CUSTOMER:
      return { ...state, customerId: action.payload }
    default:
      return state
  }
}

export const setAgency = (value) => {
  return {
    type: CHANGE_EXPORT_DATA_AGENCY,
    payload: value
  }
}

export const setPartner = (value) => {
  return {
    type: CHANGE_EXPORT_DATA_PARTNER,
    payload: value
  }
}

export const setCustomer = (value) => {
  return {
    type: CHANGE_EXPORT_DATA_CUSTOMER,
    payload: value
  }
}

export const exportCustomerData = (data) => {
  return {
    types: [
      EXPORT_CUSTOMER_DATA_START,
      EXPORT_CUSTOMER_DATA_SUCCESS,
      EXPORT_CUSTOMER_DATA_FAIL
    ],
    promise: (client) => client.get("maintenance/export_customer_data", data)
  }
}

export const getAgency = (state) => {
  const { type, id } = state.maintenance.exportData
  return type === "Agency" ? id : null
}

export const getPartner = (state) => {
  const { type, id } = state.maintenance.exportData
  return type === "Partner" ? id : null
}

export const getCustomer = (state) => state.maintenance.exportData.customerId

export const getAffiliateId = (state) => state.maintenance.exportData.id
export const getAffiliateType = (state) => state.maintenance.exportData.type

export default exportDataReducer
