import React, { memo } from "react"
import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"

export const ActionsCell = memo(
  /**
   * @param {{id?: string; buttonSize?: import("@mui/material").IconButtonProps['size']; onPreload?: () => void; actions: {label: React.ReactNode, onClick: Function}[]}} props
   */
  ({ id, buttonSize = "small", onPreload, actions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleMenuClick = (event) => {
      if (onPreload) {
        onPreload()
      }
      setAnchorEl(event?.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }
    const open = Boolean(anchorEl)

    return (
      <>
        <IconButton
          onClick={handleMenuClick}
          onMouseOver={onPreload}
          size={buttonSize}
          data-testid={`${id}_button`}
          title="Show options"
          sx={{ visibility: actions.length ? "auto" : "hidden" }}
        >
          <MoreHorizOutlinedIcon
            fontSize="small"
            aria-controls={id}
            aria-haspopup="true"
            sx={{ color: "grey.500" }}
          />
        </IconButton>
        {open && (
          <Menu
            id={id}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            variant="selectedMenu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            {actions.map((action, i) => {
              return (
                <MenuItem
                  key={i}
                  onClick={() => {
                    action.onClick()
                    handleClose()
                  }}
                >
                  {action.label}
                </MenuItem>
              )
            })}
          </Menu>
        )}
      </>
    )
  }
)
