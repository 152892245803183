import { useEffect } from "react"
import * as WS from "shared/lib/web-sockets"

/**
 * @param {string} channel
 * @param {string} subscriber
 * @param {(message: {data: any}) => void} callback
 */
export const useWebSockets = (channel, subscriber, callback) => {
  useEffect(() => {
    WS.subscribe(channel, subscriber, callback)
    return () => WS.unsubscribe(channel, subscriber)
  }, [callback, channel, subscriber])
}

export const useWebSocketsCleanup = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", WS.teardown)

    return () => window.removeEventListener("beforeunload", WS.teardown)
  }, [])
}
