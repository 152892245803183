import React from "react"
import { getIn } from "formik"
import MuiTextField from "@mui/material/TextField"
import { getErrorText } from "./helpers"

/**
 * Taken with adjustments from https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/TextField.tsx
 *
 * @param {import("@mui/material").TextFieldProps & import("formik").FieldProps & { hideError?: boolean, onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => void }} props
 * @returns
 */
export const fieldToTextField = ({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  onChange,
  helperText,
  hideError,
  ...props
}) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line
    console.assert(
      field.value !== null && field.value !== undefined,
      `Can't be nil or undefined, possibly you forgot to pass initial value to <TextField name="${field.name}" />`
    )
  }

  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    error: showError,
    helperText: !hideError && showError ? getErrorText(fieldError) : helperText,
    disabled: disabled ?? isSubmitting,
    onChange: function handleChange(e) {
      if (onChange) {
        onChange(e, field.value)
      }
      fieldOnChange(e ?? field.name)
    },
    onBlur: function handleBlur(e) {
      if (onBlur) {
        onBlur(e)
      }
      fieldOnBlur(e ?? field.name)
    },
    ...field,
    ...props
  }
}

/**
 * @param {import("@mui/material").TextFieldProps & import("formik").FieldProps} props
 * @returns
 */
export const TextField = ({ children, ...props }) => {
  return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>
}

TextField.displayName = "FormikMaterialUITextField"
