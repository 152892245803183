import React from "react"
import MuiCheckbox from "@mui/material/Checkbox"

/**
 * Taken with adjustments from: https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Checkbox.tsx
 */
function fieldToCheckbox({
  disabled,
  field: { onBlur: fieldOnBlur },
  form: { isSubmitting, setFieldValue, setFieldTouched },
  onChange,
  form,
  field,
  type,
  onBlur,
  ...props
}) {
  const indeterminate = !Array.isArray(field.value) && field.value === null

  const { onChange: _onChange, ...fieldSubselection } = field

  return {
    disabled: disabled ?? isSubmitting,
    indeterminate,
    checked: field.value,
    onBlur:
      onBlur ??
      function onBlur(e) {
        fieldOnBlur(e ?? field.name)
      },
    onChange: function handleChange(event, value, reason) {
      if (onChange) {
        onChange(event, value, reason)
      }
      setFieldValue(field.name, value)
      setFieldTouched(field.name)
    },
    ...fieldSubselection,
    ...props
  }
}

export function Checkbox(props) {
  return <MuiCheckbox {...fieldToCheckbox(props)} />
}

Checkbox.displayName = "FormikMaterialUICheckbox"
