import React, { useRef } from "react"
import { getErrorText } from "./helpers"
import { TextField } from "@mui/material"
import { getIn } from "formik"
import uniq from "lodash/uniq"
import { Autocomplete as BerryAutocomplete } from "shared/ui/berry-jass"

/**
 * Taken with adjustments from https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Autocomplete.tsx
 */
const fieldToAutocomplete = ({
  disabled,
  field,
  form: { errors, touched, isSubmitting, setFieldValue, setFieldTouched },
  onChange,
  onInputChange = null,
  onBlur,
  freeSolo,
  renderInput,
  name,
  label,
  variant,
  required,
  creatable,
  inputValue,
  inputValueMapper = (x) => x,
  ...props
}) => {
  if (process.env.NODE_ENV !== "production") {
    if (props.multiple) {
      // eslint-disable-next-line
      console.assert(
        Array.isArray(field.value),
        `value for ${field.name} is not an array, this can caused unexpected behaviour`
      )
    }
    // eslint-disable-next-line
    console.assert(
      field.value !== undefined,
      `Can't be undefined, possibly you forgot to pass initial value to <RemoteAutocomplete name="${field.name}" />`
    )
  }
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  const defaultRenderInput = (props) => {
    return (
      <TextField
        required={required}
        name={name}
        label={label}
        variant={variant}
        error={showError}
        helperText={showError ? getErrorText(fieldError) : props.helperText}
        {...props}
      />
    )
  }

  const {
    onChange: _onChange,
    onBlur: _onBlur,
    multiple: _multiple,
    ...fieldSubselection
  } = field

  return {
    freeSolo,
    onBlur: function handleBlur(event) {
      if (onBlur) {
        onBlur(event)
      }
      if (freeSolo && inputValue.current) {
        setFieldValue(field.name, inputValueMapper(inputValue.current))
      }
      field.onBlur(event ?? field.name)
      setFieldTouched(field.name)
    },
    onChange: function handleChange(_event, value, reason) {
      if (onChange) {
        onChange(_event, value, reason)
      }

      let creatableValues
      if (creatable && freeSolo && props.multiple) {
        creatableValues = uniq(value.map((val) => val?.id || val))
      }

      setFieldValue(field.name, creatableValues || value)
      setFieldTouched(field.name)
    },
    onInputChange: function handleInputValueChange(e, value, reason) {
      onInputChange && onInputChange(e, value, reason)

      if (freeSolo) {
        inputValue.current = value
      }
    },
    disabled: disabled ?? isSubmitting,
    loading: isSubmitting,
    renderInput: renderInput ?? defaultRenderInput,
    ...fieldSubselection,
    ...props
  }
}

export const Autocomplete = (props) => {
  const inputValue = useRef(null)

  return (
    <BerryAutocomplete {...fieldToAutocomplete({ ...props, inputValue })} />
  )
}

Autocomplete.displayName = "FormikMaterialUIAutocomplete"
