import React from "react"
import Button from "@mui/material/Button"

export default ({ className = "", label = "", progress = 0, classes = {} }) => {
  const progressBarStyle = { width: `${progress}%` }

  if (!progress) {
    progressBarStyle.height = 0
  }

  return (
    <div className={className}>
      <Button variant="contained" color="secondary" className={classes.button}>
        {label}{" "}
      </Button>
      <div className="progressBar" style={progressBarStyle} />
    </div>
  )
}
