import React from "react"
import PropTypes from "prop-types"
import { Fab, CircularProgress } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    display: "inline-block"
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 5,
    zIndex: 10
  }
}))

const getCircularSize = (size) => {
  switch (size) {
    case "small":
      return 40
    case "medium":
      return 48
    default:
      return 56
  }
}

/**
 * @param {import('@mui/material').FabProps & {component?: string; loading:boolean}} props
 */
const LoadingFab = ({ loading, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Fab {...rest} disabled={loading || rest.disabled} title={rest.title} />
      {loading && (
        <CircularProgress
          color={rest.color}
          className={classes.progress}
          size={getCircularSize(rest.size)}
        />
      )}
    </div>
  )
}

LoadingFab.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default LoadingFab
