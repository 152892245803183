import React from "react"
import { Avatar } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import WarningIcon from "@mui/icons-material/Warning"

export const CustomStepperIcon = ({ icon, active, completed, error }) => {
  if (error) {
    return <WarningIcon sx={{ fontSize: 32, color: "error.main" }} />
  }

  if (completed) {
    return (
      <Avatar
        sx={{
          fontSize: 14,
          width: 32,
          height: 32,
          backgroundColor: "primary.main",
          cursor: "pointer"
        }}
      >
        <CheckIcon />
      </Avatar>
    )
  }

  return (
    <Avatar
      sx={{
        fontSize: 14,
        width: 32,
        height: 32,
        backgroundColor: active ? "primary.main" : "disabled",
        cursor: "pointer"
      }}
    >
      {String(icon).padStart(2, "0")}
    </Avatar>
  )
}
