import React, { useCallback } from "react"
import { Translate } from "react-redux-i18n"
import { useRouteMatch, useHistory } from "react-router-dom"

import { getAdEditorUrl } from "entities/order"

export const useEditAdAction = () => {
  const { url: currentUrl } = useRouteMatch()
  const history = useHistory()

  return useCallback(
    ({ file, orderId }) => {
      const url = getAdEditorUrl(file, currentUrl, orderId)

      return {
        label: <Translate value="edit-latest-production-file" />,
        onClick: () => {
          history.push(url)
        }
      }
    },
    [history, currentUrl]
  )
}
