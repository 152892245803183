import Handlebars from "handlebars"

export const handlebars = Handlebars.create()

handlebars.registerHelper("withClass", function withClass(html, classname) {
  if (typeof classname !== "string") return html
  if (!html) return html

  return html.replace(/<\w*/, (tag) => `${tag} class="${classname}"`)
})

handlebars.registerHelper("withDefault", function withDefault(value) {
  return value
})

/**
 * @param {string} input
 * @returns {hbs.AST.Program}
 */
export const getHandlebarsAst = (input) => {
  return handlebars.parseWithoutProcessing(input)
}

/**
 * @param {hbs.AST.Program} ast
 * @returns {string[]}
 */
export const getHandlebarsVariables = (ast) => {
  return ast.body
    .filter(({ type }) => {
      return type === "MustacheStatement"
    })
    .map(
      // @ts-expect-error
      (statement) => statement.params[0]?.original || statement.path?.original
    )
}

/**
 *
 * @param {hbs.AST.Program} ast
 * @returns {hbs.AST.Statement[]}
 */
const getWithDefaultBlocks = (ast) => {
  return ast.body.filter(
    // @ts-expect-error
    ({ type, path }) =>
      type === "BlockStatement" && path.original === "withDefault"
  )
}

/**
 *
 * @param {hbs.AST.Statement} statement
 * @returns {string?}
 */
const getVarName = (statement) => {
  const registeredHelpers = Object.keys(handlebars.helpers)

  // @ts-expect-error
  const stack = [statement.params[0]]
  while (stack.length) {
    const node = stack.shift()
    if (
      node.type === "PathExpression" &&
      node.original &&
      !registeredHelpers.includes(node.original)
    ) {
      return node.original
    }
    node.params && stack.push(...node.params)
  }
  return null
}

/**
 *
 * @param {hbs.AST.Program} ast
 */
export const getDefaultValues = (ast) => {
  return getWithDefaultBlocks(ast)
    .map((r) => [
      getVarName(r),
      // @ts-expect-error
      r.program.body[0].original.trim()
    ])
    .filter(([name]) => Boolean(name))
}
