import React from "react"

import TextField from "@mui/material/TextField"
import { I18n } from "react-redux-i18n"
import classNames from "classnames"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import get from "lodash/get"
import noop from "lodash/noop"
import isUndefined from "lodash/isUndefined"
import uniqueId from "lodash/uniqueId"
import keys from "lodash/keys"
import size from "lodash/size"
import isString from "lodash/isString"
import head from "lodash/head"
import toString from "lodash/toString"
import assign from "lodash/assign"

class Input extends React.Component {
  constructor(props) {
    super(props)

    this.debounceTimeout = get(props, "debounceTimeout", this.debounceTimeout)
    this.changeHandler = this.changeHandler.bind(this)
    this.onFocusHandler = this.onFocusHandler.bind(this)
    this.onBlurHandler = this.onBlurHandler.bind(this)
  }

  state = {
    isFocused: false,
    isTouched: false,
    collectSearchText: undefined
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.input.focus()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.touched !== this.props.touched) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isTouched: this.props.touched })
    }
    if (prevProps.value && !this.props.value) {
      this.setSearchText("")
    }
    if (prevProps.noCollectableText && this.props.value !== prevProps.value) {
      this.setSearchText("")
    }
  }

  inputTimeoutId = null
  debounceTimeout = 50

  changeHandler(e) {
    const { onChange = noop, onChangeCustom = noop } = this.props
    const change = (value) => {
      onChange(value)
      onChangeCustom(value)
      this.setSearchText(undefined)
    }
    const value = get(e, "target.value", e)

    if (!this.debounceTimeout) {
      change(value)
      return
    }

    if (this.inputTimeoutId) {
      e.persist && e.persist()
      clearTimeout(this.inputTimeoutId)
    }

    this.setSearchText(value)

    this.inputTimeoutId = setTimeout(() => change(value), this.debounceTimeout)
  }

  // LEGACY: don't blame me, move the withReducer logic to the component
  setSearchText(newValue) {
    if (isString(newValue)) {
      this.setState({ collectSearchText: toString(newValue) })
    }
  }

  clearValue = () => {
    const { onBlur = noop, onChange = noop, onChangeCustom = noop } = this.props

    onChangeCustom("")
    this.setSearchText("")
    onChange("")

    setTimeout(() => {
      onBlur && onBlur()
    }, this.debounceTimeout)
  }

  onFocusHandler() {
    const { onFocus = noop } = this.props

    this.setState({ isFocused: true, isTouched: true })
    onFocus()
  }

  onBlurHandler(e) {
    const { blurFormat, onBlur = noop } = this.props

    this.setState({ isFocused: false })
    !blurFormat && onBlur(e)
  }

  render() {
    const {
      name = "",
      error = {},
      errorLabels = {},
      className = "",
      inputClassName,
      type = "text",
      disabled = false,
      minRows = 1,
      maxRows,
      sx,
      tabIndex = 0,
      style = {},
      containerStyle = {},
      labelIcon = null,
      maxLength = 524288,
      fieldContainer = true,
      onKeyPress,
      blurFormat = (v) => v,
      placeholder,
      noCollectableText = false,
      title,
      autoComplete,
      label,
      min,
      step,
      max,
      clearable = false
    } = this.props

    const { collectSearchText, isFocused } = this.state

    const value = noCollectableText
      ? (!collectSearchText && this.props.value) || collectSearchText
      : (isUndefined(collectSearchText) && this.props.value) ||
        collectSearchText

    const controlId = get(
      this.props,
      "controlId",
      uniqueId(name || "controlId")
    )
    const isMultiLine = minRows > 1

    const errors = keys(error)
    const errorKey =
      size(errors) && !isFocused
        ? (isString(error) && error) || head(errors)
        : ""

    const errorText =
      !errorKey || (errorKey === "required" && size(errors) === 1 && value)
        ? ""
        : get(errorLabels, errorKey, I18n.t(`errors.${errorKey}`))

    const rootStyle = {
      width: "100%",
      height: "100%"
    }

    return (
      <div
        className={classNames(className, {
          focused: isFocused,
          fieldContainer: fieldContainer,
          multiline: isMultiLine,
          "search-input": clearable
        })}
        style={containerStyle}
      >
        {labelIcon && <div className="labelIcon">{labelIcon}</div>}

        <div
          className={labelIcon ? "labelIconInput" : ""}
          style={{ height: "100%" }}
        >
          <TextField
            sx={sx}
            autoComplete={autoComplete ? "on" : "off"}
            inputRef={(input) => (this.input = input)}
            placeholder={placeholder}
            label={label}
            type={type}
            name={name}
            value={toString(isFocused ? value : blurFormat(value))}
            variant="standard"
            onChange={this.changeHandler}
            onBlur={this.onBlurHandler}
            onFocus={this.onFocusHandler}
            onKeyPress={onKeyPress}
            minRows={minRows}
            maxRows={maxRows}
            error={!!errorText}
            helperText={errorText}
            disabled={!!disabled}
            title={title}
            className={`input-${name}`}
            InputProps={{
              ...this.props.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.props.InputProps
                    ? this.props.InputProps.endAdornment
                    : null}
                  {clearable && value && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        disableRipple
                        onClick={this.clearValue}
                      >
                        <i className="mdi-content-clear" />
                      </IconButton>
                    </InputAdornment>
                  )}
                </React.Fragment>
              )
            }}
            inputProps={{
              ...this.props.inputProps,
              className: inputClassName,
              max: max,
              maxLength: maxLength,
              min: min,
              step: step
            }}
            tabIndex={tabIndex}
            id={controlId}
            style={assign({}, rootStyle, style)}
            multiline={isMultiLine}
          />
        </div>
      </div>
    )
  }
}

export default Input
