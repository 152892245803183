import { mixed, object, string } from "yup"

export const supplierSchema = object({
  name: string().required("required"),
  web_address: string().nullable(),
  image_id: mixed()
    .nullable()
    .transform((image) => image?.id),
  wiki_link: string().nullable().url("url"),
  icon_id: mixed()
    .nullable()
    .transform((image) => image?.id),
  icon_attributes: object({
    text: string().required(),
    background_color: string().required(),
    font_color: string().required()
  })
})

export const getAvatarLetters = (name) => {
  const letters = name
    .split(" ")
    .map((str) => str[0])
    .join("")

  return letters.length > 1 ? letters.substring(0, 2) : name.substring(0, 2)
}
