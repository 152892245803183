import { useState } from "react"

const usePopup = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpen = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return {
    open,
    anchorEl,
    setAnchorEl,
    handleOpen,
    handleClose
  }
}

export default usePopup
