import { createReducer } from "store/helpers/reducers"

const prefix = "jass/statistics"

const LOAD_BUDGET_START = `${prefix}/LOAD_BUDGET_START`
const LOAD_BUDGET_SUCCESS = `${prefix}/LOAD_BUDGET_SUCCESS`
const LOAD_BUDGET_FAIL = `${prefix}/LOAD_BUDGET_FAIL`

export const loadBudget = () => {
  return {
    types: [LOAD_BUDGET_START, LOAD_BUDGET_SUCCESS, LOAD_BUDGET_FAIL],
    promise: (client) => client.get("/statistics/budget.json")
  }
}

export const budgetLoading = createReducer(false, {
  [LOAD_BUDGET_START]: () => true,
  [LOAD_BUDGET_SUCCESS]: () => false,
  [LOAD_BUDGET_FAIL]: () => false
})

export const budget = createReducer(
  {},
  {
    [LOAD_BUDGET_SUCCESS]: (state, action) => action.result || state,
    [LOAD_BUDGET_FAIL]: () => ({})
  }
)
