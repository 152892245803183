import React from "react"
import get from "lodash/get"
import InputComponent from "components/FormControls/Input/Input"
import LinkInputComponent from "components/FormControls/LinkInput/LinkInput"
import CheckboxComponent from "components/Checkbox/Checkbox"
import WysiwygComponent from "components/FormControls/Wysiwyg/Wysiwyg"
import AutocompleteComponent from "components/Autocomplete/Autocomplete"
import RemoteAutocompleteComponent from "components/Autocomplete/RemoteAutocomplete"
import LocalI18nAutocompleteComponent from "components/Autocomplete/LocalI18nAutocomplete"
import PlacesAutocompleteComponent from "components/Autocomplete/GooglePlacesAutocomplete"
import DatePickerComponent from "components/FormControls/DatePicker/DatePicker"
import DateTimePickerComponent from "components/FormControls/DatePicker/DateTimePicker"
import FilePickerComponent from "components/FormControls/FilePicker/FilePicker"
import CompactColorPickerComponent from "components/FormControls/ColorPicker/ColorPicker"
import { I18n } from "react-redux-i18n"
import isNil from "lodash/isNil"
import SwitchComponent from "@mui/material/Switch"

export const createWrapComponent =
  ({ skipBlur = false, getValue = (x) => x } = {}) =>
  (Component) =>
  ({ field, form, ...rest }) => {
    if (!form) {
      throw new Error(`${Component.name} was used outside of the <Formik>`)
    }

    let error =
      (get(form.touched, field.name) || rest.showErrorsPristine) &&
      get(form.errors, field.name)

    if (error instanceof Array) {
      error = error.filter((err) => !!err)[0]
    }

    let errorText = null

    if (!isNil(error)) {
      if (error instanceof Object) {
        errorText = I18n.t(`errors.${error[Object.keys(error)[0]]}`)
      } else {
        errorText = I18n.t(`errors.${error}`)
      }
    }

    const handleBlur = rest.onBlur
      ? (e, ...values) => {
          !rest.skipBlurTouch && field.onBlur(e)
          rest.onBlur(e, ...values)
        }
      : field.onBlur

    const onBlur = (e, ...values) => {
      form.setFieldTouched(field.name)
      !skipBlur && handleBlur(e, ...values)
    }

    const onChange = (...args) => {
      form.setFieldValue(field.name, getValue(...args))
      form.setFieldTouched(field.name)
      rest.onChange && rest.onChange(...args)
    }

    return (
      <Component
        {...field}
        {...rest}
        errors={get(form.errors, field.name)}
        error={error}
        onBlur={onBlur}
        onChange={onChange}
        helperText={errorText}
      />
    )
  }

export const wrapComponent = createWrapComponent()

export const Input = wrapComponent((props) => (
  <InputComponent {...props} debounceTimeout={0} noCollectableText />
))

export const LinkInput = wrapComponent(LinkInputComponent)
export const Wysiwyg = createWrapComponent({ skipBlur: true })(WysiwygComponent)
export const DatePicker = wrapComponent(DatePickerComponent)
export const DateTimePicker = wrapComponent(DateTimePickerComponent)
export const FilePicker = wrapComponent(FilePickerComponent)
export const CompactColorPicker = wrapComponent(CompactColorPickerComponent)

export const Autocomplete = wrapComponent(AutocompleteComponent)
export const RemoteAutocomplete = wrapComponent(RemoteAutocompleteComponent)
export const LocalI18nAutocomplete = wrapComponent(
  LocalI18nAutocompleteComponent
)
export const GooglePlacesAutocomplete = wrapComponent(
  PlacesAutocompleteComponent
)

export const Switch = ({ form: _form, field, ...rest }) => {
  return (
    <SwitchComponent
      {...rest}
      checked={field.value}
      name={field.name}
      color="primary"
      onChange={(e) => {
        field.onChange(field.name)(e)
        rest.onChange && rest.onChange(e.target.checked)
      }}
    />
  )
}

export const Checkbox = ({ form: _form, field, ...rest }) => {
  return (
    <CheckboxComponent
      {...rest}
      checked={field.value}
      name={field.name}
      onChange={(e) => {
        field.onChange(field.name)(e)
        rest.onChange && rest.onChange(e.target.checked)
      }}
    />
  )
}

export { default as NumberInput } from "./NubmerInput"
