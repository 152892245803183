import React from "react"
import PropTypes from "prop-types"
import Translate from "components/Translate/Translate"

const Label = ({ value, i18n = true, required = false }) => {
  return (
    <React.Fragment>
      {i18n ? <Translate value={value} /> : value}
      {required ? " *" : null}
    </React.Fragment>
  )
}

Label.propTypes = {
  i18n: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired
}

export default Label
