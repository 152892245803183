import map from "lodash/map"
import max from "lodash/max"
import upperFirst from "lodash/upperFirst"

export function is(user, role) {
  return user?.[`is${upperFirst(role)}`] ?? false
}

export function hasAnyOfRoles(user, roles) {
  return !!max(map(roles, (role) => is(user, role)))
}

export function includesAnyOfRoles(user, roles) {
  return !!max(map(roles, (role) => user.roles.includes(role)))
}
