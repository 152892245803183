import React from "react"
import {
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography
} from "@mui/material"

export const BorderlessLinearProgressWithLabel = ({ color, value, label }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <LinearProgress
        sx={{
          flexGrow: 1,
          backgroundColor: "transparent",
          height: 10,
          borderRadius: 5,
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: color
          }
        }}
        variant="determinate"
        value={value}
        color="primary"
      />
      <Typography variant="h6" color="textSecondary">
        {label}
      </Typography>
    </Stack>
  )
}
