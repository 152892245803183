import React, { memo } from "react"

import { Translate as BaseTranslate } from "react-redux-i18n"

// By default, react-i18nify (used under the hood by react-redux-i18n)
// renders <span>{your translation here }</span>. If you want to render only
// translation without any tag wrapper then use <Translate withoutTag />
const WITHOUT_TAG_RENDER_FN = ({ children: translation }) => translation

const Translate = (props) => {
  const tag = props.withoutTag ? WITHOUT_TAG_RENDER_FN : props.tag

  return <BaseTranslate {...props} tag={tag} />
}

export default memo(Translate)
