import React from "react"
import LinearProgress from "@mui/material/LinearProgress"
import { Box } from "@mui/material"

const Loader = (props) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1302,
        width: "100%"
      }}
    >
      <LinearProgress color="primary" {...props} />
    </Box>
  )
}

export default Loader
