import React from "react"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"

const useLabelStyles = makeStyles({
  asterisk: { color: "red" },
  root: {
    fontWeight: "bold",
    color: "black",
    fontSize: 13,
    transform: "translate(5px, 0) !important"
  }
})

const useInputStyles = makeStyles(
  createStyles((theme) => ({
    root: {
      backgroundColor: ({ filled }) => (filled ? "#fbfbfb" : "white"),
      "&.Mui-disabled": {
        backgroundColor: "rgb(249, 249, 249)"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      }
    },
    input: {
      padding: "20px 20px !important",
      fontSize: 12
    },
    inputAdornedStart: {
      paddingLeft: "0 !important"
    },
    disabled: {},
    multiline: ({ textareaPaddingBottom }) => ({
      padding: `0 0 ${textareaPaddingBottom}px 0 !important`,
      height: "100%"
    }),
    notchedOutline: {
      borderColor: "white",
      boxShadow: "0px 0px 5px 1px rgb(225, 225, 225) !important",
      "& legend": { width: "0 !important" }
    }
  }))
)

const useTextFieldStyles = makeStyles({
  root: { marginBottom: 20, paddingTop: 25 }
})

const Input = ({
  onChange,
  error = "",
  textareaPaddingBottom = 0,
  filled = false,
  textFieldClasses: textFieldClassesFromProps = {},
  ...props
}) => {
  const labelClasses = useLabelStyles()
  const textFieldClasses = useTextFieldStyles()
  const inputClasses = useInputStyles({
    filled,
    color: props.color,
    textareaPaddingBottom
  })

  return (
    <TextField
      {...props}
      id={props.id ?? props.name}
      error={!!error}
      variant="outlined"
      classes={{
        ...textFieldClasses,
        ...textFieldClassesFromProps?.classes
      }}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        ...props.InputProps,
        classes: {
          ...inputClasses,
          ...props.InputProps?.classes
        }
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true,
        classes: {
          ...labelClasses,
          ...props.InputLabelProps?.classes
        }
      }}
    />
  )
}

export default Input
