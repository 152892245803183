import { createReducer } from "store/helpers/reducers"
import { combineReducers } from "redux"
import merge from "lodash/merge"

const prefix = "jass/SALES_PRODUCTS"

const LOAD_START = `${prefix}/LOAD_START`
const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`
const LOAD_FAIL = `${prefix}/LOAD_FAIL`
const SET_PARAMS = `${prefix}/SET_PARAMS`
const UPDATE_START = `${prefix}/UPDATE_START`
const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
const UPDATE_FAIL = `${prefix}/UPDATE_FAIL`
const SET_ERRORS = `${prefix}/SET_ERRORS`
const CLEAR_ERROR = `${prefix}/CLEAR_ERROR`
const ROLLBACK_START = `${prefix}/ROLLBACK_START`
const ROLLBACK_SUCCESS = `${prefix}/ROLLBACK_SUCCESS`
const ROLLBACK_FAIL = `${prefix}/ROLLBACK_FAIL`

export default combineReducers({
  list: combineReducers({
    loading: createReducer(false, {
      [LOAD_START]: () => true,
      [LOAD_SUCCESS]: () => false,
      [LOAD_FAIL]: () => false
    }),
    items: createReducer([], {
      [LOAD_SUCCESS]: (state, action) => {
        if (action.params.page > 1) {
          return [...state, ...action.result]
        }

        return action.result.items
      },
      [UPDATE_SUCCESS]: (state, action) => {
        return state.map((product) =>
          product.id === action.payload.product.id
            ? action.payload.product
            : product
        )
      },
      [ROLLBACK_SUCCESS]: (state, action) => {
        return state.map((product) =>
          product.id === action.result.id ? action.result : product
        )
      }
    }),
    errors: createReducer([], {
      [SET_ERRORS]: (_state, action) => action.errors,
      [CLEAR_ERROR]: (state, action) => {
        return state.map((productError) =>
          productError.id === action.payload.id
            ? {
                ...productError,
                errors: {
                  ...productError.errors,
                  [action.payload.columnKey]: undefined
                }
              }
            : productError
        )
      }
    }),
    count: createReducer(0, {
      [LOAD_SUCCESS]: (state, action) =>
        "count" in action.result ? action.result.count : state
    }),
    params: createReducer(
      {},
      {
        [LOAD_SUCCESS]: (state, action) => action.params,
        [SET_PARAMS]: (state, action) => merge({}, state, action.params)
      }
    )
  })
})

export const setErrors = (errors) => ({ type: SET_ERRORS, errors })
export const clearError = (id, columnKey) => ({
  type: CLEAR_ERROR,
  payload: { id, columnKey }
})

export const setSearchParams = (params) => {
  return {
    type: SET_PARAMS,
    params: { additional_search: params }
  }
}

export const load = (options) => ({
  types: [LOAD_START, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.getHybrid("sales_products", options)
})

export function update(product) {
  return {
    types: [UPDATE_START, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) =>
      client.put(`drafts/sales_products/${product.id}.json`, {
        params: {
          sales_product: { draft_values: product.draft_values }
        }
      }),
    payload: { product }
  }
}

export function rollback(id, columnKey) {
  return {
    types: [ROLLBACK_START, ROLLBACK_SUCCESS, ROLLBACK_FAIL],
    promise: (client) =>
      client.put(`drafts/rollback_sales_product_changes/${id}.json`, {
        params: {
          column_key: columnKey
        }
      })
  }
}
