import React, { Component } from "react"
import classNames from "classnames"
import { CompactPicker } from "react-color"

export default class CustomCompactColorPicker extends Component {
  state = {
    displayColorPicker: false
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange = (color) => {
    this.props.onChange(color.hex)
  }

  render() {
    const { label, value, displayTop, className } = this.props
    const containerClass = classNames(
      "fieldContainer",
      "custom-colorpicker",
      className
    )

    return (
      <div className={containerClass}>
        <div className="label-wrapper">
          <label className="color-picker-label">{label}</label>
        </div>
        <div className="colorpicker-swatch" onClick={this.handleClick}>
          <div className="colorpicker-color" style={{ background: value }} />
        </div>
        {this.state.displayColorPicker ? (
          <div
            className={classNames("colorpicker-popover", {
              "colorpicker-top": displayTop
            })}
          >
            <div className="colorpicker-cover" onClick={this.handleClose} />
            <CompactPicker
              {...this.props}
              color={value}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    )
  }
}
