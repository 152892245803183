import { I18n } from "react-redux-i18n"

export const formatWebShopOrderStates = (items) =>
  items?.map((item) => ({
    name: I18n.t(`web-shop.job-ads-table.states.${item}`),
    id: item
  })) ?? []

export const getAdEditorUrl = (file, path, orderId) => {
  let url
  const { id, ad_content, sales_product_id } = file

  if (ad_content) {
    if (ad_content.values?.custom_ad_design_id) {
      url = `${path}/custom-design-ad-editor/${orderId}/${id}`
    } else {
      url = `${path}/ad-editor/${orderId}/${id}`
    }
  } else if (sales_product_id) {
    url = `${path}/external-ad-editor/order-sales-product-texts/${id}`
  } else {
    url = `${path}/external-ad-editor/order-texts/${id}`
  }

  return url
}

/** @param {string} id */
export const findSalaryIntervalValue = (id) => {
  if (!id) {
    return null
  }

  return {
    id,
    name: I18n.t(`webshop.vacancy.salary.intervals.${id}`)
  }
}

/** @param {{id: number; title: string}} data */
export const getDropdownListAutocompleteValue = (data) => {
  if (!data) {
    return null
  }

  return {
    id: data.id,
    name: data.title
  }
}
