import { getIn } from "formik"
import React from "react"
import { FilesDropzone as JBFilesDropzone } from "shared/ui/berry-jass"
import { getErrorText } from "./helpers"

const fieldToFilePicker = ({
  disabled,
  field,
  onChange = null,
  form: {
    values,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    setFieldTouched
  },
  ...props
}) => {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  const {
    onChange: _onChange,
    onBlur: _onBlur,
    multiple: _multiple,
    ...fieldSubselection
  } = field

  return {
    error: showError,
    helperText: showError ? getErrorText(fieldError) : null,
    disabled: disabled ?? isSubmitting,
    onUploadStart: () => {
      setSubmitting(true)
    },
    onUploadSettled: () => {
      setSubmitting(false)
    },
    onDeleteSuccess: (file) => {
      if (props.multiple) {
        setFieldValue(
          field.name,
          values[field.name].filter((f) => f.id !== file.id)
        )
      } else {
        setFieldValue(field.name, null)
      }
    },
    onChange: function handleChange(value) {
      if (onChange) {
        onChange(value)
      }

      if (props.multiple) {
        setFieldValue(field.name, [...values[field.name], ...value])
      } else {
        setFieldValue(field.name, value[0])
      }
      setFieldTouched(field.name)
    },
    ...fieldSubselection,
    ...props
  }
}

export const FilesDropzone = (props) => {
  return <JBFilesDropzone {...fieldToFilePicker(props)} />
}
