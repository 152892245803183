import React, { useCallback } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import classNames from "classnames"
import { alpha, useControlled, inputLabelClasses } from "@mui/material"
import Box from "@mui/material/Box"

const useFieldStyles = makeStyles({
  root: {
    height: 18,
    width: 37,
    fontSize: 14,
    color: "#2c2c2c",
    marginBottom: 4,
    "& > input": {
      padding: "0 !important",
      marginTop: 4
    }
  },
  input: {
    textAlign: "center",
    height: 18,
    backgroundColor: "#ededed",
    lineHeight: 14,
    fontWeight: 500
  },
  notchedOutline: {
    border: "none"
  }
})

const NumberField = ({
  name = null,
  label,
  value,
  onChange,
  disabled = false
}) => {
  const classes = useFieldStyles()

  return (
    <TextField
      sx={{
        padding: 0,
        margin: 0,
        [`& .${inputLabelClasses.shrink}`]: {
          transform: "translate(-18px, -18px) scale(0.75)",
          maxWidth: "unset"
        }
      }}
      name={name}
      label={label}
      disabled={disabled}
      InputProps={{ classes: classes }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
    />
  )
}

const useButtonStyles = makeStyles({
  root: {
    minWidth: "unset",
    padding: 0,
    width: 18,
    height: 18,
    backgroundColor: "#a2a2a2",
    color: "white",
    margin: 0,
    "&:hover": {
      backgroundColor: alpha("#a2a2a2", 0.8)
    }
  },
  primary: {
    backgroundColor: "#ff9400",
    "&:hover": {
      backgroundColor: alpha("#ff9400", 0.8)
    }
  }
})

const NumberButton = ({
  id,
  color = "primary",
  children,
  disabled = false,
  onClick
}) => {
  const classes = useButtonStyles()
  return (
    <Button
      id={id}
      size="small"
      className={classNames(classes.root, {
        [classes.primary]: color === "primary"
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

const useStyles = makeStyles({
  disabled: { opacity: 0.3 },
  error: {
    border: "2px solid red",
    marginLeft: "auto",
    marginRight: 0
  },
  errorText: {
    color: "red",
    fontSize: 12
  }
})

const NumberInput = ({
  min = -Infinity,
  max = Infinity,
  step = 1,
  value: valueProp,
  defaultValue = 0,
  onChange = null,
  name = null,
  onClick = null,
  label = null,
  forceControlled = false,
  disabled = false,
  error = "",
  helperText = ""
}) => {
  const classes = useStyles()
  const [value, setValue] = useControlled({
    controlled: forceControlled ? valueProp ?? defaultValue : valueProp,
    default: defaultValue,
    name: "NumberInput"
  })

  const changeValue = useCallback(
    (newVal) => {
      let val = parseInt(newVal, 10)
      if (val > max || val < min) {
        val = defaultValue
      }

      // @ts-ignore
      setValue(val)
      onChange?.(val)
    },
    [defaultValue, max, min, onChange, setValue]
  )

  const handleValueReduce = useCallback(() => {
    if (value - step >= min) {
      changeValue(parseInt(value, 10) - step)
    }
  }, [changeValue, min, step, value])

  const handleValueIncrease = useCallback(() => {
    if (value + step <= max) {
      changeValue(parseInt(value, 10) + step)
    }
  }, [changeValue, max, step, value])

  return (
    <Box
      display="grid"
      alignItems="center"
      onClick={onClick}
      className={classNames({
        [classes.disabled]: disabled
      })}
    >
      <div className={classNames({ [classes.error]: !!error })}>
        <NumberButton
          id={`${name}-decrease-button`}
          color="secondary"
          disabled={disabled}
          onClick={handleValueReduce}
        >
          -
        </NumberButton>
        <NumberField
          disabled={disabled}
          label={label}
          name={name}
          value={value}
          onChange={changeValue}
        />
        <NumberButton
          id={`${name}-increase-button`}
          color="primary"
          disabled={disabled}
          onClick={handleValueIncrease}
        >
          +
        </NumberButton>
      </div>
      <div className={classes.errorText}>{helperText}</div>
    </Box>
  )
}

export default NumberInput
